import * as React from "react";

function Speech({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M15 4a11 11 0 00-9.46 16.6L5 24.88a1 1 0 00.24.78 1 1 0 001 .31l4.08-1A11 11 0 1015 4zm0 20a8.91 8.91 0 01-4.11-1 1 1 0 00-.45-.11 1 1 0 00-.25 0l-3 .76.4-3.18a1 1 0 00-.15-.66A9 9 0 1115 24zm0 0" />
		</svg>
	);
}

const MemoSpeech = React.memo(Speech);
export default MemoSpeech;
