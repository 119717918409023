import * as React from "react";

function CameraOff({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M28.7 27.36L2.64 1.3a1.83 1.83 0 00-1-.53h-.18A.7.7 0 001 1a.79.79 0 00-.18.7 1.83 1.83 0 00.53 1l26.01 26a1.83 1.83 0 001 .53.71.71 0 00.88-.88 1.83 1.83 0 00-.54-.99zM19.87 21.88l-1.7-1.7v2.17a1.85 1.85 0 01-1.86 1.85h-11a1.85 1.85 0 01-1.85-1.85V10.46A1.85 1.85 0 015.29 8.6h1.3L4.9 6.91a3.58 3.58 0 00-3.2 3.55v11.89a3.6 3.6 0 003.59 3.59h11a3.59 3.59 0 003.56-3.28zM27.28 7a.87.87 0 00-.87 0l-6.51 3.6v-.14a3.59 3.59 0 00-3.59-3.59H9l1.69 1.73h5.62a1.86 1.86 0 011.86 1.86v5.63l1.73 1.73v-5.24L26 9.21v14.66l1.66 1.66a.92.92 0 00.11-.43V7.74a.86.86 0 00-.49-.74z" />
		</svg>
	);
}

const MemoCameraOff = React.memo(CameraOff);
export default MemoCameraOff;
