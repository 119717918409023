import React from "react";
import BaseModal, { OK, CANCEL } from "./BaseModal";

import { Chat } from "../../assets/icons";

import constants from "../../store/constants";

import ServerImage from "../controls/ServerImage";

import { useDispatch } from "react-redux";

import { sendMessage } from "pubnub-redux";

import { loggingActions } from "../../store/logging";
import { chatActions } from "../../store/chat";
// eslint-disable-next-line no-unused-vars
const ChatRequestModal = ({ notification, me, callback, t }) => {
	const dispatch = useDispatch();

	const handleAccept = () => {
		const requesterId = notification.from.uuid; // eslint-disable-line no-unused-vars

		// chatActions.removeChatRequest(requesterId);
		// setChatRequest(false);

		dispatch(
			sendMessage({
				channel: `${notification.from.uuid}--private`,
				message: {
					type: constants.MESSAGE_TYPE.CHAT_ACCEPT,
				},
			})
		);

		dispatch(
			chatActions.acceptChat(notification.from.uuid, notification.from.avatar)
		);

		callback(notification.id, false);
	};

	const handleVCard = () => {
		dispatch(
			sendMessage({
				channel: `${notification.from.uuid}--private`,
				message: {
					type: constants.MESSAGE_TYPE.CHAT_DECLINE,
					message: { vCard: true },
				},
			})
		);
		dispatch(
			loggingActions.logActivity(
				constants.ACTIVITY.CHAT_DECLINED,
				notification.from.uuid
			)
		);

		callback(notification.id, false);

		// goodieBagActions.addItem(requesterId, user.identity.id);
		// pubnub.publish({
		// 	channel: requesterId + "--private",
		// 	message: {
		// 		type: MESSAGE_TYPE.CHAT_DECLINE,
		// 		from: user.identity.name,
		// 		id: user.identity.id,
		// 		vcard: true,
		// 	},
		// });

		callback(notification.id, false);
	};

	const handleDecline = () => {
		const requesterId = notification.from.uuid; // eslint-disable-line no-unused-vars

		dispatch(
			sendMessage({
				channel: `${notification.from.uuid}--private`,
				message: {
					type: constants.MESSAGE_TYPE.CHAT_DECLINE,
					message: { vCard: false },
				},
			})
		);
		dispatch(
			loggingActions.logActivity(
				constants.ACTIVITY.CHAT_DECLINED,
				notification.from.uuid
			)
		);
		callback(notification.id, false);
	};

	const handleIgnore = () => {
		callback(notification.id, true);
	};

	return (
		<BaseModal
			showClose={true}
			buttons={OK | CANCEL}
			icon={Chat}
			confirmCallback={handleAccept}
			confirmText={t("global.accept", "Accept")}
			cancelCallback={handleDecline}
			closeCallback={handleIgnore}
			cancelText={t("global.discard", "Discard")}
			size='xl'
			extraButtons={() => (
				<button
					className='font-semibold uppercase rounded text-white bg-customGray hover:bg-customGray-interact w-full flex-grow py-3'
					onClick={(e) => {
						e.preventDefault();
						handleVCard();
					}}>
					{t("base.chat_share_vcard", "Share vCard")}
				</button>
			)}>
			<h1 className='text-3xl font-semibold mb-4'>
				{notification.from.name}{" "}
				{t("base.chat_request", "would like to chat with you")}
			</h1>

			<figure className='text-center'>
				<ServerImage
					className='rounded-full overflow-hidden w-24 h-24 mx-auto mb-3'
					src={notification.from.avatar}
				/>
				<figcaption>
					<span className='block font-semibold'>{notification.from.role}</span>
					<span className='block '>{notification.from.company}</span>
				</figcaption>
			</figure>
		</BaseModal>
	);
};

export default ChatRequestModal;
