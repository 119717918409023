import * as React from "react";

function Loading({ className = "", ...rest }) {
	return (
		<svg viewBox="0 0 50 50" className={`fill-current ${className}`} {...rest}>
			<path d="M29.42 13.81a3.7 3.7 0 01-7.37 0 3.7 3.7 0 017.37 0zm0 0M28 37.56a2.22 2.22 0 11-2.22-2.05A2.14 2.14 0 0128 37.56zm0 0M20 17.28a3.2 3.2 0 01-3.31 3.08 3.2 3.2 0 01-3.31-3.08 3.2 3.2 0 013.31-3.07A3.2 3.2 0 0120 17.28zm0 0M36.62 34.08a1.84 1.84 0 11-1.84-1.71 1.79 1.79 0 011.84 1.71zm0 0M15.89 25.69A2.85 2.85 0 0113 28.42 2.74 2.74 0 1113 23a2.85 2.85 0 012.94 2.74zm0 0M40 25.68a1.48 1.48 0 11-1.48-1.36A1.42 1.42 0 0140 25.68zm0 0M14.87 32.39a2.28 2.28 0 000 3.39 2.72 2.72 0 003.64 0 2.28 2.28 0 000-3.39 2.7 2.7 0 00-3.64 0zm0 0M35.88 17.28a1.1 1.1 0 11-1.1-1 1.06 1.06 0 011.1 1zm0 0" />
		</svg>
	);
}

const MemoLoading = React.memo(Loading);
export default MemoLoading;
