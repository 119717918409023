import constants from "../constants";

const defaultState = {
	loadState: constants.EVENT_LOAD_STATE.UNLOADED,
	details: {},
	eventCode: "",
	eventType: "",
	inviteCode: "",
	startIn: 0,
	isHosted: false,
	showVideoOnStart: true,
	identities: {
		byId: {},
	},
};

const eventReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_EVENT_LOADSTATE:
			return {
				...state,
				loadState: action.payload,
			};
		case constants.SET_IDENTITIES:
			return {
				...state,
				identities: {
					byId: {
						...action.payload,
					},
				},
			};
		case constants.ADD_IDENTITY:
			/*	
			const identities = {
				...state.identities,
			};
			*/
			//identities.byId[action.payload.uuid] = action.payload.state;
			// console.log("REDUCING STATE", action.payload.uuid, action.payload.state);
			return {
				...state,
				identities: {
					byId: {
						...state.identities.byId,
						[action.payload.uuid]: action.payload.state,
					},
				},
			};
		case constants.SET_EVENTCODE:
			return {
				...state,
				eventCode: action.payload,
			};
		case constants.SET_EVENTTYPE:
			return {
				...state,
				eventType: action.payload,
			};
		case constants.SET_INVITECODE:
			return {
				...state,
				inviteCode: action.payload,
			};
		case constants.SET_STARTIN:
			return {
				...state,
				startIn: action.payload,
			};
		case constants.SET_IS_HOSTED:
			return {
				...state,
				isHosted: action.payload,
			};
		case constants.SET_SHOW_VIDEO_ON_START:
			return {
				...state,
				showVideoOnStart: action.payload,
			};
		case constants.default:
			return state;
		default:
			return state;
	}
};

export default eventReducer;
