import React, { useState, useEffect, useCallback, useMemo } from "react";

import { AgoraVideoPlayer } from "../../libs/AgoraWrapper";
import MiniChat from "../chat/MiniChat";
import Logo from "../../assets/logo";

import { useSelector, useDispatch } from "react-redux";

import constants from "../../store/constants";
import { callSelectors } from "../../store/call";
import { Camera, CameraOff, Exit } from "../../assets/icons";
// import CallControls from "./CallControls";

// const CameraView = () => {
// 	const client = window.useLiveClient();
// 	const dispatch = useDispatch();
// }
// const ScreenView = () => {

// }

const CallWindowSubscriber = ({
	hideCallWindow,
	channelName,
	hasChat,
	chatChannel,
	uid,
	token,
	other,
	t,
}) => {
	// using the hook to get access to the client object
	const client = window.useLiveClient();
	const clientScreen = window.useLiveClient2();
	const dispatch = useDispatch();

	const messageFormatter = useCallback(
		(userId, username, message) => {
			return {
				type: "QUESTION",
				payload: {
					from: username,
					channel: chatChannel,
					text: message,
				},
			};
		},
		[chatChannel]
	);

	// ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null
	// const { ready, tracks } = window.useMicrophoneAndCameraTracks();

	const [users, setUsers] = useState([]);
	const [screens, setScreens] = useState([]);

	const [start, setStart] = useState(false);

	const [speakerVisible, setSpeakerVisible] = useState(true);
	const [speakerPosition, setSpeakerPosition] = useState(0);

	const speakerStyle = useMemo(() => {
		let style = speakerVisible ? "visible" : "invisible";

		switch (speakerPosition) {
			case 0:
				style += " bottom-4 right-4";
				break;
			case 1:
				style += " bottom-4 left-4";
				break;
			case 2:
				style += " top-4 left-4";
				break;
			case 3:
				style += " top-4 right-4";
				break;
			default:
				break;
		}
		return style;
	}, [speakerPosition, speakerVisible]);

	const appId = useSelector(callSelectors.getCallAppId);
	const [mainToken, screenshareToken] = token.split("||");
	useEffect(() => {
		console.log("users", users);
	}, [users]);
	const leaveChannel = useCallback(async () => {
		await client.leave();
		await clientScreen.leave();

		client.removeAllListeners();
		clientScreen.removeAllListeners();

		setStart(false);

		hideCallWindow();
	}, [client, clientScreen, hideCallWindow]);

	const clientPublished = useCallback(
		async (user, mediaType) => {
			await client.subscribe(user, mediaType);
			console.log("subscribe success", mediaType);
			if (mediaType === "video") {
				setUsers((prevUsers) => {
					return [...prevUsers, user];
				});
			}
			if (mediaType === "audio") {
				user.audioTrack?.play();
			}
		},
		[client]
	);

	const clientUnpublished = useCallback((user, type) => {
		console.log("unpublished", user, type);
		if (type === "audio") {
			user.audioTrack?.stop();
		}
		if (type === "video") {
			setUsers((prevUsers) => {
				return prevUsers.filter((User) => User.uid !== user.uid);
			});
		}
	}, []);

	const clientLeft = useCallback((user) => {
		console.log("leaving", user);
		setUsers((prevUsers) => {
			return prevUsers.filter((User) => User.uid !== user.uid);
		});
	}, []);

	const clientScreenPublished = useCallback(
		async (screen, mediaType) => {
			await clientScreen.subscribe(screen, mediaType);
			console.log("subscribe success", mediaType);
			if (mediaType === "video") {
				setScreens((prevScreens) => {
					return [...prevScreens, screen];
				});
			}
			// Won't happen but leaving it in here
			if (mediaType === "audio") {
				screen.audioTrack?.play();
			}
		},
		[clientScreen]
	);
	const clientScreenUnpublished = useCallback((screen, type) => {
		console.log("unpublished", screen, type);
		if (type === "audio") {
			screen.audioTrack?.stop();
		}
		if (type === "video") {
			setScreens((prevScreens) => {
				return prevScreens.filter((Sser) => Screen.uid !== screen.uid);
			});
		}
	}, []);

	const clientScreenLeft = useCallback((screen) => {
		console.log("leaving", screen);
		setScreens((prevScreens) => {
			return prevScreens.filter((Screen) => Screen.uid !== screen.uid);
		});
	}, []);

	useEffect(() => {
		// function to initialise the SDK
		let init = async (name) => {
			const clientPublished = async (user, mediaType) => {
				await client.subscribe(user, mediaType);
				console.log("subscribe success", mediaType);
				if (mediaType === "video") {
					setUsers((prevUsers) => {
						return [...prevUsers, user];
					});
				}
				if (mediaType === "audio") {
					user.audioTrack?.play();
				}
			};
			const clientUnpublished = (user, type) => {
				console.log("unpublished", user, type);
				if (type === "audio") {
					user.audioTrack?.stop();
				}
				if (type === "video") {
					setUsers((prevUsers) => {
						return prevUsers.filter((User) => User.uid !== user.uid);
					});
				}
			};

			const clientLeft = (user) => {
				console.log("leaving", user);
				setUsers((prevUsers) => {
					return prevUsers.filter((User) => User.uid !== user.uid);
				});
			};

			const clientScreenPublished = async (screen, mediaType) => {
				await clientScreen.subscribe(screen, mediaType);
				console.log("subscribe success", mediaType);
				if (mediaType === "video") {
					setScreens((prevScreens) => {
						return [...prevScreens, screen];
					});
				}
				// Won't happen but leaving it in here
				if (mediaType === "audio") {
					screen.audioTrack?.play();
				}
			};
			const clientScreenUnpublished = (screen, type) => {
				console.log("unpublished", screen, type);
				if (type === "audio") {
					screen.audioTrack?.stop();
				}
				if (type === "video") {
					setScreens((prevScreens) => {
						return prevScreens.filter((Sser) => Screen.uid !== screen.uid);
					});
				}
			};

			const clientScreenLeft = (screen) => {
				console.log("leaving", screen);
				setScreens((prevScreens) => {
					return prevScreens.filter((Screen) => Screen.uid !== screen.uid);
				});
			};
			client.on("user-published", clientPublished);
			client.on("user-unpublished", clientUnpublished);
			client.on("user-left", clientLeft);

			clientScreen.on("user-published", clientScreenPublished);
			clientScreen.on("user-unpublished", clientScreenUnpublished);
			clientScreen.on("user-left", clientScreenLeft);

			await client.setClientRole("audience");
			await clientScreen.setClientRole("audience");

			const joinResult = await client
				.join(appId, name, mainToken, uid)
				.catch((error) => {
					console.log("Something went wrong while starting the call", error);
				});
			await clientScreen
				.join(appId, name + "-screen", screenshareToken, uid)
				.catch((error) => {
					console.log("Something went wrong while starting the call", error);
				});
			if (typeof joinResult === "undefined") {
			}

			console.log("CALL JOINED", joinResult);

			dispatch({
				type: constants.CALL_JOINED,
			});

			// Make sure the video is on
			//await tracks[1].setEnabled(true);

			//if (tracks) await client.publish([tracks[0], tracks[1]]);
			setStart(true);
		};

		init(channelName);
	}, [
		dispatch,
		channelName,
		client,
		clientScreen,
		//ready,
		//tracks,
		appId,
		// token,
		uid,
		leaveChannel,
		clientLeft,
		clientPublished,
		clientUnpublished,
		clientScreenLeft,
		clientScreenPublished,
		clientScreenUnpublished,
		mainToken,
		screenshareToken,
	]);

	//const [timeLeft, setTimeLeft] = useState(150);
	/*
	React.useEffect(() => {
		let to;
		if (timeLeft > 0) to = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
		if (timeLeft === 0) leaveChannel();
		return () => clearTimeout(to);
	}, [timeLeft, leaveChannel]);
*/
	const alone = useMemo(() => users.length === 0, [users]);

	// const setVolume = (volume) => {
	// 	for (let i = 0, l = users.length; i < l; i++) {
	// 		users[i].audioTrack.setVolume(volume);
	// 	}
	// };

	const messageFilter = useCallback(
		(m) => m.type === "QUESTION" && m.payload.channel === chatChannel,
		[chatChannel]
	);

	return (
		<div className='fixed w-screen h-screen p-10 left-0 top-0 z-40'>
			<div className={`py-10 bg-white rounded-lg w-full h-full flex`}>
				<div className='w-2/6 px-10 flex flex-col space-y-10 h-full'>
					<Logo className='w-1/2 h-auto flex-shrink-0 max-w-sm' />

					<h1 className='font-semibold text-3xl mt-10'>
						{t("base.call_expert_title", "Ask the expert")}
					</h1>
					{hasChat && (
						<>
							{/* <button className="text-white bg-black rounded-sm px-5 py-2 flex items-center">
						<Card className="mr-2" /> Send vcard
					</button> */}
							<MiniChat
								channelName='qa'
								className='border rounded-lg p-4 flex-1'
								messageFormatter={messageFormatter}
								messageFilter={messageFilter}
								title={t("base.expert_chat_title", "Ask a question")}
							/>
						</>
					)}
				</div>
				{start && (
					<>
						<div className='w-4/6 mr-10 flex flex-col'>
							<div className='flex-grow bg-gray-200 rounded-sm overflow-hidden relative'>
								{alone && (
									<div className='absolute bg-white p-4 rounded top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
										{t(
											"base.call_no_expert",
											"The expert is not broadcasting."
										)}
									</div>
								)}

								{screens.length > 0 &&
									screens.map((screen, idx) => {
										if (screen.videoTrack) {
											return (
												<AgoraVideoPlayer
													className='w-full h-full'
													videoTrack={screen.videoTrack}
													key={screen.uid}
												/>
											);
										} else return null;
									})}

								{users.length > 0 &&
									users.map((user, idx) => {
										if (user.videoTrack) {
											return (
												<AgoraVideoPlayer
													style={{
														aspectRatio: "16 / 9",
														pointerEvents: "none",
													}}
													className={
														screens.length > 0
															? `absolute ${speakerStyle} w-1/4 aspect-video`
															: "w-full h-full"
													}
													videoTrack={user.videoTrack}
													key={user.uid}
												/>
											);
										} else return null;
									})}
							</div>
							<div className='flex justify-between'>
								<div />
								<div className='text-center mt-2'>
									<button
										className='rounded-full bg-primary text-white w-14 h-14 flex justify-center items-center text-3xl mb-1 mx-auto'
										onClick={() => leaveChannel()}>
										<Exit className='text-3xl' />
									</button>
									<span className='text-xs'>{t("global.exit", "Exit")}</span>
								</div>

								<div className='flex space-x-4'>
									{screens.length > 0 && (
										<>
											<div className='relative mt-2 text-center'>
												<button
													className='rounded-full bg-trueGray-300 text-white w-14 h-14 flex justify-center items-center text-3xl mb-1 mx-auto'
													onClick={() => setSpeakerVisible(!speakerVisible)}>
													{!speakerVisible && <CameraOff />}
													{speakerVisible && <Camera />}
												</button>
												<span className='text-xs'>
													{t("base.call_camera", "Camera")}
												</span>
											</div>
											<div className='relative mt-2 text-center'>
												<button
													className='rounded-full bg-trueGray-300 text-white w-14 h-14 flex justify-center items-center text-3xl mb-1 mx-auto'
													onClick={() =>
														setSpeakerPosition(
															speakerPosition < 3 ? speakerPosition + 1 : 0
														)
													}>
													<div className='grid grid-cols-2 grid-cols-row gap-1'>
														<div
															className={`w-2 h-2 border-white border rounded-sm ${
																speakerPosition === 2 ? "bg-white" : ""
															}`}
														/>
														<div
															className={`w-2 h-2 border-white border rounded-sm ${
																speakerPosition === 3 ? "bg-white" : ""
															}`}
														/>
														<div
															className={`w-2 h-2 border-white border rounded-sm ${
																speakerPosition === 1 ? "bg-white" : ""
															}`}
														/>
														<div
															className={`w-2 h-2 border-white border rounded-sm ${
																speakerPosition === 0 ? "bg-white" : ""
															}`}
														/>
													</div>
												</button>
												<span className='text-xs'>
													{t("base.call_position", "Position")}
												</span>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default React.memo(CallWindowSubscriber);
