import * as React from "react";

function MicrophoneOff({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M22.35 20.39a10 10 0 001.53-5.32v-1a1 1 0 00-2 0v1a7.93 7.93 0 01-1 3.85zM9.88 7.92v-.85a4 4 0 118 0v8a3.87 3.87 0 01-.08.77l1.6 1.59a6 6 0 00.48-2.36v-8A6 6 0 008 6zM16.88 27.07h-2V25a10 10 0 005.58-2.43l-1.41-1.42a8 8 0 01-13.17-6.1v-1a1 1 0 00-2 0v1a10 10 0 009 10v2.05h-2a1 1 0 000 2h6a1 1 0 100-2z" />
			<path d="M13.88 21.07a5.94 5.94 0 003.75-1.32l-1.43-1.43a4 4 0 01-6.32-3.25V12l-2-2v5.06a6 6 0 006 6.01zM28.7 27.36L2.64 1.3a1.83 1.83 0 00-1-.53h-.18A.7.7 0 001 1a.79.79 0 00-.18.7 1.83 1.83 0 00.53 1l26.01 26a1.83 1.83 0 001 .53.71.71 0 00.88-.88 1.83 1.83 0 00-.54-.99z" />
		</svg>
	);
}

const MemoMicrophoneOff = React.memo(MicrophoneOff);
export default MemoMicrophoneOff;
