import constants from "../constants";
// import { fetchHereNow, fetchChannelMembers } from "pubnub-redux";
import axios from "axios";
import { authHeader } from "../../helpers";
import { camelCased } from "../../components/helpers/TextHelpers";

export const handleStateChange = (presenceEvent) => {
	return function handleStateChangeAsync(dispatch, getState, context) {
		// Check if alreayd set
		const attendees = getState().event.identities.byId;
		if (presenceEvent.action !== "join") return;
		if (attendees.hasOwnProperty(presenceEvent.uuid)) return;

		dispatch(lookupProfileByUuid(presenceEvent.uuid));
		/*
		let state = presenceEvent.state;

		if (state.hasOwnProperty("main")) state = state.main;

		dispatch({
			type: constants.ADD_IDENTITY,
			payload: {
				uuid: presenceEvent.uuid,
				state: state,
			},
		});
		*/
	};
};
export const getEventSettings = (key, dispatch) => {
	// return function getEventSettingsAsync(dispatch, getState) {
	// const key = getState().user.key;
	return axios
		.post(
			process.env.REACT_APP_API_ENDPOINT + "/settings",
			{
				level: process.env.NODE_ENV === "development" ? 1 : 4,
				event_only: 1,
			},
			{
				...authHeader(key),
			}
		)
		.then((result) => {
			const settings = { ...result.data };

			// fetch all special properties and send to respective store
			const [streamerSettings, restSettings] = extractComponentSettings(
				settings,
				"streamer"
			);

			// Relay settings to Streamer
			dispatch({
				type: constants.APPEND_STREAMERCONFIG,
				payload: streamerSettings,
			});
			// Relay settings to app
			dispatch({
				type: constants.APPEND_APPCONFIG,
				payload: restSettings,
			});

			dispatch({
				type: constants.SET_EVENT_LOADSTATE,
				payload: constants.EVENT_LOAD_STATE.LOADED,
			});
		})
		.catch((err) => {
			console.log(err);
			// if (err.response && err.response.status)
		});
	// };
};

const extractComponentSettings = (settings, prefix) => {
	const settingsCopy = { ...settings };
	const extractedSettings = {};
	for (const [key, value] of Object.entries(settingsCopy)) {
		if (key.indexOf(`${prefix}-`) === 0) {
			extractedSettings[camelCased(key.replace("streamer-", ""))] = value;
			delete settingsCopy[key];
		}
	}
	return [extractedSettings, settingsCopy];
};

export const setLoadState = (newState) => {
	return { type: constants.SET_EVENT_LOADSTATE, payload: newState };
};
export const setEventCode = (code) => {
	return { type: constants.SET_EVENTCODE, payload: code };
};
export const setEventType = (type) => {
	return { type: constants.SET_EVENTTYPE, payload: type };
};
export const setInviteCode = (code) => {
	return { type: constants.SET_INVITECODE, payload: code };
};

export const setStartIn = (time) => {
	return { type: constants.SET_STARTIN, payload: time };
};

export const getAllPeopleInEvent = () => {
	// http://localhost:8000/api/
	return function getAllPeopleInEventAsync(dispatch, getState, context) {
		const key = getState().user.key;
		axios
			.get(
				process.env.REACT_APP_API_ENDPOINT + "/profiles/timeslot?format=object",
				{
					...authHeader(key),
				}
			)
			.then((result) => {
				dispatch({
					type: constants.SET_IDENTITIES,
					payload: result.data,
				});
			})
			.catch((err) => {
				console.log(err);
				// if (err.response && err.response.status)
			});
	};
};

export const getAllPeopleInEventToday = () => {
	// http://localhost:8000/api/
	return function getAllPeopleInEventTodayAsync(dispatch, getState, context) {
		const key = getState().user.key;
		axios
			.get(
				process.env.REACT_APP_API_ENDPOINT + "/profiles/today?format=object",
				{
					...authHeader(key),
				}
			)
			.then((result) => {
				dispatch({
					type: constants.SET_IDENTITIES,
					payload: result.data,
				});
			})
			.catch((err) => {
				console.log(err);
				// if (err.response && err.response.status)
			});
	};
};

export const lookupProfileByUuid = (uuid) => {
	return function getProfileAsync(dispatch, getState, context) {
		const key = getState().user.key;
		axios
			.post(
				process.env.REACT_APP_API_ENDPOINT + "/profile/lookup",
				{
					uuid,
				},
				{
					...authHeader(key),
				}
			)
			.then((result) => {
				const uuid = result.data.uuid;
				const state = { ...result.data };

				dispatch({
					type: constants.ADD_IDENTITY,
					payload: {
						uuid,
						state,
					},
				});
			})
			.catch((err) => {
				console.log(err);
				// if (err.response && err.response.status)
			});
	};
};

export const getPeopleInEvent = () => {
	return function handleStateChangeAsync(dispatch, getState, context) {
		const me = getState().user.uuid;

		console.log("FETCHING HERE NOW WITH STATE");

		context.pubnub.api.hereNow(
			{
				channels: ["main"],
				includeUUIDs: true,
				includeState: true,
			},
			(status, response) => {
				console.log("CUSTOM HERE NOW CALLBACK", status, response);

				if (status.error === false) {
					// console.log("HERE NOW:", response);
					const attendees = {};

					if (response.channels.main.occupancy > 0) {
						response.channels.main.occupants
							.filter((o) => o.uuid !== me)
							.forEach((o) => {
								if (o.state.hasOwnProperty("main")) {
									// State is grouped per channel
									return (attendees[o.uuid] = o.state.main);
								} else return (attendees[o.uuid] = o.state);
							});
					}

					dispatch({
						type: constants.SET_IDENTITIES,
						payload: attendees,
					});

					// eventActions.loadAttendees(attendees);

					// check last messages on main to see if you missed any calls
				}
			}
		);
	};
};

/*
	return fetchHereNow({
		channels: ["main"],
		includeUUIDs: true,
		includeState: true,
	});
	*/
/*
	return fetchChannelMembers({
		channel: "main",
		include: {
			customFields: true,
			UUIDFields: true,
			customUUIDFields: true,
		},
	});
	*/
