// import { Asterisk } from "../../assets/icons";
import { ValidationMessage } from "./ValidationMessage";

const InputField = ({
	className = "",
	value,
	onChange,
	placeholder,
	required = false,
	type = "text",
	id,
	errorMessage,
	disabled = false,
}) => (
	<label className={`relative block text-gray-500  mb-4 ${className}`}>
		<input
			type={type}
			className='w-full px-3 py-4 bg-tenary rounded-md border-2 border-transparent outline-none focus:border-primary-interact text-sm placeholder-gray-500 placeholder-opacity-0'
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			id={id}
			disabled={disabled}
		/>
		<span className='absolute text-sm px-3 py-4 top-0 left-0 w-full flex items-center focused-sibling:hidden  border-2 border-transparent'>
			{placeholder}
		</span>
		{required && <ValidationMessage errorMessage={errorMessage} />}
	</label>
);
export default InputField;
