import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchHereNow, fetchMessageHistory } from "pubnub-redux";
import constants from "../store/constants";

// import lobbyBackground from "../assets/img/backgrounds-.jpg";

import GroupAttendees from "../components/call/GroupAttendees";
import { presenceSelectors } from "../store/presence";
import { messageSelectors } from "../store/message";

import MiniChat from "../components/chat/MiniChat";
import { uiActions } from "../store/ui";
import { hostActions } from "../store/host";
import { useTranslation } from "react-i18next";

/*
const someCheck = (input, check) => {
	return input.some((m) => {
		console.log(m, check);
		return m.id === check;
	});
};
*/

const LobbyPage = () => {
	const { t } = useTranslation();
	const user = useSelector((state) => state.user);
	const groupMessages = useSelector(
		messageSelectors.getMessagesForGroupChannel
	);
	const [tips] = useState([
		"For an optimal experience, allow access to camera and microphone\nwhen requested",
		"If you are having issues, refresh your browser.\nYou will join the group automatically",
	]);

	// eslint-disable-next-line no-unused-vars
	const [tipIdx, setTipIdx] = useState(0);

	const dispatch = useDispatch();

	useEffect(() => {
		// Ignore for host
		if (user.isHost) return;

		if (groupMessages.length === 0) return;

		const lastMessage = groupMessages[0];

		switch (lastMessage.message.type) {
			case constants.MESSAGE_TYPE.ROUTE_SWITCH:
			case constants.MESSAGE_TYPE.SESSION_START:
			case constants.MESSAGE_TYPE.GATHER_GROUP:
			case constants.MESSAGE_TYPE.GROUP_TELEPORT:
			case constants.MESSAGE_TYPE.START_TOUR:
			case constants.MESSAGE_TYPE.RELEASE_GROUP:
				dispatch(uiActions.setRoute("event"));
				break;
			default:
			// Do nothing
		}
	}, [groupMessages, dispatch, user]);

	// Prevent rerender
	//const members = useSelector(getAttendeesInGroup);
	const hostIsPresent = useSelector(presenceSelectors.getHostIsPresent);

	useEffect(() => {
		if (user.group === null) return;
		// console.log("In the lobby: ", user.loggedIn);

		//console.log("Dispatch HERE NOW");
		// Get user presence
		dispatch(
			fetchHereNow({
				channels: ["group-" + user.group.id],
				includeUUIDs: true,
				includeState: true, // value is ignored
			})
		);

		dispatch(fetchMessageHistory({ channel: `chat-group-${user.group.id}` }));
	}, [dispatch, user]);

	const sendNavigationCommand = () => {
		dispatch(
			hostActions.sendCommandToGroup(constants.MESSAGE_TYPE.ROUTE_SWITCH, {
				route: "event",
			})
		);
	};

	// const FP = () => {
	// 	dispatch(eventActions.getPeopleInRoom());
	// };

	const handleRefresh = () => {
		dispatch(
			fetchHereNow({
				channels: ["group-" + user.group.id],
				includeUUIDs: true,
				includeState: true, // value is ignored
			})
		);
	};

	return (
		// bg-cover bg-center bg-no-repeat
		//
		<div
			className='flex flex-col justify-center items-center p-5 lg:p-20 overflow-hidden relative md:h-screen min-h-full bg-no-repeat bg-center bg-cover'
			// style={{ backgroundImage: `url(${lobbyBackground})` }}
		>
			<div
				className='flex space-x-5 h-full items-start'
				style={{ maxHeight: "580px" }}>
				{!user.isHost && (
					<div
						className='rounded-md
bg-white shadow-xl text-gray-800 
relative text-center md:text-left
md:py-8
md:px-4
mx-auto
w-72 flex-grow-0'>
						<div className='text-center px-4 flex flex-col'>
							<h2 className='text-2xl mb-2'>
								Hi <span className='font-semibold'>{user.identity.name}</span>!
							</h2>

							{!hostIsPresent && (
								<div>
									<h3 className='font-semibold'>The host is not here yet.</h3>
								</div>
							)}
							{hostIsPresent && (
								<div>
									<h3 className='font-semibold'>
										The host is here and will start the session shortly.
									</h3>
								</div>
							)}

							{/* <Link to="/event">Event</Link> */}
						</div>
					</div>
				)}
				{user.isHost && (
					<div
						className='rounded-md
 bg-white shadow-xl text-gray-800 
 relative text-center md:text-left
 h-full
 md:py-8
 md:px-4
 mx-auto w-full max-w-lg'
						style={{ maxHeight: "580px" }}>
						<div className='h-full flex flex-col text-center px-4'>
							<h2 className='text-2xl'>
								Hi <span className='font-semibold'>{user.identity.name}</span>!
							</h2>

							<div className='mt-3'>
								<h2 className='font-semibold my-2 mb-4'>
									Invited guests
									<span
										className='text-primary ml-1 inline-block text-sm cursor-pointer font-semibold hover:bg-primary hover:text-white py-1 px-2 border border-primary rounded-md'
										onClick={handleRefresh}>
										refresh
									</span>
								</h2>
							</div>
							<div className='scrollable-panel__viewport'>
								<div className='grid grid-cols-2 gap-2 gap-x-4'>
									<GroupAttendees />
								</div>
							</div>
							<div className='mt-5'>
								<button
									className='bg-primary text-white text-center
							hover:bg-primary-interact
							shadow py-4 px-8 rounded-md block
							focus:ring-2 focus:ring-primary-interact uppercase w-full'
									onClick={sendNavigationCommand}>
									Start event
								</button>
								<p className='text-xs mt-2'>
									* Not all guests have to be present to start the session.
									Latecomers join automatically.
								</p>
							</div>
						</div>

						{/* <Link to="/event">Event</Link> */}
					</div>
				)}
				<div
					className='rounded-md
bg-white shadow-xl text-gray-800 
relative text-center md:text-left
md:py-8
md:px-4
mx-auto
w-80 h-full'>
					<MiniChat channelName={`chat-group-${user.group.id}`} t={t} />
				</div>
			</div>
			{/* NOTICE // TIPS */}
			<div
				className='rounded-md
 bg-white shadow-xl text-gray-800 
 relative text-center 
 overflow-hidden
 md:py-4
 md:px-4
 mx-auto
 max-w-lg mt-4 px-5 text-sm
 flex-shrink-0'
				//  flex space-x-4
			>
				{/* <button
					className={`
						${
							tipIdx === 0 ? "opacity-20 focus-visible:border-none" : ""
						}  focus:outline-none`}
					onClick={() => setTipIdx(Math.max(0, tipIdx - 1))}
				>
					<ArrowLeft />
				</button> */}
				<p className='whitespace-pre-line'>{tips[tipIdx]}</p>
				{/* <button
					className={`${
						tipIdx === tips.length - 1
							? "opacity-20 focus-visible:border-none"
							: ""
					}  focus:outline-none`}
					onClick={() => setTipIdx(Math.min(tips.length - 1, tipIdx + 1))}
				>
					<ArrowRight />
				</button> */}
			</div>
		</div>
	);
};

export default React.memo(LobbyPage);
