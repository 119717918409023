import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import constants from "../store/constants";
import AttendeesPanel from "./panels/AttendeesPanel";
import ChatPanel from "./panels/ChatPanel";
import FloorplanPanel from "./panels/FloorplanPanel";
import GoodiebagPanel from "./panels/GoodiebagPanel";

import GroupCallPanel from "./panels/GroupCallPanel";
import { uiActions, uiSelectors } from "../store/ui";
import HelpPanel from "./panels/HelpPanel";
import { NavigationPanel } from "./panels/NavigationPanel";
import { ProfilePanel } from "./panels/ProfilePanel";
import { RegistrationPanel } from "./panels/RegistrationPanel";
import { SaveConfigurationPanel } from "./sales/SaveConfigurationPanel";

const PanelManager = () => {
	const panelActive = useSelector(uiSelectors.selectPanelActive);
	// const panelActive = constants.PANEL.NAVIGATION_MODE;

	const dispatch = useDispatch();

	const escFunction = useCallback(
		(event) => {
			if (event.keyCode === 27) {
				dispatch(uiActions.closePanel());
			}
		},
		[dispatch]
	);

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false);

		return () => {
			document.removeEventListener("keydown", escFunction, false);
		};
	}, [escFunction]);

	const views = {
		[constants.PANEL.ATTENDEES]: <AttendeesPanel />,
		[constants.PANEL.GROUPCALL]: <GroupCallPanel />,
		[constants.PANEL.GOODIEBAG]: <GoodiebagPanel />,
		[constants.PANEL.FLOORPLAN]: <FloorplanPanel />,
		[constants.PANEL.CHAT]: <ChatPanel />,
		[constants.PANEL.HELP]: <HelpPanel />,
		[constants.PANEL.SETTINGS]: <NavigationPanel />,
		[constants.PANEL.PROFILE]: <ProfilePanel />,
		[constants.PANEL.REGISTRATION]: <RegistrationPanel />,
		[constants.PANEL.SAVE_CONFIGURATION]: <SaveConfigurationPanel />,
	};

	return (
		<div>
			{panelActive !== constants.PANEL.NONE && (
				<div className="fixed top-0 left-0 layer--panels w-screen h-screen  bg-white bg-opacity-70">
					{/* <div
						className='pointer-events-auto w-screen h-screen bg-white bg-opacity-70'
						onMouseDown={() => {
							dispatch(uiActions.closePanel());
						}}
					/> */}
					{views[panelActive]}
				</div>
			)}
		</div>
	);
};

export default PanelManager;
