export const sortOnField = (fieldName, descending = false) => {
	const customSorter = (a, b) => {
		if (a[fieldName] > b[fieldName]) {
			return descending ? -1 : 1;
		}
		if (b[fieldName] > a[fieldName]) {
			return descending ? 1 : -1;
		}
		return 0;
	};
	return customSorter;
};

export const sortDescending = (a, b) => {
	if (a > b) {
		return -1;
	}
	if (b > a) {
		return 1;
	}
	return 0;
};
