import constants from "../constants";

const defaultState = {
	notes: [
		{
			truck: "LSI-200",
			content: {
				customer: "",
				yourContact: "",
				configuration: "",
				hotspots: "",
			}
		}
	],
	files: [
		/*{
			id: "2",
			type: "pdf",
			description: "BT Reflex proven innovation"
		}*/
	],
	contents: []
};

const salesReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_SALES_FILES:
			return {
				...state,
				files: action.payload,
			};
		case constants.SET_SALES_NOTES:
			return {
				...state,
				notes: [...action.payload],
			};
		case constants.SEND_FILES:
			return {
				...state,
				contents: [...state.contents, action.payload],
			};
		default:
			return state;
	}
};

export default salesReducer;
