import React from "react";
import BaseModal, { OK } from "./BaseModal";

import constants from "../../store/constants";

import { useDispatch } from "react-redux";
// import { loggingActions } from "../../store/logging";
import { callActions } from "../../store/call";
import { uiActions } from "../../store/ui";

const ExpertRequestModal = ({ notification, me, callback, t }) => {
	const dispatch = useDispatch();

	const handleAccept = () => {
		// Join
		dispatch(uiActions.setLayer(constants.LAYER.CALL, true, false));
		dispatch(
			callActions.initCall(
				"expert-" + notification.message.expertId + "-qa",
				false,
				false,
				false,
				true,
				false,
				true,
				"expert-" + notification.message.expertId
			)
		);
		dispatch(callActions.changeCallLayout(constants.CALL_LAYOUT.FULLSCREEN));

		callback(notification.id, false);
	};
	// const handleAudioOnly = () => {
	// 	// Join

	// 	callback(notification.id, false);
	// };
	const handleDecline = () => {
		callback(notification.id, false);
	};

	// const speakers = [
	// 	{ topic: "Performance & safety", speaker: "Fredrik Nilsson" },
	// 	{ topic: "Ergonomics", speaker: "Alexandra Hjälmdahl" },
	// 	{ topic: "Energy", speaker: "Jakob Klint ardivson" },
	// ];

	return (
		<BaseModal
			showClose={true}
			buttons={OK}
			confirmCallback={handleAccept}
			confirmText={t("global.join", "Join")}
			closeCallback={handleDecline}
			cancelText={t("global.discard", "Discard")}
			size='xl'
			extraButtons={() => (
				<>
					{/* <button
						className='font-semibold uppercase rounded text-white bg-black hover:bg-gray-800 w-full flex-grow py-3'
						onClick={(e) => {
							e.preventDefault();
							handleAudioOnly();
						}}>
						Audio only
					</button> */}
					<button
						className='font-semibold uppercase rounded text-white bg-customGray hover:bg-customGray-interact w-full flex-grow py-3'
						onClick={(e) => {
							e.preventDefault();
							handleDecline();
						}}>
						{t("global.discard", "Discard")}
					</button>
				</>
			)}>
			<h1 className='text-3xl font-semibold mb-4'>Sessione di esperti</h1>

			<p className='font-semibold mb-2'>
				{/* {notification.message.participants} / 12 */}
				{/* <span className='block font-semibold'>
					{speakers[notification.message.expertId - 1].topic}
				</span>
				{speakers[notification.message.expertId - 1].speaker} */}
				Partecipa alla sessione di esperti con Ciro Martone
			</p>
		</BaseModal>
	);
};

export default ExpertRequestModal;
