import * as React from "react";

function Couple({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M22.65 14.72A6.14 6.14 0 0015 5.13a6.14 6.14 0 00-7.65 9.59 8.72 8.72 0 00-4.66 7.76v2.64a.87.87 0 00.88.88h22.86a.87.87 0 00.88-.88v-2.64a8.72 8.72 0 00-4.66-7.76zm-4.13-9a4.4 4.4 0 011.81 8.4l-.21.08a4 4 0 01-.67.21h-.13a4 4 0 01-.77.07h-.49a4.6 4.6 0 01-1.44-.43h-.05a1.46 1.46 0 01-.25-.13 6.06 6.06 0 00.94-1.71v-.08a6.61 6.61 0 00.24-.9v-.14a6 6 0 00.08-.94 6.09 6.09 0 00-.08-.95v-.13a7.17 7.17 0 00-.24-.91v-.07a6.26 6.26 0 00-.94-1.72 4.33 4.33 0 012.2-.6zM7.08 10.17A4.39 4.39 0 0114.51 7l.15.15a4 4 0 01.41.51 1 1 0 01.11.17 3.74 3.74 0 01.33.59.88.88 0 01.05.15 3.55 3.55 0 01.22.7v.08a4.22 4.22 0 010 1.66v.08a3.55 3.55 0 01-.22.7.88.88 0 01-.05.15 4.36 4.36 0 01-.33.6l-.11.17a4.72 4.72 0 01-.41.5l-.15.15a4.36 4.36 0 01-1.22.82l-.21.09a5 5 0 01-.66.2h-.17a4.28 4.28 0 01-.72.07h-.1a4.39 4.39 0 01-.72-.07h-.17a4.88 4.88 0 01-.65-.2l-.22-.09a4.42 4.42 0 01-2.59-4.01zM4.45 22.48a7 7 0 014.67-6.63 6.13 6.13 0 004.72 0 9.37 9.37 0 01.86.38l.53.31.34.22a4.54 4.54 0 01.48.39l.3.26c.14.14.28.28.41.43a3.92 3.92 0 01.27.33 4.84 4.84 0 01.33.46c.09.13.17.26.25.4l.24.46c.07.16.14.33.2.5s.11.29.16.44a5.81 5.81 0 01.15.63c0 .12.06.24.08.37a7.42 7.42 0 01.08 1.05v1.76H4.45zm21.1 1.76h-5.27v-1.76-1.05c0-.2-.05-.39-.09-.58s0-.16-.05-.25-.09-.39-.15-.58a1.33 1.33 0 00-.05-.19 8.72 8.72 0 00-1.06-2.22v-.05c-.16-.23-.32-.44-.49-.65a6.64 6.64 0 00-.56-.63 6.3 6.3 0 00.75.05h.96a4.86 4.86 0 00.55-.11h.16a4.45 4.45 0 00.7-.24 7 7 0 014.68 6.63z" />
		</svg>
	);
}

const MemoCouple = React.memo(Couple);
export default MemoCouple;
