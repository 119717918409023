import constants from "../constants";

const defaultState = {
	canHost: false,
	invitees: [],
};

const hostReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_CAN_HOST:
			return {
				...state,
				canHost: action.payload,
			};
		case constants.SET_INVITEES:
			return {
				...state,
				invitees: [...action.payload],
			};
		default:
			return state;
	}
};

export default hostReducer;
