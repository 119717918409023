import { createSelector } from "reselect";
import eventSlice from "./eventSlice";

export const getEventIdentitiesById = createSelector(
	[eventSlice],
	(event) => event.identities.byId
);

// Extract only eventCode and eventType
export const getEventDetails = createSelector([eventSlice], (event) =>
	(({ eventCode, eventType }) => ({ eventCode, eventType }))(event)
);

export const getIsHosted = createSelector(
	[eventSlice],
	(event) => event.isHosted
);

export const getStartIn = createSelector(
	[eventSlice],
	(event) => event.startIn
);

export const getIndentityById = (id) => {
	return createSelector(
		[getEventIdentitiesById],
		(identities) => identities[id]
	);
};

export const getMemberState = createSelector([eventSlice], (event) => {
	if (event && event.identities) return Object.values(event.identities.byId);
	return [];
});

export const getEventLoadingState = createSelector([eventSlice], (event) => {
	return event.loadState;
});
