import React, { useState, useEffect, useCallback } from "react";

import { AgoraVideoPlayer } from "../../libs/AgoraWrapper";
import CallControls from "./CallControls";
import Logo from "../../assets/logo";

import { useSelector, useDispatch } from "react-redux";

import constants from "../../store/constants";
import { callSelectors } from "../../store/call";

const CallWindow = ({ hideCallWindow, channelName, uid, token, other, t }) => {
	// using the hook to get access to the client object
	const client = window.useClient();

	// ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null
	const { ready, tracks } = window.useMicrophoneAndCameraTracks();

	const [users, setUsers] = useState([]);
	const [start, setStart] = useState(false);

	const appId = useSelector(callSelectors.getCallAppId);

	const dispatch = useDispatch();

	const leaveChannel = useCallback(async () => {
		await client.leave();
		client.removeAllListeners();
		// we close the tracks to perform cleanup
		tracks[0].close();
		tracks[1].close();
		setStart(false);

		hideCallWindow();
	}, [client, tracks, hideCallWindow]);

	useEffect(() => {
		console.log(
			"Calling window single with deps",
			channelName,
			client,
			ready,
			tracks,
			appId
		);
		// function to initialise the SDK
		let init = async (name) => {
			client.on("user-published", async (user, mediaType) => {
				await client.subscribe(user, mediaType);
				console.log("subscribe success");
				if (mediaType === "video") {
					setUsers((prevUsers) => {
						return [...prevUsers, user];
					});
				}
				if (mediaType === "audio") {
					user.audioTrack?.play();
				}
			});

			client.on("user-unpublished", (user, type) => {
				console.log("unpublished", user, type);
				if (type === "audio") {
					user.audioTrack?.stop();
				}
				if (type === "video") {
					setUsers((prevUsers) => {
						return prevUsers.filter((User) => User.uid !== user.uid);
					});
				}
			});

			client.on("user-left", (user) => {
				console.log("leaving", user);
				setUsers((prevUsers) => {
					return prevUsers.filter((User) => User.uid !== user.uid);
				});
			});

			const joinResult = await client
				.join(appId, name, token, uid)
				.catch((error) => {
					console.log("Something went wrong while starting the call", error);
				});

			if (typeof joinResult === "undefined") {
			}
			console.log("CALL JOINED", joinResult);

			dispatch({
				type: constants.CALL_JOINED,
			});

			// Make sure the video is on
			await tracks[1].setEnabled(true);

			if (tracks) await client.publish([tracks[0], tracks[1]]);
			setStart(true);
		};

		if (ready && tracks) {
			console.log("init ready");
			init(channelName);
		}
	}, [
		dispatch,
		channelName,
		client,
		ready,
		tracks,
		appId,
		token,
		uid,
		leaveChannel,
	]);

	const [timeLeft, setTimeLeft] = useState(150);

	React.useEffect(() => {
		let to;
		if (timeLeft > 0) to = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
		if (timeLeft === 0) leaveChannel();
		return () => clearTimeout(to);
	}, [timeLeft, leaveChannel]);

	// eslint-disable-next-line no-unused-vars
	const [alone, setAlone] = useState(true);
	useEffect(() => {
		if (users) setAlone(users.length < 1);
	}, [users]);

	const setVolume = (volume) => {
		for (let i = 0, l = users.length; i < l; i++) {
			users[i].audioTrack.setVolume(volume);
		}
	};

	return (
		<div className='fixed w-screen h-screen p-10 left-0 top-0 z-40'>
			<div className={`py-10 bg-white rounded-lg w-full h-full flex`}>
				<div className='w-1/5 px-10'>
					<Logo className='w-1/2 h-auto' />

					<h1 className='font-semibold text-3xl mt-10'>
						{t("base.call_videochat_with", "Videochat with")} {other}
					</h1>
					<h2 className='text-3xl text-gray-400 mb-10'>
						{new Date(timeLeft * 1000).toISOString().substr(14, 5)}
						{/* {timeLeft} */}
					</h2>
					{/* <button className="text-white bg-black rounded-sm px-5 py-2 flex items-center">
						<Card className="mr-2" /> Send vcard
					</button> */}
				</div>
				{start && (
					<>
						<div className='w-3/5 flex flex-col'>
							<div className='flex-grow bg-gray-200 rounded-sm overflow-hidden relative'>
								{alone && (
									<div className='absolute bg-white p-4 rounded top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
										{t("base.call_alone_message", "You're alone in this call")}
									</div>
								)}
								{users.length > 0 &&
									users.map((user) => {
										if (user.videoTrack) {
											return (
												<AgoraVideoPlayer
													className='w-full h-full'
													videoTrack={user.videoTrack}
													key={user.uid}
												/>
											);
										} else return null;
									})}
							</div>

							<CallControls
								className=' space-x-8'
								tracks={tracks}
								allowLeave={true}
								leaveChannel={leaveChannel}
								start={start}
								setVolume={setVolume}
								layout={constants.CALL_LAYOUT.FULLSCREEN}
								t={t}
							/>
						</div>

						<div className='w-1/5 flex flex-col justify-end pr-10'>
							<div className='rounded-md ring-2 ring-white overflow-hidden bg-secondary relative -ml-16 h-56'>
								{ready && (
									<AgoraVideoPlayer
										style={{ height: "100%", width: "100%" }}
										className='vid'
										videoTrack={tracks[1]}
									/>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default React.memo(CallWindow);
