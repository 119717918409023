import * as React from "react";

function Close({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M4 27.5a1.5 1.5 0 001.06-.44l10-9.95 10 10a1.49 1.49 0 102-2.11l-9.95-10 10-9.95A1.49 1.49 0 1025 2.94l-10 9.95-9.95-10a1.49 1.49 0 10-2.11 2.16l10 10-10 10A1.5 1.5 0 004 27.5z" />
		</svg>
	);
}

const MemoClose = React.memo(Close);
export default MemoClose;
