import constants from "../constants";

const defaultState = {
	name: "",
	callChannel: "",
	commandChannel: "",
	messagingChannel: "",
	host: "",
	participants: [],
};

const groupReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_GROUP:
			return {
				...state,
				...action.payload,
			};
		case constants.SET_PARTICIPANTS:
			return {
				...state,
				participants: action.payload,
			};
		default:
			return state;
	}
};

export default groupReducer;
