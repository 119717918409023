import * as React from "react";

function SpeakerOff({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M28.7 27.36L2.64 1.3a1.83 1.83 0 00-1-.53h-.18A.7.7 0 001 1a.79.79 0 00-.18.7 1.83 1.83 0 00.53 1l26.01 26a1.83 1.83 0 001 .53.71.71 0 00.88-.88 1.83 1.83 0 00-.54-.99zM11.38 9.41l7.86-4.79a.91.91 0 011.38.78v13.26l1.82 1.82V5.4a2.73 2.73 0 00-4.15-2.33l-8.18 5H10zM20.62 22.75v3.37a.91.91 0 01-1.38.78l-8-4.85V13.4l-1.79-1.82v10.07H5.87V9.87h1.87L5.92 8.05H5a.91.91 0 00-.95.95v13.6a.91.91 0 00.91.91h5.15l8.18 5a2.73 2.73 0 004.15-2.33v-1.61z" />
		</svg>
	);
}

const MemoSpeakerOff = React.memo(SpeakerOff);
export default MemoSpeakerOff;
