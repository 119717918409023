import SelectField from "./SelectField";
import { languageList } from "../../helpers/LocaleHelper";

const LanguageField = (props) => {
	const options = props.filter
		? languageList.filter((l) => props.filter(l))
		: languageList;

	return <SelectField {...props} required options={options} />;
};

export default LanguageField;
