import { ValidationMessage } from "./ValidationMessage";

const SelectField = ({
	className = "",
	value,
	onChange,
	placeholder,
	required = false,
	options = [],
	onBlur,
	errorMessage,
	id,
}) => (
	<label className={`relative block  text-gray-500 ${className}`}>
		<select
			type='text'
			className='appearance-none pr-4 w-full px-3 py-4 bg-tenary rounded-md border-2 border-transparent outline-none focus:border-primary-interact text-sm placeholder-gray-500 placeholder-opacity-0'
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			id={id}
			placeholder={placeholder}>
			<option value='' className='text-gray-300'>
				{placeholder}
			</option>
			{options.map((item, i) => (
				<option value={item.value} key={i}>
					{item.label}
				</option>
			))}
		</select>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 448 512'
			className='w-4 h-4 absolute right-3 top-1/2 transform -translate-y-1/2 fill-current pointer-events-none'>
			<path d='M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z' />
		</svg>
		<span className='hidden'>{placeholder}</span>
		{required && <ValidationMessage errorMessage={errorMessage} />}
	</label>
);

export default SelectField;
