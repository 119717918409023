import { sortOnField } from "./ArrayHelpers";

const euLanguages = [
	{
		code: "BG",
		name: "Bulgarian",
		nameNative: "\u0431\u044a\u043b\u0433\u0430\u0440\u0441\u043a\u0438",
		active: 0,
		position: 1,
	},
	{
		code: "ES",
		name: "Spanish",
		nameNative: "espa\u00f1ol",
		active: 1,
		position: 2,
	},
	{
		code: "CS",
		name: "Czech",
		nameNative: "\u010de\u0161tina",
		active: 1,
		position: 3,
	},
	{
		code: "DA",
		name: "Danish",
		nameNative: "dansk",
		active: 1,
		position: 4,
	},
	{
		code: "DE",
		name: "German",
		nameNative: "Deutsch",
		active: 1,
		position: 5,
	},
	{
		code: "ET",
		name: "Estonian",
		nameNative: "eesti keel",
		active: 1,
		position: 6,
	},
	{
		code: "EL",
		name: "Greek",
		nameNative: "\u03b5\u03bb\u03bb\u03b7\u03bd\u03b9\u03ba\u03ac",
		active: 1,
		position: 7,
	},
	{
		code: "EN",
		name: "English",
		nameNative: "English",
		active: 1,
		position: 1,
	},
	{
		code: "FR",
		name: "French",
		nameNative: "fran\u00e7ais",
		active: 1,
		position: 9,
	},
	{
		code: "GA",
		name: "Irish",
		nameNative: "Gaeilge",
		active: 0,
		position: 10,
	},
	{
		code: "HR",
		name: "Croatian",
		nameNative: "hrvatski",
		active: 1,
		position: 11,
	},
	{
		code: "IT",
		name: "Italian",
		nameNative: "italiano",
		active: 1,
		position: 12,
	},
	{
		code: "LV",
		name: "Latvian",
		nameNative: "latvie\u0161u valoda",
		active: 1,
		position: 13,
	},
	{
		code: "LT",
		name: "Lithuanian",
		nameNative: "lietuvi\u0173 kalba",
		active: 1,
		position: 15,
	},
	{
		code: "HU",
		name: "Hungarian",
		nameNative: "magyar",
		active: 1,
		position: 15,
	},
	{
		code: "MT",
		name: "Maltese",
		nameNative: "Malti",
		active: 0,
		position: 16,
	},
	{
		code: "NL",
		name: "Dutch",
		nameNative: "Nederlands",
		active: 1,
		position: 17,
	},
	{
		code: "PL",
		name: "Polish",
		nameNative: "polski",
		active: 1,
		position: 18,
	},
	{
		code: "PT",
		name: "Portuguese",
		nameNative: "portugu\u00eas",
		active: 1,
		position: 19,
	},
	{
		code: "RO",
		name: "Romanian",
		nameNative: "rom\u00e2n\u0103",
		active: 1,
		position: 20,
	},
	{
		code: "SL",
		name: "Slovenian",
		nameNative: "sloven\u0161\u010dina",
		active: 0,
		position: 21,
	},
	{
		code: "SK",
		name: "Slovak",
		nameNative: "sloven\u010dina",
		active: 1,
		position: 22,
	},
	{
		code: "FI",
		name: "Finnish",
		nameNative: "suomi",
		active: 0,
		position: 23,
	},
	{
		code: "SE",
		name: "Swedish",
		nameNative: "svenska",
		active: 1,
		position: 24,
	},
];

// { value: "IT", label: "Italian" },
export const languageList = euLanguages
	.filter((lang) => lang.active === 1)
	.sort(sortOnField("position"))
	.map((lang) => ({
		value: lang.code,
		label: lang.nameNative.charAt(0).toUpperCase() + lang.nameNative.slice(1),
	}));

export const languageListObjects = euLanguages
	.filter((lang) => lang.active === 1)
	.sort(sortOnField("position"));
