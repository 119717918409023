import React from "react";
import BaseModal, { OK } from "./BaseModal";
//import useGoodiebag from "../../store/Goodiebag";
import { Card } from "../../assets/icons";

const ChatDeniedModal = ({ notification, callback, t }) => {
	const handleAccept = () => {
		// callback({ pathname: "/goodie-bag" });
		callback(notification.id, false);
	};
	const handleClose = () => {
		callback(notification.id, false);
	};
	return (
		<BaseModal
			// buttons={notification.vcard ? OK | CANCEL : CANCEL}
			buttons={OK}
			showClose={false}
			// icon={Chat}
			confirmCallback={handleAccept}
			//confirmText="Open goodiebag"
			confirmText="OK"
			cancelCallback={handleClose}
			cancelText="Close"
		>
			<p>{t("base.denied_sorry", "Sorry")}, {notification.from.name} {t("base.denied_not_available", "is not available right now.")}</p>
			{notification.message.vCard && (
				<>
					<div className="border-t border-b border-trueGray-300 py-4 mt-8 px-8 flex justify-between items-center">
						<Card className="w-32 h-16" />
						<p className="text-sm text-left">
							{t("base.denied_with_vcard", "A vCard was added to your goodiebag.")}
						</p>
					</div>
				</>
			)}
		</BaseModal>
	);
};
export default ChatDeniedModal;
