import { createSelector } from "reselect";
import { userSlice } from "./index";

export const getUserKey = createSelector([userSlice], (user) => user.key);
export const getUserAccess = createSelector([userSlice], (user) => user.access);
export const getUserIdentity = createSelector(
	[userSlice],
	(user) => user.identity
);
export const getUserPreferences = createSelector(
	[userSlice],
	(user) => user.preferences
);

export const getLoggedIn = createSelector([userSlice], (user) => user.loggedIn);
