import React from "react";
import BaseModal, { OK, CANCEL } from "./BaseModal";
//import useGoodiebag from "../../store/Goodiebag";

import constants from "../../store/constants";

import { useDispatch } from "react-redux";
import { uiActions } from "../../store/ui";

const VCardAcceptModal = ({ notification, callback, t }) => {
	const dispatch = useDispatch();

	const handleAccept = () => {
		dispatch(uiActions.openPanel(constants.PANEL.GOODIEBAG));
		callback(notification.id, false);
	};
	const handleClose = () => {
		callback(notification.id, false);
	};
	return (
		<BaseModal
			// buttons={notification.vcard ? OK | CANCEL : CANCEL}
			buttons={OK | CANCEL}
			showClose={false}
			// icon={Chat}
			confirmCallback={handleAccept}
			confirmText={t("base.open_gb", "Open goodiebag")}
			cancelCallback={handleClose}
			cancelText={t("global.close", "Close")}>
			<p>
				{/*{notification.from.name}'s vCard is now available in your goodiebag.*/}
				{t("base.gb_vcard_available", "A vCard is now available in your goodiebag.")}
			</p>
		</BaseModal>
	);
};
export default VCardAcceptModal;
