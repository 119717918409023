import React from "react";
import PropTypes from "prop-types";

import moment from "moment";
import packageJson from "../package.json";
import preval from "preval.macro";

export const sortDescending = (a, b) => {
	if (a > b) {
		return -1;
	}
	if (b > a) {
		return 1;
	}
	return 0;
};

const absUrl = (path) => {
	if (typeof path === "undefined") return process.env.REACT_APP_ABS_URL;
	return process.env.REACT_APP_ABS_URL + path;
};
const baseUrl = (path) => {
	if (typeof path === "undefined") return process.env.REACT_APP_BASE_URL;
	// console.log(process.env.MIX_BASE_URL, path, process.env.MIX_BASE_URL + path);
	return process.env.REACT_APP_BASE_URL + path;
};
const imgUrl = (asset) => {
	return baseUrl("/img/" + asset);
};
const uploadUrl = (asset, subdir) => {
	if (typeof subdir === "undefined")
		return process.env.REACT_APP_ASSET_URL + "/" + asset;
	else return process.env.REACT_APP_ASSET_URL + "/" + subdir + "/" + asset;
};

export const assetHelper = {
	absUrl,
	baseUrl,
	imgUrl,
	uploadUrl,
};

export const authHeader = (key) => {
	return {
		headers: {
			"x-api-key": key,
		},
	};
};

class Delayed extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hidden: true };
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ hidden: false });
		}, this.props.waitBeforeShow);
	}

	render() {
		return this.state.hidden ? "" : this.props.children;
	}
}

Delayed.propTypes = {
	waitBeforeShow: PropTypes.number.isRequired,
};

// VERSION NUMBER COMPONENT
const buildTimestamp = preval`module.exports = new Date().getTime();`;

const getDateString = () => {
	const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
	const formattedDate = lastUpdateMoment.format("DD.MM.YYYY HH:mm:ss");

	return formattedDate;
};

const GetBuildInformation = () => {
	return {
		buildNumber: packageJson.version,
		buildTime: buildTimestamp,
		currentTime: getDateString(),
	};
};

class VersionNumber extends React.PureComponent {
	render() {
		return (
			<div>
				{packageJson.version}
				{"."}
				{buildTimestamp} {"("}
				{getDateString()}
				{")"}
			</div>
		);
	}
}

VersionNumber.propTypes = {};

VersionNumber.defaultProps = {};

export { Delayed, VersionNumber, GetBuildInformation };
