import * as React from "react";

function Clock({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M15 4.12A10.88 10.88 0 1025.87 15 10.88 10.88 0 0015 4.12zm0 19.6A8.72 8.72 0 1123.72 15 8.73 8.73 0 0115 23.72z" />
			<path d="M15 9.6a.81.81 0 00-.81.8v5a.81.81 0 00.23.61l3.47 3.47a.81.81 0 001.38-.57.8.8 0 00-.24-.57l-3.23-3.23V10.4a.8.8 0 00-.8-.8z" />
		</svg>
	);
}

const MemoClock = React.memo(Clock);
export default MemoClock;
