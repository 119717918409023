import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../../../store/ui";
import constants from "../../../../store/constants";
import { Downloads, Settings, Teleport } from "../../../../assets/icons";
import {
	notificationActions,
	notificationSelectors,
} from "../../../../store/notification";
import { useState } from "react";
import Sound from "react-sound";
import notificationSound from "../../../../assets/audio/notification-8-bit-1.mp3";
import ReactTooltip from "react-tooltip";

export function GuestControls() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const goodiebagPing = useSelector(notificationSelectors.selectGoodiebagPing);
	// const [playSound, setPlaySound] = useState(Sound.status.STOPPED);

	const openSettingsPanel = () => {
		dispatch(uiActions.openPanel(constants.PANEL.SETTINGS));
	};
	const openFloorplanPanel = () => {
		dispatch(uiActions.openPanel(constants.PANEL.FLOORPLAN));
	};
	const openGoodiebag = () => {
		dispatch(uiActions.openPanel(constants.PANEL.GOODIEBAG));
		// CLEAR THE GOODIEBAG PING
		notificationActions.clearPing();
	};

	return (
		<>
			<div className="fixed layer--actionbar bottom-5 left-5 flex space-x-2 text-secondary">
				<div
					className="cursor-pointer group shadow-lg rounded-full w-12 h-12 bg-white p-2 hover:bg-gray-100 hover:scale-105 duration-100 ease-in-out transition-transform hover:transform"
					onClick={openFloorplanPanel}
					data-tip={t("base.sb_floorplan", "Floor plan")}
					data-for="guest-toolbar-tips"
					id="btnFloorplan">
					<Teleport className="w-full h-full group-hover:text-secondary-interact" />
				</div>
				<div
					className="relative cursor-pointer group shadow-lg rounded-full w-12 h-12 bg-white p-2 hover:bg-gray-100 hover:scale-105 duration-100 ease-in-out transition-transform hover:transform"
					onClick={openGoodiebag}
					data-tip={t("base.sb_goodiebag", "Goodiebag")}
					data-for="guest-toolbar-tips"
					id="btnGoodiebag">
					<Downloads className="w-full h-full group-hover:text-secondary-interact" />
					{goodiebagPing && (
						<div
							className="
									w-3 h-3 bg-primary rounded-full absolute right-0 top-0">
							<Sound
								url={notificationSound}
								playStatus={Sound.status.PLAYING}
								playFromPosition={0}
								// onFinishedPlaying={() => setPlaySound(Sound.status.STOPPED)}
							/>
						</div>
					)}
				</div>
				<div
					className="cursor-pointer group shadow-lg rounded-full w-12 h-12 bg-white p-2 hover:bg-gray-100 hover:scale-105 duration-100 ease-in-out transition-transform hover:transform"
					onClick={openSettingsPanel}
					data-tip={t("base.sb_settings", "Settings")}
					data-for="guest-toolbar-tips"
					id="btnSettings">
					<Settings className="w-full h-full group-hover:text-secondary-interact" />
				</div>
			</div>
			<ReactTooltip
				place="top"
				type="dark"
				className="text-center whitespace-nowrap font-semibold"
				backgroundColor="#707070"
				effect="solid"
				id="guest-toolbar-tips"
				// getContent={(dataTip) => {
				// 	if (dataTip === null) return <></>;
				// 	const parts = dataTip.split("|");
				// 	return (
				// 		<div className='text-center'>
				// 			<b>{parts.shift()}</b>
				// 			{/* <br />
				// 			{parts.shift()} */}
				// 		</div>
				// 	);
				// }}
			/>
		</>
	);
}
