import constants from "../constants";

const defaultState = {
	library: [
		{
			reference: "INTRO",
			locale: "XX",
			live: false,
			qa: false,
			url: "https://vimeo.com/613560007",
			subtitleSupport: ["EN", "ES", "FR", "IT"],
		},
		{
			reference: "INTRO_IT",
			locale: "XX",
			live: false,
			qa: false,
			url: "https://vimeo.com/632029599",
			subtitleSupport: ["EN", "ES", "FR", "IT"],
		},
		{
			reference: "PLENARY",
			locale: "XX",
			live: false,
			qa: false,
			url: "https://vimeo.com/632057214",
			subtitleSupport: ["EN", "ES", "FR", "IT"],
		},
		// {
		// 	reference: "PLENARY_EXTRA",
		// 	locale: "DE",
		// 	live: false,
		// 	qa: false,
		// 	url: "https://vimeo.com/551402462",
		// 	subtitleSupport: ["EN", "ES", "FR", "IT"],
		// },
		// {
		// 	reference: "PLENARY_EXTRA",
		// 	locale: "ES",
		// 	live: false,
		// 	qa: false,
		// 	url: "https://vimeo.com/551356903",
		// 	subtitleSupport: ["EN", "ES", "FR", "IT"],
		// },
		{
			reference: "Q&A",
			locale: "XX",
			live: true,
			qa: true,
			url: "",
			subtitleSupport: [],
		},
	],
	isPlaying: false,
	subtitleLocale: "",
	autoClose: true,
	controls: false,
	queue: [],
};

const videoReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_VIDEO:
			return {
				...state,
				isPlaying: true,
				queue: action.payload,
			};
		case constants.CLEAR_VIDEO:
			return {
				...state,
				isPlaying: false,
				queue: [],
			};
		case constants.SET_SUBTITLES:
			return {
				...state,
				subtitleLocale: action.payload,
			};
		default:
			return state;
	}
};

export default videoReducer;
