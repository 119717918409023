import * as React from "react";

function Downloads({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<path d='M22.27 25H7.73c-2 0-3.64-1.64-3.64-3.64V15c0-.54.36-.91.91-.91s.91.36.91.91v6.36c0 1 .82 1.82 1.82 1.82h14.55c1 0 1.82-.82 1.82-1.82V15c0-.54.36-.91.91-.91s.91.36.91.91v6.36c0 2-1.64 3.64-3.64 3.64zm0 0' />
			<path d='M15 18.64c-.28 0-.45-.09-.63-.28l-2.73-2.73a.882.882 0 010-1.27c.36-.36.91-.36 1.27 0l1.18 1.18V5.91c0-.54.36-.91.91-.91s.91.36.91.91v9.63l1.18-1.18c.36-.36.91-.36 1.27 0s.36.91 0 1.27l-2.73 2.73c-.18.19-.36.28-.63.28zm0 0' />
		</svg>
	);
}

const MemoDownloads = React.memo(Downloads);
export default MemoDownloads;
