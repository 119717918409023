import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { streamerActions } from "../../store/streamer";
import { Close, Pin } from "../../assets/icons";
import Floorplan from "../../assets/img/Floorplan";
import constants from "../../store/constants";
import { uiActions } from "../../store/ui";
import { useTranslation } from "react-i18next";
import { userSelectors } from "../../store/user";
import { hostActions } from "../../store/host";
import { eventSelectors } from "../../store/event";

const MapPins = ({ pins }) => {
	const dispatch = useDispatch();

	const handlePinClick = useCallback(
		(reference) => {
			dispatch(streamerActions.sendMessage(`TELEPORT:${reference}`));
			dispatch(uiActions.closePanel(constants.PANEL.FLOORPLAN));
		},
		[dispatch]
	);

	return pins.map(({ label, reference, x, y }, idx) => (
		<MapPin
			key={label}
			label={idx + 1}
			reference={reference}
			x={x}
			y={y}
			onClick={handlePinClick}
		/>
	));
};

const MapPin = React.memo(({ label, reference, x, y, onClick }) => {
	const style = {
		left: `${x}%`,
		top: `${y}%`,
	};

	const pinClick = () => {
		onClick(reference);
	};

	return (
		<div
			className='absolute top-0 left-0 cursor-pointer bg-green-400'
			style={style}>
			<div className='absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2'>
				<Pin className='text-3xl' onClick={pinClick} />
				<span className='absolute text-sm text-white font-semibold left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-1 pointer-events-none'>
					{label}
				</span>
			</div>
		</div>
	);
});

const FloorplanPanel = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isHosted = useSelector(eventSelectors.getIsHosted);

	const pins = [
		{
			key: "base.floorplan.entrance",
			fallback: "Entrance",
			reference: "DG_ENTERANCE",
			x: 32.5,
			y: 47,
		},
		{
			key: "base.floorplan.touchscreen",
			fallback: "Touchscreen",
			reference: "DG_TOUCHSCREEN_STORY",
			x: 42.5,
			y: 17,
		},
		{
			key: "base.floorplan.battery_pack",
			fallback: "Battery pack",
			reference: "DG_ENERGY_STORY",
			x: 43.5,
			y: 79,
		},
		{
			key: "base.floorplan.configurator",
			fallback: "Configurator",
			reference: "DG_CONFIGURATOR",
			x: 55.5,
			y: 46,
		},
		{
			key: "base.floorplan.lowlevel_left1",
			fallback: "BT Staxio",
			reference: "DG_BT_SPE160L",
			x: 82.5,
			y: 22,
		},
		{
			key: "base.floorplan.lowlevel_left2",
			fallback: "BT Lifter",
			reference: "BG_BT_LHM230",
			x: 84.5,
			y: 35,
		},
		{
			key: "base.floorplan.lowlevel_right2",
			fallback: "BT Levio",
			reference: "BG_BT_LWI160",
			x: 85.5,
			y: 61,
		},
		{
			key: "base.floorplan.lowlevel_right1",
			fallback: "BT Optio",
			reference: "BG_BT_OSE250",
			x: 80.5,
			y: 67,
		},
		{
			key: "base.floorplan.toplevel_left1",
			fallback: "BT Reflex",
			reference: "DG_BT_REFLEX_SERIES",
			x: 86,
			y: 13,
		},
		{
			key: "base.floorplan.toplevel_left2",
			fallback: "Toyota Tonero",
			reference: "DG_TOYOTA_TONERO_SERIES",
			x: 87.5,
			y: 27,
		},
		{
			key: "base.floorplan.toplevel_right2",
			fallback: "Toyota Traigo",
			reference: "DG_BT_TRAIGO_SERIES",
			x: 87.5,
			y: 65,
		},
		{
			key: "base.floorplan.toplevel_right1",
			fallback: "BT Vector 150A",
			reference: "DG_BT_VECTOR",
			x: 86.5,
			y: 77,
		},
	];

	const handlePinClick = (reference) => {
		dispatch(
			streamerActions.sendMessage(
				constants.STREAMER_OUTGOING_ACTIONS.TELEPORT_ME.replace(
					"$reference",
					reference
				)
			)
		);
		// If this is a hosted session, also send to other attendees
		if (isHosted)
			dispatch(
				hostActions.sendCommandToGroup(constants.MESSAGE_TYPE.GROUP_TELEPORT, {
					reference,
				})
			);

		dispatch(uiActions.closePanel(constants.PANEL.FLOORPLAN));
	};

	return (
		<div className='bg-white rounded-md shadow-lg absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-4xl p-10 pb-0'>
			<Close
				className='absolute right-6 top-6 cursor-pointer'
				onClick={() => dispatch(uiActions.closePanel())}
			/>
			<h1 className='font-semibold text-2xl mb-3'>
				{t("base.map_title", "Navigation")}
			</h1>
			<p className='mb-4 text-sm italic'>
				{t(
					"base.floorplan.click_hotspot",
					"Click on a hotspot to teleport there."
				)}
			</p>
			<div className='absolute left-0 ml-14 z-10'>
				<ol className='list-decimal list-inside text-sm'>
					{pins.map((p) => (
						<li
							className='font-semibold cursor-pointer mb-1 text-base'
							key={p.key}
							onClick={() => handlePinClick(p.reference)}>
							<span className='inline-block bg-white bg-opacity-70 pl-2'>
								{t(p.key, p.fallback)}
							</span>
						</li>
					))}
				</ol>
			</div>
			<div className='relative max-w-md md:max-w-xl ml-auto mt-8 mb-2'>
				<Floorplan className='w-full h-auto ' />
				<MapPins pins={pins} />
			</div>
		</div>
	);
};

export default FloorplanPanel;
