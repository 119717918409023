import * as React from "react";

function VCard({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<path d='M1.3 3.9h27.9v21.6c-.2.6-.6.7-1.2.7H2.1c-1 0-1.2-.2-1.2-1.2V5.1c0-.4.2-.8.4-1.2zm1.5 20.2h24.3V5.8H2.8v18.3z' />
			<path d='M9.8 16.1c2.6 0 4.7 2 4.9 4.6 0 .3 0 .6-.1.8-.3.3-.6.5-1 .6-.4.1-.8-.2-.8-.7-.1-.4-.1-.7-.2-1.1-.3-1.4-1.6-2.3-2.9-2.3-1.4.1-2.5 1.1-2.8 2.4 0 .2-.1.4-.1.7-.1.6-.4.9-1 .9s-.9-.4-.9-1c-.2-2.6 2.1-4.9 4.9-4.9zM9.9 7.9c1.7.1 3 1.4 2.9 3.1 0 1.6-1.5 3-3.1 3-1.7 0-3-1.5-3-3.2.1-1.6 1.5-2.9 3.2-2.9zm.9 3.2c0-.6-.4-1-1-1s-1 .4-1 1 .4 1 1 1.1c.6-.1 1-.5 1-1.1zM21.1 13.9h2.4c.8 0 1.1.3 1.1 1s-.3 1-1.1 1.1h-4.9c-.7 0-1.2-.4-1.2-1s.5-1 1.2-1c.9-.1 1.7-.1 2.5-.1zM21.1 11.8h-2.5c-.7 0-1.1-.4-1.1-1s.4-.9 1.1-1h5c.8 0 1.1.3 1.1 1 0 .6-.4.9-1.1.9-.9.1-1.7.1-2.5.1zM21.1 20.1h-2.5c-.7 0-1.1-.4-1.2-1 0-.6.4-1 1.1-1h5.1c.7 0 1 .3 1 1 0 .6-.4 1-1.1 1h-2.4z' />
		</svg>
	);
}

const MemoVCard = React.memo(VCard);
export default MemoVCard;
