import * as React from "react";

function Trash({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M12.86 11.92a.94.94 0 00-.94.94v8.55a.95.95 0 101.89 0v-8.55a1 1 0 00-.95-.94zM17.14 11.92a1 1 0 00-.95.94v8.55a.95.95 0 101.89 0v-8.55a.94.94 0 00-.94-.94z" />
			<path d="M8.59 28.77h12.82a3.08 3.08 0 003.08-3.08V7.39h2.27a.94.94 0 000-1.88h-6.54V3.24a2 2 0 00-2-2h-6.43a2 2 0 00-2 2v2.27H3.24a.94.94 0 100 1.88h2.27v18.3a3.08 3.08 0 003.08 3.08zm3.08-25.65h6.66v2.39h-6.66zM7.39 7.39h15.22v18.3a1.19 1.19 0 01-1.2 1.19H8.59a1.19 1.19 0 01-1.2-1.19z" />
		</svg>
	);
}

const MemoTrash = React.memo(Trash);
export default MemoTrash;
