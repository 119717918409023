import { useDispatch, useSelector } from "react-redux";
import { Close, Transparancy } from "../../assets/icons";
import { uiActions, uiSelectors } from "../../store/ui";
import constants from "../../store/constants";
import { clsx } from "clsx/lite";
import { useState } from "react";

export function IframeOverlay() {
	const dispatch = useDispatch();

	const [opaque, setOpaque] = useState(false);

	const layerData = useSelector(uiSelectors.getLayerData);

	const closePanel = () => {
		dispatch(uiActions.setLayerData(null));
		dispatch(uiActions.setLayer(constants.LAYER.IFRAME, false));
	};

	return (
		<div
			className={clsx(
				"fixed right-0 top-0 w-full max-w-3xl h-screen layer--sales bg-white p-8 shadow-xl",
				!opaque && "bg-opacity-90"
			)}>
			<div className="absolute right-6 top-6 cursor-pointer flex space-x-4">
				<button
					onClick={(e) => setOpaque(!opaque)}
					className={clsx(opaque && "opacity-30")}>
					<Transparancy className="w-8 h-8" />
				</button>
				<button
					onClick={(e) => {
						closePanel();
					}}>
					<Close className="w-8 h-8" />
				</button>
			</div>
			<div className="h-full w-full flex flex-col">
				<h1 className="text-2xl text-gray-900 font-semibold">BT Reflex</h1>
				<div className="border-b-2 border-primary pb-4 w-1/5 mb-5"></div>
				<iframe
					src={`${process.env.PUBLIC_URL}/web-brochures/${layerData}/index.html`}
					className="w-full flex-grow"
					style={{ display: "block" }}
					title="brochure-content"
				/>
			</div>
		</div>
	);
}
