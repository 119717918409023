import SelectField from "./SelectField";
const CountrySelector = (props) => (
	<SelectField
		{...props}
		required
		options={[
			{ value: "AL", label: "Albania" },
			{ value: "AT", label: "Austria" },
			{ value: "AZ", label: "Azerbaijan" },
			{ value: "BY", label: "Belarus" },
			{ value: "BE", label: "Belgium" },
			{ value: "BA", label: "Bosnia & Herzegovina" },
			{ value: "BG", label: "Bulgaria" },
			{ value: "HR", label: "Croatia" },
			{ value: "CY", label: "Cyprus" },
			{ value: "CZ", label: "Czech Rep." },
			{ value: "DK", label: "Denmark" },
			{ value: "EE", label: "Estonia" },
			{ value: "EU", label: "Europe" },
			{ value: "FI", label: "Finland" },
			{ value: "FR", label: "France" },
			{ value: "GE", label: "Georgia" },
			{ value: "DE", label: "Germany" },
			{ value: "GI", label: "Gibraltar" },
			{ value: "GR", label: "Greece" },
			{ value: "HU", label: "Hungary" },
			{ value: "IS", label: "Iceland" },
			{ value: "IE", label: "Ireland" },
			{ value: "IL", label: "Israel" },
			{ value: "IT", label: "Italy" },
			{ value: "JP", label: "Japan" },
			{ value: "JE", label: "Jersey" },
			{ value: "LV", label: "Latvia" },
			{ value: "LI", label: "Liechtenstein" },
			{ value: "LT", label: "Lithuania" },
			{ value: "LU", label: "Luxembourg" },
			{ value: "MK", label: "Macedonia" },
			{ value: "MT", label: "Malta" },
			{ value: "MD", label: "Moldova" },
			{ value: "MC", label: "Monaco" },
			{ value: "ME", label: "Montenegro" },
			{ value: "NL", label: "Netherlands (the)" },
			{ value: "NO", label: "Norway" },
			{ value: "ZZ", label: "OTHERS" },
			{ value: "PL", label: "Poland" },
			{ value: "PT", label: "Portugal" },
			{ value: "RO", label: "Romania" },
			{ value: "RU", label: "Russia" },
			{ value: "RS", label: "Serbia" },
			{ value: "SK", label: "Slovakia" },
			{ value: "SI", label: "Slovenia" },
			{ value: "ES", label: "Spain" },
			{ value: "SE", label: "Sweden" },
			{ value: "CH", label: "Switzerland" },
			{ value: "TR", label: "Turkey" },
			{ value: "UA", label: "Ukraine" },
			{ value: "GB", label: "United Kingdom" },
			{ value: "US", label: "United States" },
		]}
	/>
);

export default CountrySelector;
