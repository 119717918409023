import * as React from "react";

function Sidebar({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<rect
				x={12.36}
				y={6.11}
				width={5.28}
				height={5.28}
				rx={0.59}
				ry={0.59}
				transform='rotate(90 15 8.75)'
			/>
			<rect
				x={12.36}
				y={12.36}
				width={5.28}
				height={5.28}
				rx={0.59}
				ry={0.59}
				transform='rotate(90 15 15)'
			/>
			<rect
				x={12.36}
				y={18.61}
				width={5.28}
				height={5.28}
				rx={0.59}
				ry={0.59}
				transform='rotate(90 15 21.25)'
			/>
		</svg>
	);
}

const MemoSidebar = React.memo(Sidebar);
export default MemoSidebar;
