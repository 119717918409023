import * as React from "react";

function Group({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 35 35"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M19.83 21.75a4.15 4.15 0 10-4.14-4.15 4.14 4.14 0 004.14 4.15zm0-6.64a2.5 2.5 0 11-2.49 2.49 2.49 2.49 0 012.49-2.49zM22.53 23h-5.41a5.48 5.48 0 00-5.48 5.47v5.07A1.44 1.44 0 0013.1 35h13.44A1.44 1.44 0 0028 33.54v-5.07A5.47 5.47 0 0022.53 23zm-5.41 1.65h5.41a3.82 3.82 0 013.82 3.82v4.89h-1.52v-4.87a.68.68 0 00-1.36 0v4.87h-7.33v-4.87a.68.68 0 10-1.35 0v4.87h-1.5v-4.89a3.83 3.83 0 013.83-3.82zM25 8.29a4.15 4.15 0 10-4.14-4.15A4.14 4.14 0 0025 8.29zm0-6.64a2.5 2.5 0 11-2.49 2.49A2.5 2.5 0 0125 1.65z" />
			<path d="M33.18 15a5.48 5.48 0 00-5.47-5.47h-5.5a5.47 5.47 0 00-4.39 2.23.84.84 0 00.18 1.16.89.89 0 00.49.16.82.82 0 00.66-.34 3.83 3.83 0 013.06-1.55h5.51A3.82 3.82 0 0131.53 15v4.89H30V15a.68.68 0 10-1.36 0v4.86H24.4a.83.83 0 000 1.65h7.32a1.44 1.44 0 001.46-1.46V15zM10 13.82a4.15 4.15 0 10-4.14-4.15A4.15 4.15 0 0010 13.82zm0-6.65a2.5 2.5 0 11-2.5 2.5 2.5 2.5 0 012.5-2.5zM10.53 25.42H6.34v-4.87a.68.68 0 00-1.36 0v4.87H3.46v-4.89a3.83 3.83 0 013.84-3.81h5.44a3.8 3.8 0 011.41.27.82.82 0 00.62-1.52 5.24 5.24 0 00-2-.4H7.3a5.48 5.48 0 00-5.48 5.46v5.08a1.44 1.44 0 001.46 1.46h7.25a.83.83 0 100-1.65z" />
		</svg>
	);
}

const MemoGroup = React.memo(Group);
export default MemoGroup;
