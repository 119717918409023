import * as React from "react";

function Mouse({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<path d='M28 3.6c-.3.2-.6.4-1 .6-1.1.3-2.2.6-3.2 1-.8.2-1.2.8-1 1.6.3 1.4-.2 2.4-1.1 3.3l-.3.3c.9 1.3 1.5 2.7 1.6 4.2.1 2-.6 3.8-2 5.3l-6.7 6.7c-1.1 1.1-2.5 1.6-3.9 2H8.3c-.3-.1-.7-.2-1-.3-5-1.5-6.9-7.7-3.5-11.6 2.2-2.6 4.7-5 7.2-7.3 2.3-2.2 5.9-2.3 8.6-.6.2.1.4.2.6.4.9-.6 1.2-1.3 1.1-2.4-.2-1.4.6-2.6 2-3.1 1.2-.4 2.4-.8 3.7-1.1.2 0 .6.3 1 .4v.6zm-8.8 6.8c-2.4-1.7-5.4-1.6-7.3.2-2.3 2.2-4.6 4.5-6.8 6.8-2.1 2.2-1.9 5.6.2 7.8s5.6 2.4 7.8.3c2.4-2.2 4.7-4.5 6.9-6.9 1.8-2 1.9-5.2.1-7.2-.2.3-.4.6-.6.8-.4.4-.6.7-.5 1.4.3 1.6-2 4.1-3.6 3.8-.7-.1-1.2 0-1.6.5-.2.2-.7.2-.9 0-.2-.1-.3-.7-.3-.8.9-.7.6-1.6.7-2.4 0-.4.2-.8.5-1.1.3-.5.8-.8 1.2-1.3.7-.7 1.5-.9 2.5-.7.2 0 .5 0 .7-.1.2-.3.5-.7 1-1.1zm-3.7 5.5c.5 0 2-1.4 2-2 0-.6-.7-1.1-1.2-.7-.6.4-1.1.9-1.5 1.5-.4.4 0 1.1.7 1.2z' />
		</svg>
	);
}

const MemoMouse = React.memo(Mouse);
export default MemoMouse;
