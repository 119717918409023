import * as React from "react";

function Home({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M25.72 14.29l-10-10a1 1 0 00-1.42 0l-10 10a1 1 0 001.4 1.42l.29-.3V25a1 1 0 001 1H23a1 1 0 001-1v-9.6l.29.3a1 1 0 001.42-1.42zM22 24H8V13.41l7-7 7 7zm0 0" />
		</svg>
	);
}

const MemoHome = React.memo(Home);
export default MemoHome;
