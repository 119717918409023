import React from "react";
import BaseModal, { OK } from "./BaseModal";

import constants from "../../store/constants";

import { useDispatch } from "react-redux";
// import { loggingActions } from "../../store/logging";
import { callActions } from "../../store/call";
import { uiActions } from "../../store/ui";

const BreakoutRequestModal = ({ notification, me, callback, t }) => {
	const dispatch = useDispatch();

	const handleAccept = () => {
		// Join
		dispatch(uiActions.setLayer(constants.LAYER.CALL, true, false));
		dispatch(callActions.initCall(notification.message.channelName, false));
		dispatch(callActions.changeCallLayout(constants.CALL_LAYOUT.FULLSCREEN));

		// dispatch(
		// 	loggingActions.logActivity(
		// 		constants.ACTIVITY.CALL_ACCEPT,
		// 		notification.channelName
		// 	)
		// );

		callback(notification.id, false);
	};
	// const handleAudioOnly = () => {
	// 	// Join

	// 	callback(notification.id, false);
	// };
	const handleDecline = () => {
		callback(notification.id, false);
	};

	return (
		<BaseModal
			showClose={true}
			buttons={OK}
			confirmCallback={handleAccept}
			confirmText={t("global.join", "Join")}
			closeCallback={handleDecline}
			cancelText={t("global.discard", "Discard")}
			size='xl'
			extraButtons={() => (
				<>
					{/* <button
						className='font-semibold uppercase rounded text-white bg-black hover:bg-gray-800 w-full flex-grow py-3'
						onClick={(e) => {
							e.preventDefault();
							handleAudioOnly();
						}}>
						Audio only
					</button> */}
					<button
						className='font-semibold uppercase rounded text-white bg-customGray hover:bg-customGray-interact w-full flex-grow py-3'
						onClick={(e) => {
							e.preventDefault();
							handleDecline();
						}}>
						{t("global.discard", "Discard")}
					</button>
				</>
			)}>
			<h1 className='text-3xl font-semibold mb-4'>
				{notification.message.channelDisplayName}
			</h1>

			<p className='font-semibold mb-2'>
				{notification.message.participants} / 12
			</p>
			<p>{t("global.user_connected", "User(s) connected")}</p>
		</BaseModal>
	);
};

export default BreakoutRequestModal;
