import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import AppRouter from "./routers/AppRouter";

import { PubNubProvider } from "pubnub-react";
import {
	//createPubNubListener,
	combineListeners,
	// createNetworkStatusListener,
	// createMessageListener,
	// createPresenceListener,
	// createSubscriptionStatusListener,
	// createUserDataListener,
	// createChannelDataListener,
	createPubNubListener,
} from "pubnub-redux";

import { createStateChangeListener } from "./store/listeners/presenceStateChange.js";
import { createSignalTypingListener } from "./store/listeners/signalTypingIndicator";
import { LoadingScreen } from "./components/LoadingScreen";
// import { createTypingIndicatorsListener } from "features/typingIndicator/typingIndicatorModel";

const App = ({ environment, pubnub, store, history }) => {
	useEffect(() => {
		// console.log("CALLING APP USE_EFFECT", pubnub, store.dispatch);

		// Start listening for messages and events from PubNub
		pubnub.addListener(
			combineListeners(
				createPubNubListener(store.dispatch)
				/*
				createNetworkStatusListener(store.dispatch),
				createMessageListener(store.dispatch),
				createPresenceListener(store.dispatch),
				createSubscriptionStatusListener(store.dispatch),
				createUserDataListener(store.dispatch),
				createChannelDataListener(store.dispatch)
				*/
			)
		);
		// createPubNubListener(store.dispatch));
		// pubnub.addListener(createTypingIndicatorsListener(store.dispatch));

		/*
		pubnub.addListener({
			objects: (objectEvent) => {
				// var channel = objectEvent.channel; // The channel
				// var channelGroup = objectEvent.subscription; // The channel group
				// var timetoken = objectEvent.timetoken; // The event timetoken
				// var publisher = objectEvent.publisher; // The UUID that triggered this event
				// var event = objectEvent.event; // The event name that occurred
				// var type = objectEvent.type; // The event type that occurred
				// var data = objectEvent.data; // The event data that occurred

				console.log("Got object update!!!!", objectEvent);
			},
		});
		*/
		pubnub.addListener(createStateChangeListener(store.dispatch));
		pubnub.addListener(createSignalTypingListener(store.dispatch));

		return () => {
			// This is required to show the current user leave immediately rather than
			// wating for the timeout period

			pubnub.unsubscribeAll();
			// agoraClient disconnect
		};
	}, [pubnub, store.dispatch]);

	return (
		<Suspense fallback={<LoadingScreen />}>
			<Provider store={store}>
				<PubNubProvider client={pubnub}>
					<AppRouter environment={environment} history={history} />
				</PubNubProvider>
			</Provider>
		</Suspense>
	);
};

export default App;
