import { createSelector } from "reselect";
import callSlice from "./callSlice";

export const getCallAppId = createSelector([callSlice], (call) => call.appId);
export const getCallRemoteEnd = createSelector([callSlice], (slice) => {
	if (slice) return slice.remoteEnd;

	return false;
});
export const getCallLayout = createSelector(
	[callSlice],
	(slice) => slice.layout
);

export const getIsActive = createSelector(
	[callSlice],
	(slice) => slice.isActive
);
export const getChannelName = createSelector(
	[callSlice],
	(slice) => slice.channelName
);
export const getUid = createSelector([callSlice], (slice) => slice.uid);
export const getHasChat = createSelector([callSlice], (slice) => slice.hasChat);
export const getChatChannel = createSelector(
	[callSlice],
	(slice) => slice.chatChannel
);
export const getChatSettings = createSelector([callSlice], (slice) => ({
	enabled: slice.hasChat,
	channel: slice.chatChannel,
}));
export const getChannelToken = createSelector(
	[callSlice],
	(slice) => slice.channelToken
);
export const getOther = createSelector([callSlice], (slice) => slice.other);
export const getLayout = createSelector([callSlice], (slice) => slice.layout);
export const getIs1to1 = createSelector([callSlice], (slice) => slice.is1to1);
export const getIsPresenter = createSelector(
	[callSlice],
	(slice) => slice.isPresenter
);
export const getIsSubscriber = createSelector(
	[callSlice],
	(slice) => slice.isSubscriber
);

export const getCallParticipants = createSelector(
	[callSlice],
	(slice) => slice.participantsInCall
);
