import React from "react";
import BaseModal, { CANCEL, OK } from "./BaseModal";

import constants from "../../store/constants";

import { useDispatch } from "react-redux";
// import { loggingActions } from "../../store/logging";
import { callActions } from "../../store/call";
import { uiActions } from "../../store/ui";

const TruckInfoModal = ({ notification, me, callback, t }) => {
	const dispatch = useDispatch();

	const handleAccept = () => {
		dispatch(uiActions.setLayerData(notification.message.truckId));
		dispatch(uiActions.setLayer(constants.LAYER.IFRAME, true, false));

		callback(notification.id, false);
	};

	const handleDecline = () => {
		callback(notification.id, false);
	};

	const skipNext = () => {
		sessionStorage.setItem("seen-truck-info", 1);
		handleDecline();
	};

	return (
		<BaseModal
			showClose={true}
			buttons={OK | CANCEL}
			confirmCallback={handleAccept}
			confirmText={t("global.yes", "Yes")}
			closeCallback={handleDecline}
			cancelText={t("global.no", "No")}
			cancelCallback={handleDecline}
			size="xl"
			extraButtons={() => (
				<>
					<button
						className="font-semibold uppercase rounded text-white bg-customGray hover:bg-customGray-interact w-full flex-grow py-1"
						onClick={(e) => {
							e.preventDefault();
							skipNext();
						}}>
						{t("global.remember-decision", "Don't show again")}
					</button>
				</>
			)}>
			<h1 className="text-3xl font-semibold mb-4">
				{t("truck.learn-more.title", "More information")}
			</h1>

			<p className="font-semibold mb-2">
				{t(
					"truck.learn-more.body",
					"Would you like to learn more about this product?"
				)}
			</p>
		</BaseModal>
	);
};

export default TruckInfoModal;
