import constants from "../constants";
import { v4 as uuid } from "uuid";

export const clearModal = (id, ignore) => ({
	type: constants.CLOSE_MODAL,
	payload: { id, ignore },
});

export const fireModal = (type, message, from) => {
	const modelAction = {
		type: constants.ADD_MODAL,
		payload: {
			type,
			id: uuid(),
			ts: new Date().getTime(),
			message,
			from,
		},
	};
	return modelAction;
};

export const fireExpertModal = (expertId) =>
	fireModal(constants.MESSAGE_TYPE.EXPERT, { expertId }, "WEBSTREAM");

export const fireTruckInfoModal = (truckId) => {
	return fireModal(constants.MESSAGE_TYPE.TRUCK_INFO, { truckId }, "WEBSTREAM");
};
