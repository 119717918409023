import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { useFullScreenHandle } from "react-full-screen";
import NotificationManager from "../components/NotificationManager";

import { userSelectors } from "../store/user";

const PrivateRoute = ({ component: Component, ...rest }) => {
	const loggedIn = useSelector(userSelectors.getLoggedIn); //useSelector((state) => state.user);

	// Gave issues with restarting video feed / Streamer. To check
	// eslint-disable-next-line no-unused-vars
	const FSHandle = useFullScreenHandle();

	return !loggedIn ? (
		<Redirect to='/login' />
	) : (
		<>
			{/* <FullScreen handle={FSHandle}> */}
			<Route {...rest} component={(props) => <Component {...props} />} />
			{/* <div
					onClick={FSHandle.enter}
					className="text-white bg-black bg-opacity-50 hover:bg-opacity-90 cursor-pointer text-sm font-medium absolute z-50 left-0 bottom-0 ml-2 mb-2 p-2 rounded-md"
				>
					Fullscreen
				</div> */}

			<NotificationManager />
			{/* </FullScreen> */}
		</>
	);
};

export default React.memo(PrivateRoute, (prevProps, nextProps) => {
	return prevProps.path === nextProps.path;
});
