import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import MessageInterceptor from "./middleware/MessageInterceptor";
// ;
import { routerMiddleware, connectRouter } from "connected-react-router";

// https://github.com/supasate/connected-react-router/blob/master/FAQ.md#how-to-set-router-props-eg-basename-initialentries-etc

// Import all individual store fragments
import user from "./user";
import event from "./event";
import ui from "./ui";
import chat from "./chat";
import goodiebag from "./goodiebag";
import call from "./call";
import video from "./video";
import streamer from "./streamer";
import modal from "./modal";
import notification from "./notification";
import host from "./host";
import group from "./group";
import app from "./app";
import overlay from "./overlay";
import sales from "./sales";

import {
	createNetworkStatusReducer,
	createMessageReducer,
	createPresenceReducer,
	createUserDataReducer,
	createChannelDataReducer,
	createChannelsListReducer,
	createUsersListReducer,
	// createSubscriptionStatusReducer,
} from "pubnub-redux";

const createRootReducer = (history) =>
	combineReducers(
		{
			router: connectRouter(history),
			app,
			user,
			event,
			ui,
			chat,
			goodiebag,
			call,
			video,
			streamer,
			host,
			modal,
			group,
			notification,
			overlay,
			sales,
			networkStatus: createNetworkStatusReducer(false),
			messages: createMessageReducer(),
			presence: createPresenceReducer(),
			userData: createUserDataReducer(),
			channelData: createChannelDataReducer(),
			channelList: createChannelsListReducer(),
			userList: createUsersListReducer(),
		}
		// Pubnub reducers

		// createSubscriptionStatusReducer() ??? missing
	);

const createAppStore = (thunkContext, history) => {
	const storeEnhancer = composeWithDevTools(
		applyMiddleware(
			thunkMiddleware.withExtraArgument(thunkContext),
			MessageInterceptor, // Intercept command messages that don't belong in chats
			routerMiddleware(history)
		)
	);

	return createStore(createRootReducer(history), {}, storeEnhancer);
};

export default createAppStore;
