import axios from "axios";
import constants from "../constants";
import { authHeader } from "../../helpers";

export const clearOverlay = () => ({
	// type: constants.CLOSE_MODAL,
	type: constants.VISIBILITY.HIDE,
	payload: {},
});

export const openOverlay = (id, type, format) => {
	return function setOverlayDetails(dispatch, getState) {
		const language = getState().app.language;

		// Get hotspot's details
		axios
			.get(
				[
					process.env.REACT_APP_API_ENDPOINT,
					"resource-set",
					type,
					id,
					language,
				].join("/") + "?format=array",
				{
					...authHeader(getState().user.key),
				}
			)
			.then((result) => {
				const details = { ...result.data };

				dispatch({
					type: constants.VISIBILITY.SHOW,
					// payload: details
					payload: {
						id,
						details: details,
						type,
						format,
					},
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};
