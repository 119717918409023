import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
// import { ArrowLeft } from "../../assets/icons";
// import CheckboxGroup from "./CheckboxGroup";
import CountrySelector from "../../controls/CountrySelector";
import InputField from "../../controls/InputField";
import LanguageField from "../../controls/LanguageField";
import SelectField from "../../controls/SelectField";
import { Schema } from "./FormValidation";
import { userActions } from "../../../store/user";

const ProfileInformation = ({ next, email, t }) => {
	const dispatch = useDispatch();

	const [profile] = useState({
		first_name: "",
		password: "",
		password_confirmation: "",
		last_name: "",
		prefix: "",
		email: email,
		country: "",
		company_name: "",
		native_language: "",
		preferred_language: "",
		industry: "",
		contact_person: "",
		business_interests: "",
	});

	const formSubmit = (values) => {
		let newBusiinessInt = profile?.business_interests;
		const payloads = {
			...values,
			business_interests: [newBusiinessInt] || [],
		};

		dispatch(userActions.saveProfileAction(payloads))
			.then((r) => dispatch(userActions.byPassLoginHandler(values)))
			.then((r) => {
				next((step) => step + 1);
			})
			.catch((err) => console.log(err));
	};

	return (
		<div className=' p-10 w-full bg-white rounded-lg max-w-4xl mx-auto shadow-xl '>
			<Formik
				initialValues={{ ...profile }}
				validationSchema={Schema}
				onSubmit={(values, actions) => {
					formSubmit(values);
				}}>
				{({
					values,
					handleChange,
					handleSubmit,
					setFieldValue,
					handleBlur,
					errors,
					touched,
				}) => (
					<form
						onSubmit={handleSubmit}
						id='registrationForm'
						className='overflow-auto max-h-100 pr-4  stylized-scrollbar'>
						{/* {{-- @csrf--}} */}
						<h2 className='font-semibold text-xl text-center mb-8'>
							{t("profile.complete_user_profile", "Complete your user profile")}
						</h2>
						<input
							type='hidden'
							name='_token'
							value='hevg94UGlLbeTlgeSCScNU7MOMF0vMjJtXkWKYfq'
						/>

						<div className='w-full mb-6 flex space-x-4'>
							<SelectField
								id='prefix'
								name='prefix'
								className='w-20 mb-4'
								value={values.prefix}
								// onChange={(e) => {
								// 	updateProfile({ ...profile, prefix: e.target.value });
								// }}
								onChange={handleChange}
								onBlur={handleBlur}
								required={true}
								placeholder={t("profile.prefix", "prefix")}
								errorMessage={
									errors.prefix && touched.prefix ? errors.prefix : ""
								}
								options={[
									{ value: "Mr", label: "Mr" },
									{ value: "Miss", label: "Miss" },
									{ value: "Mrs", label: "Mrs" },
									{ value: "Mx", label: "Mx" },
								]}
							/>

							<InputField
								id='first_name'
								type='text'
								name='first_name'
								placeholder={t("profile.first_name", "first name")}
								className='flex-grow'
								required={true}
								value={values.first_name}
								onChange={handleChange}
								errorMessage={
									errors.first_name && touched.first_name
										? errors.first_name
										: ""
								}
								formik
								// onChange={(e) => {
								// 	updateProfile({ ...profile, first_name: e.target.value });
								// }}
							/>

							<InputField
								id='last_name'
								type='text'
								name='last_name'
								placeholder={t("profile.last_name", "last name")}
								className='flex-grow'
								required
								value={values.last_name}
								onChange={handleChange}
								errorMessage={
									errors.last_name && touched.last_name ? errors.last_name : ""
								}
								formik
								// onChange={(e) => {
								// 	updateProfile({ ...profile, last_name: e.target.value });
								// }}
							/>
						</div>
						<div className='flex justify-between space-x-6'>
							<div className='w-full mb-6'>
								<InputField
									id='password'
									type='password'
									placeholder={t("profile.password", "password")}
									required
									name='password'
									value={values.password}
									onChange={handleChange}
									errorMessage={
										errors.password && touched.password ? errors.password : ""
									}
									formik
									// onChange={(e) => {
									// 	updateProfile({ ...profile, password: e.target.value });
									// }}
								/>
							</div>

							<div className='w-full mb-6'>
								<InputField
									id='password_confirmation'
									type='password'
									name='password_confirmation'
									placeholder={t(
										"profile.password_confirmation",
										"repeat password"
									)}
									required
									value={values.password_confirmation}
									onChange={handleChange}
									errorMessage={
										errors.password_confirmation &&
										touched.password_confirmation
											? errors.password_confirmation
											: ""
									}
									formik
									// onChange={(e) => {
									// 	updateProfile({ ...profile, password_repeat: e.target.value });
									// }}
								/>
							</div>
						</div>
						<div className='flex justify-between space-x-4'>
							<div className='w-full mb-6'>
								<LanguageField
									id='native_language'
									name='native_language'
									className='mb-4'
									placeholder={t("profile.native_language", "Native language")}
									value={values.native_language}
									onChange={handleChange}
									onBlur={handleBlur}
									errorMessage={
										errors.native_language && touched.native_language
											? errors.native_language
											: ""
									}

									// onChange={(e) => {
									// 	updateProfile({ ...profile, native_language: e.target.value });
									// }}
								/>
							</div>

							<div className='w-full mb-6'>
								<LanguageField
									id='preferred_language'
									name='preferred_language'
									className='mb-4'
									placeholder={t(
										"profile.preferred_language",
										"Preferred subtitles language"
									)}
									value={values.preferred_language}
									onChange={handleChange}
									onBlur={handleBlur}
									filter={(e) => e.value !== "NL"}
									errorMessage={
										errors.preferred_language && touched.preferred_language
											? errors.preferred_language
											: ""
									}
								/>
							</div>
						</div>

						<div className='flex justify-between space-x-6'>
							<div className='w-full mb-6'>
								<InputField
									id='email'
									disabled={true}
									type='text'
									placeholder={t("profile.email", "email")}
									required
									name='email'
									value={values.email}
									onChange={handleChange}
									errorMessage={
										errors.email && touched.email ? errors.email : ""
									}
									formik
									// onChange={(e) => {
									// 	updateProfile({ ...profile, email: e.target.value });
									// }}
								/>
							</div>

							<div className='w-full mb-6'>
								<InputField
									id='company_name'
									type='text'
									placeholder={t("profile.company_name", "company")}
									name='company_name'
									required
									value={values.company_name}
									onChange={handleChange}
									errorMessage={
										errors.company_name && touched.company_name
											? errors.company_name
											: ""
									}
									formik
									// onChange={(e) => {
									// 	updateProfile({ ...profile, company_name: e.target.value });
									// }}
								/>
							</div>
						</div>

						<div className='flex justify-between space-x-6'>
							<div className='w-full mb-6'>
								<InputField
									id='industry'
									type='text'
									placeholder='industry'
									// required
									name='industry'
									value={values.industry}
									onChange={handleChange}
									// errorMessage={errors.industry && touched.industry ? errors.industry : ''}
									formik
									// onChange={(e) => {
									// 	updateProfile({ ...profile, industry: e.target.value });
									// }}
								/>
							</div>

							<div className='w-full mb-6'>
								<CountrySelector
									id='country'
									className='mb-4'
									name='country'
									placeholder={t("profile.country", "Country")}
									value={values.country}
									onChange={handleChange}
									onBlur={handleBlur}
									errorMessage={
										errors.country && touched.country ? errors.country : ""
									}
									// onChange={(e) => {
									// 	updateProfile({ ...profile, country: e.target.value });
									// }}
								/>
							</div>
						</div>

						<div className='w-full mb-6'>
							<InputField
								id='contact_person'
								type='text'
								name='contact_person'
								placeholder='Contact person within TMH'
								required
								value={values.contact_person}
								onChange={handleChange}
								errorMessage={
									errors.contact_person && touched.contact_person
										? errors.contact_person
										: ""
								}
								formik
								// onChange={(e) => {
								// 	updateProfile({ ...profile, contact_person: e.target.value });
								// }}
							/>
						</div>
						{/*}
						<div className='w-full mb-6'>
							<CheckboxGroup
								name=' business_interests'
								placeholder='Business interests (multiple choices)'
								value={profile.business_interests}
								// onChange={handleChange}
								onChange={(newValue) => {
									updateProfile({ ...profile, business_interests: newValue });
								}}
								options={[
									{ value: "Warehouse products", label: "Warehouse products" },
									{ value: "CBEL forklifts", label: "CBEL forklifts" },
									{ value: "IC forklifts", label: "IC forklifts" },
									{ value: "Fuel Cell", label: "Fuel Cell" },
									{ value: "Energy", label: "Energy" },
									{ value: "Services", label: "Services" },
									{ value: "Rental & Used", label: "Rental & Used" },
									{ value: "Innovation", label: "Innovation" },
									{ value: "Automation", label: "Automation" },
									{ value: "Connectivity", label: "Connectivity" },
									{ value: "Financial products", label: "Financial products" },
									{ value: "European contracts", label: "European contracts" },
									{ value: "Others", label: "Others" },
								]}
							/>
						</div>
*/}
						{/* <div className="w-full mb-6">
<x-checkbox name="agree" className="mb-2"
id="agree"
label="I agree that my contact details will be stored and used for TMH events only. This agreement can be dismissed at any time via 'My Profile'"
:checked="old('agree')"
value="1" required/>
</div> */}

						<div className='w-full mb-6'>
							<x-checkbox
								name='anonymize'
								className='mb-2'
								id='anonymize'
								label='Anonymize my data in statistics'
								// :checked="old('anonymize')"
								value='1'
								required
							/>
						</div>

						<div className='flex items-center justify-start mt-4'>
							<button
								type='submit'
								className='bg-primary text-white text-center
hover:bg-primary-interact
mx-auto max-w-sm shadow py-4 w-full rounded-md
focus:ring-2 focus:ring-primary-interact uppercase'>
								{t("profile.save_profile", "Save profile")}
							</button>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default ProfileInformation;
