import * as React from "react";

function Save({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<path d='M22.7 3H5.81C4.26 3 3 4.26 3 5.81v18.38C3 25.74 4.26 27 5.81 27h18.38c1.55 0 2.81-1.26 2.81-2.81V7.3L22.7 3zM10.36 4.88h5.58v3.56h1.88V4.88h1.88v4.5c0 .52-.42.94-.94.94h-7.45c-.52 0-.94-.42-.94-.94V4.87zm11.2 20.25H8.48v-8.25c0-.52.42-.94.94-.94h11.2c.52 0 .94.42.94.94v8.25zm3.56-.94c0 .52-.42.94-.94.94h-.75v-8.25c0-1.55-1.26-2.81-2.81-2.81H9.42c-1.55 0-2.81 1.26-2.81 2.81v8.25h-.8c-.52 0-.94-.42-.94-.94V5.81c0-.52.42-.94.94-.94h2.67v4.5c0 1.55 1.26 2.81 2.81 2.81h7.45c1.55 0 2.81-1.26 2.81-2.81v-4.5h.36l3.2 3.2v16.12zm0 0' />
		</svg>
	);
}

const MemoSave = React.memo(Save);
export default MemoSave;
