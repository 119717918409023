import * as React from "react";

function Hamburger({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M1.55 7h26.9a1.45 1.45 0 000-2.9H1.55a1.45 1.45 0 000 2.9zM28.45 13.55H1.55a1.45 1.45 0 000 2.9h26.9a1.45 1.45 0 000-2.9zM28.45 23H1.55a1.45 1.45 0 000 2.9h26.9a1.45 1.45 0 000-2.9z" />
		</svg>
	);
}

const MemoHamburger = React.memo(Hamburger);
export default MemoHamburger;
