import * as React from "react";

function Call({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<path d='M27.16 20.71s-5.24-3.75-6.65-3.51c-.68.12-1.06.58-1.84 1.51-.12.15-.43.51-.66.76-.49-.16-.97-.35-1.43-.58a11.835 11.835 0 01-5.48-5.48c-.23-.46-.42-.94-.58-1.43.25-.23.61-.53.76-.66.92-.77 1.38-1.16 1.5-1.84.25-1.4-3.47-6.6-3.51-6.64-.34-.49-.89-.79-1.48-.84-1.51 0-5.81 5.58-5.81 6.52 0 .05.08 5.6 6.92 12.57C15.85 27.93 21.4 28 21.46 28c.94 0 6.52-4.3 6.52-5.81-.04-.59-.35-1.14-.84-1.48zm-5.78 5.55c-.76-.06-5.42-.68-11.24-6.4C4.4 14.01 3.8 9.34 3.74 8.62c1.14-1.78 2.51-3.4 4.08-4.82.03.03.08.09.14.15 1.2 1.64 2.24 3.4 3.1 5.25-.28.28-.57.54-.88.79-.48.36-.92.78-1.31 1.23l-.21.29.06.36c.18.79.46 1.56.84 2.29a13.56 13.56 0 006.28 6.28c.73.37 1.49.65 2.29.84l.36.06.29-.21c.46-.39.87-.84 1.24-1.32.27-.32.63-.76.77-.88 1.86.85 3.62 1.89 5.27 3.1.07.06.12.11.16.14a23.809 23.809 0 01-4.82 4.08zm0 0' />
		</svg>
	);
}

const MemoCall = React.memo(Call);
export default MemoCall;
