import { useDispatch, useSelector } from "react-redux";
import { Close } from "../../assets/icons";
import { uiActions, uiSelectors } from "../../store/ui";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import { userSelectors } from "../../store/user";
import { authHeader } from "../../helpers";
import { notificationActions } from "../../store/notification";
import { hostActions } from "../../store/host";
import constants from "../../store/constants";

export const SaveConfigurationPanel = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const panelData = useSelector(uiSelectors.getPanelData);
	// const invitees = useSelector((state) => state.host.invitees);

	const key = useSelector(userSelectors.getUserKey);

	const [action, setAction] = useState("personal");
	const [comment, setComment] = useState("");
	const [commentIsPrivate, setCommentIsPrivate] = useState(false);

	// const [selectedAttendees, setSelectedAttendees] = useState([]);
	// const [activeConfiguration, setActiveConfiguration] = useState(null);
	const [uiState, setUiState] = useState("waiting");

	// const inValue = (input) => selectedAttendees.includes(input);

	useEffect(() => {
		if (panelData === null) return;
		// if (activeConfiguration !== null) return;

		setUiState("loaded");

		// Not really necessary to fetch the configuration, we can just send the comment
		// let url =
		// 	process.env.REACT_APP_API_ENDPOINT + "/configurations/" + panelData;
		// axios
		// 	.get(url, {
		// 		...authHeader(key),
		// 	})
		// 	.then((result) => {
		// 		console.log(result.data);
		// 		setActiveConfiguration(result.data);
		// 	})
		// 	.catch((error) => {});
	}, [panelData]);

	const handleChange = (e) => {
		setAction(e.target.value);
	};

	// const handleAttendeeToggle = (target) => {
	// 	let newValue = selectedAttendees; // []

	// 	if (!newValue.includes(target.value)) {
	// 		newValue.push(target.value);
	// 	} else {
	// 		newValue = newValue.filter((item) => item !== target.value);
	// 	}

	// 	setSelectedAttendees(newValue);
	// };

	const saveConfiguration = () => {
		// Construct basic payload
		const updatedConfiguration = {
			configuration_id: panelData,
			// configuration: activeConfiguration.configuration,
			// truck_name: activeConfiguration.truck_ref,
			// truck_ref: activeConfiguration.truck_ref,
			comments: comment,
			link_to_guests: true,
			send_to_guests: action !== "personal",
			comment_private: commentIsPrivate,
		};

		let url = process.env.REACT_APP_API_ENDPOINT + "/configurations/update";
		axios
			.post(url, updatedConfiguration, {
				...authHeader(key),
			})
			.then((result) => {
				dispatch(uiActions.closePanel(true));
				dispatch(
					notificationActions.addNotification({
						type: "INFO",
						message: "Config successfully updated.",
					})
				);
				if (action !== "personal")
					dispatch(
						hostActions.sendCommandToGroup(constants.MESSAGE_TYPE.RECEIVE_FILE)
					);
			})
			.catch((error) => {});
	};

	return (
		<div className="bg-white rounded-md shadow-lg absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-2xl p-10">
			<Close
				className="absolute right-6 top-6 cursor-pointer"
				onClick={() => dispatch(uiActions.closePanel())}
			/>
			<h1 className="font-semibold text-2xl mb-3">
				{t("hostactions.save-configuration.title", "Save configuration")}
			</h1>
			{uiState === "waiting" && (
				<div>{t("global.saving", "Fetching configuration, please wait")}</div>
			)}
			{uiState !== "waiting" && (
				<div className="flex space-x-10">
					<div className="w-1/2">
						<p className="mb-4">
							{t("hostactions.save-configuration.help", "Where to save to?")}
						</p>
						<label className="relative flex items-center space-x-2 text-gray-500 my-2">
							<input
								type="radio"
								className="w-4 h-4 bg-tenary rounded-md border-2 border-transparent outline-none focus:border-primary-interact text-primary accent-red-600"
								value="personal"
								name="action"
								checked={action === "personal"}
								onChange={handleChange}
							/>
							<span className="text-sm">
								{t(
									"hostactions.save-configuration.save-personal",
									"My account only"
								)}
							</span>
						</label>
						<label className="relative flex items-center space-x-2 text-gray-500 my-2">
							<input
								type="radio"
								className="w-4 h-4 bg-tenary rounded-md border-2 border-transparent outline-none focus:border-primary-interact text-primary accent-red-600"
								value="everyone"
								name="action"
								checked={action === "everyone"}
								onChange={handleChange}
							/>
							<span className="text-sm">
								{t(
									"hostactions.save-configuration.save-everyone",
									"Send copy to attendees"
								)}
							</span>
						</label>
						{/* {action === "everyone" && (
							<div>
								<p className="my-4 text-sm font-semibold">
									{t(
										"hostactions.save-configuration.select-participants",
										"Select participants"
									)}
								</p>
								<ul>
									{invitees.map((invitee, index) => {
										return (
											<li className="" key={`invitee-${index}`}>
												<label className="relative flex items-center space-x-2 text-gray-500 my-2">
													<input
														type="checkbox"
														className="w-4 h-4 bg-tenary rounded-md border-2 border-transparent outline-none focus:border-primary-interact text-primary accent-red-600"
														value={invitee.uuid}
														selected={inValue(invitee.uuid)}
														onChange={(e) => handleAttendeeToggle(e.target)}
													/>
													<span className="text-sm">{invitee.name}</span>
												</label>
											</li>
										);
									})}
								</ul>
							</div>
						)} */}
					</div>
					<div className="w-1/2 flex flex-col justify-between">
						<div className="">
							<label className="relative flex flex-col space-y-2 text-gray-500 mb-2">
								<span className="text-sm">
									{t(
										"hostactions.save-configuration.comment",
										"Add a comment to this configuration"
									)}
								</span>
								<textarea
									className="border border-trueGray-300 rounded p-2 w-full h-18 text-base"
									value={comment}
									onChange={(e) => {
										e.preventDefault();
										setComment(e.target.value);
									}}></textarea>
							</label>
							<label className="relative flex items-center space-x-2 text-gray-500 my-2">
								<input
									type="checkbox"
									className="w-4 h-4 bg-tenary rounded-md border-2 border-transparent outline-none focus:border-primary-interact text-primary accent-red-600"
									value={commentIsPrivate}
									name="commentPrivate"
									checked={commentIsPrivate}
									onChange={(e) => setCommentIsPrivate(e.target.checked)}
								/>
								<span className="text-sm">
									{t(
										"hostactions.save-configuration.comment-private",
										"Comment is private"
									)}
								</span>
							</label>
						</div>
						<button
							className="mt-4 bg-primary justify-center items-center uppercase text-sm rounded-md py-3 px-4 text-white"
							onClick={saveConfiguration}>
							{t("hostactions.save-configruation.save_button", "Save")}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};
