import { memo } from "react";
import { Asterisk } from "../../assets/icons";
// import RerenderTester from "../helpers/RerenderTester";

function ValidationMessageFn({ errorMessage }) {
	return (
		<span className='absolute right-0 -bottom-4 text-xxs uppercase text-primary flex space-x-2'>
			<Asterisk className='text-sm' />
			{`${errorMessage || ""}`}
		</span>
	);
}

export const ValidationMessage = memo(ValidationMessageFn);
