import React from "react";
import BaseOverlay from "../modals/BaseOverlay";
// import constants from "../../store/constants";
// import { uiActions } from "../../store/ui";
import { useDispatch } from "react-redux";
import { overlayActions } from "../../store/overlay";
import Download from "../../assets/icons/Download";
import { streamerActions } from "../../store/streamer";
import constants from "../../store/constants";

const HotspotDetailsOverlay = ({ details, format, callback, t }) => {
	const dispatch = useDispatch();
	const asset_url = process.env.REACT_APP_ASSET_URL;
	// const cloneDetails = {...details};
	let detailsObject = null;
	let cloneDetails = null;
	let title = "";
	let subtitle = "";
	let text = "";
	let image = "";
	let video = "";
	let document = "";
	let documentName = "";

	if (format === "array") {
		console.log("format array");
		cloneDetails = details;
		detailsObject = Object.values(cloneDetails);

		detailsObject.forEach((item) => {
			// console.log(item);
			if (
				(item.type === "translation" || item.type === "subtitle") &&
				(item.key.includes(".title") || item.key.includes("_title"))
			) {
				title = item.value ? item.value : "";
			}

			if (
				(item.type === "translation" || item.type === "subtitle") &&
				item.key.includes("subtitle")
			) {
				subtitle = item.value ? item.value : "";
			}

			if (
				(item.type === "translation" || item.type === "subtitle") &&
				item.key.includes("text")
			) {
				text = item.value ? item.value : "";
			}

			if (item.type === "image") {
				image =
					item.key.includes("image") && item.value
						? asset_url + item.value
						: "";
			}

			if (item.type === "video") {
				video = item.key.includes("video") && item.value ? item.value : "";
			}

			if (item.type === "document") {
				document =
					item.key.includes("document") && item.value
						? asset_url + item.value
						: "";
				documentName = document
					? document.substring(document.lastIndexOf("/") + 1)
					: "";
			}
			console.log("video", video);
		});
	} else if (format === "object") {
		console.log("format object");
		cloneDetails = { ...details };
		detailsObject = cloneDetails;

		const keys = Object.keys(cloneDetails);
		const titleKey = keys.find((key) => key.includes("title"));
		const subtitleKey = keys.find((key) => key.includes("subtitle"));
		const textKey = keys.find((key) => key.includes("text"));
		const imageKey = keys.find((key) => key.includes("image"));
		const videoKey = keys.find((key) => key.includes("video"));
		const documentKey = keys.find((key) => key.includes("document"));
		title = titleKey ? detailsObject[titleKey] : "";
		subtitle = subtitleKey ? detailsObject[subtitleKey] : "";
		text = textKey ? detailsObject[textKey] : "";
		image = imageKey ? detailsObject[imageKey] : "";
		video = videoKey ? detailsObject[videoKey] : "";
		document = documentKey ? detailsObject[documentKey] : "";
		documentName = document
			? document.substring(document.lastIndexOf("/") + 1)
			: "";
	} else {
		console.log("no format defined");
	}

	// console.log("detailsObject", detailsObject);
	/*title = 'Hotspot title';
	subtitle = 'This is a subtitle';
	text = 'This is the hotspot text';
	image = asset_url+'/resources/images/chi_1661519858.png'; // use asset_url+image
	video = 'https://player.vimeo.com/progressive_redirect/playback/696909498/rendition/1080p?loc=external&signature=8925807d1c0d43f216c93334f2163e8d4c6713e76e485a4bd8bf53b8dc402322';
	document = asset_url+'/resources/documents/doc1.pdf';
	documentName = document ? document.substring(document.lastIndexOf('/') + 1) : '';*/

	// const handleAccept = () => {
	// 	console.log("handle accept: more info button");
	// };

	const handleClose = () => {
		dispatch(
			streamerActions.sendMessage(
				constants.STREAMER_OUTGOING_ACTIONS.HOTSPOT_RESET
			)
		);
		dispatch(overlayActions.clearOverlay());
	};

	const downloadDoc = (url) => {
		console.log("download this", url);
		// dispatch(overlayActions.downloadDocument(url));
	};

	return (
		<BaseOverlay
			// confirmCallback={handleAccept}
			buttonText="More info"
			closeCallback={handleClose}
			size="md"
			disableClickThrough={false}>
			<div className="text-gray-900 ">
				{title && <h3 className="text-2xl text-gray-900 font-bold">{title}</h3>}

				{!title && <h3 className="text-2xl text-gray-900 font-bold">Title</h3>}

				<div className="border-b-4 border-primary pb-4 w-16"></div>
			</div>

			<div
				className="text-gray-900  overflow-auto fancy-scrollbar"
				style={{ height: "calc(100% - 9rem)" }}>
				{subtitle && (
					<h3 className="text-lg text-gray-500 font-semibold pt-6">
						{subtitle}
					</h3>
				)}

				{text && (
					// OR npm install react-html-parser, import ReactHtmlParser from "react-html-parser"; {ReactHtmlParser(text)}
					<p
						className="pt-4 text-gray-900 text-lg"
						dangerouslySetInnerHTML={{ __html: text }}></p>
				)}

				{!text && (
					<p className="pt-6">
						Placeholder text: "In publishing and graphic design, Lorem ipsum is
						a placeholder text commonly used to demonstrate the visual form of a
						document or a typeface without relying on meaningful content. Lorem
						ipsum may be used as a placeholder before final copy is available.
						typeface without relying on meaningful content. Lorem ipsum may be
						used as a placeholder before final copy is available."
					</p>
				)}

				{image && (
					<div className="w-2/3 flex flex-col border border-gray-200 mt-6">
						<img src={image} alt="" />
					</div>
				)}

				{documentName && (
					<div className="flex space-x-2 mt-6">
						<h3 className="font-semibold text-md">{documentName}</h3>
						<button
							className="w-6 h-6 flex justify-center items-center"
							onClick={() => downloadDoc(document)}
							title="Download document">
							<Download className="text-xl" />
						</button>
					</div>
				)}

				{video && (
					<div className="flex flex-col relative mt-6">
						<video className="w-full" controls>
							{/*<source className="w-30 h-30" src='https://www.w3schools.com/html/mov_bbb.mp4' type='video/mp4' />*/}
							<source className="w-30 h-30" src={video} type="video/mp4" />
						</video>

						<div className="absolute bottom-0 w-full bg-gradient-to-r from-black">
							<span className="text-white text-xs uppercase px-2">
								Video title
							</span>
						</div>
					</div>
				)}
			</div>
		</BaseOverlay>
	);
};

export default HotspotDetailsOverlay;
