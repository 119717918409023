import axios from "axios";
import constants from "../constants";
import { authHeader } from "../../helpers";

// GOODIEBAG CONTENT

export const loadGoodiebag = () => {
	return function loadGoodiebagAsync(dispatch, getState) {
		dispatch({ type: constants.SET_GOODIEBAG_FETCHING, payload: true });

		axios
			.get(process.env.REACT_APP_API_ENDPOINT + "/goodiebag/index", {
				...authHeader(getState().user.key),
			})
			.then((result) => {
				dispatch({
					type: constants.SET_GOODIEBAG,
					payload: result.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

export const addItemToGoodieBag = (referenceId) => {
	return function addItemToGoodieBagAsync(dispatch, getState) {
		axios
			.post(
				process.env.REACT_APP_API_ENDPOINT + "/goodiebag/add",
				{
					type: constants.ASSET_TYPE.VCARD,
					ref: referenceId,
				},
				{
					...authHeader(getState().user.key),
				}
			)
			.then((result) => {
				dispatch({
					type: constants.ADD_TO_GOODIEBAG,
					payload: result.data,
				});

				dispatch({
					type: constants.ADD_PING,
					payload: "GOODIEBAG",
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

export const removeItemFromGoodiebag = (id) => {
	return function removeItemFromGoodiebagAsync(dispatch, getState) {
		axios
			.post(
				process.env.REACT_APP_API_ENDPOINT + "/goodiebag/remove",
				{
					id,
				},
				{
					...authHeader(getState().user.key),
				}
			)
			.then((result) => {
				console.log(result);
				dispatch({
					type: constants.REMOVE_FROM_GOODIEBAG,
					payload: { id },
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

export const downloadGoodiebagItem = (id) => {
	const asset_url = process.env.REACT_APP_ASSET_URL;

	return function doGetUserThunk(dispatch, getState) {
		axios
			.post(
				process.env.REACT_APP_API_ENDPOINT + "/goodiebag/download",
				{
					id,
				},
				{
					...authHeader(getState().user.key),
				}
			)
			.then((result) => {
				window.open(
					asset_url + result.data.direct_path.replace("uploads", ""),
					"_blank"
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};
};
