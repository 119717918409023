import * as React from "react";

function Gather({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<path d='M15 2.63c-2.46 0-4.47 2.01-4.47 4.47s2.01 4.47 4.47 4.47 4.47-2.01 4.47-4.47S17.46 2.63 15 2.63zm0 7.03a2.554 2.554 0 010-5.11c1.4 0 2.55 1.15 2.55 2.55S16.4 9.66 15 9.66zM5.78 5.26c-1.98 0-3.59 1.61-3.59 3.59s1.61 3.59 3.59 3.59 3.59-1.61 3.59-3.59-1.61-3.59-3.59-3.59zm0 5.27c-.92 0-1.68-.75-1.68-1.68s.75-1.68 1.68-1.68 1.68.75 1.68 1.68-.75 1.68-1.68 1.68zM24.22 5.26c-1.98 0-3.59 1.61-3.59 3.59s1.61 3.59 3.59 3.59 3.59-1.61 3.59-3.59-1.61-3.59-3.59-3.59zm0 5.27c-.92 0-1.68-.75-1.68-1.68s.75-1.68 1.68-1.68 1.68.75 1.68 1.68-.75 1.68-1.68 1.68zM5.81 16.08zM9.25 15.49c-.88-.9-2.07-1.41-3.34-1.44h-.12c-1.22 0-2.4.49-3.33 1.4-1.02.99-1.6 2.34-1.6 3.7v3.76c0 1.5 1.22 2.71 2.71 2.71h4.5l.03.08c.42 1.02 1.4 1.68 2.5 1.68h8.78c1.09 0 2.08-.66 2.5-1.68l.03-.08h4.5c1.5 0 2.71-1.22 2.71-2.71v-3.95c0-1.32-.51-2.55-1.44-3.47-.92-.93-2.15-1.44-3.47-1.44s-2.7.59-3.64 1.62l-.1.11-.09-.11c-1.35-1.59-3.32-2.5-5.4-2.5s-4.04.91-5.4 2.49l-.09.11-.09-.11c-.05-.06-.11-.12-.16-.17zm-.85 2.15c-.34.84-.51 1.73-.51 2.63v3.43H3.58c-.44 0-.8-.36-.8-.8v-3.76c0-.85.36-1.69 1-2.31.57-.56 1.29-.87 2.03-.87h.03c1.06.02 2.06.64 2.56 1.57l.03.05-.02.05zm13.2-.12c.51-.95 1.54-1.56 2.62-1.56 1.59 0 2.99 1.4 2.99 2.99v3.95c0 .44-.36.8-.8.8H22.1v-3.43c0-.91-.17-1.8-.51-2.64l-.02-.05.03-.05zM9.81 20.27c0-2.86 2.33-5.19 5.19-5.19s5.19 2.33 5.19 5.19v4.39c0 .44-.36.8-.8.8h-8.78c-.44 0-.8-.36-.8-.8v-4.39z' />
		</svg>
	);
}

const MemoGather = React.memo(Gather);
export default MemoGather;
