import constants from "../constants";

const defaultState = {
	id: "",
	language: "en",
	config: {
		chatEnabled: 1,
		presenceEnabled: 1,
		callEnabled: 1,
		goodiebagEnabled: 1,
		interfaceEnabled: 1,
		identityEnabled: 1,
	},
};
const appReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_APPCONFIG:
			// Parse string values to integers
			const parsedPayload = { ...action.payload };
			parsedPayload.chatEnabled = parseInt(parsedPayload?.chatEnabled);
			parsedPayload.callEnabled = parseInt(parsedPayload?.callEnabled);
			parsedPayload.presenceEnabled = parseInt(parsedPayload?.presenceEnabled);
			parsedPayload.goodiebagEnabled = parseInt(
				parsedPayload?.goodiebagEnabled
			);
			parsedPayload.interfaceEnabled = parseInt(
				parsedPayload?.interfaceEnabled
			);
			parsedPayload.identityEnabled = parseInt(parsedPayload?.identityEnabled);
			parsedPayload.startIn = parseInt(parsedPayload?.startIn);
			return {
				...state,
				config: parsedPayload,
			};
		case constants.SET_LANGUAGE:
			return {
				...state,
				language: action.payload,
			};

		case constants.APPEND_APPCONFIG:
			return {
				...state,
				config: Object.assign({ ...state.config }, action.payload),
			};
		default:
			return state;
	}
};

export default appReducer;
