import { useTranslation } from "react-i18next";
import ServerImage from "../../controls/ServerImage";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import defaultAvatar from "../../../assets/img/default-avatar.png";
import { uiActions } from "../../../store/ui";
import constants from "../../../store/constants";

export function ProfileButton({ user }) {
	// const { t } = useTranslation();
	const dispatch = useDispatch();

	const showDefaultProfilePicture = useMemo(() => {
		if (!user || user.avatar === "no-avatar.jpg") return true;
		return false;
	}, [user]);

	const openProfilePanel = () => {
		dispatch(uiActions.openPanel(constants.PANEL.PROFILE));
	};

	return (
		<div
			className="fixed layer--actionbar bottom-5 right-5"
			onClick={openProfilePanel}
			id="btnProfile">
			<div className="cursor-pointer shadow-lg overflow-hidden rounded-full w-14 h-14 bg-white p-1 hover:bg-primary">
				<div className="rounded-full w-full h-full ">
					{showDefaultProfilePicture ? (
						<img
							className="rounded-full w-full h-full object-cover object-center bg-white mb-3  transition-all transform hover:scale-105"
							src={defaultAvatar}
							alt={user?.name}
						/>
					) : (
						<ServerImage
							className="rounded-full w-full h-full object-cover bg-white mb-3  transition-all transform hover:scale-105"
							src={user?.avatar}
							alt={user?.name}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
