import React from "react";
import ServerImage from "../controls/ServerImage";
import { Card, Chat, Camera } from "../../assets/icons";

const AttendeeListItem = ({
	element,
	handleInvite,
	handleVcardInvite,
	handleCallInvite,
	showChatInvite = false,
	showVCardInvite = true,
	showCallInvite = true,
	t,
	showControls = true,
	// className = "",
}) => (
	<li className='flex py-3 border-b border-trueGray-300 space-x-4 items-center px-4'>
		<ServerImage
			className='rounded-full overflow-hidden w-12 h-12 flex-none'
			src={element?.avatar?.replace(".png", "-small.png")}
			alt={element?.name}
		/>
		<div className='flex flex-grow flex-nowrap space-x-4'>
			<div className='font-semibold w-full'>
				<span className='text-trueGray-400 text-xs block'>
					{t("base.participants.name", "Name")}
				</span>
				<span className='block'>{element?.name}</span>
			</div>

			<div className='attendees__list-item-details w-full'>
				<span className='text-trueGray-400 text-xs block'>
					{t("base.participants.entity", "Entity")}
				</span>
				<span className='block'>{element?.company}</span>
			</div>

			<div className='attendees__list-item-details w-full'>
				<span className='text-trueGray-400 text-xs block'>
					{t("base.participants.title", "Title")}
				</span>
				<span className='block'>{element?.role}</span>
			</div>
		</div>
		{showControls && (
			<div className='flex text-primary text-2xl space-x-4'>
				<button
					data-tip={t("base.participants.request_vcard", "Request vCard")}
					className={`${showVCardInvite ? "" : "opacity-25"}`}
					onClick={(e) => {
						handleVcardInvite(element.uuid);
					}}>
					<Card className='' />
				</button>
				<button
					data-tip={t("base.participants.request_chat", "Request Chat")}
					className={`${showChatInvite ? "" : "opacity-25"}`}
					onClick={(e) => {
						handleInvite(element.uuid);
					}}>
					<Chat className='' />
				</button>
				<button
					data-tip={t(
						"base.participants.request_video_call",
						"Request Video Call"
					)}
					className={`${showCallInvite ? "" : "opacity-25"}`}
					onClick={(e) => {
						handleCallInvite(element.uuid);
					}}>
					<Camera className='' />
				</button>
			</div>
		)}
	</li>
);

export default AttendeeListItem;
