import { createSelector } from "reselect";
import chatSlice from "./chatSlice";
import { eventSelectors } from "../event";
import { userSelectors } from "../user";
import { messageSelectors } from "../message";

export const getCurrentConversation = createSelector(
	[chatSlice],
	(chatState) => chatState.activeConversation
);

export const getCurrentConversationName = createSelector(
	[
		getCurrentConversation,
		eventSelectors.getEventIdentitiesById,
		userSelectors.getUserIdentity,
	],
	(currentConversation, identities, currentUser) => {
		if (currentConversation === null) return "";
		if (currentConversation.isGroup) return "group";
		// console.log(currentUser.uuid, currentConversation.participants);
		return currentConversation.participants
			.filter((p) => p !== currentUser.uuid)
			.map((p) => {
				return (
					identities[p] ?? {
						name: "...",
					}
				).name;
			})
			.join(", ");
	}
);

export const getCurrentConversationMessages = createSelector(
	[
		messageSelectors.getMessagesById,
		getCurrentConversation,
		eventSelectors.getEventIdentitiesById,
		userSelectors.getUserIdentity,
	],
	(messages, currentConversation, identities, me) => {
		if (currentConversation === null) return [];
		return messages[currentConversation.channelName]
			? Object.values(messages[currentConversation.channelName]).map(
					(message) => {
						let sender = {};

						if (message.message.senderId === me.uuid) {
							sender = { ...me, me: true };
						} else {
							sender = {
								...(identities[message.message.senderId] ?? {
									name: "?",
									avatar: "dummy.png",
								}),
								me: false,
							};
						}

						return {
							sender,
							text: message.message.text,
							ts: Math.min(
								new Date().getTime(),
								Math.ceil(+message.timetoken / 10000)
							), // Prevent "in a few seconds messages => to test!"
						};
					}
			  )
			: [];
	}
);
export const getRawMessages = (channelName) =>
	createSelector(
		[messageSelectors.getMessagesByChannelName(channelName)],
		(messages) => {
			return messages.map((m) => ({
				...m.message,
				ts: Math.min(new Date().getTime(), Math.ceil(+m.timetoken / 10000)),
			}));
		}
	);

export const getConversationMessages = (channelName) =>
	createSelector(
		[
			messageSelectors.getMessagesByChannelName(channelName),
			eventSelectors.getEventIdentitiesById,
			userSelectors.getUserIdentity,
		],
		(messages, identities, me) => {
			return messages
				? Object.values(messages).map((message) => {
						let sender = {};

						if (message.message.senderId === me.uuid) {
							sender = { ...me, me: true };
						} else {
							sender = {
								...(identities[message.message.senderId] ?? {
									name: "?",
									avatar: "dummy.png",
								}),
								me: false,
							};
						}

						return {
							sender,
							text: message.message.text,
							ts: Math.min(
								new Date().getTime(),
								Math.ceil(+message.timetoken / 10000)
							), // Prevent "in a few seconds messages => to test!"
						};
				  })
				: [];
		}
	);

export const getConversations = createSelector(
	[chatSlice],
	(chat) => chat.conversations
);

export const getConversationCount = createSelector(
	[chatSlice],
	(chatState) => chatState.conversations.length
);

export const getLastMessageReceived = createSelector(
	[chatSlice],
	(chatSlice) => chatSlice.lastMessageAt
);
