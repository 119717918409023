import * as React from "react";

function Chat({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M1.6 28.88a1 1 0 01-1-1v-12.4a4.78 4.78 0 014.79-4.79H15a4.75 4.75 0 013.38 1.4 4.8 4.8 0 011.41 3.39v5.74A4.79 4.79 0 0115 26H5.74l-3.59 2.7a.91.91 0 01-.55.18zm3.83-16.27a2.88 2.88 0 00-2.88 2.87V26l2.3-1.72a.94.94 0 01.58-.19H15a2.87 2.87 0 002.87-2.88v-5.73A2.87 2.87 0 0015 12.6zm0 0" />
			<path d="M28.4 19.3a.94.94 0 01-.58-.19l-3.59-2.69h-1.58a1 1 0 110-1.91h1.92a.93.93 0 01.58.18l2.3 1.73V5.9a2.88 2.88 0 00-.85-2 2.83 2.83 0 00-2-.84H15a2.87 2.87 0 00-2.87 2.84v1.92a1 1 0 01-.25.73.95.95 0 01-1.42 0 1 1 0 01-.25-.73V5.9A4.79 4.79 0 0115 1.12h9.57a4.8 4.8 0 014.8 4.78v12.45a1 1 0 01-1 .95zm0 0M12.12 17.42H6.38a1 1 0 01-.74-.25 1 1 0 010-1.42 1 1 0 01.74-.25h5.74a1 1 0 01.74.25 1 1 0 010 1.42 1 1 0 01-.74.25zm0 0M9.25 21.24H6.38a1 1 0 01-.74-.24 1 1 0 010-1.43 1 1 0 01.74-.24h2.87a1 1 0 01.74.24A1 1 0 0110 21a1 1 0 01-.74.24zm0 0" />
		</svg>
	);
}

const MemoChat = React.memo(Chat);
export default MemoChat;
