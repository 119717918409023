import constants from "../constants";
import { MessageActionType, sendMessage } from "pubnub-redux";
import { streamerActions } from "../streamer";

import { v4 as uuid } from "uuid";
import { goodiebagActions } from "../goodiebag";
import { chatActions } from "../chat";
import { callActions } from "../call";
import { loggingActions } from "../logging";
import { uiActions } from "../ui";
import { videoActions } from "../video";
import { notificationActions } from "../notification";

const MessageInterceptor = (store) => (next) => (action) => {
	// Do anything here: pass the action onwards with next(action),
	// or restart the pipeline with storeAPI.dispatch(action)
	// Can also use storeAPI.getState() here

	let allowNext = true;

	// If need to dispatch other actions. Otherwise return;
	const dispatch = store.dispatch;

	if (action && action.type === MessageActionType.MESSAGE_RECEIVED) {
		if (
			action.payload.hasOwnProperty("message") &&
			action.payload.message.hasOwnProperty("type") &&
			action.payload.message.type !== "text" // internal message handled by framework
		) {
			const callState = store.getState().call;
			const me = store.getState().user.identity;
			// Temporarily disabled

			if (action.payload.message.type === constants.MESSAGE_TYPE.USER_JOINED) {
				if (action.payload.publisher !== me.uuid)
					// Prevent notifications of your own streamer instance
					dispatch(
						notificationActions.addNotification({
							type: "INFO",
							title: `${action.payload.message.payload.userName} has joined the session`,
							message:
								"Find this person in the environment or in the participants menu.",
						})
					);
			}

			// Modal exceptions that require extra step
			if (action.payload.message.type === constants.MESSAGE_TYPE.CHAT_DECLINE) {
				if (action.payload.message.message.vCard) {
					dispatch(
						goodiebagActions.addItemToGoodieBag(action.payload.publisher)
					);
					dispatch(
						loggingActions.logActivity(
							constants.ACTIVITY.VCARD_ACCEPT,
							action.payload.publisher
						)
					);
				}
			} else if (
				action.payload.message.type === constants.MESSAGE_TYPE.VCARD_ACCEPT
			) {
				dispatch(goodiebagActions.addItemToGoodieBag(action.payload.publisher));
				dispatch(
					loggingActions.logActivity(
						constants.ACTIVITY.VCARD_ACCEPT,
						action.payload.publisher
					)
				);
			}

			switch (action.payload.message.type) {
				case constants.MESSAGE_TYPE.CALL_INVITE:
					// Check if user is in a call. If so reject automatically
					if (store.getState().call.isConnected) {
						dispatch(
							sendMessage({
								channel: `${action.payload.publisher}--private`,
								message: {
									type: constants.MESSAGE_TYPE.CALL_DECLINE,
								},
							})
						);
					} else {
						dispatch({
							type: constants.ADD_MODAL,
							payload: {
								type: action.payload.message.type,
								id: uuid(),
								ts: new Date().getTime(),
								message: action.payload.message.payload.callID,
								from: action.payload.publisher,
							},
						});
					}
					break;
				case constants.MESSAGE_TYPE.HOSTEDCALL_INVITE:
					if (store.getState().call.isConnected) {
						dispatch(
							sendMessage({
								channel: `${action.payload.publisher}--private`,
								message: {
									type: constants.MESSAGE_TYPE.CALL_DECLINE,
								},
							})
						);
					} else {
						dispatch({
							type: constants.ADD_MODAL,
							payload: {
								type: action.payload.message.type,
								id: uuid(),
								ts: new Date().getTime(),
								message: action.payload.message.payload.callID,
								from: action.payload.publisher,
							},
						});
					}

					break;
				case constants.MESSAGE_TYPE.CHAT_INVITE:
				case constants.MESSAGE_TYPE.CHAT_DECLINE:
				case constants.MESSAGE_TYPE.VCARD_REQUEST:
				case constants.MESSAGE_TYPE.VCARD_ACCEPT:
				case constants.MESSAGE_TYPE.VCARD_DECLINE:

				case constants.MESSAGE_TYPE.CALL_DECLINE: // eslint-disable-line no-fallthrough
					dispatch({
						type: constants.ADD_MODAL,
						payload: {
							type: action.payload.message.type,
							id: uuid(),
							ts: new Date().getTime(),
							message: action.payload.message.message,
							from: action.payload.publisher,
						},
					});
					break;
				case constants.MESSAGE_TYPE.NOTIFICATION:
					dispatch(
						notificationActions.addNotification(action.payload.message.payload)
					);
					break;
				case constants.MESSAGE_TYPE.CHAT_ACCEPT:
					// TODO: CHECK DENIES / VCARD
					dispatch(chatActions.acceptChat(action.payload.publisher));
					break;

				case constants.MESSAGE_TYPE.CALL_ACCEPT:
					// TODO: CHECK DENIES / VCARD
					dispatch(
						callActions.acceptCall(
							action.payload.publisher,
							action.payload.message.payload.callID
						)
					);
					break;

				case constants.MESSAGE_TYPE.ROOM_SWITCH:
					break;

				case constants.MESSAGE_TYPE.SESSION_START:
					// Make sure we shut off the video call
					if (action.payload.channel.indexOf("group-") > -1) {
						dispatch({
							type: constants.SET_REMOTE_END,
							payload: true,
						});
					}

					dispatch(
						uiActions.setLayer(
							constants.LAYER.VIDEO,
							true,
							!store.getState().ui.layers[constants.LAYER.CALL]
						)
					);

					dispatch(
						videoActions.playVideo(
							action.payload.message.payload.refs,
							action.payload.message.payload.locale
						)
					);
					break;
				case constants.MESSAGE_TYPE.SESSION_END:
					dispatch(videoActions.stopVideo());
					dispatch(uiActions.setLayer(constants.LAYER.streamer, true, true));
					break;

				case constants.MESSAGE_TYPE.MEETING_START:
					dispatch({
						type: constants.ADD_MODAL,
						payload: {
							type: action.payload.message.type,
							id: uuid(),
							ts: new Date().getTime(),
							message: action.payload.message.payload,
							from: action.payload.publisher,
						},
					});
					break;
				case constants.MESSAGE_TYPE.MEETING_END:
					break;

				case constants.MESSAGE_TYPE.ROUTE_SWITCH:
					dispatch(uiActions.setRoute(action.payload.message.payload.route));

					// if (action.payload.message.payload.route === "event") {
					// 	dispatch(
					// 		uiActions.setLayer(constants.LAYER.VIDEO, true, true)
					// 	);
					// 	dispatch(videoActions.playVideo("INTRO", false, false));
					// }
					// dispatch({
					// 	type: constants.SET_ROUTE,
					// 	payload: action.payload.message.payload.route,
					// });
					break;
				case constants.MESSAGE_TYPE.GROUP_TELEPORT:
					dispatch(
						streamerActions.sendMessage([
							constants.STREAMER_OUTGOING_ACTIONS.TELEPORT_ME.replace(
								"$reference",
								action.payload.message.payload.reference
							),
							"MOVEMENT:TRUE",
						])
					);

					break;
				case constants.MESSAGE_TYPE.RESTART_CALL:
					if (!callState.isConnected) {
						dispatch(uiActions.setLayer(constants.LAYER.CALL, true, false));
						dispatch({
							type: constants.SET_CALL_LAYOUT,
							payload: constants.CALL_LAYOUT.SIDEBAR,
						});
						dispatch({
							type: constants.SET_REMOTE_END,
							payload: false,
						});

						// End streamer
						// Start call again
						dispatch(
							callActions.initCall(
								"group-" + store.getState().user.group.id,
								false
							)
						);
					}
					break;
				case constants.MESSAGE_TYPE.SWITCH_VIDEO:
					if (action.payload.message.payload.mode === callState.layout) break;

					if (
						action.payload.message.payload.mode === constants.CALL_LAYOUT.OFF
					) {
						dispatch({
							type: constants.SET_REMOTE_END,
							payload: true,
						});
					}
					dispatch({
						type: constants.SET_CALL_LAYOUT,
						payload: action.payload.message.payload.mode,
					});
					break;

				case constants.MESSAGE_TYPE.START_TOUR:
					// dispatch({
					// 	type: constants.SET_REMOTE_END,
					// 	payload: true,
					// });

					dispatch({
						type: constants.SET_CALL_LAYOUT,
						payload: constants.CALL_LAYOUT.PIP,
					});

					dispatch(
						uiActions.setLayer(
							constants.LAYER.streamer,
							true,
							!store.getState().ui.layers[constants.LAYER.CALL]
						)
					);

					break;
				case constants.MESSAGE_TYPE.RELEASE_GROUP:
					/*
					dispatch({
						type: constants.SET_REMOTE_END,
						payload: true,
					});
					dispatch(streamerActions.sendMessage("MOVEMENT:TRUE"));
					*/
					// dispatch(
					// 	uiActions.setLayer(constants.LAYER.streamer, true, true)
					// );
					break;
				case constants.MESSAGE_TYPE.GATHER_GROUP:
					dispatch(uiActions.setLayer(constants.LAYER.CALL, true, true));
					dispatch({
						type: constants.SET_CALL_LAYOUT,
						payload: constants.CALL_LAYOUT.FULLSCREEN,
					});
					// End streamer
					// Start call again
					dispatch(
						callActions.initCall(
							"group-" + store.getState().user.group.id,
							false
						)
					);
					break;
				case constants.MESSAGE_TYPE.QUESTION:
					break;
				case constants.MESSAGE_TYPE.SCREENSHARE_START:
					break;
				case constants.MESSAGE_TYPE.RECEIVE_FILE:
					dispatch(notificationActions.addPing("GOODIEBAG"));

					dispatch(
						notificationActions.addNotification({
							type: "INFO",
							title: "T|file_received_notification_title",
							message: "T|file_received_notification_message",
						})
					);
					break;
				default:
					// Do nothing
					break;
			}

			if (action.payload.message.type === constants.MESSAGE_TYPE.QUESTION)
				allowNext = true;
			else allowNext = false;
		} else {
			// Regular chat messages
			try {
				dispatch({
					type: constants.SET_LAST_MESSAGE_RECEIVED,
					payload: new Date().getTime(),
				});

				dispatch(notificationActions.addPing(action.payload.channel));
			} catch (e) {
				console.log(e);
			}
			//console.log("CRAAASHED");
		}
	}

	if (allowNext) return next(action);
};

export default MessageInterceptor;
