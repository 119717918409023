import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Close, Keyboard, Language, Mouse } from "../../assets/icons";
import { streamerActions, streamerSelectors } from "../../store/streamer";
import { uiActions } from "../../store/ui";
import { PrimaryButton } from "../controls/PrimaryButton";
import reactStringReplace from "react-string-replace";
import { InlineLanguageSwitcher } from "../locale/InlineLanguageSwitcher";
import { useTranslation } from "react-i18next";
import constants from "../../store/constants";

export const NavigationPanel = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	// const [activeTab, setActiveTab] = useState(0);

	const mode = useSelector(streamerSelectors.getstreamerNavState);

	const switchMode = (m) => {
		dispatch(
			streamerActions.sendMessage(
				constants.STREAMER_OUTGOING_ACTIONS.NAVMODE.replace("$m", m)
			)
		);
		dispatch(streamerActions.setNavState(m));
	};

	return (
		<div className="bg-white rounded-md shadow-lg absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-2xl p-14 pt-7 pb-10 text-center">
			<Close
				className="absolute right-6 top-6 cursor-pointer"
				onClick={() => dispatch(uiActions.closePanel())}
			/>

			<>
				<h1 className="font-semibold text-2xl mb-5">
					{t("base.navigationmode", "Navigation mode")}
				</h1>

				<div className="flex flex-col space-y-8">
					<p className="whitespace-pre-line">
						{reactStringReplace(
							t(
								"base.navigationmode_text",
								"Please select which mode you want to use. \nYou can switch at any time by selecting the settings from the actionbar."
							),
							"\\n",
							(match, i) => (
								<br key={i} />
							)
						)}
					</p>

					<div className="flex flex-col space-y-4">
						<div
							className={`w-full flex space-x-4  border border-gray-300 cursor-pointer rounded-lg p-4 hover:shadow-lg ${
								mode === "fps" ? " ring-2 ring-primary" : ""
							}`}
							onClick={(e) => {
								switchMode("fps");
							}}>
							<div className="relative w-1/4">
								<svg
									className="w-3/4 h-auto"
									id="Layer_2"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 612 660.39">
									<g>
										<path
											d="M337.24,165.36c8.46,1.23,29.89,4.37,35.52,5.39l.94,.17c2.94,.52,6.61,1.18,8.7,2.59,.78,.52,1.95,1.31,1.51,5.23l-.02,.17v.17c-.34,4.55-8.03,111.47-8.28,115.43-.02,.27-.03,.59-.05,.96-.11,2.17-.31,6.22-1.47,9.33-.87,2.34-1.45,2.34-1.99,2.34,0,0-4.74,.01-8.27,.01-1.61,0-2.96,0-3.42-.01-.1,0-.2,0-.3,0-4.6,0-9.87,2.56-10.86,9.74l-.03,.21-.02,.21-15.52,172.61c-6.19,1.55-16.73,3.71-27.7,3.71s-21.5-2.16-27.7-3.72l-15.52-172.6-.02-.21-.03-.21c-.99-7.18-6.26-9.74-10.86-9.74-.1,0-.2,0-.3,0-.46,0-1.8,.01-3.42,.01-3.52,0-8.26-.01-8.29-.01-.51,0-1.09,0-1.96-2.34-1.16-3.11-1.36-7.16-1.47-9.33-.02-.37-.03-.69-.05-.96-.25-3.96-7.94-110.88-8.26-115.43v-.17s-.03-.17-.03-.17c-.44-3.92,.74-4.71,1.51-5.23,2.1-1.41,5.76-2.06,8.7-2.59l.94-.17c5.63-1.02,27.06-4.16,35.52-5.39,11.54,6.89,27.65,7.27,31.04,7.27h.2s.19,0,.19,0c3.39,0,19.5-.38,31.04-7.27m-2.17-9.41c-9.33,7.46-26.84,7.68-28.87,7.68-.13,0-.19,0-.19,0,0,0-.07,0-.19,0-2.03,0-19.55-.22-28.87-7.68,0,0-32.04,4.62-39.3,5.94-7.27,1.32-20.15,2.97-18.5,17.83,0,0,8.01,111.39,8.26,115.35,.25,3.96,.33,21.07,12.47,21.07,0,0,4.76,.01,8.29,.01,1.69,0,3.1,0,3.58-.01,.05,0,.09,0,.14,0,1.37,0,1.73,.42,1.94,1.97l16.07,178.79s17.39,5.72,36.11,5.72,36.11-5.72,36.11-5.72l16.07-178.79c.21-1.55,.57-1.97,1.94-1.97,.04,0,.09,0,.14,0,.48,0,1.89,.01,3.58,.01,3.54,0,8.29-.01,8.29-.01,12.14,0,12.22-17.1,12.47-21.07,.25-3.96,8.26-115.35,8.26-115.35,1.65-14.86-11.23-16.51-18.5-17.83-7.27-1.32-39.3-5.94-39.3-5.94h0Z"
											fill="#c8202f"
										/>
										<path
											d="M305.78,35.16c26.12,0,47.37,21.25,47.37,47.37s-21.25,47.37-47.37,47.37-47.37-21.25-47.37-47.37,21.25-47.37,47.37-47.37m0-9c-31.13,0-56.37,25.24-56.37,56.37s25.24,56.37,56.37,56.37,56.37-25.24,56.37-56.37-25.24-56.37-56.37-56.37h0Z"
											fill="#c8202f"
										/>
									</g>
									<path
										d="M306,529.66c4.89,0,8.86,3.94,8.86,8.8v66.79l20.31-20.2c3.46-3.44,9.08-3.44,12.55,0s3.46,9.02,0,12.47l-35.44,35.22c-3.46,3.44-9.07,3.45-12.53,.02,0,0-.01-.01-.02-.02l-35.44-35.22c-3.46-3.44-3.46-9.02,0-12.47,3.46-3.44,9.08-3.44,12.55,0l20.31,20.2v-66.79c0-4.86,3.97-8.8,8.86-8.8Zm-190.74-121.32c-3.46-3.43-3.47-9.01-.02-12.45,0,0,.01-.01,.02-.02l35.44-35.22c3.46-3.44,9.08-3.44,12.55,0,3.46,3.44,3.46,9.02,0,12.47l-20.33,20.18h67.21c4.89,0,8.86,3.94,8.86,8.8s-3.97,8.8-8.86,8.8h-67.21l20.33,20.18c3.46,3.44,3.46,9.02,0,12.47-3.46,3.44-9.08,3.44-12.55,0h0s-35.44-35.22-35.44-35.22Zm265.71-6.23c0-4.86,3.97-8.8,8.86-8.8h67.21l-20.33-20.18c-3.46-3.44-3.46-9.02,0-12.47s9.08-3.44,12.55,0h0l35.44,35.22c3.46,3.43,3.47,9.01,.02,12.45,0,0-.01,.01-.02,.02l-35.44,35.22c-3.46,3.44-9.08,3.44-12.55,0-3.46-3.44-3.46-9.02,0-12.47l20.33-20.18h-67.21c-4.89,0-8.86-3.94-8.86-8.8Z"
										fill="#c8202f"
										fillRule="evenodd"
									/>
								</svg>
								<div className="absolute  right-0 top-1/2 -translate-y-1/2 flex flex-col items-center space-y-2">
									<Keyboard className="w-12 h-12 text-primary" />
									<Mouse className="w-10 h-10 text-primary" />
								</div>
							</div>
							<div className="flex flex-col justify-between text-left pr-2">
								<p className="whitespace-pre-line text-left">
									{reactStringReplace(
										t(
											"base.navigationmode_fps",
											"Use keyboard and mouse.\nIdeal for users with experience in 3D software or games."
										),
										"\\n",
										(match, i) => (
											<br key={i} />
										)
									)}
								</p>
								<button
									onClick={(e) => {
										switchMode("fps");
									}}
									className={`font-semibold text-left ${
										mode === "fps" ? "text-gray-500" : "text-primary"
									}`}>
									{mode === "fps" && (
										<span>{t("base.sb_current", "Active mode")}</span>
									)}
									{mode !== "fps" && (
										<span>{t("base.sb_select", "Click to switch")}</span>
									)}
								</button>
								{/* <PrimaryButton
									className="inline-block px-4 w-auto ml-auto float-right"
									disabled={mode === "fps"}
									onClick={(e) => {
										switchMode("fps");
									}}>
									
								</PrimaryButton> */}
							</div>
						</div>
						<div
							className={`w-full flex space-x-4 border border-gray-300 cursor-pointer rounded-lg hover:shadow-lg p-4 ${
								mode === "mouse" ? " ring-2 ring-primary" : ""
							}`}
							onClick={(e) => {
								switchMode("mouse");
							}}>
							<div className="relative w-1/4">
								<svg className="mx-auto w-3/4 h-auto" viewBox="0 0 612 660.18">
									<path
										d="M129.01 215.36c11.54 6.89 27.65 7.27 31.04 7.27h.39c3.39 0 19.5-.38 31.04-7.27 8.46 1.23 29.89 4.37 35.52 5.39l.94.17c2.94.52 6.61 1.18 8.7 2.59.78.52 1.95 1.31 1.51 5.23l-.02.17v.17c-.34 4.55-8.03 111.47-8.28 115.43-.02.27-.03.59-.05.96-.11 2.17-.31 6.22-1.47 9.33-.87 2.34-1.45 2.34-1.99 2.34 0 0-4.75.01-8.28.01-1.61 0-2.95 0-3.41-.01h-.3c-4.6 0-9.87 2.56-10.86 9.74l-.03.21-.02.21-15.52 172.61c-6.19 1.55-16.73 3.71-27.7 3.71s-21.5-2.16-27.7-3.72L117 367.3l-.02-.21-.03-.21c-.99-7.18-6.26-9.74-10.86-9.74h-.3c-.46 0-1.8.01-3.41.01-3.53 0-8.27-.01-8.3-.01-.51 0-1.09 0-1.96-2.34-1.16-3.11-1.36-7.16-1.47-9.33-.02-.37-.03-.69-.05-.96-.25-3.96-7.94-110.88-8.26-115.43v-.17l-.03-.17c-.44-3.92.74-4.71 1.51-5.23 2.1-1.41 5.76-2.06 8.7-2.59l.94-.17c5.63-1.02 27.06-4.16 35.52-5.39m60.3-9.41c-9.33 7.46-26.84 7.68-28.87 7.68h-.19-.19c-2.03 0-19.55-.22-28.87-7.68 0 0-32.04 4.62-39.3 5.94-7.27 1.32-20.15 2.97-18.5 17.83 0 0 8.01 111.39 8.26 115.35.25 3.96.33 21.07 12.47 21.07 0 0 4.76.01 8.3.01 1.69 0 3.1 0 3.58-.01h.14c1.37 0 1.73.42 1.94 1.97l16.07 178.79s17.39 5.72 36.11 5.72 36.11-5.72 36.11-5.72l16.07-178.79c.21-1.55.57-1.97 1.94-1.97h.14c.48 0 1.89.01 3.58.01 3.54 0 8.3-.01 8.3-.01 12.14 0 12.22-17.1 12.47-21.07.25-3.96 8.26-115.35 8.26-115.35 1.65-14.86-11.23-16.51-18.5-17.83-7.27-1.32-39.3-5.94-39.3-5.94zM160.03 85.16c26.12 0 47.37 21.25 47.37 47.37s-21.25 47.37-47.37 47.37-47.37-21.25-47.37-47.37 21.25-47.37 47.37-47.37m0-9c-31.13 0-56.37 25.24-56.37 56.37s25.24 56.37 56.37 56.37 56.37-25.24 56.37-56.37-25.24-56.37-56.37-56.37z"
										fill="#c8202f"
									/>
									<path
										d="M510.68 354.48l-17.62 44.06-35.25-96.94 96.94 35.25-44.06 17.62zm0 0l44.06 44.06M441.83 242.02l6.85 25.53m-24.93 24.93l-25.54-6.85m103.21-27.65l-18.7 18.7"
										fill="#fff"
										stroke="#c8202f"
										strokeMiterlimit={10}
										strokeWidth={9}
									/>
									<path
										d="M196.36 546.91c105.87-36.86 184.71-110.32 236.51-220.38"
										fill="#fff"
									/>
									<g
										fill="none"
										stroke="#c8202f"
										strokeMiterlimit={10}
										strokeWidth={9}>
										<path d="M196.36 546.91c3.16-1.1 6.29-2.23 9.41-3.4" />
										<path
											d="M225.09 535.72c85.81-37.13 152.06-100.76 198.74-190.88"
											strokeDasharray="0 0 20.83 20.83"
										/>
										<path d="M428.53 335.54c1.47-2.98 2.92-5.98 4.34-9.01" />
									</g>
								</svg>
								<div className="absolute right-0 top-0 flex items-center space-x-2">
									<Mouse className="w-10 h-10 text-primary" />
								</div>
							</div>
							<div className="flex flex-col justify-between pr-4">
								<p className="whitespace-pre-line text-left">
									{reactStringReplace(
										t(
											"base.navigationmode_mouse",
											"Use mouse or trackpad exclusively.\nIdeal for new users or laptop and mobile devices without keyboard."
										),
										"\\n",
										(match, i) => (
											<br key={i} />
										)
									)}
								</p>
								<button
									onClick={(e) => {
										switchMode("mouse");
									}}
									className={`font-semibold text-left ${
										mode === "mouse" ? "text-gray-500" : "text-primary"
									}`}>
									{mode === "mouse" && (
										<span>{t("base.sb_current", "Active mode")}</span>
									)}
									{mode !== "mouse" && (
										<span>{t("base.sb_select", "Click to switch")}</span>
									)}
								</button>
								{/* <PrimaryButton
									disabled={mode === "mouse"}
									onClick={(e) => {
										switchMode("mouse");
									}}>
									{mode === "mouse" && (
										<span>{t("base.sb_current", "Active")}</span>
									)}
									{mode !== "mouse" && (
										<span>{t("base.sb_select", "Select")}</span>
									)}{" "}
								</PrimaryButton> */}
							</div>
						</div>
					</div>
				</div>
			</>

			<div className="flex space-x-4 items-center justify-center mt-5 pt-4 border-t">
				<Language className={`text-4xl text-secondary`} />
				<h1 className="font-semibold text-2xl ">
					{t("base.language-selection", "Language")}
				</h1>
				<InlineLanguageSwitcher />
				{/* <div className='mt-4 flex justify-center items-center space-x-4 -mb-4 pt-4 border-t border-t-secondary'>

				</div> */}
			</div>
		</div>
	);
};
