import * as React from "react";

function Guide({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 35 35"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M7.25 22.7a5.13 5.13 0 00-5.13 5.12V34a1 1 0 001 1h16.43a1 1 0 001-1v-6.18a5.13 5.13 0 00-5.13-5.12h-1v-2.16a5.14 5.14 0 004.11-5V12.3h1a1 1 0 00.87-.48 1 1 0 000-1l-1.87-3.88V5.13c0-4-3.15-5.13-7.18-5.13S4.17 1.1 4.17 5.13v1.81l-1.94 3.88a1 1 0 000 1 1 1 0 00.87.48h1v3.22a5.14 5.14 0 004.1 5v2.18zm-3.08 5.12a3.08 3.08 0 012.56-3l2.7 4.06H8.27a1 1 0 00-1 1V33h-3.1zM13.4 33H9.3v-2.1h4.1zm5.13-5.13V33h-3.08v-3.13a1 1 0 00-1-1h-1.19L16 24.79a3.08 3.08 0 012.56 3zM4.8 10.25l1-2.05h11l1 2.05zm6.55-8.2c4.25 0 5.12 1.23 5.12 3.08v1H6.22v-1c0-1.85.87-3.08 5.13-3.08zM6.22 15.52V12.3h10.25v3.22a3.08 3.08 0 01-3.07 3.07 1 1 0 00-1 1v4.1a1 1 0 001 1h.13L11.35 28l-2.19-3.25h.14a1 1 0 001-1v-4.1a1 1 0 00-1-1 3.08 3.08 0 01-3.08-3.07zm0 0M24.68 34v-7.59l7.63-3.82a1 1 0 000-1.84l-8.2-4.1a1 1 0 00-1 .05 1 1 0 00-.48.87V34a1 1 0 102.05 0zm0-14.74l4.88 2.44-4.88 2.44v-4.91zm0 0" />
		</svg>
	);
}

const MemoGuide = React.memo(Guide);
export default MemoGuide;
