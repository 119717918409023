import * as React from "react";

function Goodiebag({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M26 23.36l-1.48-13.9a1 1 0 00-1-.85h-2.9v-.22C20.65 5.45 18.12 4 15 4S9.35 5.45 9.35 8.39v.22h-2.9a1 1 0 00-1 .85L4 23.36a2.35 2.35 0 00.68 1.85 2.65 2.65 0 001.9.79h16.83a2.65 2.65 0 001.9-.79 2.35 2.35 0 00.69-1.85zm-14.7-15c-.01-1.9 1.7-2.53 3.7-2.53s3.71.63 3.71 2.56v.22h-7.42zM23.89 24a.67.67 0 01-.48.2H6.59a.67.67 0 01-.48-.2.58.58 0 01-.17-.46l1.41-13.09h2v2.9a1 1 0 001.94 0v-2.9h7.42v2.9a1 1 0 001.94 0v-2.9h2l1.41 13.06a.58.58 0 01-.17.46zm0 0" />
		</svg>
	);
}

const MemoGoodiebag = React.memo(Goodiebag);
export default MemoGoodiebag;
