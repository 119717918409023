import { createSelector } from "reselect";
import streamerSlice from "./streamerSlice";

export const getstreamerId = createSelector(
	[streamerSlice],
	(slice) => slice.id
);
export const getstreamerState = createSelector(
	[streamerSlice],
	(slice) => slice.state
);
export const getstreamerServerConfig = createSelector(
	[streamerSlice],
	(slice) => {
		return {
			serverPath: slice.server.serverPath,
			useHttps: slice.server.https === "1",
			iceServers: [
				{
					urls: [`stun:${slice.server.stunUrl}`],
					username: "",
					credential: "",
				},
				{
					urls: [`turn:${slice.server.turnUrl}`],
					username: slice.server.turnUsername,
					credential: slice.server.turnCredential,
				},
			],
		};
	}
);
export const getstreamerOutgoingMessages = createSelector(
	[streamerSlice],
	(slice) => slice.outgoingMessages
);
export const getstreamerNavState = createSelector(
	[streamerSlice],
	(slice) => slice.navState
);
