import constants from "../constants";
import { v4 as uuid } from "uuid";

export const clearNotification = (notif) => ({
	type: constants.CLOSE_NOTIFICATION,
	payload: notif,
});

export const addPing = (key) => {
	return function addPingAsync(dispatch, getState, context) {
		// Check if the chat for this ping isn't currently open
		const activeChatChannel = getState().chat.activeConversation;

		if (activeChatChannel !== null && activeChatChannel.channelName === key)
			return;

		dispatch({
			type: constants.ADD_PING,
			payload: key,
		});
	};
};

export const clearPing = (key) => ({
	type: constants.CLEAR_PING,
	payload: key,
});

export const addNotification = (message) => ({
	type: constants.ADD_NOTIFICATION,
	payload: {
		id: uuid(),
		ts: new Date().getTime(),
		message,
	},
});
