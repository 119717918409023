import * as React from "react";

function List({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<path d='M26.22 9.85H9.55c-.71 0-1.28-.57-1.28-1.28s.57-1.28 1.28-1.28h16.67c.71 0 1.28.57 1.28 1.28s-.57 1.28-1.28 1.28zm0 0M6.35 8.56a1.92 1.92 0 11-3.841-.001 1.92 1.92 0 013.841.001zm0 0M26.22 17.01H9.55c-.71 0-1.28-.57-1.28-1.28s.57-1.28 1.28-1.28h16.67c.71 0 1.28.57 1.28 1.28s-.57 1.28-1.28 1.28zm0 0M6.35 15.73a1.92 1.92 0 11-3.841-.001 1.92 1.92 0 013.841.001zm0 0M26.22 24.18H9.55c-.71 0-1.28-.57-1.28-1.28s.57-1.28 1.28-1.28h16.67c.71 0 1.28.57 1.28 1.28s-.57 1.28-1.28 1.28zm0 0M6.35 22.9a1.92 1.92 0 11-3.841-.001 1.92 1.92 0 013.841.001zm0 0' />
		</svg>
	);
}

const MemoList = React.memo(List);
export default MemoList;
