import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
// import ActionBar from "../components/ui/t-city/ActionBar";
import PanelManager from "../components/PanelManager";
import ModalManager from "../components/ModalManager";
import OverlayManager from "../components/OverlayManager";

import constants from "../store/constants";
import CallManager from "../components/CallManager";

import { useTimeout } from "react-use-timeout";
import { fetchHereNow } from "pubnub-redux";
import { uiActions, uiSelectors } from "../store/ui";
import { appSelectors } from "../store/app";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useState } from "react";
// import { userSelectors } from "../store/user";
import DevActions from "../components/dev/DevActions";
import { StreamingPlayer } from "../components/streaming/StreamingPlayer";
import { eventSelectors } from "../store/event";
import { HostActionBar } from "../components/ui/digital-showroom/host/HostActionBar";
import { SalesNotes } from "../components/sales/SalesNotes";
import { SalesFiles } from "../components/sales/SalesFiles";
import { ProfileButton } from "../components/ui/digital-showroom/ProfileButton";
import { GuestControls } from "../components/ui/digital-showroom/guest/GuestControls";
import { PanelSwitcher } from "../components/dev/PanelSwitcher";
import { SalesParticipants } from "../components/sales/SalesParticipants";
import { Tutorial } from "../components/Tutorial";
import { userSelectors } from "../store/user";
import { IframeOverlay } from "../components/overlays/IframeOverlay";

// eslint-disable-next-line no-unused-vars
// const getcurrentRoute = createSelector(
// 	(state) => state.router,
// 	(router) => router.location.pathname
// );

//#region Main function
const EventPage = () => {
	const { t } = useTranslation();
	const appConfig = useSelector(appSelectors.getAppConfig);

	// const user = useSelector((state) => state.user);
	const uiLayers = useSelector(uiSelectors.getLayers);
	const streamerLayerActive = useSelector(uiSelectors.streamerLayerActive);
	// const isHost = useSelector(userSelectors.getIsHost); // eslint-disable-line no-unused-vars
	// const event = useSelector((state) => state.event);
	const uiVisible = useSelector(uiSelectors.getUIVisible);
	const isHosted = useSelector(eventSelectors.getIsHosted);
	const user = useSelector(userSelectors.getUserIdentity);

	const dispatch = useDispatch();

	// If internal only we need to notify the user up front
	const internalUse = useMemo(
		() =>
			appConfig.hasOwnProperty("internal_use") &&
			parseInt(appConfig.internal_use) === 1,
		[appConfig]
	);

	const devMode = useMemo(
		() =>
			appConfig.hasOwnProperty("devMode") && parseInt(appConfig.devMode) === 1,
		[appConfig]
	);

	const [hasReadInternalNotice, setHasReadInternalNotice] = useState(false);

	const refreshUserList = useCallback(() => {
		// dispatch(eventActions.getPeopleInEvent());
		dispatch(
			fetchHereNow({
				channels: ["main"],
				includeUUIDs: true,
				includeState: false,
			})
		);
	}, [dispatch]);

	const refreshUserListInterval = useTimeout(refreshUserList, 120000);

	useEffect(() => {
		dispatch(uiActions.setLayer(constants.LAYER.STREAMER, true, true));
		// dispatch(uiActions.setLayer(constants.LAYER.IFRAME, true, false));
		// Fetch here now after another minute to catch people that have joined roughly
		// at the same time we joined
		refreshUserListInterval.start();
	}, [dispatch, refreshUserListInterval]);

	return (
		<>
			{streamerLayerActive && <StreamingPlayer />}
			{uiLayers[constants.LAYER.CALL] && appConfig.callEnabled === 1 && (
				<CallManager host={isHosted} user={user} />
			)}

			{uiVisible && (
				<>
					{/* <ActionBar /> */}
					{!isHosted && <GuestControls />}
					<ProfileButton user={user} />
					{isHosted && <HostActionBar />}
					<PanelManager />
					<ModalManager />
					<OverlayManager />
					{/* <DevActions t={t} /> */}
					{devMode && (
						<div className="fixed layer--top">
							<DevActions t={t} />
							{/* <PanelSwitcher /> */}
						</div>
					)}
				</>
			)}

			<Tutorial host={isHosted} user={user} />

			{/* Add sales mode enabled on appConfig  */}
			{uiLayers[constants.LAYER.SALES] && <SalesNotes />}

			{uiLayers[constants.LAYER.FILES] && <SalesFiles />}

			{uiLayers[constants.LAYER.PARTICIPANTS] && <SalesParticipants />}

			{uiLayers[constants.LAYER.IFRAME] && <IframeOverlay />}

			{internalUse && !hasReadInternalNotice && (
				<div className="fixed layer--notices bg-white bg-opacity-75 left-0 top-0 h-screen w-screen flex justify-center items-center">
					<div className="md:w-1/2 md:max-w-sm mx-auto p-10 bg-white shadow-xl rounded-2xl ">
						<h2 className="font-semibold text-xl text-center mb-8">
							{t("notice.internal_only.title", "For internal use only")}
						</h2>
						<p className="mb-6 text-center">
							{t(
								"notice.internal_only.body",
								"The information presented in this event is for internal use only and should not be shared publicly."
							)}
						</p>
						<button
							className="bg-primary text-white text-center
	hover:bg-primary-interact
 block shadow py-4 w-full rounded-md
 focus:ring-2 focus:ring-primary-interact uppercase"
							onClick={(e) => {
								e.preventDefault();
								setHasReadInternalNotice(true);
							}}>
							{t("notice.internal_only.button", "I understand")}
						</button>
					</div>
				</div>
			)}
		</>
	);
};
//#endregion
export default React.memo(EventPage, (prevProps, nextProps) => {
	// console.log("event page memo", prevProps, nextProps);
	return true;
});
