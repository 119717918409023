import constants from "../constants";
import { sendMessage, fetchMessageHistory } from "pubnub-redux";
import { uiActions } from "../ui";
import { loggingActions } from "../logging";

export const switchChat = (channelName) => {
	return {
		type: constants.SET_ACTIVE_CONVERSATION,
		payload: channelName,
	};
};

export const sendChatInvite = (other) => {
	return function sendChatInviteAsync(dispatch, getState, context) {
		const me = getState().user.identity;

		const channelName = `chat+${[me.uuid, other].sort().join("+")}`;
		// Check if we're alreay chatting with this person. If so activate conversation

		if (
			typeof getState().chat?.conversations.find(
				(e) => e.channelName === channelName
			) !== "undefined"
		)
			return;
		// console.log(me);
		/*
		dispatch(
			sendMessage({
				channel: `${other}--private`,
				message: {
					type: constants.MESSAGE_TYPE.CHAT_INVITE,
					payload: {},
				},
				sender: me.uuid,
			})
		);
		*/
		dispatch(
			sendMessage({
				channel: `${other}--private`,
				message: {
					type: constants.MESSAGE_TYPE.CHAT_INVITE,
					from: me.uuid,
				},
			})
		);

		dispatch(
			loggingActions.logActivity(constants.ACTIVITY.CHAT_REQUEST, other)
		);
	};
};
export const sendVCardInvite = (other) => {
	return function sendVCardInviteAsync(dispatch, getState, context) {
		const me = getState().user.identity;
		dispatch(
			sendMessage({
				channel: `${other}--private`,
				message: {
					type: constants.MESSAGE_TYPE.VCARD_REQUEST,
					from: me.uuid,
				},
			})
		);
		dispatch(
			loggingActions.logActivity(constants.ACTIVITY.VCARD_REQUEST, other)
		);
	};
};

export const sendCallInvite = (other) => {
	return function sendVideoInviteAsync(dispatch, getState, context) {
		const me = getState().user.identity;
		// generate channelID
		const channelID = me.uuid + new Date().getTime();

		dispatch(
			sendMessage({
				channel: `${other}--private`,
				message: {
					type: constants.MESSAGE_TYPE.CALL_INVITE,
					from: me.uuid,
					payload: {
						callID: channelID,
					},
				},
			})
		);
		dispatch(
			loggingActions.logActivity(constants.ACTIVITY.CALL_REQUEST, other)
		);
	};
};

export const acceptChat = (other, avatar = "", name = "") => {
	return function openChatAsync(dispatch, getState, context) {
		const me = getState().user.identity;
		const channelName = `chat+${[me.uuid, other].sort().join("+")}`;

		if (
			typeof getState().chat?.conversations.find(
				(e) => e.channelName === channelName
			) !== "undefined"
		) {
			dispatch(uiActions.openPanel(constants.PANEL.CHAT));
			dispatch(switchChat(channelName));

			return;
		}

		if (avatar === "") {
			const identities = getState().event.identities.byId;
			// Look up identity to generate avatar
			avatar = identities.hasOwnProperty(other)
				? identities[other].avatar
				: "temp.png";
		}
		if (name === "") {
			const identities = getState().event.identities.byId;
			// Look up identity to generate avatar
			name = identities.hasOwnProperty(other) ? identities[other].name : "";
		}

		dispatch(fetchMessageHistory({ channel: channelName }));

		dispatch(uiActions.openPanel(constants.PANEL.CHAT));

		// const channelName = `chat-${[me.uuid, other].sort().join("-")}`;

		dispatch({
			type: constants.JOIN_CONVERSATION,
			payload: {
				channelName,
				name,
				participants: [me.uuid, other],
				avatar: avatar,
				isHost: false,
				isGroup: false,
			},
		});
		/*
		context.pubnub.api.subscribe({
			channels: [channelName],
			withPresence: false,
		});
		*/
		context.pubnub.api.channelGroups.addChannels({
			channels: [channelName],
			channelGroup: me.uuid + "-channels",
		});

		dispatch(switchChat(channelName));

		dispatch(loggingActions.logActivity(constants.ACTIVITY.CHAT_ACCEPT, other));
	};
};

export const joinChannel = (channelName) => {
	return function joinChannelAsync(dispatch, getState, context) {
		const me = getState().user.identity;

		context.pubnub.api.channelGroups.addChannels({
			channels: [channelName],
			channelGroup: me.uuid + "-channels",
		});
	};
};
export const leaveChannel = (channelName) => {
	return function leaveChannelAsync(dispatch, getState, context) {
		const me = getState().user.identity;

		context.pubnub.api.channelGroups.removeChannels({
			channels: [channelName],
			channelGroup: me.uuid + "-channels",
		});
	};
};
