import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Close, Send } from "../../assets/icons";
import ServerImage from "../controls/ServerImage";

import Moment from "react-moment";
import Spinner from "../controls/Spinner";
import { sendMessage } from "pubnub-redux";

import { chatSelectors, chatActions } from "../../store/chat";
import { userSelectors } from "../../store/user";
import {
	notificationSelectors,
	notificationActions,
} from "../../store/notification";

// import { eventSelectors } from "../../store/event";

import ReactTooltip from "react-tooltip";
import { uiActions } from "../../store/ui";
import { useTranslation } from "react-i18next";
// import { groupSelectors } from "../../store/group";

const ChatSelector = React.memo(
	({ conversations, dispatch, isSelected, unread = {} }) => {
		const selectConversation = (id) => {
			dispatch(chatActions.switchChat(id));
			dispatch(notificationActions.clearPing(id));
		};
		useEffect(() => {
			ReactTooltip.rebuild();
		}, [conversations]);

		return (
			<ul className='flex -space-x-4 absolute top-0 ml-5 w-1/3 pt-1 pb-2 px-1 overflow-x-auto stylized-scrollbar'>
				{conversations
					.filter((c) => !c.isGroup && !c.isHost)
					.map((c, idx) => (
						<li
							key={`chat-${idx}`}
							onClick={(e) => {
								selectConversation(c.channelName);
							}}
							className='relative flex-none'
							data-tip={c.name}
							data-for='chat-tips'
							style={{ zIndex: conversations.length - idx }}>
							<ServerImage
								className={`rounded-full overflow-hidden ring-2 ${
									c.channelName === isSelected ? "ring-primary" : "ring-white"
								}  w-8 h-8`}
								src={c.avatar.replace(".png", "-small.png")}
							/>
							{unread.hasOwnProperty(c.channelName) && (
								<div
									className='
									w-2 h-2 bg-primary rounded-full absolute top-0 left-0'></div>
							)}
						</li>
					))}
			</ul>
		);
	}
);

/*
const conversationAttendeeIdentities = createSelector(
	eventIdentitiesById,
	(state) => state.chat.activeConversation,
	(identities, activeConversation) => {
		const selectedIdentities = {};
		activeConversation.participants.forEach((p) => {
			if (identities.hasOwnProperty(p)) selectedIdentities[p] = identities[p];
		});
	}
);
*/

const ChatPanel = () => {
	const { t } = useTranslation();
	// const event = useSelector((state) => state.event);
	const dispatch = useDispatch();

	const me = useSelector(userSelectors.getUserIdentity);

	const conversations = useSelector(chatSelectors.getConversations);
	const activeConversation = useSelector(chatSelectors.getCurrentConversation);
	const activeConversationName = useSelector(
		chatSelectors.getCurrentConversationName
	);
	const conversationMessages = useSelector(
		chatSelectors.getCurrentConversationMessages
	);

	// const isHosted = useSelector(eventSelectors.getIsHosted);
	// const isHost = useSelector(groupSelectors.getIsHost);
	// const group = useSelector(groupSelectors.getGroup);
	// const hostIdentity = useSelector(
	// 	eventSelectors.getIndentityById(group?.leader)
	// );

	const messagesEnd = useRef(null);
	const inputField = useRef(null);

	const [timeoutRef, setTimeoutRef] = useState(0);

	const pingById = useSelector(notificationSelectors.selectPingById);

	useEffect(() => {
		// console.log("messages updated");
		scrollToBottom();
	}, [conversationMessages]);

	// eslint-disable-next-line no-unused-vars
	const [sending, setSending] = useState(false);
	const [message, setMessage] = useState("");

	const handleMessageChange = (e) => {
		e.preventDefault();
		if (e.target.value.length === 1 && message.length === 0) {
			setTypingState(true);
		}
		clearTimeout(timeoutRef);
		setMessage(e.target.value);
		setTimeoutRef(setTimeout(typingTimeout, 10000));
	};

	const typingTimeout = () => {
		setTypingState(false);
	};

	const setTypingState = (isTyping) => {
		// pubnub.setState({
		// 	channels: [chat.activeChat.channelId],
		// 	uuid: user.identity.id,
		// 	state: {
		// 		isTyping: false,
		// 	},
		// });
	};

	const handleSendMessage = (e) => {
		e.preventDefault();

		if (!message.replace(/\s/g, "").length || activeConversation === null)
			return;

		resetTextField();

		dispatch(
			sendMessage({
				channel: activeConversation.channelName,
				message: {
					type: "text",
					senderId: me.uuid,
					text: message,
				},
			})
		);
		// pubnub.publish(
		// 	{
		// 		channel: chat.activeChat.channelId,
		// 		message: { from: user.identity.name, msg: message },
		// 	},
		// 	(result) => {
		// 		console.log("message send", result);
		// 	}
		// );

		// chatActions.sendMessage(chat.activeChat.channel, message);
	};

	const scrollToBottom = () => {
		messagesEnd.current.scrollIntoView(); //{ behavior: "smooth" });
	};
	const resetTextField = () => {
		setMessage("");
		// pubnub.setState({
		// 	channels: [chat.activeChat.channelId],
		// 	uuid: user.identity.id,
		// 	state: {
		// 		isTyping: false,
		// 	},
		// });
		inputField.current.focus();
		setTypingState(false);
	};

	// const handleClose = () => {
	// 	history.replace({ pathname: "/" });
	// 	/*
	// 	pubnub.unsubscribe({
	// 		channels: [chat.activeChat.channelId],
	// 	});

	// 	chatActions.endChat(chat.activeChat.channelId);
	// 	*/
	// };

	useEffect(() => {
		// ReactTooltip.rebuild();
	});

	return (
		<div className='bg-white rounded-md shadow-lg absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-3xl chat-panel scrollable-panel'>
			<Close
				className='absolute right-6 top-6 cursor-pointer z-10'
				onClick={() => dispatch(uiActions.closePanel())}
			/>
			<div className='font-semibold my-4 mt-8 relative'>
				<ChatSelector
					conversations={conversations}
					dispatch={dispatch}
					isSelected={activeConversation?.channelName}
					unread={pingById}
				/>
				<ReactTooltip
					place='top'
					type='dark'
					effect='solid'
					backgroundColor='#707070'
					id='chat-tips'
				/>
				{activeConversation && (
					<h1 className='font-semibold text-3xl text-center select-none'>
						{activeConversationName}
					</h1>
				)}
			</div>

			<div className='scrollable-panel__viewport chat-panel__content'>
				<div className='chat-panel__messages'>
					{conversationMessages.map((message, idx) => {
						return (
							<div
								className={`chat-panel__messages-item ${
									message.sender.me
										? "chat-panel__messages-item--mine"
										: "chat-panel__messages-item--theirs"
								}`}
								key={`chatmessage-${idx}`}>
								<ServerImage
									className='img'
									src={message.sender.avatar}
									alt={message.sender.name}
								/>
								{/* <img
										className="img"
										src={`${serverConfig.baseUrl}/${serverConfig.uploadsDir}/${message.avatar}`}
										alt={message.name}
									/> */}
								<div className='bubble-wrap'>
									<p className='bubble'>{message.text}</p>
									<span className='timestamp'>
										<Moment date={message.ts} fromNow />
									</span>
								</div>
							</div>
						);
					})}
					<div ref={messagesEnd}></div>
				</div>
			</div>
			<form className='flex px-8 mb-7' onSubmit={handleSendMessage}>
				<input
					type='text'
					value={message}
					disabled={sending}
					onChange={handleMessageChange}
					className='flex-grow 
					border border-gray-400 bg-gray-100 text-gray-700 p-3
					rounded-full
					focus-visible:ring-2
					focus:ring-2
					focus:ring-gray-700
					focus:outline-none
					focus-visible:outline-none
					focus:rounded-full
					'
					ref={inputField}
				/>

				<button
					disabled={sending}
					className='bg-transparent font-medium ml-2 px-2 flex-grow-0
					focus:bg-gray-300
					focus:ring-gray-700
					'>
					{!sending && <Send className='text-4xl' />}
					{sending && (
						<span className='inline-block'>
							<Spinner size='5' thickness='2'></Spinner>
						</span>
					)}
				</button>
			</form>

			{!!!activeConversation && (
				<div className='fixed bg-white bg-opacity-40 w-full bottom-0 mt-18 flex justify-center top-0 items-center'>
					<p>
						{t(
							"global.chat.select_conversation",
							"Select a conversation to start chatting"
						)}
					</p>
				</div>
			)}
		</div>
	);
};

export default ChatPanel;
