import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import {
	Call,
	CallEnd,
	Card,
	Gather,
	List,
	Save,
	Send,
	Settings,
	Teleport,
} from "../../../../assets/icons";
import { CallTimer } from "./CallTimer";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../../../store/ui";
import { salesActions } from "../../../../store/sales";
import constants from "../../../../store/constants";
import { streamerActions } from "../../../../store/streamer";
import { callActions, callSelectors } from "../../../../store/call";

export const HostActionBar = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const inCall = useSelector(callSelectors.getIsActive);

	const openSalesUi = () => {
		dispatch(uiActions.setLayer(constants.LAYER.SALES, true, false));
		dispatch(uiActions.setLayer(constants.LAYER.FILES, false));
		dispatch(uiActions.setLayer(constants.LAYER.PARTICIPANTS, false));
	};

	const openFilesUi = () => {
		dispatch(uiActions.setLayer(constants.LAYER.FILES, true, false));
		dispatch(uiActions.setLayer(constants.LAYER.SALES, false));
		dispatch(uiActions.setLayer(constants.LAYER.PARTICIPANTS, false));
	};

	const openParticipantsUi = () => {
		dispatch(uiActions.setLayer(constants.LAYER.PARTICIPANTS, true, false));
		dispatch(uiActions.setLayer(constants.LAYER.SALES, false));
		dispatch(uiActions.setLayer(constants.LAYER.FILES, false));
	};

	const teleportToMyLocation = () => {
		dispatch(
			streamerActions.sendMessage(
				constants.STREAMER_OUTGOING_ACTIONS.TELEPORT_GROUP
			)
		);
	};

	const requestSaveConfiguration = () => {
		dispatch(
			streamerActions.sendMessage(
				constants.STREAMER_OUTGOING_ACTIONS.SAVE_CONFIGURATION
			)
		);
		dispatch(uiActions.openPanel(constants.PANEL.SAVE_CONFIGURATION));
	};

	const handleStartGroupCall = () => {
		dispatch(uiActions.openPanel(constants.PANEL.GROUPCALL));
	};

	return (
		<div
			className="fixed bottom-5 left-1/2 transform -translate-x-1/2 layer--actionbar"
			id="hostActionbar">
			<div className="flex rounded-md shadow-lg overflow-hidden items-stretch  bg-white  text-secondary">
				{" "}
				{/* border border-gray-100 */}
				{inCall && (
					<div className="px-6 flex items-center border-r border-gray-200 mr-3">
						<CallTimer />
					</div>
				)}
				{/* Action Buttons */}
				<div className="flex flex-1 space-x-2 p-1 items-center mr-3">
					{!inCall && (
						<button
							className="group hover:bg-gray-100 cursor-pointer p-2 rounded-lg"
							data-tip={`<b>${t(
								"hostactions.start_call",
								"Start call"
							)}</b><br/>${t(
								"hostactions.start_call_info",
								"Invite other attendees into an video call"
							)}`}
							data-for="host-toolbar-tips"
							onClick={handleStartGroupCall}>
							<Call className="w-6 h-6 group-hover:text-secondary-interact" />
						</button>
					)}
					<button
						className="group hover:bg-gray-100 cursor-pointer p-2 rounded-lg"
						data-tip={`<b>${t(
							"hostactions.teleport_here",
							"Teleport to my position"
						)}</b><br/>${t(
							"hostactions.teleport_here_info",
							"Move all the participants to your current location"
						)}`}
						data-for="host-toolbar-tips"
						onClick={teleportToMyLocation}>
						<Gather className="w-6 h-6 group-hover:text-secondary-interact" />
					</button>
					<button
						className="group hover:bg-gray-100 cursor-pointer p-2 rounded-lg"
						data-tip={`<b>${t(
							"hostactions.teleport_all",
							"Teleport to location"
						)}</b><br/>${t(
							"hostactions.teleport_all_info",
							"Open the floorplan and move all participants to a new location"
						)}`}
						data-for="host-toolbar-tips"
						onClick={() =>
							dispatch(uiActions.openPanel(constants.PANEL.FLOORPLAN))
						}
						id="btnFloorplan">
						<Teleport className="w-6 h-6 group-hover:text-secondary-interact" />
					</button>
					<button
						onClick={openFilesUi}
						className="group hover:bg-gray-100 cursor-pointer p-2 rounded-lg"
						data-tip={`<b>${t(
							"hostactions.send_file",
							"Send file"
						)}</b><br/>${t(
							"hostactions.send_file_info",
							"Select one or more documents and send them to all participants"
						)}`}
						data-for="host-toolbar-tips">
						<Send className="w-6 h-6 group-hover:text-secondary-interact" />
					</button>
					<button
						className="group hover:bg-gray-100 cursor-pointer p-2 rounded-lg"
						data-tip={`<b>${t(
							"hostactions.save_configuration",
							"Save configuration"
						)}</b><br/>${t(
							"hostactions.save_configuration_info",
							"Save the current truck configuration for your client"
						)}`}
						data-for="host-toolbar-tips"
						onClick={requestSaveConfiguration}>
						<Save className="w-6 h-6 group-hover:text-secondary-interact" />
					</button>
					<button
						onClick={openSalesUi}
						className="group hover:bg-gray-100 cursor-pointer p-2 rounded-lg"
						data-tip={`<b>${t(
							"hostactions.sales_notes",
							"Sales notes"
						)}</b><br/>${t(
							"hostactions.sales_notes_info",
							"Open the sales notes interface with useful speaking notes"
						)}`}
						data-for="host-toolbar-tips">
						<Card className="w-6 h-6 group-hover:text-secondary-interact" />
					</button>
					<button
						onClick={openParticipantsUi}
						className="group hover:bg-gray-100 cursor-pointer p-2 rounded-lg"
						data-tip={`<b>${t(
							"hostactions.participants",
							"Participants list"
						)}</b><br/>${t(
							"hostactions.sales_notes_info",
							"See all participants in the session"
						)}`}
						data-for="host-toolbar-tips">
						<List className="w-6 h-6 group-hover:text-secondary-interact" />
					</button>
					<button
						className="group hover:bg-gray-100 cursor-pointer p-2 rounded-lg"
						onClick={() =>
							dispatch(uiActions.openPanel(constants.PANEL.SETTINGS))
						}
						data-tip={`<b>${t("base.sb_settings", "Settings")}</b><br/>${t(
							"base.sb_settings_info",
							"Change language and navigation mode"
						)}`}
						data-for="host-toolbar-tips"
						id="btnSettings">
						<Settings className="w-6 h-6 group-hover:text-secondary-interact" />
					</button>
				</div>
				{inCall && (
					<button
						className="bg-primary flex  items-center px-4 text-white"
						onClick={() => dispatch(callActions.leaveCall())}>
						<CallEnd className="w-6 h-6 group-hover:text-secondary-interact" />
					</button>
				)}
			</div>
			<ReactTooltip
				place="top"
				type="dark"
				html={true}
				className="text-center whitespace-nowrap"
				backgroundColor="#707070"
				effect="solid"
				id="host-toolbar-tips"
				// getContent={(dataTip) => {
				// 	if (dataTip === null) return <></>;
				// 	const parts = dataTip.split("|");
				// 	return (
				// 		<div className='text-center'>
				// 			<b>{parts.shift()}</b>
				// 			{/* <br />
				// 			{parts.shift()} */}
				// 		</div>
				// 	);
				// }}
			/>
		</div>
	);
};
