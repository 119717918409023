import constants from "../constants";

const defaultState = {
	contents: [],
	fetching: false,
};

const goodiebagReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_GOODIEBAG_FETCHING:
			return { ...state, fetching: action.payload };
		case constants.SET_GOODIEBAG:
			return {
				...state,
				contents: action.payload,
				fetching: false,
			};
		case constants.ADD_TO_GOODIEBAG:
			return {
				...state,
				contents: [...state.contents, action.payload],
			};
		case constants.REMOVE_FROM_GOODIEBAG:
			return {
				...state,
				contents: state.contents.filter((i) => !(i.id === action.payload.id)),
			};
		default:
			return defaultState;
	}
};
export default goodiebagReducer;
