import React from "react";
import { useSelector } from "react-redux";
import { groupSelectors } from "../../store/group";
import ServerImage from "../controls/ServerImage";

const GroupAttendees = ({ isCompact = false }) => {
	const user = useSelector((state) => state.user);
	// Prevent rerender
	const members = useSelector(groupSelectors.getAttendeesInGroup);

	return (
		<>
			{user.group.attendees.map((m, idx) => (
				<div
					key={`person-${idx}`}
					className={`flex border-b border-gray-200  items-center ${
						isCompact ? "text-xs pb-1 mb-1" : "pb-2 mb-2"
					}`}>
					<div
						className={`isCompact ? "w-8 h-8" : "w-10 h-10" bg-gray-300 rounded-full mr-2`}>
						<ServerImage
							className={`rounded-full overflow-hidden ${
								isCompact ? "w-8 h-8" : "w-10 h-10"
							} flex-none`}
							src={m.avatar.replace(".png", "-small.png")}
							alt={m.name}
						/>
					</div>
					<div className='text-left flex-grow'>
						<div className='font-semibold'>{m.name} </div>
						<div className='text-sm'>{m.company}</div>
					</div>

					<div
						className={`rounded-full ${isCompact ? "w-2 h-2" : "w-4 h-4"} ${
							members.some((member) => {
								return member.uuid === m.uuid;
							})
								? "bg-green-500"
								: "bg-red-500"
						}`}></div>
				</div>
			))}
		</>
	);
};

export default GroupAttendees;
