import { createSelector } from "reselect";
import presenceSlice from "./presenceSlice";
import { groupSelectors } from "../group";
import { eventSelectors } from "../event";

export const getPresenceById = createSelector(
	[presenceSlice],
	(presence) => presence.byId
);

export const getAttendeesInMain = createSelector(
	// UNTIL PUBNUB FIXES THIS WE JUST SHOW EVERYONE WHO IS EXPECTED IN THIS EVENT
	[eventSelectors.getEventIdentitiesById],
	(identities) => Object.values(identities)
	/*		Object.values(identities).map((i) => {
			if (i.hasOwnProperty("main")) return i.main;
			return i;
		})*/
);

export const getAttendeesInGroup = createSelector(
	[
		getPresenceById,
		groupSelectors.getGroup,
		eventSelectors.getEventIdentitiesById,
	],
	(presence, group, userData) => {
		if (group === null) return [];
		if (!presence.hasOwnProperty("group-" + group.id)) return [];
		// console.log("userData", userData);
		return presence["group-" + group.id].occupants
			.filter((u) => userData.hasOwnProperty(u.uuid))
			.map((u) => {
				// console.log(u, u.uuid === group.leader);
				return {
					...userData[u.uuid],
					isHost: u.uuid === group.leader,
				};
			});
	}
);

export const getPresenceInMain = createSelector(
	[getPresenceById],
	(presence) => {
		if (presence.hasOwnProperty("main"))
			return presence.main.occupants.map((p) => p.uuid);
		return [];
	}
);

export const getHostIsPresent = createSelector(getAttendeesInGroup, (users) => {
	let hostPresent = false;
	users.forEach((u) => {
		if (u.isHost) hostPresent = true;
	});
	return hostPresent;
});

export const getSortedAttendees = createSelector(
	[getAttendeesInMain, getPresenceInMain],
	(attendees, presence) =>
		attendees
			.filter((a) => presence.indexOf(a.uuid) > -1)
			.sort((a, b) => {
				if (a.name > b.name) return 1;
				if (a.name < b.name) return -1;
				return 0;
			})
);
