import React from "react";
import { Close } from "../../assets/icons";

const BaseModal = ({
	size = "xl",
	buttons = 0,
	children,
	modalOpen = true,
	closeCallback,
	className = "",
	confirmCallback,
	confirmText,
	cancelCallback,
	cancelText,
	extraButtons,
	showClose = true,
	z = 40,
}) => {
	return (
		modalOpen && (
			<div
				className={`w-screen h-screen bg-white bg-opacity-25 fixed top-0 left-0 z-${z}`}>
				<div
					className={`fixed w-full bg-white  
					py-16 px-20 pb-8
					 bg-opacity-90 rounded-lg 
					 shadow-xl top-1/2 left-1/2 
					 transform -translate-x-1/2 -translate-y-1/2 
					 ${className} max-w-${size} relative`}>
					{showClose && (
						<div
							className="absolute right-6 top-6 cursor-pointer"
							onClick={(e) => {
								if (closeCallback) closeCallback();
							}}>
							<Close className="" />
						</div>
					)}

					<div className="px-15 pb-10 text-black text-center pt-5">
						{children}
					</div>
					{!!buttons > 0 && (
						<div className="flex px-2 pb-10 text-center space-x-2 justify-center">
							{!!(buttons & OK) && (
								<button
									className="font-semibold max-w-xs uppercase rounded bg-black text-white hover:bg-gray-800 w-full flex-grow py-3"
									onClick={(e) => {
										e.preventDefault();
										if (confirmCallback) confirmCallback(true);
									}}>
									{confirmText}
								</button>
							)}
							{!!(buttons & CANCEL) && (
								<button
									className="font-semibold  max-w-xs uppercase rounded text-white bg-customGray hover:bg-customGray-interact w-full flex-grow py-3"
									onClick={(e) => {
										e.preventDefault();
										if (cancelCallback) cancelCallback(false);
									}}>
									{cancelText}
								</button>
							)}
							{extraButtons && extraButtons()}
						</div>
					)}
				</div>
			</div>
		)
	);
};

export default BaseModal;

export const OK = 1;
export const CANCEL = 2;
