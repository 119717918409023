import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Close } from "../../assets/icons";

import ReactTooltip from "react-tooltip";

// import { userSelectors } from "../../store/user";

import { eventSelectors } from "../../store/event";

import { uiActions } from "../../store/ui";
import { hostSelectors } from "../../store/host";
import AttendeeGroupListItem from "../itemrenderers/AttendeeGroupListItem";
import { callActions } from "../../store/call";
import { useTranslation } from "react-i18next";
// import constants from "../../store/constants";
// import { groupActions } from "../../store/group";

// import dummy from "../../assets/img/dummy/participants.jpg";

/*
	[
		(state) => state.presence.byId,
		(state) => state.event.identities.byId,
		(state) => state.user?.identity?.uuid,
	],
	(presence, userData, me) => {
		if (!presence.hasOwnProperty("main")) return [];
		// console.log("userData", userData);
		return presence["main"].occupants
			.filter((u) => userData.hasOwnProperty(u.uuid) && u.uuid !== me)
			.map((u) => {
				return {
					...userData[u.uuid],
				};
			});
	}
);
*/

const GroupCallPanel = () => {
	const { t } = useTranslation();
	const invitees = useSelector(hostSelectors.getInvitees); // useSelector((state) => state.event.attendees);
	const allAttendees = useSelector(eventSelectors.getEventIdentitiesById);
	// const me = useSelector(userSelectors.getUserIdentity);
	const dispatch = useDispatch();

	const [invitedInvitees, setInvitedInvitees] = useState([]);

	useEffect(() => {
		// Needs a rebinding for when list updates
		ReactTooltip.rebuild();
	});

	const handleAdd = (element) => {
		setInvitedInvitees((e) => [...e, element]);
	};
	const handleRemove = (element) => {
		setInvitedInvitees((e) => [...e].filter((i) => i.uuid !== element.uuid));
	};

	const handleStartCall = () => {
		dispatch(uiActions.closePanel());
		dispatch(callActions.initGroupCall(invitedInvitees));
	};

	return (
		<div
			className='bg-white rounded-md shadow-lg 
		p-10 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 
		w-full max-w-4xl scrollable-panel'>
			<Close
				className='absolute right-6 top-6 cursor-pointer'
				onClick={() => dispatch(uiActions.closePanel())}
			/>
			<div
				className='flex divide-x border-trueGray-300'
				style={{ height: "calc(100% - 3rem)" }}>
				<div className='pr-4 w-full scrollable-panel'>
					<h1 className='font-semibold text-2xl mb-3 text-center'>
						{t("base.group_call.your_invitees", "Your invitees")}
					</h1>
					<p className='text-center'>
						{t(
							"base.group_call.alphabetical_list_message",
							"You’ll find an alphabetical list of participants below"
						)}
						.
						<br />{" "}
						{t(
							"base.group_call.click_on_plus",
							"Click on the plus sign to add someone to the call"
						)}
					</p>
					{/* <button onClick={refreshUserList}>Refresh</button> */}
					{/* Search not required here as attendees will be filtered */}
					{/* <div className='my-3 flex border border-warmGray-300 text-sm mx-auto max-w-xs w-full rounded'>
						<input
							type='text'
							value={tempSearchTerm}
							onChange={(e) => {
								e.preventDefault();
								setTempSearchTerm(e.target.value);
							}}
							className='border-0 flex-grow px-2 bg-none focus:ring-0 focus:border-0'
						/>
						<button
							className='font-semibold border-white border-2 text-xs uppercase rounded bg-black text-white hover:bg-gray-800 py-2 px-4'
							onClick={(e) => {
								e.preventDefault();
								setSearchTerm(tempSearchTerm);
							}}>
							Search
						</button>
					</div> */}

					<div className='scrollable-panel__viewport mt-4'>
						<ul className='mt-2 striped-children'>
							{invitees
								.filter((element) => {
									if (!allAttendees.hasOwnProperty(element.uuid)) return false;
									return invitedInvitees.indexOf(element) === -1;
								})
								.map(
									(element, idx) =>
										element.name && (
											<AttendeeGroupListItem
												element={element}
												key={idx}
												added={false}
												handleAdd={() => handleAdd(element)}
												className=''
												t={t}
											/>
										)
								)}
						</ul>
					</div>
				</div>
				<div className='pl-4 w-full scrollable-panel'>
					<h1 className='font-semibold text-2xl mb-3 text-center'>
						{t("base.group_call.invited_to_call", "Invited to call")}
					</h1>
					<p className='text-center'>
						{t(
							"base.group_call.click_on_minus",
							"Click on the minus symbol to exclude them from the call"
						)}
					</p>
					<div className='scrollable-panel__viewport mt-4'>
						<ul className='mt-2 striped-children'>
							{invitedInvitees.map(
								(element, idx) =>
									element.name && (
										<AttendeeGroupListItem
											element={element}
											key={idx}
											added={true}
											handleRemove={() => handleRemove(element)}
											className=''
											t={t}
										/>
									)
							)}
						</ul>
					</div>
				</div>
			</div>
			<button
				className='font-semibold border-white border-2 text-md uppercase rounded bg-black text-white hover:bg-gray-800 py-4 px-8  mt-4 max-w-lg mx-auto'
				onClick={handleStartCall}>
				{t("base.group_call.start_group_call", "Start group call")}
			</button>
			{/* <img src={dummy} className="w-full" /> */}
			<ReactTooltip place='top' type='dark' effect='solid' />
		</div>
	);
};

export default GroupCallPanel;
