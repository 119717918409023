import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMessageHistory, sendMessage } from "pubnub-redux";
import { Send } from "../../assets/icons";
import Spinner from "../controls/Spinner";
import { chatActions, chatSelectors } from "../../store/chat";
import { userSelectors } from "../../store/user";
import Moment from "react-moment";

const Message = React.memo(({ message }) => {
	const [mouseOver, setMouseOver] = useState(false);
	return (
		<div
			onMouseOver={() => setMouseOver(true)}
			onMouseOut={() => setMouseOver(false)}>
			<h2>
				<span className='font-semibold flex justify-between items-baseline'>
					{message.payload.from}&nbsp;{" "}
					{mouseOver && (
						<Moment
							className='text-xs text-gray-400 font-normal'
							date={message.ts}
							format='HH:mm:ss'
						/>
					)}
				</span>
			</h2>
			<p>{message.payload.text}</p>
		</div>
	);
});

const MessagesList = React.memo(
	({ channelName, messageFilter, easyMode = true }) => {
		const messagesEnd = useRef(null);
		const messages = useSelector(chatSelectors.getRawMessages(channelName));

		useEffect(() => {
			// console.log("messages updated", messages);
			scrollToBottom();
		}, [messages]);

		const scrollToBottom = () => {
			messagesEnd.current.scrollIntoView(); // { behavior: "smooth" }
		};

		return (
			<div className='w-full h-full flex-grow scrollable-panel__viewport'>
				{messages.filter(messageFilter).map((message, idx) => (
					<div className='mb-2 pb-2 border-b' key={`chatmessage-${idx}`}>
						{easyMode && <Message message={message} />}
						{!easyMode && (
							<>
								<div
									className={`pb-1 border-b mb-2 ${
										message.sender.me ? "text-right" : "text-left"
									}`}>
									<p className='font-semibold'>{message.sender.name}</p>
									<p>{message.text}</p>
								</div>
							</>
						)}
					</div>
				))}
				<div ref={messagesEnd}></div>
			</div>
		);
	}
);

const MiniChat = ({
	channelName = "",
	className = "",
	showTitle = true,
	title = "",
	messageFormatter = null,
	messageFilter = (f) => {
		return true;
	},
}) => {
	const dispatch = useDispatch();
	// const { t } = useTranslation();
	const me = useSelector(userSelectors.getUserIdentity);

	const inputField = useRef(null);

	const [sending, setSending] = useState(false); // eslint-disable-line no-unused-vars
	const [message, setMessage] = useState("");

	const handleMessageChange = (e) => {
		e.preventDefault();

		setMessage(e.target.value);
	};

	useEffect(() => {
		dispatch(chatActions.joinChannel(channelName));
		dispatch(fetchMessageHistory({ channel: channelName }));

		return () => {
			dispatch(chatActions.leaveChannel(channelName));
		};
	}, [dispatch, channelName]);

	const handleSendMessage = (e) => {
		e.preventDefault();

		if (!message.replace(/\s/g, "").length) return;

		resetTextField();
		// construct basic message format
		const messageToSend = {
			channel: channelName,
		};
		if (messageFormatter !== null)
			messageToSend.message = messageFormatter(me.uuid, me.name, message);
		else
			messageToSend.message = {
				type: "text",
				senderId: me.uuid,
				from: me.name,
				text: message,
			};

		dispatch(sendMessage(messageToSend));
	};

	const resetTextField = () => {
		setMessage("");

		inputField.current.focus();
	};

	return (
		<div
			style={{ minHeight: "340px" }}
			className={`${className} flex flex-col h-full`}>
			{showTitle && (
				<h2 className='text-xl mb-2 font-semibold border-b pb-2 mb-2 text-center'>
					{title}
				</h2>
			)}
			<MessagesList channelName={channelName} messageFilter={messageFilter} />
			<form className='flex mt-4' onSubmit={handleSendMessage}>
				<input
					type='text'
					value={message}
					disabled={sending}
					onChange={handleMessageChange}
					className='flex-grow 
					border border-gray-400 bg-gray-100 text-gray-700 py-1 px-3
					rounded-full
					focus-visible:ring-2
					focus:ring-2
					focus:ring-gray-700
					focus:outline-none
					focus-visible:outline-none
					focus:rounded-full
					w-full
					'
					ref={inputField}
				/>

				<button
					disabled={sending}
					className='bg-transparent font-medium ml-2 px-2 flex-grow-0
					focus:bg-gray-300
					focus:ring-gray-700

					'>
					{!sending && <Send className='text-4xl' />}
					{sending && (
						<span className='inline-block'>
							<Spinner size='5' thickness='2'></Spinner>
						</span>
					)}
				</button>
			</form>
		</div>
	);
};

export default React.memo(MiniChat);
