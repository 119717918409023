import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui";
import constants from "../../store/constants";
import { Close, MagnifyingGlass } from "../../assets/icons";
// import Checkbox from "../ui/Checkbox";
import { salesActions } from "../../store/sales";
import { notificationActions } from "../../store/notification"; // addNotification
import { useTranslation } from "react-i18next";
import { hostActions } from "../../store/host";

export const SalesFiles = () => {
	const { t } = useTranslation();
	const files = useSelector((state) => state.sales.files);
	const invitees = useSelector((state) => state.host.invitees);
	const dispatch = useDispatch();
	const [selectedFiles, setSelectedFiles] = useState([]);
	// console.log(files);
	const asset_url = process.env.REACT_APP_ASSET_URL;

	const closePanel = () => {
		dispatch(uiActions.setLayer(constants.LAYER.FILES, false));
	};

	const inValue = (input) => selectedFiles.includes(input);

	const handleFileToggle = (target) => {
		let newValue = selectedFiles; // []

		if (!newValue.includes(target.value)) {
			newValue.push(target.value);
		} else {
			newValue = newValue.filter((item) => item !== target.value);
		}

		setSelectedFiles(newValue);
	};

	// console.log("invitees", invitees);
	const sendFiles = (e) => {
		e.preventDefault();
		let usersArray = null;
		let filesArray = null;
		let fileIds = selectedFiles;

		let userIds = invitees.map((i) => i.uuid); // [2, 11];
		// let userIds = invitees; // from the store, probably extract only the ids

		if (typeof userIds != "undefined") usersArray = JSON.stringify(userIds);
		if (fileIds.length > 0) filesArray = JSON.stringify(fileIds);

		dispatch(salesActions.sendFileToUsers(filesArray, usersArray));
		dispatch(
			notificationActions.addNotification({
				type: "INFO",
				message: "Files successfully sent.",
			})
		);
		dispatch(
			hostActions.sendCommandToGroup(constants.MESSAGE_TYPE.RECEIVE_FILE)
		);
		closePanel();
	};

	return (
		<div className='absolute left-1/2 bottom-20 transform -translate-x-1/2 w-full max-w-xl layer--sales bg-white bg-opacity-90 p-6 shadow-xl rounded-md'>
			<div
				className='absolute right-4 top-4 cursor-pointer'
				onClick={(e) => {
					closePanel();
				}}>
				<Close className='w-5 h-5' />
			</div>
			<div className='w-full h-full flex flex-col'>
				<div className='flex-grow'>
					<div className='mt-6 flex flex-col text-md'>
						{files.length > 0 &&
							files.map((file, index) => {
								return (
									<div key={`file-checkbox-${index}`}>
										<div className='flex items-center justify-between'>
											{/*<Checkbox*/}
											{/*	type='checkbox'*/}
											{/*	className='mt-4'*/}
											{/*	label={file.description}*/}
											{/*	value={file.id}*/}
											{/*	checked={inValue(file.id)}*/}
											{/*	onChange={(e) => handleFileToggle(e.target)}*/}
											{/*/>*/}
											<label className='relative flex items-center space-x-2 text-gray-500 my-2'>
												<input
													type='checkbox'
													className='w-4 h-4 bg-tenary rounded-md border-2 border-transparent outline-none focus:border-primary-interact text-primary accent-red-600'
													value={file.id}
													selected={inValue(file.id)}
													onChange={(e) => handleFileToggle(e.target)}
												/>
												<span className='text-sm'>{file.description}</span>
											</label>
											<p className='sm text-gray-400 lowercase pr-4 flex-grow text-right'>
												{file.type}
											</p>
											<a
												href={asset_url + file.fileUrl.replace("uploads", "")}
												target='_blank'
												rel='noreferrer'
												className='flex-grow-0 text-secondary'>
												<MagnifyingGlass />
											</a>
										</div>

										<hr />
									</div>
								);
							})}
					</div>

					<div className='mt-4 flex block w-full'>
						<button
							className='w-full bg-primary flex justify-center items-center uppercase text-sm rounded-md py-3 px-4 text-white'
							onClick={sendFiles}>
							{t("global.send_files", "Send files")}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
