import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Joyride, { STATUS } from "react-joyride";
import BaseModal, { CANCEL, OK } from "./modals/BaseModal";
import { Close, Info, Keyboard, Mouse } from "../assets/icons";
import imgCentralArea from "../assets/img/tutorial/central-area.png";
import imgModelOptions from "../assets/img/tutorial/model-options.png";
import imgPOI from "../assets/img/tutorial/point-of-interest.png";
import ReactMarkdown from "react-markdown";

const TUTORIAL_STEPS = (t) => ({
	INTRO: {
		title: t("tutorial.intro.title", "Welcome to the Digital Showroom!"),
		content: <h2>{t("tutorial.intro.body", "Come on in")}</h2>,
		// locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
		placement: "center",
		target: "body",
	},
	MOVING_AROUND: {
		title: t("tutorial.movement.title", "Moving around"),
		content: (
			<div>
				<div className="space-y-2">
					<ReactMarkdown>
						{t(
							"tutorial.intro.body",
							"The digital showroom is a real-time interactive 3D environment.\n\nLet's go over the different ways you can move around in the showroom."
						)}
					</ReactMarkdown>
				</div>
				{/* <div>
					<Keyboard className="w-12 h-12 text-primary mb-2" />
					<Mouse className="w-10 h-10 text-primary" />
				</div> */}
			</div>
		),
		// locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
		placement: "center",
		target: "body",
	},
	TELEPORTING: {
		title: t("tutorial.teleport.title", "Teleporting"),
		content: (
			<div className="space-y-2">
				<ReactMarkdown>
					{t(
						"tutorial.teleport.content",
						"Using the floorplan you can teleport yourself from one place to another within the environment.\n\nOpen the panel and click on the point of interest to teleport yourself."
					)}
				</ReactMarkdown>
			</div>
		),
		// locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
		placement: "top",
		target: "#btnFloorplan",
	},
	WALKING: {
		title: t("tutorial.walking.title", "Walking around"),
		content: (
			<div className="flex space-x-8">
				<div className="space-y-2 text-left">
					<ReactMarkdown>
						{t(
							"tutorial.walking.content",
							"You can also walk around the environment using the keyboard or mouse depending on the mode you selected.\n\nWe've selected the mouse-only mode by default but you can easily switch by accessing the settings menu."
						)}
					</ReactMarkdown>
				</div>
				<div>
					<Keyboard className="w-12 h-12 text-primary mb-2" />
					<Mouse className="w-10 h-10 text-primary" />
				</div>
			</div>
		),
		// locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
		placement: "top",
		target: "#btnSettings",
	},
	DOWNLOADS: {
		title: t("tutorial.downloads.title", "Downloads"),
		content: (
			<div className="space-y-2">
				<ReactMarkdown>
					{t(
						"tutorial.downloads.content",
						"Throughout your visit you can collect downloadable assets. If you are accompanied by a host they can also send you documents.\n\nOpen the panel to see all files available for download."
					)}
				</ReactMarkdown>
			</div>
		),
		// locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
		placement: "top",
		target: "#btnGoodiebag",
	},
	PROFILE: {
		title: t("tutorial.profile-new.title", "Profile"),
		content: (
			<div className="space-y-2">
				<ReactMarkdown>
					{t(
						"tutorial.profile-new.content",
						"If this is your first visit, we've created a temporary profile for you.\n\nTo save your progress and access your downloads after your visit, please create a profile."
					)}
				</ReactMarkdown>
			</div>
		),
		// locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
		placement: "top",
		target: "#btnProfile",
	},
	PROFILE_EXISTING: {
		title: t("tutorial.profile-existing.title", "Profile"),
		content: (
			<div className="space-y-2">
				<ReactMarkdown>
					{t(
						"tutorial.profile-existing.content",
						"This button brings up your profile.\n\nHere you can modify your details, go to your downloads and configurations and change your avatar."
					)}
				</ReactMarkdown>
			</div>
		),
		// locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
		placement: "top",
		target: "#btnProfile",
	},

	ENVIRONMENT_INTRO: {
		title: t("tutorial.environment-tips.intro.title", "Interaction tips"),
		content: (
			<div className="space-y-2">
				<ReactMarkdown>
					{t(
						"tutorial.environment-tips.intro.content",
						"Let's go over some of the interactions you can have with the 3D environment.\n\nAs a rule of thumb, interactive elements are indicated by a change of the cursor when hovering over them."
					)}
				</ReactMarkdown>
			</div>
		),
		// locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
		placement: "center",
		target: "body",
	},
	ENVIRONMENT_POI: {
		title: t(
			"tutorial.environment-tips.points-of-interest.title",
			"Points of interest"
		),
		content: (
			<div className="flex space-x-4">
				<div className="space-y-2 text-left w-2/3">
					<ReactMarkdown>
						{t(
							"tutorial.environment-tips.points-of-interest.content",
							"Throughout the environment you will find points of interest. These specific points are highlighted with a white circle.\n\nMove towards them to learn more about the product or service being showcased."
						)}
					</ReactMarkdown>
				</div>
				<div className="w-1/3">
					<img
						className="w-full h-auto overflow-hidden rounded-md"
						alt="Point-of-interest"
						src={imgPOI}
					/>
				</div>
			</div>
		),
		// locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
		placement: "center",
		target: "body",
	},
	ENVIRONMENT_MODEL_SELECTION: {
		title: t(
			"tutorial.environment-tips.model-selection.title",
			"Truck model selection"
		),
		content: (
			<div className="flex space-x-4">
				<div className="space-y-2 text-left w-2/3">
					<ReactMarkdown>
						{t(
							"tutorial.environment-tips.model-selection.content",
							"Some trucks have different models.\n\nUse the panels on the side to switch between models."
						)}
					</ReactMarkdown>
				</div>
				<div className="w-1/3">
					<img
						className="w-full h-auto overflow-hidden rounded-md"
						alt="Truck models"
						src={imgModelOptions}
					/>
				</div>
			</div>
		),
		// locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
		placement: "center",
		target: "body",
	},
	ENVIRONMENT_CONFIGURATION_AREA: {
		title: t(
			"tutorial.environment-tips.configuration-area.title",
			"Configuration area"
		),
		content: (
			<div className="space-y-4">
				<img
					className="w-full h-auto overflow-hidden rounded-md"
					alt="Central configuration area"
					src={imgCentralArea}
				/>
				<div className="space-y-2">
					<ReactMarkdown>
						{t(
							"tutorial.environment-tips.configuration-area.content",
							"In the central area of the showroom you can explore the truck's features.\n\nYou can switch between configuration and exploration (using hotspots)."
						)}
					</ReactMarkdown>
				</div>
			</div>
		),
		// locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
		placement: "center",
		target: "body",
	},
	HOST_ACTIONBAR: {
		title: t("tutorial.hostactionbar.title", "Host activity"),
		content: (
			<p>
				{t(
					"tutorial.hostactionbar.content",
					"This is your action bar. Here you will find several controls to guide your visitors through the experience or share files and truck configurations."
				)}
			</p>
		),
		placement: "top",
		target: "#hostActionbar",
	},
});

export function Tutorial({ host, user }) {
	const { t } = useTranslation();
	// Tutorial steps
	const [tutorialSteps] = useState(() => {
		// Start with a basis that is the same for all use cases
		let steps = [
			TUTORIAL_STEPS(t).INTRO,
			TUTORIAL_STEPS(t).MOVING_AROUND,
			TUTORIAL_STEPS(t).TELEPORTING,
			TUTORIAL_STEPS(t).WALKING,
		];

		if (host) {
			steps.push(TUTORIAL_STEPS(t).HOST_ACTIONBAR);
		} else {
			steps.push(TUTORIAL_STEPS(t).DOWNLOADS);
		}

		// Check if the user is known.
		if (user.company === "") steps.push(TUTORIAL_STEPS(t).PROFILE);
		else steps.push(TUTORIAL_STEPS(t).PROFILE_EXISTING);

		steps = steps.concat([
			TUTORIAL_STEPS(t).ENVIRONMENT_INTRO,
			TUTORIAL_STEPS(t).ENVIRONMENT_POI,
			TUTORIAL_STEPS(t).ENVIRONMENT_MODEL_SELECTION,
			TUTORIAL_STEPS(t).ENVIRONMENT_CONFIGURATION_AREA,
		]);

		return steps;
	});

	const [run, setRun] = useState(!!!localStorage.getItem("tutorial"));

	const handleJoyrideCallback = (data) => {
		const { status } = data;

		if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
			setRun(false);
			localStorage.setItem("tutorial", true);
		}
	};
	return (
		<Joyride
			steps={tutorialSteps}
			showProgress={true}
			showSkipButton={true}
			callback={handleJoyrideCallback}
			// hideCloseButton={true}
			styles={{
				options: {
					zIndex: 10000,
				},
			}}
			continuous
			disableScrolling={true}
			locale={{
				back: t("tutorial.back", "Back"),
				close: t("tutorial.close", "Close"),
				last: t("tutorial.last", "Last"),
				next: t("tutorial.next", "Next"),
				open: t("tutorial.open-dialog", "Open the dialog"),
				skip: t("tutorial.skip", "Skip"),
			}}
			tooltipComponent={Tooltip}
			run={run}
		/>
	);
}

const Tooltip = ({
	continuous,
	index,
	size,
	step,
	isLastStep,
	backProps,
	closeProps,
	primaryProps,
	tooltipProps,
	skipProps,
}) => {
	const buttons = useMemo(() => {
		let val = 0;
		if (continuous) val += OK;
		if (index > 0) val += CANCEL;
		return val;
	}, [index, continuous]);

	const extraButtons = () => {
		if (index === 0) {
			return (
				<button
					className="font-semibold  max-w-xs uppercase rounded text-white bg-customGray hover:bg-customGray-interact w-full flex-grow py-3"
					onClick={skipProps.onClick}>
					{skipProps.title}
				</button>
			);
		}
	};

	return (
		<div
			className={`bg-white  
		py-4 px-10 pb-4
		 bg-opacity-90 rounded-lg 
		 shadow-xl  
		 w-full max-w-lg relative`}>
			<div
				className="absolute right-6 top-6 cursor-pointer"
				onClick={closeProps.onClick}>
				<Close className="" />
			</div>
			<div className="px-2 pb-10 text-black text-center pt-5">
				{step.title && (
					<h1 className="text-2xl font-semibold mb-4">{step.title}</h1>
				)}
				<>{step.content}</>
			</div>
			{!!buttons > 0 && (
				<div className="flex px-2 pb-4 text-center space-x-2 justify-center">
					{extraButtons && extraButtons()}

					{!!(buttons & CANCEL) && (
						<button
							className="font-semibold  max-w-xs uppercase rounded text-white bg-customGray hover:bg-customGray-interact w-full flex-grow py-3"
							onClick={index === 0 ? skipProps.onClick : backProps.onClick}>
							{index === 0 ? skipProps.title : backProps.title}
						</button>
					)}
					{!!(buttons & OK) && (
						<button
							className="font-semibold max-w-xs uppercase rounded bg-black text-white hover:bg-gray-800 w-full flex-grow py-3"
							onClick={isLastStep ? closeProps.onClick : primaryProps.onClick}>
							{isLastStep && closeProps.title}
							{!isLastStep && (
								<>
									{primaryProps.title}
									&nbsp;
									<span className=" text-secondary font-normal">
										({index + 1}/{size})
									</span>
								</>
							)}
						</button>
					)}
				</div>
			)}
		</div>
	);
};
