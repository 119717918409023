import { createSelector } from "reselect";
import salesSlice from "./salesSlice";

export const getSalesNotes = createSelector(
	[salesSlice],
	(sales) => sales.notes
);
export const getSalesFiles = createSelector(
	[salesSlice],
	(sales) => sales.files
);
