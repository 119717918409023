import * as React from "react";

function QA({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M15 4c-4 0-7.6 2.1-9.6 5.5s-1.9 7.7.1 11.1L5 24.9c0 .3.1.6.2.8.2.2.5.3.8.3h.2l4.1-1c1.5.7 3.1 1.1 4.7 1.1 6.1 0 11-4.9 11-11S21.1 4 15 4zm0 20c-1.4 0-2.8-.3-4.1-1-.1-.1-.3-.1-.5-.1h-.2l-3 .8.4-3.2c0-.2 0-.5-.2-.7-2.2-3.5-1.8-8 1-11s7.3-3.7 10.9-1.7c3.6 2 5.4 6.1 4.4 10.1S19.1 24 15 24zm0 0" />
			<path d="M17 14.9c-1 .8-1.2 1.2-1.2 1.7v.5h-1.4s-.4-.5-.4-1.2c0-.6.4-1 1.2-1.7 1.1-.9 1.3-1.2 1.3-1.9 0-.8-.4-1.2-1.6-1.2-1 0-2.3.3-2.3.3l-.1-1.3s1.4-.5 2.6-.5c2.1 0 3.1.7 3.1 2.5.1 1.4-.1 2-1.2 2.8zm-2.8 3.3H16v2.1h-1.8v-2.1z" />
		</svg>
	);
}

const MemoQA = React.memo(QA);
export default MemoQA;
