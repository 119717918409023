import * as React from "react";

function CheckSolid({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M27 10.7c-.6-.6-1.5-.6-2.1-.1l-13 12.1-7-7.1c-.6-.6-1.5-.5-2.1.1-.6.6-.5 1.5.1 2.1l8 8.1c.3.3.6.4 1 .4s.7-.1 1-.4l14-13.1c.7-.6.7-1.5.1-2.1" />
		</svg>
	);
}

const MemoCheckSolid = React.memo(CheckSolid);
export default MemoCheckSolid;
