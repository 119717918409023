import * as React from "react";

function RaiseHand({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 35 35"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M27.15 16l-1.45 2.54a.78.78 0 01-1.35-.78l1.45-2.51a1 1 0 00.09-.76 1 1 0 00-.49-.59 1 1 0 00-1.32.42l-1.95 3.38a.79.79 0 01-.48.36.76.76 0 01-.59-.07 2.2 2.2 0 00-2.66 3.46.75.75 0 01.23.55.76.76 0 01-.23.55.75.75 0 01-.55.23.76.76 0 01-.55-.23 3.76 3.76 0 013.83-6.23l.43-.75V6.18a1 1 0 00-.93-1 1 1 0 00-.72.27 1 1 0 00-.3.71V14a.78.78 0 11-1.56 0V4a1 1 0 00-1.95 0v10a.78.78 0 01-1.56 0V6.13a1 1 0 10-1.95 0V14A.78.78 0 0111 14V8.47a1 1 0 00-1.95 0v12.46a6.17 6.17 0 001.32 3.84 5 5 0 011 3.05v4.12h7.83v-4.12a5.06 5.06 0 011.05-3.07 6 6 0 00.53-.81L23 20.05a.79.79 0 01.48-.36.72.72 0 01.59.08.74.74 0 01.36.47.72.72 0 01-.08.59l-2.19 3.8a7.28 7.28 0 01-.7 1.07 3.5 3.5 0 00-.72 2.12v4.77a.91.91 0 01-.91.91h-9.05a.91.91 0 01-.91-.91v-4.77a3.42 3.42 0 00-.7-2.09 7.76 7.76 0 01-1.65-4.8V8.47A2.55 2.55 0 0111 6.13a2.54 2.54 0 012.53-2.54 2.57 2.57 0 011 .2 2.54 2.54 0 015.05 0 2.55 2.55 0 013.52 2.39V13a2.53 2.53 0 014 3z" />
		</svg>
	);
}

const MemoRaiseHand = React.memo(RaiseHand);
export default MemoRaiseHand;
