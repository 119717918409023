import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelectors } from "../../store/user";
import { Close, Person } from "../../assets/icons";
import { useMemo, useState } from "react";

import { uiActions } from "../../store/ui";
import ProfileInformation from "../forms/login-register/ProfileInformation";
import Confirmation from "../forms/login-register/Confirmation";
import ProfilePicture from "../forms/login-register/ProfilePicture";

export function RegistrationPanel() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const user = useSelector(userSelectors.getUserIdentity);

	const [registerStep, setRegisterStep] = useState(0);
	const closeCallback = () => dispatch(uiActions.closePanel());

	return (
		<div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-2xl text-center'>
			<Close
				className='absolute right-6 top-6 cursor-pointer'
				onClick={closeCallback}
			/>
			{registerStep === 0 && (
				<ProfileInformation
					next={setRegisterStep}
					email={user.email}
					t={t}
					showClose={true}
					closeCallback={closeCallback}
				/>
			)}
			{registerStep === 1 && <ProfilePicture next={setRegisterStep} t={t} />}
			{registerStep === 2 && <Confirmation next={setRegisterStep} t={t} />}
		</div>
	);
}
