import constants from "../constants";
import axios from "axios";
import { sendMessage } from "pubnub-redux";
import { authHeader } from "../../helpers";

export const sendCommandToGroup = (type, body = "") => {
	return function sendCommandToGroupThunk(dispatch, getState, context) {
		const channelName = getState().group.commandChannel;
		// console.log("sending to channel");
		dispatch(
			sendMessage({
				channel: channelName,
				message: {
					type: type,
					payload: body,
				},
				sender: getState().user.identity.uuid,
			})
		);
	};
};

export const sendCommandToGroupIndividuals = (type, body = "") => {
	return function sendCommandToGroupIndividualsThunk(
		dispatch,
		getState,
		context
	) {
		const groupMembers = getState().call.participantsInCall;
		const myId = getState().user.identity.uuid;

		for (let i = 0, l = groupMembers.length; i < l; i++) {
			dispatch(
				sendMessage({
					channel: groupMembers[i].uuid + "--private",
					message: {
						type: type,
						payload: body,
					},
					sender: myId,
				})
			);
		}
		// SEND TO SELF TOO
		dispatch(
			sendMessage({
				channel: myId + "--private",
				message: {
					type: type,
					payload: body,
				},
				sender: myId,
			})
		);
	};
};

export const startVideo = (url, withQA) => {
	return sendCommandToGroup(constants.MESSAGE_TYPE.SESSION_START, {
		url,
		withQA,
	});
};
export const stopVideo = () => {
	return sendCommandToGroup(constants.MESSAGE_TYPE.SESSION_END);
};
export const startVideoCall = () => {
	return sendCommandToGroup(constants.MESSAGE_TYPE.MEETING_START);
};
export const stopVideoCall = () => {
	return sendCommandToGroup(constants.MESSAGE_TYPE.MEETING_END);
};
export const restartVideoCall = () => {
	return sendCommandToGroupIndividuals(constants.MESSAGE_TYPE.RESTART_CALL);
};

export const startTour = () => {
	return sendCommandToGroup(constants.MESSAGE_TYPE.START_TOUR);
};
export const releaseGroup = (endVideo) => {
	return sendCommandToGroup(constants.MESSAGE_TYPE.RELEASE_GROUP, {
		endVideo,
	});
};
export const changeVideoLayout = (layout) => {
	return sendCommandToGroup(constants.MESSAGE_TYPE.SWITCH_VIDEO, {
		mode: layout,
	});
};
// Rewrite to only teleport people
export const gatherGroup = () => {
	return sendCommandToGroup(constants.MESSAGE_TYPE.GATHER_GROUP);
};
export const sendGroupToRoute = (route) => {
	return sendCommandToGroup(constants.MESSAGE_TYPE.GATHER_GROUP, { route });
};

// Fetch your invited guests
export const getMyInvitees = (key, type = 1) => {
	return function getMyInviteesThunk(dispatch, getState, context) {
		let url = process.env.REACT_APP_API_ENDPOINT + "/profiles/invitees";
		if (type === constants.USER_TYPE.HOST) {
			url += "?my_group=1";
		}
		axios
			.get(url, {
				...authHeader(key),
			})
			.then((result) => {
				dispatch({ type: constants.SET_INVITEES, payload: result.data });
			});
	};
};
