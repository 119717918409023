import * as React from "react";

function Location({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M15 28a.9.9 0 01-.69-.32l-7.62-9a8.31 8.31 0 01-1.53-2.51 10.51 10.51 0 011.22-9.64 10.49 10.49 0 0117.24 0 10.5 10.5 0 011.21 9.67 8.31 8.31 0 01-1.52 2.51l-7.62 9A.9.9 0 0115 28zm0-24.16a8.54 8.54 0 00-7.12 3.73 8.67 8.67 0 00-1 8 6.6 6.6 0 001.2 2l6.92 8.1 6.93-8.14a6.36 6.36 0 001.19-2 8.64 8.64 0 00-1-8A8.54 8.54 0 0015 3.84z" />
			<path d="M15 17.57a5.08 5.08 0 115.08-5.08A5.09 5.09 0 0115 17.57zm0-8.35a3.27 3.27 0 103.27 3.27A3.28 3.28 0 0015 9.22z" />
		</svg>
	);
}

const MemoLocation = React.memo(Location);
export default MemoLocation;
