import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Close } from "../../assets/icons";
import GoodieBagListItem from "../itemrenderers/GoodiebagListItem";

import { Loading } from "../../assets/icons";
import { notificationActions } from "../../store/notification";
import { uiActions } from "../../store/ui";
import { goodiebagActions } from "../../store/goodiebag";
import { useTranslation } from "react-i18next";
import { appSelectors } from "../../store/app";

const GoodiebagPanel = () => {
	const { t } = useTranslation();
	const goodiebag = useSelector((state) => state.goodiebag.contents);
	const dispatch = useDispatch();

	const appConfig = useSelector(appSelectors.getAppConfig);

	const directDownloadEnabled = useMemo(() => {
		return (
			appConfig.hasOwnProperty("direct-file-downloads") &&
			parseInt(appConfig["direct-file-downloads"]) === 1
		);
	}, [appConfig]);

	const deleteItem = (id) => {
		dispatch(goodiebagActions.removeItemFromGoodiebag(id));
	};

	const fetching = useSelector((state) => state.goodiebag.fetching);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		dispatch(goodiebagActions.loadGoodiebag());
		dispatch(notificationActions.clearPing("GOODIEBAG"));
	}, [dispatch]);

	return (
		<div
			className="bg-white rounded-md shadow-lg 
		p-10 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 
		w-full max-w-2xl scrollable-panel">
			<Close
				className="absolute right-6 top-6 cursor-pointer"
				onClick={() => dispatch(uiActions.closePanel())}
			/>

			<h1 className="font-semibold text-3xl mb-3 text-center">
				{t("base.downloads.title", "Downloads")}
			</h1>
			<p className="text-center mb-6">
				{t(
					"base.downloads.below_message",
					"Below are the files you have collected during your visit."
				)}{" "}
				<br />
				{t(
					"base.downloads.can_download_message",
					"You can download these right now, or later from"
				)}
				<a
					href="https://tcity.toyota-forklifts.eu/downloads"
					target="_blank"
					rel="noreferrer"
					className="underline text-primary font-semibold mx-1">
					{t("base.downloads.profile_page", "profile page")}
				</a>
				{t(
					"base.downloads.after-completing-profile",
					"after completing your profile."
				)}
				.
			</p>

			<div className="scrollable-panel__viewport">
				{fetching && (
					<div className="mx-auto w-10 mt-5">
						<Loading className="animation animate-spin w-10 h-10 text-primary " />
					</div>
				)}
				{!fetching && (
					<ul className="mt-4 striped-children border-t border-trueGray-300">
						{goodiebag.map((element, idx) => (
							<GoodieBagListItem
								key={idx}
								type={element.type}
								description={element.description}
								id={element.id}
								onDelete={deleteItem}
								t={t}
								onDownload={(id) => {
									dispatch(goodiebagActions.downloadGoodiebagItem(id));
								}}
								directDownloadEnabled={directDownloadEnabled}
							/>
						))}
					</ul>
				)}
			</div>
		</div>
	);
};

export default GoodiebagPanel;
