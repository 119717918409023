import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { overlayActions } from "../store/overlay";
import { useTranslation } from "react-i18next";
import HotspotDetailsOverlay from "./overlays/HotspotDetailsOverlay";
import constants from "../store/constants";
import WalkthroughOverlay from "./overlays/WalkthroughOverlay";

const OverlayManager = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const overlay = useSelector((state) => state.overlay);

	const clearOverlay = () => {
		dispatch(overlayActions.clearOverlay());
	};

	return (
		<>
			{overlay.showOverlay &&
				overlay.type === constants.OVERLAY_TYPE.HOTSPOT && (
					<HotspotDetailsOverlay
						details={overlay.details}
						format={overlay.format}
						callback={clearOverlay}
						t={t}
					/>
				)}
			{overlay.showOverlay &&
				overlay.type === constants.OVERLAY_TYPE.WALKTHROUGH && (
					<WalkthroughOverlay
						id={overlay.id}
						details={overlay.details}
						format={overlay.format}
						callback={clearOverlay}
					/>
				)}
		</>
	);
};

export default React.memo(OverlayManager);
