import * as React from "react";

function Microphone({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M15 21a6 6 0 01-6-6V7a6 6 0 0112 0v8a6 6 0 01-6 6zm0-18a4 4 0 00-4 4v8a4 4 0 008 0V7a4 4 0 00-4-4zm9 10a1 1 0 00-1 1v1a8 8 0 01-16 0v-1a1 1 0 00-2 0v1a10 10 0 009 10v2h-2a1 1 0 000 2h6a1 1 0 000-2h-2v-2a10 10 0 009-10v-1a1 1 0 00-1-1zm0 0" />
		</svg>
	);
}

const MemoMicrophone = React.memo(Microphone);
export default MemoMicrophone;
