import * as React from "react";

function Teleport({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<path d='M15 8.85c-1.81 0-3.29 1.48-3.29 3.29s1.48 3.29 3.29 3.29 3.29-1.48 3.29-3.29S16.81 8.85 15 8.85zm0 5.04c-.96 0-1.74-.78-1.74-1.74s.78-1.75 1.74-1.75 1.74.78 1.74 1.75-.78 1.74-1.74 1.74zM20.99 19.01l-.12-.03-.35 1.51.12.03c2.33.55 2.96 1.23 3 1.42 0 .61-3.02 2.05-8.63 2.05s-8.63-1.43-8.63-2.02c.04-.29.93-1.03 3.42-1.55l.12-.03-.31-1.51-.12.03c-3.05.63-4.66 1.69-4.66 3.06 0 2.45 5.28 3.57 10.18 3.57s10.18-1.12 10.18-3.57c0-.9-.73-2.14-4.19-2.96z' />
			<path d='M22.86 12.08c-.12-4.08-3.41-7.42-7.48-7.61-.14 0-.27-.01-.41-.01-2.01 0-3.92.76-5.39 2.17a7.79 7.79 0 00-2.43 5.68c0 2.29.98 4.44 2.68 5.91h.01l4.24 4.6c.24.26.58.41.93.41s.69-.15.93-.41l4.18-4.53a7.87 7.87 0 002.75-6.22zm-3.82 5.1L15 21.55l-4.12-4.45a6.293 6.293 0 01-2.19-4.78c0-1.74.69-3.36 1.95-4.57a6.288 6.288 0 014.35-1.74h.3c3.27.15 5.91 2.83 6.01 6.11.06 1.95-.75 3.74-2.27 5.06z' />
			<path fill='none' d='M4.94 4.59h20.12v20.83H4.94z' />
		</svg>
	);
}

const MemoTeleport = React.memo(Teleport);
export default MemoTeleport;
