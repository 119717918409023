import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { userActions } from "../store/user";
import { useTranslation } from "react-i18next";
import { eventSelectors } from "../store/event";
import constants from "../store/constants";
import { push } from "connected-react-router";

const JoinPage = () => {
	const { code } = useParams();
	const dispatch = useDispatch();
	const ui = useSelector((state) => state.ui);
	const { t } = useTranslation();
	const eventLoadState = useSelector(eventSelectors.getEventLoadingState);

	// Fetch join session data from server
	useEffect(() => {
		if (code) {
			// Try autologin
			localStorage.setItem("apiKey", code);
			dispatch(userActions.loginFromCache());
		}
	}, [dispatch, code]);

	useEffect(() => {
		if (eventLoadState === constants.EVENT_LOAD_STATE.LOADED) {
			// Redirect to event route
			dispatch(push("/event"));
		}
	}, [eventLoadState, dispatch]);
	// Show enter first / last name
	// Register user
	return (
		<div className='w-screen h-screen flex justify-center items-center p-10'>
			{ui.loginState === 1 && (
				<div
					className='bg-transparent text-primary text-center
	 block py-4 px-10 rounded-md
	   shadow-lg uppercase max-w-xs bg-white'>
					<p className='flex items-center space-x-4 justify-center'>
						<svg
							width='38'
							height='38'
							viewBox='0 0 38 38'
							xmlns='http://www.w3.org/2000/svg'
							className='text-primary'
							stroke='currentColor'>
							<g fill='none' fillRule='evenodd'>
								<g transform='translate(1 1)' strokeWidth='2'>
									<circle strokeOpacity='.5' cx='18' cy='18' r='18' />
									<path d='M36 18c0-9.94-8.06-18-18-18'>
										<animateTransform
											attributeName='transform'
											type='rotate'
											from='0 18 18'
											to='360 18 18'
											dur='1s'
											repeatCount='indefinite'
										/>
									</path>
								</g>
							</g>
						</svg>
						<span>{t("login.state.logging_in", "Logging in")}</span>
					</p>
				</div>
			)}
			{ui.loginState === 419 && (
				<div
					className='text-center
	 block py-4 px-10 rounded-md
	   shadow-lg uppercase max-w-xs bg-white border-2 border-primary text-primary'>
					<p>{t("login.state.link_expired", "This link has expired")}</p>
				</div>
			)}
		</div>
	);
};

export default JoinPage;
