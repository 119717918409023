import React from "react";

function Logo(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 147.7 57.5' {...props}>
			<g fill='#E02926'>
				<path d='M60.3 14.9c-2.8 0-5.1-2.6-5.1-5.8s2.3-5.8 5.1-5.8 5.1 2.6 5.1 5.8-2.3 5.8-5.1 5.8m0-14.8c-5 0-9.1 4.1-9.1 9.1s4.1 9.1 9.1 9.1 9.1-4.1 9.1-9.1c0-5.1-4.1-9.1-9.1-9.1'></path>
				<path d='M85 .7H70.5v3.2h5.3v14h3.8v-14H85V.7z'></path>
				<path d='M90.7 11.1l2.4-6.3 2.4 6.3h-4.8zM95.5.7h-4.8l-7.1 17.1h4.5l1.5-3.8h7l1.5 3.8h4.5L95.5.7z'></path>
				<path d='M0 .7v3.2h5.3v14h3.8v-14h5.3V.7H0z'></path>
				<path d='M24.6 14.9c-2.8 0-5.1-2.6-5.1-5.8s2.3-5.8 5.1-5.8 5.1 2.6 5.1 5.8-2.3 5.8-5.1 5.8m0-14.8c-5 0-9.1 4.1-9.1 9.1s4.1 9.1 9.1 9.1 9.1-4.1 9.1-9.1C33.6 4.1 29.6.1 24.6.1'></path>
				<path d='M51 .7h-4.5l-4.1 7.2L38.3.7h-4.5l6.7 10.6v6.5h3.9v-6.5L51 .7z'></path>
			</g>
			<path fill='#C4C4C5' d='M0 29.8h147.7v-3.4H0v3.4z'></path>
			<path
				fill='#2B2E34'
				d='M.7 46.9h2l2.4 7.3c.3 1 .4 1.2.5 1.5.2-.6.2-.7.5-1.6L8.6 47h1.8v10.3H9.2v-8.6l-3 8.6H5l-2.9-8.7v8.7H.7V46.9z'></path>
			<path
				fill='#2B2E34'
				d='M17 46.9h-1.5l-3.9 10.3H13l1.1-3.1h4.3l1.2 3.1h1.5L17 46.9zM14.6 53l1.1-3c.2-.6.4-1.3.5-2 .2.7.4 1.4.7 2.1L18 53h-3.4z'></path>
			<path
				fill='#2B2E34'
				d='M20.6 46.9v1.2H24v9.1h1.4v-9.1h3.4v-1.2h-8.2z'></path>
			<path
				fill='#2B2E34'
				d='M30.2 46.9v10.3h7.7V56h-6.3v-3.5h5.7v-1.2h-5.7v-3.1h6.1V47h-7.5z'></path>
			<g transform='translate(39.741 46.841)'>
				<path
					fill='#2B2E34'
					d='M0 .1h4.6c.7 0 1.4.1 2 .3 1 .4 1.7 1.4 1.6 2.5 0 1.6-1.1 2.5-2.9 2.8.8.4 1.5 1.1 1.9 1.9L9 10.4H7.3L6 8.2C4.5 5.9 4.1 5.8 2.9 5.8H1.4v4.5H0V.1zm1.4 4.5h2.9c.6 0 1.1 0 1.6-.2.6-.3.9-.8.9-1.5 0-.6-.3-1.1-.8-1.4-.4-.2-.9-.3-1.4-.3H1.4v3.4z'></path>
			</g>
			<path fill='#2B2E34' d='M50.3 57.2h1.4V46.9h-1.4v10.3z'></path>
			<g transform='translate(52.93 46.841)'>
				<path
					fill='#2B2E34'
					d='M4 .1h1.5l4.2 10.3H8.1L6.9 7.2H2.6l-1.1 3.1H0L4 .1zM3 6.2h3.5L5.4 3.3c-.3-.7-.5-1.4-.7-2.1-.1.7-.3 1.3-.5 2L3 6.2z'></path>
			</g>
			<path fill='#2B2E34' d='M63.6 46.9v10.3H70V56h-5v-9.1h-1.4z'></path>
			<path
				fill='#2B2E34'
				d='M82.3 46.9v4.2H77v-4.2h-1.4v10.3H77v-4.8h5.3v4.8h1.4V46.9h-1.4z'></path>
			<g transform='translate(84.831 46.841)'>
				<path
					fill='#2B2E34'
					d='M4 .1h1.5l4.2 10.3H8.1L6.9 7.2H2.6l-1.1 3.1H0L4 .1zM3 6.2h3.5L5.4 3.3c-.3-.7-.5-1.4-.7-2.1-.1.7-.3 1.3-.5 2L3 6.2z'></path>
			</g>
			<path
				fill='#2B2E34'
				d='M102.3 46.9V55l-5.4-8.1h-1.4v10.3h1.3v-8.1l5.4 8.1h1.4V46.9h-1.3z'></path>
			<g transform='translate(105.927 46.841)'>
				<path
					fill='#2B2E34'
					d='M0 .1h3.5c1.4-.2 2.7.3 3.7 1.2.9 1.1 1.3 2.4 1.3 3.8.1 1.5-.4 3-1.5 4.2-.9.8-2.1 1.1-3.3 1H0V.1zm1.4 9h2.2c1.2 0 3.5 0 3.5-4C7.2 4 6.8 2.8 6 2c-.7-.6-1.6-.8-2.5-.7H1.4v7.8z'></path>
			</g>
			<path fill='#2B2E34' d='M116.2 46.9v10.3h6.4V56h-5.1v-9.1h-1.3z'></path>
			<path fill='#2B2E34' d='M124.4 57.2h1.4V46.9h-1.4v10.3z'></path>
			<path
				fill='#2B2E34'
				d='M135 46.9V55l-5.4-8.1h-1.4v10.3h1.3v-8.1l5.4 8.1h1.4V46.9H135z'></path>
			<g transform='translate(138.216 46.668)'>
				<path
					fill='#2B2E34'
					d='M5.2 5.3h4.3v3.8c-1.2 1-2.7 1.6-4.2 1.6C2.5 10.9.2 8.8 0 6.1v-.6C0 3.5 1 .1 5.2.1c3.4 0 4 2.3 4.2 3l-1.3.4c-.3-1.4-1.6-2.4-3-2.2-3.5 0-3.7 3.1-3.7 4.1-.1 1.1.3 2.1.9 3 .7.8 1.8 1.2 2.8 1.1 1.1 0 2.1-.4 3-1.1V6.5h-3V5.3z'></path>
			</g>
		</svg>
	);
}

export default React.memo(Logo);
