import React from "react";
import BaseModal, { OK, CANCEL } from "./BaseModal";

import constants from "../../store/constants";

import { useDispatch } from "react-redux";

import { uiActions } from "../../store/ui";
import { videoActions } from "../../store/video";
import { notificationActions } from "../../store/notification";

const VideoRequestModal = ({ notification, callback, t }) => {
	const dispatch = useDispatch();

	const handleAccept = () => {
		dispatch(uiActions.setLayerNotInCall(constants.LAYER.VIDEO, true));
		dispatch({
			type: constants.SET_REMOTE_END,
			payload: true,
		});

		dispatch(
			videoActions.playVideo(
				notification.message.streamUrl,
				notification.message.live,
				false,
				notification.message.qa
			)
		);
		// dispatch(notificationActions.addPing("QA"));
		callback(notification.id, false);
	};

	const handleDecline = () => {
		dispatch(notificationActions.addPing("QA"));

		callback(notification.id, false);
	};

	const handleIgnore = () => {
		dispatch(notificationActions.addPing("QA"));

		callback(notification.id, true);
	};

	return (
		<BaseModal
			showClose={true}
			buttons={OK | CANCEL}
			confirmCallback={handleAccept}
			confirmText={t("base.video_request_confirm", "Take me to the Q&amp;A session")}
			cancelCallback={handleDecline}
			closeCallback={handleIgnore}
			cancelText='I want to continue exploring'
			size='xl'>
			<h1 className='text-3xl font-semibold mb-4 uppercase'>
				{t("base.video_request_qa_session", "The live Q&amp;A session will start in 5 min.")}
			</h1>
		</BaseModal>
	);
};

export default VideoRequestModal;
