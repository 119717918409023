import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../store/user";

const ProfilePicture = ({ next, t }) => {
	const [images, setImages] = useState([]);
	const [imgUrl, setImgUrl] = useState("");
	const [uploadeImg, setUploadedImg] = useState("");

	const dispatch = useDispatch();
	const profileInfo = useSelector((state) => state.user?.profileInfo);
	const firstName = profileInfo?.first_name?.charAt(0).toUpperCase();
	const lastName = profileInfo?.last_name?.charAt(0).toUpperCase();

	const onImageChanged = (e) => {
		// let file = e.target.files[0];
		const reader = new FileReader();
		// let url = reader.readAsDataURL(file);

		reader.onloadend = (e) => {
			return setUploadedImg(reader.result);
		};

		reader.readAsDataURL(e.target.files[0]);

		setImages(e.target.files);
	};

	useEffect(() => {
		if (images.length < 1) return;
		setImgUrl(URL.createObjectURL(images[0]));
	}, [images]);

	const formSubmit = (e) => {
		e.preventDefault();

		// if (images.length == 0)
		// 	next((step) => step + 1);
		// else {
		// 	//Wait for save complete

		// }
	};

	const onSaveImage = (e) => {
		dispatch(userActions.uploadUImageAction({ avatar: uploadeImg }))
			.then((r) => next((step) => step + 1))
			.catch((err) => console.log(err));
	};

	const onDefaultClicktHandler = () => {
		next((step) => step + 1);
	};
	return (
		<div className=' p-10 w-full bg-white rounded-lg max-w-2xl mx-auto shadow-xl text-center'>
			<form
				onSubmit={formSubmit}
				id='registrationForm'
				className='overflow-auto max-h-100 pr-4  stylized-scrollbar'>
				{/* {{-- @csrf--}} */}
				<h2 className='font-semibold text-xl text-center mb-8'>
					{t("profile.upload_picture", "Upload your profile picture")}
				</h2>
				<p>
					{t(
						"profile.presence_shown",
						"Your presence will be shown to other attendees of the virtual event using an avatar."
					)}
				</p>
				<p className='my-4'>
					{t(
						"profile.initials_used",
						"If you don't upload a profile picture your initials will be used."
					)}
				</p>
				<div className='rounded-full w-32 h-32 bg-white border-primary border-2 flex mx-auto justify-center items-center overflow-hidden relative'>
					{imgUrl === "" && (
						<span className='text-primary text-5xl uppercase'>{`${
							firstName || ""
						}${lastName || ""}`}</span>
					)}
					{imgUrl !== "" && (
						<img
							src={imgUrl}
							className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
							alt='Avatar'
						/>
					)}
				</div>
				<div className='flex flex-col space-y-4 mt-5'>
					<label
						className='bg-black text-white text-center
                      hover:bg-gray-800 cursor-pointer
                      mx-auto max-w-sm shadow py-4 w-full rounded-md
                      focus:ring-2 focus:ring-primary-interact uppercase'>
						{t("profile.select_image", "Select image")}
						<input
							className='absolute opacity-0'
							style={{ zIndex: -1 }}
							type='file'
							accept='image/*'
							onChange={onImageChanged}
						/>
					</label>
					{imgUrl !== "" && (
						<button
							className='
							bg-primary text-white text-center
                            hover:bg-primary-interact
                            mx-auto max-w-sm shadow py-4 w-full rounded-md
                            focus:ring-2 focus:ring-primary-interact uppercase'
							onClick={onSaveImage}>
							{t("profile.save_profile_image", "Save profile image")}
						</button>
					)}
					<button
						className='text-secondary text-center 
                          mx-auto max-w-sm shadow py-4 w-full rounded-md border border-secondary
                          focus:ring-2 focus:ring-secondary-interact uppercase'
						onClick={onDefaultClicktHandler}>
						{t("profile.use_default_image", "Use default image")}
					</button>
				</div>
			</form>
		</div>
	);
};

export default ProfilePicture;
