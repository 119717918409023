import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/ui";
import {
	ArrowLeft,
	ArrowRight,
	Close,
	Headset,
	Keyboard,
	Location,
	Mouse,
	Settings,
	VCard,
} from "../../assets/icons";
import { useReducer } from "react";
import defaultProfile from "../../assets/img/default-account.png";
import { streamerSelectors } from "../../store/streamer";
import reactStringReplace from "react-string-replace";
import { useTranslation } from "react-i18next";
// import { PrimaryButton } from "../ui/PrimaryButton";

const HelpPanel = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const mode = useSelector(streamerSelectors.getstreamerNavState);
	const steps = 4;

	const [state, localDispatch] = useReducer(
		(state, action) => {
			console.log(action, state);
			switch (action.type) {
				case "NEXT":
					if (state.step + 1 <= steps) return { step: state.step + 1 };
					else return state;
				case "PREVIOUS":
					if (state.step - 1 > 0) return { step: state.step - 1 };
					else return state;
				case "SET":
					if (action.payload > 0 && action.payload <= steps)
						return { step: action.payload };
					else return state;
				default:
					throw new Error();
			}
		},
		{ step: 1 }
	);

	const stepViews = {
		NAVIGATION_MODE: (
			<>
				<div className='mt-8 text-secondary border-t pt-5'>
					{reactStringReplace(
						t(
							"base.instruc_move_extra",
							"You can change the navigation mode under the $icon$ settings section in the actionbar"
						),
						"$icon$",
						(match, i) => (
							<span className='bg-gray-100 shadow-lg rounded-md p-2 mx-1'>
								<Settings className='text-2xl inline-block' />
							</span>
						)
					)}
				</div>
			</>
		),
		MOVEMENT: (
			<>
				{mode === "fps" && (
					<>
						<div className='flex space-x-5'>
							<div className='flex flex-col space-y-4 items-center w-full text-center'>
								<Keyboard className='w-28 h-28' />
								<div>
									{t(
										"base.instruc_move",
										"Move through the world \nusing your arrow keys"
									)}
								</div>
							</div>
							<div className='flex flex-col space-y-4 items-center w-full text-center'>
								<Mouse className='w-28 h-28' />
								<div>
									{t("base.instruc_rotate", "Use your mouse to look around")}
								</div>
							</div>
						</div>
						{/* <PrimaryButton>Switch mode</PrimaryButton> */}
					</>
				)}
				{mode === "mouse" && (
					<>
						<div className='flex space-x-5'>
							<div className='flex flex-col space-y-4 items-center w-full text-center'>
								<Mouse className='w-28 h-28' />
								<div className='whitespace-pre-line'>
									{t(
										"base.instruc_mouse",
										"Aim towards the floor and click to automatically move to that location.\nHold down the left mouse button and drag to look around."
									)}
								</div>
							</div>
						</div>
						{/* <PrimaryButton>Switch mode</PrimaryButton> */}
					</>
				)}
			</>
		),
		SOCIAL: (
			<>
				<div className='flex  space-x-5 whitespace-pre-line'>
					<div className='flex flex-col space-y-4  items-center w-full text-center'>
						<img
							alt='Profile'
							src={defaultProfile}
							className='w-28 h-28 rounded-full border-secondary border-4 overflow-hidden'
						/>
						<p className=' whitespace-pre-line'>
							{t(
								"base.instruc_chat",
								"Would you like to chat with\nsomeone? Just click their\navatar and start a\nconversation."
							)}
						</p>
					</div>

					<div className='flex flex-col space-y-4 items-center w-full text-center'>
						<VCard className='w-28 h-28' />
						<p className=' whitespace-pre-line'>
							{t(
								"base.instruc_vcard",
								"You can request a vCard \nwhile you chat or from the\nParticipants list available in\nthe Action Bar menu."
							)}
						</p>
					</div>
				</div>
			</>
		),
		MAP: (
			<>
				<div className='flex flex-col items-center space-y-4'>
					<Location className='w-28 h-28' />
					<p className=' whitespace-pre-line'>
						{t(
							"base.instruc_teleport",
							"Use the floorplan panel to teleport directly to \n one of the interesting hotspots inside the environment."
						)}
					</p>
				</div>
			</>
		),
		SUPPORT: (
			<>
				<div className='flex flex-col items-center space-y-4'>
					<Headset className='w-28 h-28' />
					<p className=' whitespace-pre-line'>
						{t(
							"base.instruc_nav",
							"Need help?\nUse the support link in the action bar"
						)}
					</p>
				</div>
			</>
		),
	};

	const stepOrder = [
		stepViews.MAP,
		stepViews.MOVEMENT,
		stepViews.NAVIGATION_MODE,
		stepViews.SOCIAL,
		stepViews.SUPPORT,
	];

	return (
		<div className='bg-white rounded-md shadow-lg absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-2xl p-10 pb-0 text-center'>
			<Close
				className='absolute right-6 top-6 cursor-pointer'
				onClick={() => dispatch(uiActions.closePanel())}
			/>
			<h1 className='font-semibold text-2xl mb-5'>
				{t("base.sb_navhelp", "Navigation tips")}
			</h1>

			<div>
				{stepOrder[state.step - 1]}

				<div className='flex space-x-2 justify-center my-8 items-center'>
					<div
						className='flex justify-center items-center cursor-pointer pr-4'
						onClick={(e) => localDispatch({ type: "PREVIOUS" })}>
						<ArrowLeft
							className={`opacity-${state.step === 1 ? "20" : "100"}`}
						/>
					</div>
					{[...Array(steps)].map((e, i) => (
						<div
							key={i}
							className={`cursor-pointer rounded w-2 h-2  ${
								state.step === i + 1 ? "bg-black" : "bg-gray-300"
							}`}
							onClick={(e) =>
								localDispatch({ type: "SET", payload: i + 1 })
							}></div>
					))}
					<div
						className='flex justify-center items-center cursor-pointer pl-4'
						onClick={(e) => localDispatch({ type: "NEXT" })}>
						<ArrowRight
							className={`opacity-${state.step === steps ? "20" : "100"}`}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HelpPanel;
