import { useDispatch } from "react-redux";
import { CONNECTION_STATES } from "../../libs/streaming/useStreamer";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import CONSTANTS from "../../store/constants";
import { Enter } from "../../assets/icons";
import EnterGraphic from "../../assets/img/DigitalShowroom_Enter.jpg";

export const LoadStep = ({ loadStep, restartSession, connect }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const openExpertSession = () => {
		dispatch({
			type: CONSTANTS.ADD_MODAL,
			payload: {
				type: CONSTANTS.MESSAGE_TYPE.EXPERT,
				id: uuid(),
				ts: new Date().getTime(),
				message: { expertId: 1 },
				from: "WEBSTREAM",
			},
		});
	};

	const views = {
		[CONNECTION_STATES.IDLE]: (
			<p className="font-normal">
				{t("startup.session_idle", "The session will start in a moment")}
			</p>
		),

		[CONNECTION_STATES.RESERVATION_READY]: (
			<div className="space-y-5">
				<img
					src={EnterGraphic}
					alt="Enter"
					className="w-full h-auto overflow-hidden rounded"
				/>
				<p className="font-semibold text-lg">
					{t(
						"startup.session_ready",
						"Your digital experience is ready to start."
					)}
				</p>
				<button
					className="font-semibold uppercase rounded text-white bg-primary hover:bg-primary-interact px-4 py-3 flex space-x-2 items-center mx-auto"
					onClick={connect}>
					<Enter className="w-8 h-auto" />
					<span>{t("startup.enter_showroom", "Enter showroom")}</span>
				</button>
			</div>
		),

		[CONNECTION_STATES.DISCONNECTED]: (
			<div className="">
				<p className="mb-4">
					{t(
						"streaming.connection_lost",
						"The server connection to the server has been lost."
					)}
				</p>
				<button
					className="font-semibold max-w-xs uppercase rounded bg-black text-white hover:bg-gray-800 w-full py-3 my-2"
					onClick={restartSession}>
					{t("streaming.connection_lost_button", "Reconnect")}
				</button>
			</div>
		),

		[CONNECTION_STATES.CONNECTING]: (
			<>
				<p className="font-normal mb-3">
					{t(
						"startup.env_connecting",
						"We're connecting you to the event environment."
					)}
					<br />
					<strong>{t("startup.stand_by", "Please stand-by")}</strong>
				</p>
				<p className="font-normal text-sm">
					{t(
						"startup.loader",
						"If you don't see a loader within the next 2 minutes please"
					)}{" "}
					<br />
					<a
						href="/test/3d"
						target="_blank"
						className="text-primary underline font-semibold"
						rel="noreferrer">
						{t("startup.test_connection", "test your connection here")}
					</a>{" "}
					{t("global.or", "or")}{" "}
					<a
						href="/faq"
						target="_blank"
						className="text-primary underline font-semibold"
						rel="noreferrer">
						{t("startup.contact_support", "contact support here")}
					</a>
					.
				</p>
			</>
		),
		// [CONSTANTS.STREAMER_STATE.LOADING]: (
		// 	<p className='font-normal text-sm my-5'>
		// 		{t("startup.loading", "Loading the experience")}
		// 		<span className='font-bold my-2 block text-2xl'>
		// 			{loadingPercentage + "%"}
		// 		</span>
		// 	</p>
		// ),

		[CONNECTION_STATES.SERVICE_ERROR]: (
			<p className="font-normal">
				<strong className="text-lg">
					{t("streaming.service_error_title", "Streaming offline")}
				</strong>
				<br />
				<br />
				{t(
					"streaming.service_error_body",
					"The streaming service is down at the moment, please try again later"
				)}
			</p>
		),

		[CONNECTION_STATES.UNAVAILABLE]: (
			<div className="font-normal flex flex-col space-y-4">
				<p>
					<strong className="text-lg">
						{t("startup.full", "Digital showroom is full at this moment.")}
					</strong>
				</p>
				<p>
					{t(
						"startup.wait",
						"Wait until a spot opens up or \ntry to connect a little later."
					)}
				</p>
				<p>
					{t(
						"startup.meantime",
						"In the meantime you can still call and chat with other \nattendees."
					)}
				</p>
				<p>{t("startup.thank_you", "Thank you for your patience.")}</p>
				<button
					className="font-semibold max-w-xs uppercase rounded bg-black text-white hover:bg-gray-800 w-full py-3 my-2 mx-auto"
					onClick={restartSession}>
					{t("streaming.connection_retry", "Retry")}
				</button>
				{/* 
				Specific T-City live event option
				<p>
					<button
						onClick={() => openExpertSession()}
						className='font-semibold max-w-xs uppercase rounded bg-black text-white hover:bg-gray-800 w-full py-3 my-2'>
						or join the expert session
					</button>
				</p> */}
			</div>
		),

		// [CONNECTION_STATES.DISCONNECTED]: (
		// 	<p className="font-normal">
		// 		<strong className="text-lg">
		// 			{t("startup.session_closed", "Session closed")}
		// 		</strong>
		// 		<br />
		// 		<br />
		// 		{t(
		// 			"startup.session_was_closed",
		// 			"The session was closed because of inactivity."
		// 		)}
		// 		<br />
		// 		<br />
		// 		<button
		// 			className="font-semibold uppercase rounded text-white bg-black hover:bg-gray-800 px-6 py-3"
		// 			onClick={restartSession}>
		// 			{t("global.restart", "Restart")}
		// 		</button>
		// 	</p>
		// ),
	};

	return views[loadStep] ?? <></>;
};
