import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo";
import TCity from "../assets/tcity";
import moment from "moment-timezone";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import { eventSelectors } from "../store/event";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../components/locale/LanguageSwitcher";

const HomePage = () => {
	const startIn = useSelector(eventSelectors.getStartIn);
	const [endDate, setEndDate] = useState(new Date());
	const { t } = useTranslation();

	useEffect(() => {
		setEndDate(moment().add(startIn, "seconds").toDate());
	}, [startIn]);

	const zeroPad = useCallback((value) => {
		const iVal = parseInt(value);
		if (iVal < 10) return "0" + value;
		else return value;
	}, []);

	return (
		<div className='flex items-center justify-center p-5 lg:p-20 overflow-hidden md:h-screen min-h-full'>
			<div className='text-center'>
				<div className='relative mb-5'>
					<h2 className='font-normal text-3xl absolute top-0 right-0 mt-10 mr-9'>
						{t("home.welcome_to", "Welcome to")}
					</h2>
					<TCity className='w-100 h-auto' />
				</div>
				<h3 className='font-normal text-base mt-2'>
					{t("home.powered_by", "Powered by")}
				</h3>
				<Logo className='w-28 h-auto mx-auto mt-4' />
				<Countdown
					date={endDate}
					key={endDate}
					intervalDelay={1000}
					renderer={({ days, hours, minutes, seconds, completed }) => {
						if (completed) {
							// Render a completed state
							return (
								<Link
									to='/login'
									className='mt-8 bg-primary text-white text-center text-base
								hover:bg-primary-interact
							 inline-block shadow py-3 px-8 rounded-md
							 focus:ring-2 focus:ring-primary-interact uppercase'>
									{t("home.enter_button")}
								</Link>
							);
						} else {
							// Render a countdown
							return (
								<div
									className='
			  mt-8 bg-secondary text-white text-center text-sm
			inline-block py-3 px-8 rounded-md
			uppercase'>
									Opening soon ({days} days, {zeroPad(hours)}:{zeroPad(minutes)}
									:{zeroPad(seconds)})
								</div>
							);
						}
					}}
				/>
			</div>
			<div className='absolute right-8 top-8'>
				<LanguageSwitcher />
			</div>
		</div>
	);
};

export default HomePage;
