import * as React from "react";

function Info({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M15 2.13A12.83 12.83 0 1027.87 15 12.8 12.8 0 0015 2.13zm0 23.56A10.73 10.73 0 1125.77 15 10.71 10.71 0 0115 25.69z" />
			<path fill="none" d="M2 2h26v26H2z" />
			<path d="M15 11.43a1.18 1.18 0 00-1.18 1.18v8.25a1.18 1.18 0 002.36 0v-8.25A1.18 1.18 0 0015 11.43zm0 0M16.22 9.07A1.18 1.18 0 1115 7.89a1.18 1.18 0 011.18 1.18zm0 0" />
		</svg>
	);
}

const MemoInfo = React.memo(Info);
export default MemoInfo;
