import constants from "../constants";

const defaultState = {
	id: "488.465",
	server: {
		serverPath: "",
		https: 1,
		stunUrl: "",
		turnUrl: "",
		turnUsername: "",
		turnCredential: "",
	},
	outgoingMessages: [],
	incomingMessages: [],
	state: "ACTIVE",
	muted: false,
	navState: "fps",
};

const STREAMERReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.STREAMER_SET_ID:
			return {
				...state,
				id: action.payload,
			};
		case constants.STREAMER_SET_STATE:
			return {
				...state,
				state: action.payload,
			};
		case constants.STREAMER_ADD_OUTGOING_MESSAGE:
			return {
				...state,
				outgoingMessages: state.outgoingMessages.concat(action.payload),
			};
		case constants.STREAMER_CLEAR_OUTGOING_MESSAGES:
			return {
				...state,
				outgoingMessages: [],
			};
		case constants.STREAMER_ADD_INCOMING_MESSAGE:
			return {
				...state,
				incomingMessages: state.incomingMessages.concat([action.payload]),
			};
		case constants.STREAMER_CLEAR_INCOMING_MESSAGES:
			return {
				...state,
				incomingMessages: [],
			};
		case constants.STREAMER_SET_MUTED:
			return {
				...state,
				muted: action.payload,
			};
		case constants.STREAMER_SET_NAVSTATE:
			return {
				...state,
				navState: action.payload,
			};
		case constants.APPEND_STREAMERCONFIG:
			return {
				...state,
				server: Object.assign({ ...state.server }, action.payload),
			};
		default:
			return state;
	}
};

export default STREAMERReducer;
