import { useEffect, useMemo, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Language } from "../../assets/icons";
import { languageList } from "../../helpers/LocaleHelper";
import { appActions, appSelectors } from "../../store/app";

export function LanguageSwitcher() {
	const [open, setOpen] = useState(false);
	const activeLanguage = useSelector(appSelectors.getAppLanguage).toUpperCase();
	const dispatch = useDispatch();
	const ref = useRef();

	function handleClickOutside(event) {
		if (ref.current && !ref.current.contains(event.target)) {
			setOpen(false);
		}
	}

	useEffect(() => {
		if (open) {
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		/**
		 * Alert if clicked on outside of element
		 */

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [open]);

	const changeLanguage = (iso) => {
		dispatch(appActions.setInterfaceLanguage(iso.toLowerCase()));
		// i18n.changeLanguage(iso.toLowerCase());
		setOpen(false);
		// console.log('on switch language ', i18n.language, iso.toLowerCase());
	};

	const activeLanguagFirst = useMemo(() => {
		return [
			languageList.find((l) => l.value === activeLanguage),
			...languageList.filter((l) => l.value !== activeLanguage),
		];
	}, [activeLanguage]);

	return (
		<div className='flex flex-col space-y-3 items-end' ref={ref}>
			<div
				className={`p-4 ${
					open ? "bg-primary text-white" : "bg-white text-primary"
				} hover:bg-primary hover:text-white shadow-lg rounded-full inline-block cursor-pointer group
				`}
				onClick={() => setOpen(!open)}>
				<Language
					className={`text-xl ${
						open ? "scale-125" : ""
					} group-hover:scale-125 transform transition-all`}
				/>
			</div>
			{open && (
				<div className='bg-white shadow-xl rounded-lg overflow-auto max-h-96 grid grid-cols-1 divide-y fancy-scrollbar'>
					{activeLanguagFirst.map((lang) => (
						<span
							key={lang.value}
							onClick={() => changeLanguage(lang.value)}
							className={`block px-4 py-2 hover:bg-primary hover:text-white cursor-pointer ${
								activeLanguage === lang.value ? "font-bold" : ""
							}`}>
							{lang.label}
						</span>
					))}
				</div>
			)}
		</div>
	);
}
