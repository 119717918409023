import { createSelector } from "reselect";
import constants from "../constants";
import uiSlice from "./uiSlice";

export const getActionbarUIState = createSelector(
	[uiSlice],
	(ui) => ui.actionbar
);

export const selectActionbar = createSelector([uiSlice], (ui) => ui.actionbar);
export const selectPanelActive = createSelector(
	[uiSlice],
	(ui) => ui.panelActive
);

export const getUIVisible = createSelector([uiSlice], (ui) => ui.visible);

export const getLayers = createSelector([uiSlice], (ui) => ui.layers ?? {});
export const streamerLayerActive = createSelector(
	[getLayers],
	(layers) => layers[constants.LAYER.STREAMER]
);

export const getActivationState = createSelector(
	[uiSlice],
	(ui) => ui.activationState
);

export const getPanelData = createSelector([uiSlice], (ui) => ui.panelData);

export const getLayerData = createSelector([uiSlice], (ui) => ui.layerData);
