import constants from "../constants";

export const playVideo = (refs, forceLocale = "") => {
	return function playVideoAsync(dispatch, getState, context) {
		// Use getState to retrieve user language preferences
		// Get url config by axios call
		//, "https://vimeo.com/504704884"

		const videoLibrary = getState().video.library;

		// Look up video based on ref and locale
		if (forceLocale === "") forceLocale = getState().user.identity.country;

		const queue = [];

		refs.forEach((ref) => {
			videoLibrary.forEach((vid) => {
				if (
					vid.reference === ref &&
					(vid.locale === forceLocale || vid.locale === "XX")
				)
					queue.push(vid);
			});
		});

		// "https://player.vimeo.com/external/543521891.hd.mp4?s=493ad841a7ade37dfee9bcb8ec7f34387defcb26&profile_id=175"; ;

		// const subtitleLocale = (
		// 	getState().user.identity.language ?? "EN"
		// ).toLowerCase();

		dispatch({
			type: constants.SET_VIDEO,
			payload: queue,
		});
	};
};

export const stopVideo = () => {
	return {
		type: constants.CLEAR_VIDEO,
	};
};
