const defaultState = {
	id: "",
	details: [],
	format: "object",
	showOverlay: false,
	type: null, // hotspot | paged
};

const overlayReducer = (state = defaultState, action) => {
	switch (action.type) {
		// case constants.ADD_MODAL:
		case "SHOW":
			return {
				...state,
				// details: action.payload,
				id: action.payload.id,
				details: action.payload.details,
				format: action.payload.format,
				type: action.payload.type,
				showOverlay: true,
			};
		// case constants.CLOSE_MODAL:
		case "HIDE":
			return {
				details: [],
				showOverlay: false,
			};
		default:
			return state;
	}
};

export default overlayReducer;
