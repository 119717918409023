import constants from "../constants";

const defaultState = {
	loggedIn: false,
	key: "",
	identity: {
		uuid: "",
		avatar: "",
		company: "",
		language: "",
		name: "",
		role: "",
		type: 3,
	},

	access: {
		access_from: null,
		access_until: null,
		elevated: false,
		inviter: "",
		permissions: 0,
		server_time: 0,
		session_length: 0,
	},
	preferences: {},
	activated: false,
	hasAccess: false,
	profileInfo: {},
};

const userReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_KEY:
			// save to localstorage for automatic logins in next 24 hours
			localStorage.setItem("apiKey", action.payload.key);
			return {
				...state,
				key: action.payload.key,
			};
		case constants.SET_USER_IDENTITY:
			return {
				...state,
				identity: action.payload,
				loggedIn: true,
			};
		case constants.SET_USER_ACCESS:
			return {
				...state,
				access: action.payload,
				loggedIn: true,
			};
		case constants.SET_USER_PREFERENCES:
			return {
				...state,
				preferences: action.payload,
				loggedIn: true,
			};

		case constants.LOGOUT:
			localStorage.removeItem("apiKey");
			return {
				...state,
				key: "",
				identity: {},
				loggedIn: false,
			};
		case constants.SAVE_PROFILE_DATA:
			return {
				...state,
				profileInfo: action.payload,
			};
		default:
			return state;
	}
};

export default userReducer;
