import constants from "../constants";

export const setPlayerId = (id) => {
	return {
		type: constants.STREAMER_SET_ID,
		payload: id,
	};
};

export const sendMessage = (msg) => {
	return {
		type: constants.STREAMER_ADD_OUTGOING_MESSAGE,
		payload: msg,
	};
};

export const messageReceived = (msg) => {
	return {
		type: constants.STREAMER_ADD_INCOMING_MESSAGE,
		payload: msg,
	};
};

export const clearOutgoingMessages = () => {
	return {
		type: constants.STREAMER_CLEAR_OUTGOING_MESSAGES,
	};
};

export const clearIncomingMessages = () => {
	return {
		type: constants.STREAMER_CLEAR_INCOMING_MESSAGES,
	};
};

export const setNavState = (state) => {
	return {
		type: constants.STREAMER_SET_NAVSTATE,
		payload: state,
	};
};

// NOT IN USE?
export const setState = (state) => {
	return {
		type: constants.STREAMER_SET_STATE,
		payload: state,
	};
};
