import constants from "../constants";

const defaultState = {
	queue: [],
	ping: {
		byId: {},
	},
};

const notificationReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.ADD_NOTIFICATION:
			return {
				...state,
				queue: state.queue.concat([action.payload]),
			};
		case constants.CLOSE_NOTIFICATION:
			return {
				...state,
				queue: state.queue.filter((n) => n.id !== action.payload),
			};
		case constants.ADD_PING:
			return {
				...state,
				ping: {
					byId: {
						...state.ping.byId,
						[action.payload]: true,
					},
				},
			};
		case constants.CLEAR_PING:
			// copy byId first
			const copyById = { ...state.ping.byId };
			delete copyById[action.payload];

			return {
				...state,
				ping: {
					byId: copyById,
				},
			};
		default:
			return state;
	}
};

export default notificationReducer;
