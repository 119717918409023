import { VersionNumber } from "../helpers";

import background from "../assets/img/backgrounds/background.png";
import TCity from "../assets/tcity";
import BgOverlay from "../assets/img/bg-overlay";
import { Route, Switch } from "react-router-dom";

import LoginForm from "../components/forms/login-register/LoginForm";

import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../components/locale/LanguageSwitcher";

const LoginPage = () => {
	const { t } = useTranslation();
	return (
		<div
			className='flex items-center p-5 lg:p-10 overflow-hidden relative md:h-screen min-h-full
			bg-cover bg-center bg-no-repeat justify-items-center'
			style={{ backgroundImage: `url(${background})` }}>
			<BgOverlay className='absolute w-full h-full top-0 left-0 object-cover bg-opacity-50 bg-gray-200' />

			<TCity className='absolute top-0 left-0 ml-10 mt-10 w-24 md:w-32' />
			{/* <TransitionGroup> => Doesn't work b/c rerender parent route */}
			{/* <CSSTransition key={location.key} classNames='slide' timeout={1000}> */}
			<Switch>
				<Route path='/login' exact={true}>
					<LoginForm t={t} />
				</Route>
			</Switch>
			{/* </CSSTransition> */}
			{/* </TransitionGroup> */}
			{process.env.NODE_ENV === "development" && (
				<div className='fixed bottom-2 left-2 text-sm'>
					<VersionNumber />
				</div>
			)}

			<div className='absolute right-8 top-8'>
				<LanguageSwitcher />
			</div>
		</div>
	);
};
export default LoginPage;
