import constants from "../constants";

const defaultState = {
	visible: false,

	layers: {
		[constants.LAYER.STREAMER]: false,
		[constants.LAYER.VIDEO]: false,
		[constants.LAYER.CALL]: false,
		[constants.LAYER.SALES]: false,
		[constants.LAYER.IFRAME]: true,
	},

	actionbar: constants.ACTIONBAR.COLLAPSED,
	panelActive: constants.PANEL.NONE,
	panelData: null, // For panels that need data
	layerData: null, // For layers that need data
	notifications: [],

	loginState: 0,
	activationState: 0,
	registrationState: 0,
};

const uiReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.SET_LAYERS:
			return {
				...state,
				layers: action.payload,
			};
		case constants.SET_LAYER_DATA:
			return {
				...state,
				layerData: action.payload,
			};
		case constants.SET_UI_VISIBILITY:
			return {
				...state,
				visible: action.payload,
			};
		case constants.SET_PANEL:
			return {
				...state,
				panelActive: action.payload,
			};
		case constants.SET_PANEL_WITH_DATA:
			return {
				...state,
				panelActive: action.payload.active,
				panelData: action.payload.data,
			};
		case constants.SET_PANEL_DATA:
			return {
				...state,
				panelData: action.payload,
			};
		case constants.SET_ACTIONBAR_STATE:
			return {
				...state,
				actionbar: action.payload,
			};
		case constants.SET_LOGIN_STATE:
			return {
				...state,
				loginState: action.payload,
			};
		case constants.SET_ACTIVATION_STATE:
			return {
				...state,
				activationState: action.payload,
			};
		case constants.SET_REGISTRATION_STATE:
			return {
				...state,
				registrationState: action.payload,
			};

		default:
			return state;
	}
};

export default uiReducer;
