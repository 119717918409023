import * as React from "react";

function Enter({ className = "", ...rest }) {
	return (
		<svg viewBox="0 0 24 24" className={`fill-current ${className}`} {...rest}>
			<path d="M8.189 12.75H3a.75.75 0 010-1.5h5.189l-1.72-1.72A.75.75 0 117.53 8.469l3 3a.75.75 0 010 1.061l-3 3a.748.748 0 01-1.06 0 .75.75 0 010-1.061l1.72-1.72zM21.75 5.781v12.438c0 .805-.545 1.503-1.325 1.698l-6 1.5a1.738 1.738 0 01-1.502-.319 1.713 1.713 0 01-.328-.348H7.001c-.965 0-1.75-.785-1.75-1.75v-1a.75.75 0 011.5 0v1c0 .138.112.25.25.25h5.25V4.75h-5.25a.25.25 0 00-.25.25v1a.75.75 0 01-1.5 0V5c0-.965.785-1.75 1.75-1.75h5.594c.094-.128.2-.247.328-.348a1.738 1.738 0 011.501-.319l6.001 1.5c.78.195 1.325.894 1.325 1.698zm-1.5 0a.25.25 0 00-.189-.243l-6-1.5a.24.24 0 00-.214.046.246.246 0 00-.096.197v15.438c0 .106.06.169.096.197a.26.26 0 00.215.046l5.999-1.5a.25.25 0 00.189-.243V5.781z" />
		</svg>
	);
}

const MemoEnter = React.memo(Enter);
export default MemoEnter;
