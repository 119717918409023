import * as React from "react";

function Exit({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path
				d="M27.43 7.32a4.9 4.9 0 00-4.9-4.89H10a4.9 4.9 0 00-4.9 4.89v2.51a1.13 1.13 0 002.26 0V7.32A2.64 2.64 0 0110 4.69h12.53a2.64 2.64 0 012.64 2.63v15.07A2.64 2.64 0 0122.53 25H10a2.64 2.64 0 01-2.64-2.63v-2.49a1.13 1.13 0 10-2.26 0v2.51a4.91 4.91 0 004.9 4.89h12.53a4.91 4.91 0 004.9-4.89z"
				fillRule="evenodd"
			/>
			<path
				d="M14.54 19.42l3.77-3.77a1.12 1.12 0 000-1.59l-3.77-3.77a1.12 1.12 0 00-1.59 0 1.13 1.13 0 000 1.6l1.83 1.83H3.7a1.13 1.13 0 100 2.28h11.08L13 17.82a1.13 1.13 0 000 1.6 1.12 1.12 0 001.54 0z"
				fillRule="evenodd"
			/>
		</svg>
	);
}

const MemoExit = React.memo(Exit);
export default MemoExit;
