import * as React from "react";

function Camera({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M27.57 5.59a.85.85 0 00-.86 0l-6.51 3.6v-.14a3.58 3.58 0 00-3.59-3.58h-11A3.59 3.59 0 002 9.06v11.88a3.59 3.59 0 003.59 3.59h11a3.59 3.59 0 003.59-3.59v-.13l6.51 3.61a.87.87 0 001.31-.75V6.33a.85.85 0 00-.43-.74zm-9.1 15.35a1.86 1.86 0 01-1.86 1.86h-11a1.86 1.86 0 01-1.86-1.86V9.06A1.86 1.86 0 015.59 7.2h11a1.86 1.86 0 011.86 1.86zm7.8 1.25l-6.07-3.37v-7.64l6.07-3.37zm0 0" />
		</svg>
	);
}

const MemoCamera = React.memo(Camera);
export default MemoCamera;
