import * as React from "react";

function Navigation({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M23.41 6.59L8.17 12.83l7 1.27a.91.91 0 01.74.74l1.27 7zm-9.2 9.2l-9.46-1.72a.92.92 0 01-.18-1.75l20.16-8.25a.92.92 0 011.2 1.2l-8.25 20.16a.92.92 0 01-1.75-.18z" />
		</svg>
	);
}

const MemoNavigation = React.memo(Navigation);
export default MemoNavigation;
