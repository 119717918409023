import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { notificationActions } from "../store/notification";
import { uiActions } from "../store/ui";
import constants from "../store/constants";
import { v4 as uuid } from "uuid";
import { overlayActions } from "../store/overlay";
import { fireTruckInfoModal } from "../store/modal/modalActions";

export default function useDispatchToStore() {
	const dispatch = useDispatch();

	const dispatchToStore = useCallback(
		(message) => {
			if (message === "SCENE_LOADED") {
				// if (isHosted) sendMessageToPlayer("MOVEMENT:FALSE");
				// TODO: CHECK IF HOST HAS ALREADY RELEASED VISITORS!!!!!!
				// IF HOSTED SESSION, SEND PLAYER LOCK
				// sendMessageToPlayer("MOVEMENT:TRUE|FALSE");
				// sendMessageToPlayer("SOUND:TRUE|FALSE");
				// IF PLAYERS ALREADY RELEASED, DON'T SEND
			} else if (message.indexOf("NOTIFICATION:GOODIEBAG") > -1) {
				dispatch(notificationActions.addPing("GOODIEBAG"));
			} else if (message === "TUTORIAL" || message === "JOSE") {
				dispatch(uiActions.openPanel(constants.PANEL.HELP));
			} else if (message.indexOf("EXT-LINK") > -1) {
				window.open(message.replace("EXT-LINK:", ""));
			} else if (message.indexOf("BREAKOUT") > -1) {
				// Get data
				const [channelName, channelDisplayName, participants] = message
					.split(":")[1]
					.split("|");

				dispatch({
					type: constants.ADD_MODAL,
					payload: {
						type: constants.MESSAGE_TYPE.BREAKOUT,
						id: uuid(),
						ts: new Date().getTime(),
						message: { channelName, channelDisplayName, participants },
						from: "WEBSTREAM",
					},
				});
			} else if (message.indexOf("EXPERT") > -1) {
				let expertId = parseInt(message.split(":")[1]);
				expertId++; // zero based...
				dispatch({
					type: constants.ADD_MODAL,
					payload: {
						type: constants.MESSAGE_TYPE.EXPERT,
						id: uuid(),
						ts: new Date().getTime(),
						message: { expertId },
						from: "WEBSTREAM",
					},
				});
			} else if (message === "ERNESTO_WATCHED") {
				if (!localStorage.hasOwnProperty("seen-tutorial")) {
					localStorage.setItem("seen-tutorial", 1);

					dispatch(uiActions.openPanel(constants.PANEL.HELP));
				}
			} else if (message.indexOf("HOTSPOT") > -1) {
				const hotspotName = message.split(":")[1];
				dispatch(
					overlayActions.openOverlay(
						hotspotName,
						constants.OVERLAY_TYPE.HOTSPOT,
						constants.FORMAT.ARRAY
					)
				);
			} else if (message.indexOf("WALKTHROUGH") > -1) {
				const walkthroughName = message.split(":")[1];
				if (walkthroughName === "RESET") {
					return dispatch(overlayActions.clearOverlay());
				}
				const walkthroughPage = message.split(":")[2];
				dispatch(
					overlayActions.openOverlay(
						`${walkthroughName}.page${walkthroughPage}`,
						constants.OVERLAY_TYPE.WALKTHROUGH,
						constants.FORMAT.ARRAY
					)
				);
			} else if (message.indexOf("CONFIG-SAVED") > -1) {
				dispatch(uiActions.setPanelData(message.split(":")[1]));
				// dispatch(uiActions.openPanel(constants.PANEL.SAVE_CONFIGURATION));
			} else if (message.indexOf("MODEL-SELECTED") > -1) {
				// Prevent showing the question for models other than RRE160HE
				if (message.split(":")[1] === "RRE160HE") {
					if (sessionStorage.getItem("seen-truck-info") !== null) return;
					dispatch(fireTruckInfoModal(message.split(":")[1]));
				}
			}
		},
		[dispatch]
	);

	return dispatchToStore;
}
