import * as React from "react";

function TeleportGroup({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<path d='M9.77 12.57c0 .46.38.84.84.84h8.79c.46 0 .84-.38.84-.84v-1.76c0-1.63-.94-3.16-2.41-3.89l-.13-.07.09-.12c.45-.6.69-1.32.69-2.08 0-1.95-1.56-3.53-3.47-3.53s-3.47 1.58-3.47 3.53c0 .75.24 1.47.69 2.08l.09.12-.13.07a4.391 4.391 0 00-2.41 3.89v1.76zM15 2.8c.98 0 1.8.85 1.8 1.86s-.81 1.8-1.8 1.8-1.8-.81-1.8-1.8.82-1.86 1.8-1.86zm-3.56 8.02c0-1.48 1.2-2.68 2.68-2.68h1.76c1.48 0 2.68 1.2 2.68 2.68v.92h-7.12v-.92zM1.13 28.04c0 .46.38.84.83.84h8.85c.46 0 .84-.38.84-.84v-1.82c0-1.63-.94-3.16-2.41-3.89l-.13-.07.09-.12a3.472 3.472 0 00-2.78-5.55c-1.91 0-3.47 1.56-3.47 3.47 0 .75.24 1.47.69 2.07l.09.12-.13.07c-.42.21-.81.48-1.15.81-.84.83-1.31 1.92-1.31 3.09v1.82zm5.29-9.77c.99 0 1.8.81 1.8 1.8s-.81 1.8-1.8 1.8-1.8-.81-1.8-1.8.81-1.8 1.8-1.8zM2.8 26.23c0-1.45 1.25-2.68 2.74-2.68H7.3c1.48 0 2.68 1.2 2.68 2.68v.98H2.8v-.98zM18.35 28.04c0 .46.38.84.84.84h8.85c.46 0 .84-.38.84-.84v-1.82c0-1.63-.97-3.16-2.46-3.9l-.13-.07.09-.12c.45-.6.69-1.32.69-2.07 0-1.92-1.56-3.47-3.47-3.47s-3.47 1.56-3.47 3.47c0 .75.24 1.47.69 2.08l.09.12-.13.07a4.391 4.391 0 00-2.41 3.89v1.82zm5.23-9.77c.99 0 1.8.81 1.8 1.8s-.81 1.8-1.8 1.8-1.8-.81-1.8-1.8.81-1.8 1.8-1.8zm-3.56 7.96c0-1.48 1.2-2.68 2.68-2.68h1.76c1.48 0 2.74 1.23 2.74 2.68v.98h-7.18v-.98zM15 19.45l3.14 1.57c.12.06.24.09.37.09.09 0 .18-.01.26-.04.21-.07.38-.22.49-.42.1-.2.12-.43.04-.64a.828.828 0 00-.42-.48L15.83 18v-3c0-.46-.38-.84-.84-.84s-.84.38-.84.84v3l-3.06 1.53c-.41.21-.58.71-.37 1.12.1.2.27.35.48.42.21.07.44.05.64-.05l3.14-1.57z' />
		</svg>
	);
}

const MemoTeleportGroup = React.memo(TeleportGroup);
export default MemoTeleportGroup;
