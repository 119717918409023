import * as React from "react";

function Pin({ className = "", onClick, ...rest }) {
	return (
		<svg
			viewBox='0 0 23.45 31.71'
			width='1em'
			height='1em'
			className={`fill-current ${className}`}
			{...rest}>
			<path
				onClick={onClick}
				d='M22.7 11.42a11 11 0 01-1.86 6.12v.06l-5.18 7.94L12.27 31a.64.64 0 01-1.09 0l-3.35-5.46-5.12-7.84a11 11 0 1120-6.28z'
				fill='#e50027'
			/>
		</svg>
	);
}

const MemoPin = React.memo(Pin);
export default MemoPin;
