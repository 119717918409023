import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui";
import constants from "../../store/constants";
import { Close } from "../../assets/icons";
// import Checkbox from "../ui/Checkbox";
import { salesActions } from "../../store/sales";
import { notificationActions } from "../../store/notification"; // addNotification
import { useTranslation } from "react-i18next";
import AttendeeListItem from "../itemrenderers/AttendeeListItem";
import ServerImage from "../controls/ServerImage";
import { showDefaultProfilePicture } from "../../helpers/AssetHelper";
import defaultAvatar from "../../assets/img/default-avatar.png";

export const SalesParticipants = () => {
	const { t } = useTranslation();
	const invitees = useSelector((state) => state.host.invitees);
	const dispatch = useDispatch();

	const closePanel = () => {
		dispatch(uiActions.setLayer(constants.LAYER.PARTICIPANTS, false));
	};

	// console.log("invitees", invitees);

	return (
		<div className="absolute left-1/2 bottom-20 transform -translate-x-1/2 w-full max-w-xs layer--sales bg-white bg-opacity-90 p-6 shadow-xl rounded-md">
			<div
				className="absolute right-4 top-4 cursor-pointer"
				onClick={(e) => {
					closePanel();
				}}>
				<Close className="w-5 h-5" />
			</div>
			<div className="w-full h-full flex flex-col">
				<div className="flex-grow">
					<ul className="mt-6 flex flex-col text-md divide-y ">
						{invitees.map((invitee, index) => {
							return (
								<li
									className="flex space-x-4 items-center py-2"
									key={`invitee-${index}`}>
									<div className=" rounded-full overflow-hidden w-10 h-10">
										{showDefaultProfilePicture ? (
											<img
												className="w-full h-full object-cover object-center bg-white"
												src={defaultAvatar}
												alt={invitee?.name}
											/>
										) : (
											<ServerImage
												className="w-full h-full object-cover object-center bg-white"
												src={invitee?.avatar}
												alt={invitee?.name}
											/>
										)}
									</div>

									<span>{invitee.name}</span>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};
