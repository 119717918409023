import * as React from "react";

function Language({ className = "", ...rest }) {
	return (
		<svg
			viewBox='0 0 24 24'
			width='1em'
			height='1em'
			fill='none'
			className={`stroke-current ${className}`}
			{...rest}>
			<path
				d='M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'></path>
			<path
				d='M13 2.04932C13 2.04932 16 5.99994 16 11.9999C16 17.9999 13 21.9506 13 21.9506'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'></path>
			<path
				d='M11 21.9506C11 21.9506 8 17.9999 8 11.9999C8 5.99994 11 2.04932 11 2.04932'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'></path>
			<path
				d='M2.62964 15.5H21.3704'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'></path>
			<path
				d='M2.62964 8.5H21.3704'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'></path>
		</svg>
	);
}

const MemoLanguage = React.memo(Language);
export default MemoLanguage;
