import { useReducer, useState } from "react";
import { useStreamer } from "../../libs/streaming/useStreamer";
import { useEffect } from "react";
// import { Separator } from "./controls/Separator";
import { useDispatch, useSelector } from "react-redux";
import { streamerActions, streamerSelectors } from "../../store/streamer";
import { userSelectors } from "../../store/user";

import { LoadStep } from "./LoadStep";
import useDispatchToStore from "../../hooks/useDispatchToStore";
import { CONNECTION_STATES } from "../../libs/streaming/useStreamer";
import { useTranslation } from "react-i18next";

// const CONNECTION_STATE_LABELS = {
// 	IDLE: "gray",
// 	CONNECTING: "info",
// 	CONNECTED: "success",
// 	UNAVAILABLE: "failure",
// 	RESERVATION: "indigo",
// };

const INTERNAL_ACTIONS = {
	SET_LOADSTEP: "set_loadstep",
	SET_PLAYERLOADING: "set_playerloading",
	SET_SHOWOVERLAY: "set_showoverlay",
	SET_LOADINGPERCENTAGE: "set_loadingpercentage",
	SET_SHOWSKIPBUTTON: "set_showskipbutton",
};

function internalStateReducer(state, action) {
	switch (action.type) {
		case INTERNAL_ACTIONS.SET_SHOWOVERLAY:
			return { ...state, showOverlay: action.payload };

		case INTERNAL_ACTIONS.SET_SHOWSKIPBUTTON:
			return { ...state, showSkipButton: action.payload };
		default:
			return state;
	}
}

export const StreamingPlayer = (props) => {
	const dispatch = useDispatch();

	// Get Store state
	const settings = useSelector(streamerSelectors.getstreamerServerConfig);
	const userKey = useSelector(userSelectors.getUserKey);
	const outgoingMessages = useSelector(
		streamerSelectors.getstreamerOutgoingMessages
	);
	const { t } = useTranslation();
	const [activeCursor, setActiveCursor] = useState("auto");

	// Internal state
	const [internalState, internalDispatch] = useReducer(internalStateReducer, {
		showOverlay: true,
		showSkipButton: false,
	});
	const dispatchToStore = useDispatchToStore();

	const { streamerStatus, videoStream, sendMessage, reconnect, connect } =
		useStreamer({
			...settings,
			messageCallback: (message) => {
				// Process message (does it need to be processed here or bubble up)
				// if (msg === "READY" && appConfig.identityEnabled === 1)
				// sendMessageToPlayer(`EVENT_JOINED:${userKey}`);
				// if (message === "READY" && appConfig.identityEnabled === 1)
				// sendMessageToPlayer(`EVENT_JOINED:${userKey}`);
				if (message === "TUTORIAL" || message === "JOSE") {
					if (localStorage.getItem("has-seen-tutorial") !== null) {
						internalDispatch({
							type: INTERNAL_ACTIONS.SET_SHOWSKIPBUTTON,
							payload: true,
						});
					} else {
						dispatchToStore(message);
					}
					localStorage.setItem("has-seen-tutorial", "1");
				} else if (message === "ERNESTO_WATCHED") {
					internalDispatch({
						type: INTERNAL_ACTIONS.SET_SHOWSKIPBUTTON,
						payload: false,
					});
				} else if (message === "MOUSE_OVER") {
					setActiveCursor("pointer");
				} else if (message === "MOUSE_OUT") {
					setActiveCursor("auto");
				} else {
					dispatchToStore(message);
				}
			},
			onReady: () => {},
			onSdkReady: () => {
				sendMessage(`EVENT_JOINED:${userKey}`);
			},
			onDisconnect: () => {},
		});
	// const sendCommand = () => {
	// 	sendMessage("Testing");
	// 	// videoPlayer.current.sendCommand("Testing");
	// };

	// const sendNavigationSwitch = (m) => {
	// 	sendMessage(`NAVMODE:${m}`);
	// };

	// const loginUser = () => {
	// 	sendMessage(`EVENT_JOINED:${userId}`);
	// };

	// const sendResolutionUpdate = (w, h) => {
	// 	sendMessage(`RESOLUTION_UPDATE:${w}x${h}`);
	// };

	useEffect(() => {
		if (streamerStatus === CONNECTION_STATES.CONNECTED)
			internalDispatch({
				type: INTERNAL_ACTIONS.SET_SHOWOVERLAY,
				payload: false,
			});
		else
			internalDispatch({
				type: INTERNAL_ACTIONS.SET_SHOWOVERLAY,
				payload: true,
			});
	}, [streamerStatus]);

	useEffect(() => {
		if (outgoingMessages.length > 0) {
			outgoingMessages.forEach((m) => sendMessage(m));

			dispatch(streamerActions.clearOutgoingMessages());
		}
	}, [dispatch, sendMessage, outgoingMessages]);

	return (
		<div className="h-screen layer--streamer">
			<div className="relative w-full h-full">
				<div
					id="player"
					ref={videoStream}
					className="w-full h-full"
					style={{ cursor: activeCursor }}></div>

				<div
					className={`player-overlay ${
						internalState.showOverlay ? "" : "hidden"
					}`}>
					<div
						className={`w-screen h-screen bg-white bg-opacity-25 fixed top-0 left-0 z-10`}>
						<div
							className={`fixed w-full bg-white 
					py-8 px-10 
					 bg-opacity-90 rounded-lg 
					 shadow-xl top-1/2 left-1/2 
					 transform -translate-x-1/2 -translate-y-1/2 
					  max-w-md `}>
							<div className="text-black text-center">
								{
									<LoadStep
										loadStep={streamerStatus}
										restartSession={reconnect}
										connect={connect}
									/>
								}
							</div>
						</div>
					</div>
				</div>
				{/* <Card className='bg-white absolute left-2 top-1/2 -translate-y-1/2 flex flex-col space-y-1'>
					<Label>Send command</Label>
					<Button onClick={sendCommand}>Send</Button>
					<Label>Received command</Label>
					<output>{receivedMessage}</output>
					<Separator />
					<Label>Login</Label>
					<TextInput
						type='text'
						value={userId}
						onChange={(e) => setUserId(e.target.value)}
					/>
					<Button onClick={loginUser}>Login</Button>
					<Separator />
					<Label>Switch navigation</Label>
					<Button
						onClick={() => {
							sendNavigationSwitch("fps");
						}}>
						FPS
					</Button>
					<Button
						onClick={() => {
							sendNavigationSwitch("mouse");
						}}>
						Mouse
					</Button>
					<Button
						onClick={() => {
							sendResolutionUpdate(800, 600);
						}}>
						800 x 600
					</Button>
					<Button
						onClick={() => {
							sendResolutionUpdate(window.innerWidth, window.innerHeight);
						}}>
						Browser resolution
					</Button>
				</Card> */}
			</div>
		</div>
	);
};
