import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import constants from "../../store/constants";
import { eventSelectors } from "../../store/event";
import { groupSelectors } from "../../store/group";
import { userSelectors } from "../../store/user";
// eslint-disable-next-line no-unused-vars
import { callActions, callSelectors } from "../../store/call";
import { uiActions, uiSelectors } from "../../store/ui";

import { Sidebar, Grid, CameraOff } from "../../assets/icons";
import { AgoraVideoPlayer } from "../../libs/AgoraWrapper";
import CallControls from "./CallControls";
// import Logo from "../assets/logo";
import { useSelector } from "react-redux";
import ServerImage from "../controls/ServerImage";

// import { CallHostControls } from "./HostControls";

// import MiniChat from "./MiniChat"; // TEMPORARILY NOT USED

import CallHostControls from "./CallHostControls";

// const getCallLayout = createSelector([getCallSlice], (slice) => slice.layout);

const CallWindowGroup = ({
	hideCallWindow,
	channelName,
	uid,
	token,
	layout,
	t,
	isHost,
}) => {
	// using the hook to get access to the client object
	const client = window.useClient();
	const dispatch = useDispatch();
	// ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null
	const { ready, tracks } = window.useMicrophoneAndCameraTracks();

	const [users, setUsers] = useState([]);
	// const [start, setStart] = useState(false);

	const members = useSelector(eventSelectors.getMemberState);
	// const isHost = useSelector(groupSelectors.isHost);
	const currentUser = useSelector(userSelectors.getUserIdentity);

	// Hosts view scales differently
	const actionbarLayout = useSelector(uiSelectors.getActionbarUIState);

	const remoteEnd = useSelector(callSelectors.getCallRemoteEnd);

	const appId = useSelector(callSelectors.getCallAppId);

	const tempSwapLocalView = (layout) => {
		dispatch(callActions.changeCallLayout(layout));
	};

	const init = useCallback(
		async (name) => {
			client.on("user-published", async (user, mediaType) => {
				await client.subscribe(user, mediaType);
				// console.log("subscribe success");
				if (mediaType === "video") {
					setUsers((prevUsers) => {
						const lookup = prevUsers.find((u) => u.uid === user.uid);
						if (typeof lookup === "undefined") return [...prevUsers, user];

						return [...prevUsers];
					});
				}
				if (mediaType === "audio") {
					user.audioTrack?.play();
				}
			});

			client.on("user-unpublished", (user, type) => {
				// console.log("unpublished", user, type);
				if (type === "audio") {
					user.audioTrack?.stop();
				}
				if (type === "video") {
					/*
					setUsers((prevUsers) => {
						return prevUsers.filter((User) => User.uid !== user.uid);
					});
					*/
				}
			});

			client.on("user-left", (user) => {
				console.log("leaving", user);
				setUsers((prevUsers) => {
					return prevUsers.filter((User) => User.uid !== user.uid);
				});
			});

			const joinResult = await client
				.join(appId, name, token, uid)
				.catch((error) => {
					console.log("Something went wrong while starting the call", error);
				});

			if (typeof joinResult === "undefined") {
			}

			console.log("CALL JOINED", joinResult);

			dispatch({
				type: constants.CALL_JOINED,
			});

			if (tracks) await client.publish([tracks[0], tracks[1]]);
			// setStart(true);
		},
		[dispatch, client, tracks, appId, token, uid]
	);

	useEffect(() => {
		if (ready && tracks) {
			// console.log("init ready");
			init(channelName);
		}
	}, [channelName, ready, tracks, init]); // Removing dependencies fixes issue...

	// const [timeLeft, setTimeLeft] = useState(150);

	// React.useEffect(() => {
	// 	let to;
	// 	if (timeLeft > 0) to = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
	// 	if (timeLeft === 0) leaveChannel();
	// 	return () => clearTimeout(to);
	// }, [timeLeft]);

	const [alone, setAlone] = useState(true); // eslint-disable-line no-unused-vars
	useEffect(() => {
		if (users) setAlone(users.length < 1);
	}, [users]);

	useEffect(() => {
		if (layout === constants.CALL_LAYOUT.PIP) {
		}
	}, [layout]);

	const leaveChannel = useCallback(async () => {
		await client.leave();
		client.removeAllListeners();
		// we close the tracks to perform cleanup
		if (tracks !== null) {
			tracks[0].close();
			tracks[1].close();
		}
		// setStart(false);
		dispatch({
			type: constants.SET_REMOTE_END,
			payload: false,
		});
		hideCallWindow();
	}, [dispatch, client, hideCallWindow, tracks]);

	useEffect(() => {
		if (remoteEnd) leaveChannel();

		/*
		dispatch({
			type: constants.SET_REMOTE_END,
			payload: false,
		});
		*/
	}, [remoteEnd, leaveChannel]);

	// eslint-disable-next-line no-unused-vars
	const handleAlone = () => {
		leaveChannel();
		dispatch(uiActions.setLayer(constants.LAYER.STREAMER, true, false));
	};

	const setVolume = (volume) => {
		for (let i = 0, l = users.length; i < l; i++) {
			users[i].audioTrack.setVolume(volume);
		}
	};

	return (
		<div
			className={`call-grid--${layout} wrapper expected-${
				users.length + 1
			} actionbar--${actionbarLayout} ${isHost ? "host" : "guest"}`}>
			<div className="inner">
				<CallControls
					className="call-controls z-50"
					tracks={tracks}
					allowLeave={true}
					leaveChannel={leaveChannel}
					layout={layout}
					setVolume={setVolume}
					t={t}
					// start={start}
				/>
				<div className="call-layout-controls z-50 rounded-md">
					<div className="buttons flex space-x-2 md:space-x-4 xl:space-x-8  justify-center text-center text-xs mt-2">
						<div>
							<button
								className={`rounded-full 
								${layout === constants.CALL_LAYOUT.SIDEBAR ? "bg-black" : "bg-trueGray-300"}
								text-white w-14 h-14 flex justify-center items-center text-3xl mb-1 mx-auto`}
								onClick={() =>
									tempSwapLocalView(constants.CALL_LAYOUT.SIDEBAR)
								}>
								<Sidebar />
							</button>
							<span>{t("base.group_call.sidebar", "Sidebar")}</span>
						</div>
						<div>
							<button
								className={`rounded-full 
								${layout === constants.CALL_LAYOUT.FULLSCREEN ? "bg-black" : "bg-trueGray-300"}
								text-white w-14 h-14 flex justify-center items-center text-3xl mb-1 mx-auto`}
								onClick={() =>
									tempSwapLocalView(constants.CALL_LAYOUT.FULLSCREEN)
								}>
								<Grid />
							</button>
							<span>Grid</span>
						</div>
						<div>
							<button
								className={`rounded-full 
								${layout === constants.CALL_LAYOUT.AUDIO_ONLY ? "bg-black" : "bg-trueGray-300"}
								text-white w-14 h-14 flex justify-center items-center text-3xl mb-1 mx-auto`}
								onClick={() =>
									tempSwapLocalView(constants.CALL_LAYOUT.AUDIO_ONLY)
								}>
								<CameraOff />
							</button>
							<span>{t("base.group_call.audio_only", "Audio only")}</span>
						</div>
					</div>
				</div>

				{/* 
				HIDE FOR NOW. ONLY REQUIRED FOR EXTERNAL */}
				{/* {isHost && (
					<div className="host-actions fixed right-5 bottom-5 z-50">
						<CallHostControls t={t} />
					</div>
				)} */}
				{/*
				TEMPORARILY LEAVE OUT
				{layout === constants.CALL_LAYOUT.FULLSCREEN && (
					<div className="call-chat p-2 rounded-md shadow-md">
						<MiniChat
							channelName={`chat-group-${group?.id}`}
							showTitle={false}
							t={t}
						/>
					</div>
				)} */}
				{/*
				PERSONAL VIDEO FEED
				 */}
				<div
					className="user1 rounded-md
					 overflow-hidden bg-white relative
					 ">
					<div className="absolute  py-1 px-3 bg-white text-black text-xs font-semibold z-10 bottom-0 left-0 rounded-tr-md">
						{currentUser.name}
					</div>
					{ready && (
						<AgoraVideoPlayer
							style={{ height: "100%", width: "100%" }}
							className="vid user-video"
							videoTrack={tracks[1]}
						/>
					)}
				</div>

				{users.length > 0 &&
					(layout === constants.CALL_LAYOUT.FULLSCREEN ||
						layout === constants.CALL_LAYOUT.SIDEBAR) &&
					users
						.reduce((filtered, user) => {
							const identity = members.find((m) => m.videoUid === user.uid);

							return filtered.concat({
								...user,
								name: identity?.name,
								avatar: identity?.avatar,
							});
						}, [])
						.map((user, idx) => {
							// console.log("USERSTREAM", user);
							return (
								<div
									key={user.uid.toString()}
									className={`${
										"user" + (idx + 2)
									} rounded-sm overflow-hidden bg-white  relative`}
									id={user.uid.toString()}>
									<div className="absolute py-1 px-3 bg-white text-black text-xs font-semibold z-10 bottom-0 left-0 rounded-tr-md">
										{user.name}
									</div>

									<ServerImage
										className="absolute rounded-full overflow-hidden w-12 h-12 flex-none left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
										style={{ zIndex: -1 }}
										src={user.avatar}
										alt={user.name}
									/>

									{user._videoTrack && (
										<AgoraVideoPlayer
											className="w-full h-full"
											videoTrack={user._videoTrack}
										/>
									)}
								</div>
							);
						})}
			</div>
		</div>
	);
};

export default React.memo(CallWindowGroup);
