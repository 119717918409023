import { eventActions } from "../event";

export const createStateChangeListener = (dispatch) => ({
	presence: (presenceEvent) => {
		// handle presence
		// console.log("PRESENCE EVENTS:", presenceEvent);
		// Ignore own events

		// if (presenceEvent.uuid === user.identity?.id) return;

		// if (presenceEvent.action !== "state-change") return;
		if (presenceEvent.channel !== "main") return;

		dispatch(eventActions.handleStateChange(presenceEvent));
	},
});
