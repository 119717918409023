import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend";
import axios from "axios";

// Integrate backend API translations
import fallbackEn from "./assets/locale/en.json";

// Get the application resources from the Backend
let loadResources = async (locale) => {
	return await axios
		.get(
			process.env.REACT_APP_API_ENDPOINT + "/resources/application/" + locale
		)
		.then((response) => {
			// console.log('Response from Api ', response.data);
			return response.data;
		})
		.catch((error) => {
			console.log("Error loading locale. Using default english");
			return fallbackEn;
			// console.log(error);
		});
};

const backendOptions = {
	loadPath: "{{lng}}|{{ns}}", // 'en|US'
	// loadPath: '/locales/{{lng}}/{{ns}}.json'
	request: (options, url, payload, callback) => {
		const [lng] = url.split("|");

		try {
			loadResources(lng).then((response) => {
				callback(null, {
					data: response,
					status: 200,
				});
			});
		} catch (e) {
			// console.error(e, fallbackEn);
			console.log("Error loading locale. Using default english");
			callback(null, {
				data: fallbackEn,
				status: 200,
				//status: 500,
			});
		}
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(backend)
	.init({
		backend: backendOptions,
		load: "languageOnly",
		ns: ["translation"],
		defaultNS: "translation",
		debug: process.env.NODE_ENV === "development",
		fallbackLng: "en",
		supportedLngs: [
			"bg",
			"hr",
			"cs",
			"da",
			"nl",
			"en",
			"et",
			"fi",
			"fr",
			"de",
			"el",
			"hu",
			"ga",
			"it",
			"lv",
			"lt",
			"mt",
			"pl",
			"pt",
			"ro",
			"sk",
			"sl",
			"es",
			"se",
		],
		interpolation: {
			escapeValue: false,
		},

		react: {
			bindI18n: "languageChanged loaded",
			bindStore: "added removed",
			nsMode: "default",
			useSuspense: true,
		},
	});

// i18n.changeLanguage(navigator.language);

export default i18n;
