import { createSelector } from "reselect";
import userSlice from "../user/userSlice";
import groupSlice from "./groupSlice";

export const getGroup = createSelector([groupSlice], (group) => group);

export const getGroupLoader = createSelector(
	[groupSlice],
	(slice) => slice.leader
);
export const isHost = createSelector(
	[userSlice, groupSlice],
	(user, group) => group.leader === user.identity.uuid
);

export const getAttendeesInGroup = createSelector([groupSlice], (group) => {
	return group.attendees;
});
