import constants from "../constants";

export const setAppConfig = (config) => {
	return { type: constants.SET_APPCONFIG, payload: config };
};

export const setInterfaceLanguage = (iso) => {
	return function setInterfaceLanguageAsync(dispatch, getState, context) {
		context.i18n.changeLanguage(iso.toLowerCase());
		dispatch({
			type: constants.SET_LANGUAGE,
			payload: iso,
		});
	};
	//i18n.changeLanguage(iso.toLowerCase());
};
