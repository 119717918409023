import * as React from "react";

function Speaker({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 35 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M16.2 2.31L8 7.29H2.87A.91.91 0 002 8.2v13.6a.91.91 0 00.91.91H8l8.18 5a2.73 2.73 0 004.15-2.33V4.64a2.73 2.73 0 00-4.13-2.33zM3.78 9.11h3.58v11.78H3.78zm14.75 16.25a.91.91 0 01-1.38.78l-8-4.85V8.71l8-4.85a.91.91 0 011.38.78zM22.94 12.64a.92.92 0 000 1.29 1.5 1.5 0 010 2.14.92.92 0 000 1.29.91.91 0 001.29 0 3.34 3.34 0 000-4.72.91.91 0 00-1.29 0z" />
			<path d="M27 9.89a.91.91 0 00-1.29 1.29 5.4 5.4 0 010 7.64.91.91 0 001.29 1.29 7.23 7.23 0 000-10.22z" />
			<path d="M29.73 7.14a.92.92 0 00-1.29 0 .91.91 0 000 1.29 9.2 9.2 0 010 13.14.91.91 0 000 1.29.92.92 0 001.29 0 11 11 0 000-15.72z" />
		</svg>
	);
}

const MemoSpeaker = React.memo(Speaker);
export default MemoSpeaker;
