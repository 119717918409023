import React from "react";
import { assetHelper } from "../../helpers";

const ServerImage = ({ className, src, alt }) => {
	return (
		<img
			className={className}
			src={assetHelper.uploadUrl(src, "attendees")}
			alt={alt}
		/>
	);
};

export default ServerImage;
