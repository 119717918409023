import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import HomePage from "../pages/HomePage";
import LobbyPage from "../pages/LobbyPage";
import LoginPage from "../pages/LoginPage";
import EventPage from "../pages/EventPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import background from "../assets/img/backgrounds/background.png";
import { ConnectedRouter } from "connected-react-router";

import { streamerActions } from "../store/streamer";
import { eventActions } from "../store/event";
import { callActions } from "../store/call";

import { useDispatch } from "react-redux";
import { appActions } from "../store/app";
import JoinPage from "../pages/JoinPage";
import { Gallery } from "../assets/icons/Gallery";

const AppRouter = ({ environment, history }) => {
	const dispatch = useDispatch();

	// Store environment variables passed via <root> data attributes in Redux
	useEffect(() => {
		if (typeof environment === "undefined") return;
		dispatch(eventActions.setEventCode(environment.eventCode));
		dispatch(eventActions.setEventType(environment.eventType));
		dispatch(eventActions.setInviteCode(environment.inviteCode));
		dispatch(eventActions.setStartIn(environment.startIn));
		dispatch(callActions.setAppId(environment.agoraApp));
		dispatch(streamerActions.setPlayerId(environment.streamer));
		dispatch(appActions.setAppConfig(environment));

		if (localStorage.hasOwnProperty("i18nextLng"))
			dispatch(
				appActions.setInterfaceLanguage(localStorage.getItem("i18nextLng"))
			);
	}, [dispatch, environment]);

	return (
		<div
			className='w-full h-full bg-cover bg-center bg-no-repeat bg-opacity-80 bg-white'
			style={{ backgroundImage: `url(${background})` }}>
			<div className='absolute w-full h-full top-0 left-0 object-cover bg-opacity-90 bg-white z-0' />
			<div className='relative'>
				{/* <Router basename={process.env.REACT_APP_BASE_URL}> */}
				<ConnectedRouter history={history}>
					<Switch>
						<PublicRoute
							path='/dev/icon-gallery'
							exact={true}
							component={Gallery}
						/>
						<PublicRoute path='/' exact={true} component={HomePage} />
						<PublicRoute path='/join/:code' component={JoinPage} />
						<PublicRoute path='/login' component={LoginPage} />
						<PrivateRoute path='/lobby' exact={true} component={LobbyPage} />
						<PrivateRoute path='/event' exact={true} component={EventPage} />
					</Switch>
				</ConnectedRouter>
			</div>
		</div>
	);
};

export default AppRouter;
