import React from "react";
import { Route } from "react-router-dom";

const PublicRoute = ({ component: Component, ...rest }) => {
	// useEffect((e) => {
	// 	console.log("RERENDERED ROUTE");
	// });
	return <Route {...rest} component={(props) => <Component {...props} />} />;
};
export default PublicRoute;

// function propsAreEqualBasedOnPathname(prevProps, nextProps) {
// 	if (nextProps.location.pathname.indexOf("login") == -1) return false;
// 	if (
// 		prevProps.location.pathname.indexOf("login") == -1 &&
// 		nextProps.location.pathname.indexOf("login") > 0
// 	)
// 		return false;

// 	return true;
// 	// console.log("RERENDER? ", prevProps.location.pathname.split("/"));
// 	// return nextProps.location.pathname.indexOf("login") == -1;
// }

// export default withRouter(memo(PublicRoute, propsAreEqualBasedOnPathname));
