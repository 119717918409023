import * as React from "react";

function Asterisk({ className = "", ...rest }) {
	return (
		<svg
			viewBox='0 0 30 30'
			width='1em'
			height='1em'
			className={`fill-current ${className}`}
			{...rest}>
			<path
				d='M11 6H13V10.079L16.3413 7.73938L17.4885 9.37768L13.7434 12L17.4885 14.6223L16.3413 16.2606L13 13.921V18H11V13.921L7.65864 16.2606L6.51148 14.6223L10.2565 12L6.51147 9.37769L7.65863 7.73938L11 10.079V6Z'
				fill='currentColor'></path>
		</svg>
	);
}

const MemoArrowLeft = React.memo(Asterisk);
export default MemoArrowLeft;
