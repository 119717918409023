import * as React from "react";

function ThumbsUp({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M23.39 11.68h-4.8l1.08-4.88a3.21 3.21 0 00-1.2-3.26L17.72 3a2.38 2.38 0 00-1.91-.43 2.34 2.34 0 00-1.58 1.14 59.34 59.34 0 01-5 7.68.9.9 0 00-.81-.51H3.45a.89.89 0 00-.9.89v14a.89.89 0 00.9.89h5a.88.88 0 00.87-.77 4.89 4.89 0 003.62 1.6h4.9a4.92 4.92 0 004.1-2.19l4.83-7.3a4.07 4.07 0 00-3.38-6.32zM7.51 24.89H4.34V12.64h3.17zM25.28 17l-4.87 7.31a3.13 3.13 0 01-2.62 1.39h-4.9a3.13 3.13 0 01-2.51-1.25L9.29 23v-9.16c1.87-1.57 4.52-5.83 6.48-9.26a.58.58 0 01.4-.28.56.56 0 01.48.11l.74.56a1.42 1.42 0 01.54 1.45l-1.32 6a.88.88 0 00.87 1.09h5.91A2.28 2.28 0 0125.28 17zm0 0" />
		</svg>
	);
}

const MemoThumbsUp = React.memo(ThumbsUp);
export default MemoThumbsUp;
