import constants from "../constants";

export const setGroup = (name, callChannel, commandChannel, host) => {
	return {
		type: constants.SET_GROUP,
		payload: {
			name,
			callChannel,
			commandChannel,
			messagingChannel: commandChannel,
			host,
		},
	};
};
