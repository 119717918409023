import React from "react";
import BaseOverlay from "../modals/BaseOverlay";
// import constants from "../../store/constants";
// import { uiActions } from "../../store/ui";
import { useDispatch } from "react-redux";
import { overlayActions } from "../../store/overlay";
import Download from "../../assets/icons/Download";
import { streamerActions } from "../../store/streamer";
import constants from "../../store/constants";

const WalkthroughOverlay = ({ id, details, format }) => {
	const dispatch = useDispatch();
	// const cloneDetails = {...details};

	let detailsObject = null;
	let cloneDetails = null;

	let title = null;
	let titlePrefix = null;
	let body = null;
	let button = null;

	if (format === "array") {
		console.log("format array");
		cloneDetails = details;
		detailsObject = Object.values(cloneDetails);

		detailsObject.forEach((item) => {
			if (
				item.type === "translation" &&
				item.key.match(/title$/) // ends with .title
			) {
				title = item.value ? item.value : "";
			}

			if (item.type === "translation" && item.key.match(/title-prefix$/)) {
				titlePrefix = item.value ? item.value : "";
			}

			if (item.type === "translation" && item.key.match(/body/)) {
				body = item.value ? item.value : "";
			}
			if (item.type === "translation" && item.key.match(/button$/)) {
				button = item.value ? item.value : "";
			}
		});
	} else if (format === "object") {
		console.log("format object");
		cloneDetails = { ...details };
		detailsObject = cloneDetails;

		const keys = Object.keys(cloneDetails);
		const titleKey = keys.find((key) => key.includes("title"));
		const titlePrefixKey = keys.find((key) => key.includes("subtitle"));
		const bodyKey = keys.find((key) => key.includes("body"));
		const buttonKey = keys.find((key) => key.includes("button"));

		title = titleKey ? detailsObject[titleKey] : "";
		titlePrefix = titlePrefixKey ? detailsObject[titlePrefixKey] : "";
		body = bodyKey ? detailsObject[bodyKey] : "";
		button = buttonKey ? detailsObject[buttonKey] : "";
	} else {
		console.log("no format defined");
	}

	const handleClose = () => {
		dispatch(
			streamerActions.sendMessage(
				constants.STREAMER_OUTGOING_ACTIONS.WALKTHROUGH_RESET
			)
		);
		dispatch(overlayActions.clearOverlay());
	};

	const loadNext = () => {
		// Extract page from id
		const found = id.match(/^(\D*)(\d+)$/);
		if (found === null) return;

		let pageNumber = parseInt(found.pop()); // get the page number
		const pageName = found.pop(); // get the rest of the string without page number

		dispatch(
			overlayActions.openOverlay(
				[pageName, ++pageNumber].join(""),
				constants.OVERLAY_TYPE.WALKTHROUGH,
				constants.FORMAT.ARRAY
			)
		);

		dispatch(
			streamerActions.sendMessage(
				constants.STREAMER_OUTGOING_ACTIONS.WALKTHROUGH_STEP.replace(
					"$pageNumber",
					pageNumber
				)
			)
		);
	};

	return (
		<BaseOverlay
			// confirmCallback={handleAccept}
			buttonText="More info"
			closeCallback={handleClose}
			size="md"
			disableClickThrough={false}>
			<div className="flex flex-col h-full">
				{titlePrefix && (
					<h3 className="text-lg text-gray-500 font-semibold">{titlePrefix}</h3>
				)}

				{title && (
					<h3 className="text-4xl text-gray-900 font-bold mt-0">{title}</h3>
				)}

				{!title && <h3 className="text-4xl text-gray-900 font-bold">Title</h3>}

				<div className="border-b-4 border-primary pb-4 w-16 my-4"></div>

				<div className="text-gray-900 overflow-auto fancy-scrollbar flex-grow">
					{body && (
						// OR npm install react-html-parser, import ReactHtmlParser from "react-html-parser"; {ReactHtmlParser(text)}
						<p
							className="pt-4 text-gray-900 text-xl"
							dangerouslySetInnerHTML={{ __html: body }}></p>
					)}

					{!body && (
						<p className="pt-6">
							Placeholder text: "In publishing and graphic design, Lorem ipsum
							is a placeholder text commonly used to demonstrate the visual form
							of a document or a typeface without relying on meaningful content.
							Lorem ipsum may be used as a placeholder before final copy is
							available. typeface without relying on meaningful content. Lorem
							ipsum may be used as a placeholder before final copy is
							available."
						</p>
					)}
				</div>
				{button && (
					<button
						className="btn-secondary bg-black font-semibold"
						onClick={loadNext}>
						{button}
					</button>
				)}
			</div>
		</BaseOverlay>
	);
};

export default WalkthroughOverlay;
