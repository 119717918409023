import React from "react";
import { Close } from "../../assets/icons";

const BaseOverlay = ({
	size = "xl",
	children,
	modalOpen = true,
	className = "",
	confirmCallback = null,
	closeCallback,
	buttonText = "More info",
	disableClickThrough = true,
}) => {
	return (
		modalOpen && (
			<div
				className={`w-screen h-screen layer--top fixed top-0 left-0 z-50 ${
					!disableClickThrough
						? "pointer-events-none"
						: " bg-white  bg-opacity-25"
				}`}>
				<div
					className={`fixed w-full h-full bg-white  
					py-16 px-20 pb-8 
					 bg-opacity-90 
					 shadow-xl top-0 left-0
					 ${className} max-w-${size} relative pointer-events-auto`}>
					<div
						className={`absolute right-6 top-6 cursor-pointer $`}
						onClick={(e) => {
							if (closeCallback) closeCallback();
						}}>
						<Close className="" />
					</div>

					<div className="h-full text-black text-white">{children}</div>
					{confirmCallback !== null && (
						<div className="absolute bottom-0 w-1/3 pb-8">
							<button
								className="bg-primary text-white text-center hover:bg-primary-interact block shadow py-4 w-full rounded-md focus:ring-2 focus:ring-primary-interact uppercase"
								onClick={(e) => {
									e.preventDefault();
									if (confirmCallback) confirmCallback(true);
								}}>
								{buttonText}
							</button>
						</div>
					)}
				</div>
			</div>
		)
	);
};

export default BaseOverlay;
