import React from "react";
import BaseModal, { OK } from "./BaseModal";
//import useGoodiebag from "../../store/Goodiebag";

const CallDeniedModel = ({ notification, callback, t }) => {
	const handleAccept = () => {
		// callback({ pathname: "/goodie-bag" });
		callback(notification.id, false);
	};
	const handleClose = () => {
		callback(notification.id, false);
	};
	return (
		<BaseModal
			// buttons={notification.vcard ? OK | CANCEL : CANCEL}
			buttons={OK}
			showClose={false}
			// icon={Chat}
			confirmCallback={handleAccept}
			//confirmText="Open goodiebag"
			confirmText="OK"
			cancelCallback={handleClose}
			cancelText="Close"
		>
			<p>{t("base.denied_sorry", "Sorry")}, {notification.from.name} {t("base.denied_not_available", "is not available right now.")}</p>
			{/* {notification.vcard && (
				<>
					<div className="border-t border-b border-white py-4 my-4 px-8 flex justify-between items-center">
						<SVGIcon icon={Vcard} className="w-32 h-32" />
						<p className="text-sm">The vCard was added to your goodiebag.</p>
					</div>
				</>
			)} */}
		</BaseModal>
	);
};
export default CallDeniedModel;
