// import "./wdyr";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AppSetup from "./AppSetup";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GetBuildInformation } from "./helpers";

import ErrorMessage from "./components/controls/ErrorMessage";

// Disable soundmanager debug logging in dev mode
window.soundManager.setup({ debugMode: false });

const root = document.getElementById("root");

// Do some init first. Init Agora & Pubnub. Has to be inited before the first React init
const comms = AppSetup(root.dataset);

if (comms.ready) {
	ReactDOM.render(<App {...comms} environment={root.dataset} />, root);
} else {
	ReactDOM.render(
		<ErrorMessage message='Application is misconfigured.' />,
		root
	);
}

// Log version number, then console is off
const buildInformation = GetBuildInformation();
console.log(
	`BUILD INFO: ${buildInformation.buildNumber}.${buildInformation.buildTime} (${buildInformation.currentTime})`
);

if (process.env.NODE_ENV !== "development") {
	console.log = () => {};
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

// window.onbeforeunload = (e) => {
// 	e.preventDefault();
// 	window.alert("Are you sure?");

// 	/*
// 	if (localStorage.getItem("apiKey") === null) return;

// 	const myHeaders = new Headers({
// 		"x-api-key": localStorage.getItem("apiKey"),
// 	});
// 	localStorage.removeItem("apiKey");

// 	const myRequest = new Request(
// 		process.env.REACT_APP_API_ENDPOINT + "/activity/track",
// 		{
// 			method: "POST",
// 			headers: myHeaders,
// 			mode: "cors",
// 			body: {
// 				type: "LOGOUT",
// 			},
// 			cache: "default",
// 		}
// 	);

// 	fetch(myRequest);
// 	*/
// };
