import axios from "axios";
import { authHeader } from "../../helpers";
import constants from "../constants";

export const logActivity = (type, value, data) => {
	return function getUserAsync(dispatch, getState, context) {
		const activity = { type };

		if (typeof value != "undefined") activity.value = value;
		if (typeof data != "undefined") activity.data = JSON.stringify(data);
		// console.log(getState().user.key);
		axios
			.post(process.env.REACT_APP_API_ENDPOINT + "/activity/track", activity, {
				...authHeader(getState().user.key),
			})
			.then((result) => {
				dispatch({ type: constants.ACTIVITY_LOGGED, payload: type });
			})
			.catch((err) => {
				console.log(err);
			});
	};
};
