import React from "react";
import { Download, Trash } from "../../assets/icons";
import constants from "../../store/constants";

const GoodieBagListItem = ({
	type,
	description,
	id,
	onDelete,
	t,
	onDownload,
	directDownloadEnabled = false,
}) => {
	const types = {
		1: t("base.goodiebag.vcard", "vCard"),
		2: t("base.goodiebag.files", "File(s)"),
		3: t("base.goodiebag.configuration", "Configuration"),
	};

	return (
		<li className="flex py-3 border-b border-trueGray-300 space-x-4 items-center px-4">
			<div className="flex flex-grow flex-nowrap space-x-4">
				<div className="w-1/3">
					<span className="text-trueGray-400 text-xs block">
						{t("base.goodiebag.kind", "Kind")}
					</span>
					<span className="block">
						{types[type] || t("base.goodiebag.unknown", "Unknown")}
					</span>
				</div>

				<div className="w-full">
					<span className="text-trueGray-400 text-xs block">
						{t("base.goodiebag.description", "Description")}
					</span>
					<span className="block font-semibold text-primary">
						{description}
					</span>
				</div>
			</div>
			<div className="flex text-primary text-2xl space-x-4">
				<button
					onClick={(e) => {
						onDownload(id);
					}}>
					<Download />
				</button>
				<button
					onClick={(e) => {
						e.preventDefault();
						onDelete(id);
					}}>
					<Trash />
				</button>
			</div>
		</li>
	);
};
export default GoodieBagListItem;
