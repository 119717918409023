import * as React from "react";

function CallEnd({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<path d='M27.7 10.87c-.23-.21-3.99-3.56-12.71-3.63-8.94.08-12.66 3.61-12.7 3.64-.6.79-1.17 6.85-.47 7.55.29.25.7.36 1.11.29 1.68-.24 6-1.21 6.48-1.9.25-.37.23-.78.13-1.91-.02-.19-.05-.64-.07-.97v-.32l.27-.15c.45-.23.93-.43 1.41-.6 2.47-.85 5.2-.85 7.67 0 .48.16.96.36 1.41.6l.29.15v.32c-.03.33-.07.78-.08.98-.1 1.13-.12 1.54.14 1.91.48.69 4.75 1.64 6.46 1.89.42.07.84-.04 1.15-.31.67-.67.1-6.7-.51-7.53z' />
		</svg>
	);
}

const MemoCallEnd = React.memo(CallEnd);
export default MemoCallEnd;
