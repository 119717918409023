import { useMemo } from "react";
// import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { languageList } from "../../helpers/LocaleHelper";
import { appActions, appSelectors } from "../../store/app";
import SelectField from "../controls/SelectField";

export function InlineLanguageSwitcher(props) {
	const activeLanguage = useSelector(appSelectors.getAppLanguage).toUpperCase();
	const dispatch = useDispatch();

	const changeLanguage = (e) => {
		dispatch(appActions.setInterfaceLanguage(e.target.value.toLowerCase()));
		// i18n.changeLanguage(iso.toLowerCase());
	};

	const activeLanguagFirst = useMemo(() => {
		return [
			languageList.find((l) => l.value === activeLanguage),
			...languageList.filter((l) => l.value !== activeLanguage),
		];
	}, [activeLanguage]);

	return (
		<SelectField
			className='mb-0 w-40'
			value={activeLanguage}
			onChange={changeLanguage}
			options={activeLanguagFirst}
		/>
	);
}
