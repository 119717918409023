import React from "react";
import BaseModal, { OK, CANCEL } from "./BaseModal";

import { Chat } from "../../assets/icons";

import constants from "../../store/constants";

import ServerImage from "../controls/ServerImage";

import { useDispatch } from "react-redux";

import { sendMessage } from "pubnub-redux";

import { loggingActions } from "../../store/logging";
import { callActions } from "../../store/call";
import { uiActions } from "../../store/ui";
import { groupActions } from "../../store/group";
// import { userActions } from "../../store/user";
import { chatActions } from "../../store/chat";

const HostedCallRequestModal = ({ notification, me, callback, t }) => {
	const dispatch = useDispatch();

	const handleAccept = () => {
		const requesterId = notification.from.uuid; // eslint-disable-line no-unused-vars

		// chatActions.removeChatRequest(requesterId);
		// setChatRequest(false);
		// TODO: Check if already in group
		dispatch(
			groupActions.setGroup(
				"Group " + requesterId,
				notification.message,
				"group-" + requesterId,
				requesterId,
				[]
			)
		);

		// Also listen in on group channel
		dispatch(chatActions.joinChannel(notification.message));

		dispatch(uiActions.setLayer(constants.LAYER.CALL, true, false));
		dispatch(callActions.initCall(notification.message, false));
		dispatch(callActions.changeCallLayout(constants.CALL_LAYOUT.FULLSCREEN));

		callback(notification.id, false);
	};

	const handleDecline = () => {
		const requesterId = notification.from.uuid; // eslint-disable-line no-unused-vars

		dispatch(
			sendMessage({
				channel: `${notification.from.uuid}--private`,
				message: {
					type: constants.MESSAGE_TYPE.CALL_DECLINE,
				},
			})
		);

		dispatch(
			loggingActions.logActivity(
				constants.ACTIVITY.CALL_DECLINED,
				notification.from.uuid
			)
		);

		callback(notification.id, false);
	};

	const handleIgnore = () => {
		callback(notification.id, true);
	};

	return (
		<BaseModal
			showClose={true}
			buttons={OK | CANCEL}
			icon={Chat}
			confirmCallback={handleAccept}
			confirmText={t("global.accept", "Accept")}
			cancelCallback={handleDecline}
			closeCallback={handleIgnore}
			cancelText={t("global.discard", "Discard")}
			size='xl'>
			<h1 className='text-3xl font-semibold mb-4'>
				{notification.from.name}{" "}
				{t(
					"base.call_hosted_request",
					"would like to add you to a hosted call"
				)}
			</h1>

			<figure className='text-center'>
				<ServerImage
					className='rounded-full overflow-hidden w-24 h-24 mx-auto mb-3'
					src={notification.from.avatar}
				/>
				<figcaption>
					<span className='block font-semibold'>{notification.from.role}</span>
					<span className='block '>{notification.from.company}</span>
				</figcaption>
			</figure>
		</BaseModal>
	);
};

export default HostedCallRequestModal;
