import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import constants from "../store/constants";
import CallWindowSingle from "./call/CallWindowSingle";
import CallWindowGroup from "./call/CallWindowGroup";
import { callActions, callSelectors } from "../store/call";
import { uiActions } from "../store/ui";
import { useTranslation } from "react-i18next";
import CallWindowSubscriber from "./call/CallWindowSubscriber";

const CallManager = ({ host, user }) => {
	const { t } = useTranslation();
	const isActive = useSelector(callSelectors.getIsActive);
	const channelName = useSelector(callSelectors.getChannelName);
	const uid = useSelector(callSelectors.getUid);
	const channelToken = useSelector(callSelectors.getChannelToken);
	// const chatSettings = useSelector(callSelectors.getChatSettings);
	const hasChat = useSelector(callSelectors.getHasChat);
	const chatChannel = useSelector(callSelectors.getChatChannel);

	const other = useSelector(callSelectors.getOther);
	const layout = useSelector(callSelectors.getLayout);
	const is1to1 = useSelector(callSelectors.getIs1to1);
	// const isPresenter = useSelector(callSelectors.getIsPresenter);
	const isSubscriber = useSelector(callSelectors.getIsSubscriber);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isActive) {
			// Get Token
			dispatch(callActions.getCallToken());
		}
	}, [dispatch, isActive, isSubscriber]);

	const hideCallWindow = () => {
		dispatch(callActions.leaveCall());
		dispatch(uiActions.setLayer(constants.LAYER.CALL, false));
	};

	const showSomething = useMemo(
		() => channelToken !== "" && isActive,
		[channelToken, isActive]
	);

	const showSingleWindow = useMemo(
		() => showSomething && is1to1 && !isSubscriber,
		[showSomething, is1to1, isSubscriber]
	);

	const showGroupWindow = useMemo(
		() => channelToken !== "" && isActive && !is1to1 && !isSubscriber,
		[channelToken, isActive, is1to1, isSubscriber]
	);

	const showSubscriberWindow = useMemo(
		() => channelToken !== "" && isActive && !is1to1 && isSubscriber,
		[channelToken, isActive, is1to1, isSubscriber]
	);

	return (
		<>
			{showSingleWindow && (
				<CallWindowSingle
					hideCallWindow={hideCallWindow}
					// key={uuid()} // uncomment to destroy each call
					channelName={channelName}
					uid={uid}
					token={channelToken}
					isHost={host}
					other={other}
					layout={layout}
					t={t}
				/>
			)}
			{showGroupWindow && (
				<CallWindowGroup
					hideCallWindow={hideCallWindow}
					// key={uuid()} // uncomment to destroy each call
					channelName={channelName}
					uid={uid}
					token={channelToken}
					layout={layout}
					t={t}
					isHost={host}
				/>
			)}
			{showSubscriberWindow && (
				<>
					<CallWindowSubscriber
						hideCallWindow={hideCallWindow}
						// key={uuid()} // uncomment to destroy each call
						channelName={channelName}
						uid={uid}
						token={channelToken}
						layout={layout}
						t={t}
						hasChat={hasChat}
						chatChannel={chatChannel}
					/>
				</>
			)}
		</>
	);
};

export default React.memo(CallManager);
