import constants from "../constants";

const defaultState = {
	conversations: [],
	activeConversation: null,
	lastMessageAt: 0,
	typingIndicators: {
		byId: {},
	},
};

const chatReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.JOIN_CONVERSATION:
			return {
				...state,
				conversations: [...state.conversations, action.payload],
			};
		case constants.JOIN_CONVERSATIONS:
			return {
				...state,
				conversations: [...state.conversations].concat(action.payload),
			};
		case constants.LEAVE_CONVERSATION:
			return {
				...state,
				conversations: [...state.conversations, action.payload],
			};

		case constants.SET_ACTIVE_CONVERSATION:
			return {
				...state,
				activeConversation: state.conversations.find((e) => {
					return e.channelName === action.payload;
				}),
			};
		case constants.SET_LAST_MESSAGE_RECEIVED:
			return {
				...state,
				lastMessageAt: action.payload,
			};
		default:
			return state;
	}
};

export default chatReducer;
