import * as React from "react";

function ThumbsDown({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M23.39 18.32A4.07 4.07 0 0026.77 12l-4.88-7.31a4.92 4.92 0 00-4.1-2.19h-4.9a4.89 4.89 0 00-3.62 1.6.88.88 0 00-.87-.77H3.45a.89.89 0 00-.9.89v14a.89.89 0 00.9.89h4.94a.9.9 0 00.81-.51 59.34 59.34 0 015 7.68 2.34 2.34 0 001.58 1.14 2.38 2.38 0 001.94-.42l.75-.56a3.21 3.21 0 001.2-3.26l-1.08-4.88zm-15.88-1H4.34V5.11h3.17zM25.28 13a2.28 2.28 0 01-1.89 3.54h-5.91a.88.88 0 00-.87 1.09l1.32 6a1.42 1.42 0 01-.54 1.37l-.74.56a.56.56 0 01-.48.11.58.58 0 01-.4-.28c-2-3.43-4.61-7.69-6.48-9.26V7l1.09-1.45a3.13 3.13 0 012.51-1.25h4.9a3.13 3.13 0 012.62 1.39L25.28 13zm0 0" />
		</svg>
	);
}

const MemoThumbsDown = React.memo(ThumbsDown);
export default MemoThumbsDown;
