import { useHistory } from "react-router-dom";

const Confirmation = ({ next, t }) => {
	let history = useHistory();

	const onGoToLoginClick = () => {
		history.push("/login");
	};
	// const onBackClick = () => {
	// 	next((step) => step - 1);
	// }
	return (
		<div className=' p-10 w-full bg-white rounded-lg max-w-2xl mx-auto shadow-xl text-center'>
			<div className='' role='alert'>
				<h2 className=' font-bold animate-pulse text-2xl'>
					{t("activate.success", "Success")}!{" "}
					<i className='fa fa-check-circle' aria-hidden='true' />
				</h2>
				<p className='text-lg  mb-4 my-4'>
					{t(
						"activate.account_activated_message",
						"Your account is now activated and your personal details have been saved."
					)}
				</p>

				<button
					className='
							bg-primary text-white text-center
                            hover:bg-primary-interact
                            mx-auto max-w-sm shadow py-4 w-full rounded-md
                            focus:ring-2 focus:ring-primary-interact uppercase'
					onClick={onGoToLoginClick}>
					{t("activate.go_to_login", "Go to Login")}
				</button>
				{/* <button className="bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border  rounded-md"
                    onClick={onBackClick}>
                    Back
                </button> */}
			</div>
		</div>
	);
};

export default Confirmation;
