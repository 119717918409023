import * as React from "react";
// import img from "../img/IslandOverviewMap.png";
import img from "../img/DSFloorplanLevel1.png";

function Floorplan(props) {
	return <img alt='floorplan' src={img} {...props} />;
}

const MemoFloorplan = React.memo(Floorplan);
export default MemoFloorplan;
