import { useEffect, useState } from "react";
import { useInterval } from "react-use-timeout";

export const CallTimer = () => {
	const [timePassed, setTimePassed] = useState(0);
	const secondPassed = useInterval(() => {
		setTimePassed((t) => t + 1);
	}, 1000);

	useEffect(() => {
		secondPassed.start();
		return () => {
			secondPassed.stop();
		};
	}, [secondPassed]);

	return <>{new Date(timePassed * 1000).toISOString().substring(14, 19)}</>;
};
