import constants from "../constants";
import { push } from "connected-react-router";

export const setAppState = () => {
	return {
		type: constants.SET_APP_STATE,
		payload: { login: `alwyn@realitymatters.studio`, pass: "1234" },
	};
};
export const setLoginState = (state) => {
	return {
		type: constants.SET_LOGIN_STATE,
		payload: state,
	};
};
export const setActivationState = (state) => {
	return {
		type: constants.SET_ACTIVATION_STATE,
		payload: state,
	};
};
export const setRegistrationState = (state) => {
	return {
		type: constants.SET_REGISTRATION_STATE,
		payload: state,
	};
};

export const setRoute = (route) => {
	return push(route);
};

export const setActionbar = (state) => {
	return { type: constants.SET_ACTIONBAR_STATE, payload: state };
};

export const minimizeActionbar = () => {
	return setActionbar(constants.ACTIONBAR.MINI);
};

export const toggleActionBar = () => {
	return (dispatch, getState) => {
		const { actionbar } = getState().ui;

		dispatch(
			setActionbar(
				actionbar === constants.ACTIONBAR.EXPANDED
					? constants.ACTIONBAR.COLLAPSED
					: constants.ACTIONBAR.EXPANDED
			)
		);
	};
};

export const collapseActionbar = () => {
	return setActionbar(constants.ACTIONBAR.COLLAPSED);
};

export const expandActionbar = () => {
	return setActionbar(constants.ACTIONBAR.EXPANDED);
};

export const setLayerNotInCall = (layer, on) => {
	return (dispatch, getState) => {
		const unique = !getState().ui.layers[constants.LAYER.CALL];

		if (unique) {
			dispatch({
				type: constants.SET_LAYERS,
				payload: {
					[constants.LAYER.STREAMER]: false,
					[constants.LAYER.VIDEO]: false,
					[constants.LAYER.CALL]: false,
					[layer]: on,
				},
			});
		} else {
			const { layers } = getState().ui;
			dispatch({
				type: constants.SET_LAYERS,
				payload: {
					...layers,
					[layer]: on,
				},
			});
		}
	};
};

export const setLayer = (layer, on, unique) => {
	return (dispatch, getState) => {
		if (unique) {
			dispatch({
				type: constants.SET_LAYERS,
				payload: {
					[constants.LAYER.STREAMER]: false,
					[constants.LAYER.VIDEO]: false,
					[constants.LAYER.CALL]: false,
					[layer]: on,
				},
			});
		} else {
			const { layers } = getState().ui;
			dispatch({
				type: constants.SET_LAYERS,
				payload: {
					...layers,
					[layer]: on,
				},
			});
		}
	};
};

export const openPanel = (active, panelData = null) => {
	if (panelData) {
		return {
			type: constants.SET_PANEL_WITH_DATA,
			payload: { active: active, data: panelData },
		};
	}

	return {
		type: constants.SET_PANEL,
		payload: active,
	};
};

export const closePanel = (clearData = false) => {
	if (clearData) {
		return {
			type: constants.SET_PANEL_WITH_DATA,
			payload: { active: constants.PANEL.NONE, data: null },
		};
	}
	return {
		type: constants.SET_PANEL,
		payload: constants.PANEL.NONE,
	};
};

export const setPanelData = (data) => {
	return {
		type: constants.SET_PANEL_DATA,
		payload: data,
	};
};

export const setLayerData = (data) => {
	return {
		type: constants.SET_LAYER_DATA,
		payload: data,
	};
};
