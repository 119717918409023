import axios from "axios";
import constants from "../constants";
import { uiActions } from "../ui";
import { eventActions } from "../event";
import { authHeader } from "../../helpers";
import { loggingActions } from "../logging";

import Users from "../dummy/users.json";

import {
	// setUserData,
	fetchHereNow,
	// setMemberships,
	// fetchMessageHistory,
} from "pubnub-redux";
import { push } from "connected-react-router";
import { hostActions } from "../host";
import { appActions } from "../app";
import { salesActions } from "../../store/sales";
import { groupActions } from "../group";

export const fakeLogin = (id, set = -1) => {
	if (set === -1)
		// freeroam logins
		return logIn(Users.freeroam[id]);

	// HOST login
	if (id === -1) {
		return logIn(Users.groups[set].host);
	}
	// USER login
	return logIn(Users.groups[set].visitors[id]);
};

export const logIn = (credentials) => {
	// export async function doLogin(dispatch, getState) {
	// 	const response = await axios.post
	// }
	return function logInAsync(dispatch, getState, context) {
		dispatch(uiActions.setLoginState(1));

		const { eventCode, eventType } = getState().event;

		axios
			.post(process.env.REACT_APP_API_ENDPOINT + "/access/register-login", {
				type: eventType,
				code: eventCode,
				name: credentials.name,
				email: credentials.email,
			})
			.then((result) => {
				dispatch(uiActions.setLoginState(result.status));

				dispatch({
					type: constants.SET_KEY,
					payload: result.data,
				});

				//dispatch(logActivity(constants.ACTIVITY.LOGIN));

				// axios.defaults.headers.common['x-api-key'] = result.data.key
				dispatch(getUser(result.data.key));
			})
			.catch((err) => {
				if (err.response && err.response.status)
					dispatch(uiActions.setLoginState(err.response.status));
				// SERVICE OFFLINE
				else dispatch(uiActions.setLoginState(500));
				/*
				dispatch(
					logActivity(
						constants.ACTIVITY.LOGIN_FAILED,
						getState().event.eventCode
					)
				);
				*/
				// How to log generic error?
			});
	};
};

// Called if APIKey is found in localstorage
export const loginFromCache = () => {
	return async function loginFromCacheAsync(dispatch, getState, context) {
		// Get key from localstorage
		const key = localStorage.getItem("apiKey");

		dispatch({
			type: constants.SET_KEY,
			payload: { key },
		});

		try {
			// dispatch(getUser(key));
			dispatch(uiActions.setLoginState(1));
			await getUser(key, true)(dispatch, getState, context);
		} catch (e) {
			dispatch(uiActions.setLoginState(419));
		}
	};
};

export const setUserIdentity = (identity) => ({
	type: constants.SET_USER_IDENTITY,
	payload: identity,
});

export const setUserAccess = (identity) => ({
	type: constants.SET_USER_ACCESS,
	payload: identity,
});

export const setUserPreferences = (identity) => ({
	type: constants.SET_USER_PREFERENCES,
	payload: identity,
});

// From LS lets the function know we are trying to do a re-login.
// This should throw a unique error instead of regular error handling
export const getUser = (key, fromLS = false) => {
	return async function getUserAsync(dispatch, getState, context) {
		const appConfig = getState().app.config;

		try {
			// Get user profile
			const result = await axios.get(
				process.env.REACT_APP_API_ENDPOINT + "/profile",
				{
					...authHeader(key),
				}
			);
			if (fromLS) dispatch(uiActions.setLoginState(200));

			dispatch(
				loggingActions.logActivity(
					constants.ACTIVITY.LOGIN_SUCCESS,
					getState().event.eventCode
				)
			);

			// Start loading event
			dispatch(eventActions.setLoadState(constants.EVENT_LOAD_STATE.LOADING));

			if (appConfig.interfaceEnabled === 1)
				dispatch({ type: constants.SET_UI_VISIBILITY, payload: true });

			//#region SET IDENTITY
			// Set up user identity, communication settings and UI permissions
			const identity = {
				...result.data.identity,
			};
			const access = {
				...result.data.access,
			};
			const preferences = {
				...result.data.preferences,
			};

			// Generate a Agora ID. Must be an integer and is limited in length (32 bit)
			const uid = identity.uuid.split("-").join("4");

			// remove all non-numerals and get rid of leading 0
			const uidNumbers = uid.replace(/[^0-9]/gi, "");
			let intUid = parseInt(uidNumbers.substr(0, 9));

			identity.videoUid = intUid;

			dispatch(setUserIdentity(identity));

			// User can HOST calls with other people
			if (result.data.identity.type === constants.USER_TYPE.HOST) {
				dispatch({
					type: constants.SET_IS_HOSTED,
					payload: true,
				});

				dispatch(hostActions.getMyInvitees(key, result.data.identity.type));
				// dispatch(salesActions.getSalesNotes(key));
				// dispatch(salesActions.getEventFiles(key));
			}

			// Set interface language to profile setting
			dispatch(appActions.setInterfaceLanguage(identity.language));

			// Get additional event settings, pass dispatch to handle extra
			// data setting
			await eventActions.getEventSettings(key, dispatch);

			// User can HOST calls with other people
			if (
				result.data.identity.type === constants.USER_TYPE.HOST ||
				result.data.access.elevated
			) {
				await salesActions.getEventFiles(key, dispatch);
				await salesActions.getSalesNotes(key, dispatch);
			}

			dispatch(setUserAccess(access));
			dispatch({
				type: constants.SET_CAN_HOST,
				payload: access.elevated,
			});
			dispatch(setUserPreferences(preferences));
			dispatch({
				type: constants.SET_SUBTITLES,
				payload: identity.language.toLowerCase(),
			});
			//#endregion

			// This event doesn't allow for presence or chat features
			// return early
			if (appConfig.presenceEnabled === 0) return;

			//#region SETUP PUBNUB

			// TODO: add based on permissions level!
			const userChannels = {
				presence: {
					name: identity.uuid + "-presence-channels",
					list: ["main"],
				},
				nonPresence: {
					name: identity.uuid + "-channels",
					list: [identity.uuid + "--private"],
				},
			};

			if (result.data.hasOwnProperty("group")) {
				const groupChannel = `group-${result.data.group.id}`;
				const groupName = result.data.group.name;
				const groupHost = result.data.group.host;
				userChannels.presence.list.push(groupChannel);
				dispatch(
					groupActions.setGroup(
						groupName,
						groupChannel,
						groupChannel,
						groupHost
					)
				);
			}

			// const user = getState().user;

			context.pubnub.api.setUUID(identity.uuid);

			// Uncomment to get all people you are expecting in the event
			dispatch(eventActions.getAllPeopleInEventToday());

			// TODO: cleanup my channels

			// Make presence channelGroup
			context.pubnub.api.channelGroups.addChannels(
				{
					channels: userChannels.presence.list,
					channelGroup: userChannels.presence.name,
				},
				(status) => {
					if (status.error !== false) return;

					// Subscribe to channelGroup that requires presence
					context.pubnub.api.subscribe({
						channelGroups: [userChannels.presence.name],
						withPresence: true,
					});
				}
			);

			// Make non-presence channelGroup
			context.pubnub.api.channelGroups.addChannels(
				{
					channels: userChannels.nonPresence.list,
					channelGroup: userChannels.nonPresence.name,
				},
				(status) => {
					if (status.error !== false) return;
					// Subscribe to channelGroup that doesn't requires presence
					context.pubnub.api.subscribe({
						channelGroups: [userChannels.nonPresence.name],
						withPresence: false,
					});
				}
			);

			// Fetch initial state of the system
			dispatch(
				fetchHereNow({
					channels: userChannels.presence.list,
					includeUUIDs: true,
					includeState: false,
				})
			);
			//#endregion
		} catch (err) {
			if (fromLS) {
				throw new Error(419);
			} else {
				// If this was a 500 error => retry
				console.log(err);
			}

			return;
		}
	};
};

export const checkEmail = (email) => {
	return function checkEmailAsync(dispatch, getState, context) {
		dispatch(uiActions.setActivationState(1));

		const { eventCode, eventType } = getState().event;

		axios
			.post(process.env.REACT_APP_API_ENDPOINT + "/access/check-account", {
				type: eventType,
				code: eventCode,
				email: email,
			})
			.then((result) => {
				dispatch(uiActions.setActivationState(result.status));

				// TBD
				// localStorage.setItem("apiKey", result.data);

				// dispatch({
				// 	type: constants.SET_KEY,
				// 	payload: result.data,
				// });

				//dispatch(logActivity(constants.ACTIVITY.LOGIN));

				// axios.defaults.headers.common['x-api-key'] = result.data.key
				// dispatch(getUser(result.data.key));
			})
			.catch((err) => {
				if (err.response && err.response.status)
					dispatch(uiActions.setActivationState(err.response.status));
				// SERVICE OFFLINE
				else dispatch(uiActions.setActivationState(500));
				/*
				dispatch(
					logActivity(
						constants.ACTIVITY.LOGIN_FAILED,
						getState().event.eventCode
					)
				);
				*/
				// How to log generic error?
			});
	};
};

/*
NO LONGER USED BECAUSE PUBNUB PRESENCE USER DATA IS BUGGY 
=> KEEPING IT HERE FOR FUTURE REFERENCE
MOVE TO CONNECTED CALLBACK (SOMEWHERE...)
.then((identity) => {
				dispatch(
					setUserData({
						uuid: identity.uuid,
						data: {
							name: identity.name,
							profileUrl: identity.avatar,
							custom: {
								name: identity.name,
								avatar: identity.avatar,
								company: identity.company,
								role: identity.role,
							},
						},
					})
				);
			})
*/

export const logOut = () => {
	return function logOutAsync(dispatch, getState, context) {
		dispatch(loggingActions.logActivity(constants.ACTIVITY.LOGOUT));
		dispatch(uiActions.setLoginState(0));
		dispatch({
			type: constants.SET_EVENT_LOADSTATE,
			payload: constants.EVENT_LOAD_STATE.UNLOADED,
		});

		dispatch({
			type: constants.LOGOUT,
			payload: "",
		});

		dispatch(push("/"));
	};
};

// ---------Activate profile Info Action------

export const saveProfileAction = (profile) => {
	const url = process.env.REACT_APP_API_ENDPOINT + "/profiles/activate";

	return function updateProfile(dispatch) {
		return axios
			.post(url, profile)
			.then((resp) => {
				dispatch({
					type: constants.SAVE_PROFILE_DATA,
					payload: profile,
				});
				return resp;
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const uploadUImageAction = (payloads) => {
	const url = process.env.REACT_APP_API_ENDPOINT + "/profile/updatePicture";
	const key = localStorage.getItem("apiKey");
	return function uploadImage(dispatch) {
		return axios
			.post(url, payloads, { ...authHeader(key) })

			.then((resp) => {
				dispatch({
					type: "SAVE_PROFILE_IMAGE",
					payload: resp,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const byPassLoginHandler = (credentials) => {
	return function (dispatch, getState) {
		const url = process.env.REACT_APP_API_ENDPOINT + "/access/login";
		// dispatch(uiActions.setLoginState(1));

		const { eventCode, eventType } = getState().event;
		axios
			.post(url, {
				type: eventType,
				code: eventCode,
				login: credentials.email,
				password: credentials.password,
			})
			.then((result) => {
				// dispatch(uiActions.setLoginState(result.status));
				localStorage.setItem("apiKey", result?.data?.key);
				dispatch({
					type: constants.SET_KEY,
					payload: result.data,
				});

				//dispatch(logActivity(constants.ACTIVITY.LOGIN));
				// axios.defaults.headers.common['x-api-key'] = result.data.key
			})
			.catch((err) => {
				// if (err.response && err.response.status)
				// 	dispatch(uiActions.setLoginState(err.response.status));
				// // SERVICE OFFLINE
				// else dispatch(uiActions.setLoginState(500));
			});
	};
};
