import { createSelector } from "reselect";
import notificationSlice from "./notificationSlice";

export const selectPingById = createSelector(
	[notificationSlice],
	(slice) => slice.ping.byId
);

export const selectGoodiebagPing = createSelector(
	[notificationSlice],
	(slice) => slice.ping.byId.hasOwnProperty("GOODIEBAG")
);

export const selectQAPing = createSelector([notificationSlice], (slice) =>
	slice.ping.byId.hasOwnProperty("QA")
);

export const getLastNotification = createSelector(
	[notificationSlice],
	(notifications) => {
		if (typeof notifications === "undefined") return false;
		if (typeof notifications.queue === "undefined") return false;
		if (notifications.queue.length === 0) return false;

		const lastEntry = notifications.queue[0];
		return lastEntry;
	}
);
