import { createSelector } from "reselect";
import overlaySlice from "./overlaySlice";

export const getShowOverlay = createSelector(
	[overlaySlice],
	(overlay) => overlay.showOverlay
);
export const getHotspotId = createSelector(
	[overlaySlice],
	(overlay) => overlay.hotspotId
);
