import * as React from "react";

function Card({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M24 5H6a3.26 3.26 0 00-3.25 3.25v13.5A3.26 3.26 0 006 25h18a3.26 3.26 0 003.26-3.25V8.25A3.26 3.26 0 0024 5zm1.25 16.75A1.25 1.25 0 0124 23H6a1.25 1.25 0 01-1.25-1.24V8.25A1.25 1.25 0 016 7h18a1.25 1.25 0 011.25 1.24z" />
			<path d="M11.63 18.49H8.25a1 1 0 000 2h3.38a1 1 0 000-2zM15 15.12H8.25a1 1 0 000 2H15a1 1 0 100-2z" />
		</svg>
	);
}

const MemoCard = React.memo(Card);
export default MemoCard;
