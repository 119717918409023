import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTimeout } from "react-use-timeout";
// import constants from "../store/constants";
import { fireExpertModal } from "../store/modal/modalActions";
import constants from "../store/constants";

import {
	notificationActions,
	notificationSelectors,
} from "../store/notification";

const NotificationManager = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const lastNotification = useSelector(
		notificationSelectors.getLastNotification,
		shallowEqual
	);

	const clearNotification = (id) => {
		autoCloseTimeout.stop();
		dispatch(notificationActions.clearNotification(id));
	};

	const autoCloseCallback = () => {
		if (lastNotification !== false) clearNotification(lastNotification.id);
	};

	const autoCloseTimeout = useTimeout(autoCloseCallback, 10000);

	useEffect(() => {
		if (lastNotification !== false) {
			// startTimeout
			autoCloseTimeout.start();
		}
	}, [lastNotification, autoCloseTimeout]);

	const processMessage = useCallback(
		(message) => {
			if (message.match(/T\|/)) {
				return t(message.replace("T|", ""));
			}
			return message;
		},
		[t]
	);

	return (
		<>
			{lastNotification && (
				<div
					className=" fixed top-0 left-1/2 transform -translate-x-1/2 mt-10 mx-auto w-full max-w-sm layer--notifications"
					onMouseEnter={() => {
						autoCloseTimeout.stop();
					}}>
					<div
						key={lastNotification.id}
						className={`animate-fade-in-down shadow-lg rounded-lg p-4 text-gray-700 ${
							{
								[constants.NOTIFICATION_TYPE.INFO]: "bg-white",
								[constants.NOTIFICATION_TYPE.WARNING]: "bg-yellow-200",
								[constants.NOTIFICATION_TYPE.CRITICAL]: "bg-red-200",
								[constants.NOTIFICATION_TYPE["EXPERT-INFO"]]: "bg-white",
							}[lastNotification.message.type]
						}
				`}>
						<div className="text-base pb-2 font-semibold fade-in-down">
							{lastNotification.message.title}
							<span
								className="float-right"
								onClick={() => clearNotification(lastNotification.id)}>
								<svg
									className="fill-current text-gray-600"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="22"
									height="22">
									<path
										className="heroicon-ui"
										d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
									/>
								</svg>
							</span>
						</div>
						<div className="text-sm text-gray-600  tracking-tight ">
							{processMessage(lastNotification.message.message)}
						</div>
						{lastNotification.message.hasOwnProperty("action") && (
							<div className="mt-4">
								<button
									className="font-semibold uppercase rounded bg-black text-white hover:bg-gray-800 py-3 px-10"
									onClick={(e) => {
										clearNotification(lastNotification.id);
										// Not the cleanest way but client wanted this feature. Move to action!
										// TODO rewrite so it can trigger any tipe of thing!
										dispatch(fireExpertModal(1));
									}}>
									{t("global.join", "Join")}
								</button>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default React.memo(NotificationManager);
