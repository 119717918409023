import * as React from "react";

function Keyboard({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<path d='M18 4.4c1.9.8 2.3 1.5 2.3 3.6v4.7c0 1.1-.3 2.1-1.2 2.8.3.2.5.4.8.7.7-1 2-1.2 3.3-1.1h3.2c1.8 0 3.1 1.2 3.1 3.1v5.4c0 1.6-.6 2.5-2.1 3.1l-.1.1h-5.7c-.6-.4-1.3-.7-1.8-1.1-.6.3-1.2.7-1.9 1.1h-6c-.6-.4-1.3-.7-1.9-1.1-.5.3-1.2.7-1.8 1.1H2.5C1.4 26.4.7 25.7.3 24.6v-7.1c.7-1.9 2-2.6 3.8-2.5h2.5c1.4 0 2.7 0 3.6 1.2l.8-.8c-1-.7-1.3-1.8-1.3-3V7.9c0-2.1.5-2.8 2.3-3.6 2 .1 4 .1 6 .1zm-7.4 16.4v2.8c0 1.3.8 2.1 2.1 2.1h4.5c1.3 0 2.1-.8 2.1-2v-5.6c0-1.3-.8-2-2-2h-4.6c-1.2 0-2 .8-2 2-.1.9-.1 1.8-.1 2.7zm8.7-10.5V7.5c0-1.3-.8-2-2.1-2.1h-4.6c-1.2 0-2 .8-2 2V13c0 1.3.8 2 2 2h4.6c1.3 0 2-.8 2-2 .2-.9.1-1.8.1-2.7zM9.7 20.8V18c0-1.2-.8-2-1.9-2H3.5c-1.1 0-2 .8-2 1.9v5.8c0 1.1.8 1.9 1.9 1.9h4.4c1.2 0 1.9-.9 1.9-2v-2.8zm10.6 0v2.9c0 1.2.8 2 2 2h4.3c1.1 0 1.9-.8 2-1.9V18c0-1.1-.8-1.9-2-1.9h-4.3c-1.2 0-1.9.8-2 2v2.7z' />
			<path d='M15 22.9c-.4-.2-.8-.3-1-.5-.5-.5-1-1.1-1.4-1.7-.1-.1 0-.4.1-.7.2 0 .5 0 .7.1.4.4.7.9 1.1 1.3.4.4.6.4 1 0 .3-.4.6-.8 1-1.2.2-.3.5-.5.8-.2.3.2.2.5 0 .8-.4.4-.7.9-1.1 1.3-.4.4-.8.6-1.2.8zM17.5 10.7c-.1.1-.2.4-.4.4s-.4 0-.6-.2c-.4-.4-.6-.8-1-1.2-.5-.6-.7-.6-1.1 0-.3.4-.6.8-.9 1.1-.2.2-.5.1-.8.2 0-.3-.1-.6 0-.8.3-.5.7-.9 1.1-1.4.6-.7 1.6-.7 2.3 0 .4.5.8.9 1.1 1.4.1.2.2.4.3.5zM5.8 18.9c.2.1.4.2.4.3 0 .2 0 .5-.2.6-.4.4-.9.7-1.3 1-.5.4-.5.6 0 1 .4.3.9.6 1.2 1 .2.2.1.5.2.7-.2 0-.6.1-.7 0-.6-.4-1.1-.8-1.6-1.2-.6-.5-.7-1.5-.1-2 .5-.5 1.2-.9 1.8-1.4.1.1.2 0 .3 0zM26.8 21.3c-.2.4-.4.8-.6 1.1l-1.5 1.2c-.2.1-.5 0-.8 0 .1-.3.1-.6.2-.7.4-.4.9-.7 1.3-1 .4-.3.4-.6 0-.9-.4-.3-.9-.7-1.3-1-.3-.2-.4-.5-.2-.8.2-.3.5-.2.8 0 .5.4 1 .7 1.4 1.1.3.2.4.6.7 1z' />
		</svg>
	);
}

const MemoKeyboard = React.memo(Keyboard);
export default MemoKeyboard;
