import React, { useEffect, useRef, useState } from "react";

import iconEmail from "../../../assets/img/icon-email.png";
import iconUser from "../../../assets/img/icon-user.png";
import { useSelector, useDispatch } from "react-redux";

import { assetHelper } from "../../../helpers";
import { userActions } from "../../../store/user";
// import { Link } from "react-router-dom";
// import { ArrowLeft } from "../../../assets/icons";
import { eventSelectors } from "../../../store/event";
import constants from "../../../store/constants";
import { push } from "connected-react-router";
// import DummyUsers from "./DummyUsers";

const LoginForm = ({ t }) => {
	// Using default login here, remove for production
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");

	// const user = useSelector((state) => state.user);
	// const event = useSelector((state) => state.event);
	const ui = useSelector((state) => state.ui);
	const eventLoadState = useSelector(eventSelectors.getEventLoadingState);

	const dispatch = useDispatch();

	useEffect(() => {
		if (localStorage.hasOwnProperty("apiKey")) {
			// Try autologin
			dispatch(userActions.loginFromCache());
		}
	}, [dispatch]);

	useEffect(() => {
		if (eventLoadState === constants.EVENT_LOAD_STATE.LOADED) {
			localStorage.removeItem("hasTriedAutoLogin");
			// Redirect to event route
			dispatch(push("/event"));
		}
	}, [eventLoadState, dispatch]);

	useEffect(() => {
		if (localStorage.getItem("name") !== null) {
			setName(localStorage.getItem("name"));
		}
	}, []);

	const handleNameChange = (e) => {
		e.preventDefault();

		setName(e.target.value);
	};

	const handleEmailChange = (e) => {
		e.preventDefault();

		setEmail(e.target.value);
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				localStorage.setItem("name", name);
				dispatch(
					userActions.logIn({
						name,
						email,
					})
				);
			}}
			className='md:w-1/2 md:max-w-sm mx-auto p-10 bg-white shadow-xl rounded-2xl relative'>
			<div className='w-full'>
				{/* <Link
					to='/login'
					className='font-light text-sm text-gray-500 text-left mb-2 inline-flex space-x-2 items-center hover:border-gray-500 border border-transparent rounded-md p-2'>
					<ArrowLeft />
					<span>Back</span>
				</Link> */}
				<h2 className='font-semibold text-xl text-center mb-4'>
					{t("login.start", "Enter your details to enter the showroom")}
				</h2>
				<p className='font-normal text-base text-center mb-4'>
					Please fill in these details so people can identify you in the
					environment.
				</p>
				<div className='flex pl-4 mb-2'>
					<div className='w-8 z-10 text-center pointer-events-none flex items-center justify-center'>
						<img src={iconUser} alt='' />
					</div>
					<input
						type='text'
						className='w-full -ml-12 -mr-8 pl-14 pr-3 py-4 bg-tenary rounded-md border-2 border-transparent outline-none focus:border-primary-interact text-sm'
						placeholder={t("login.display", "Display name")}
						value={name}
						style={{ width: "calc(100% + 1rem)" }}
						onChange={handleNameChange}
					/>
				</div>
				<div className='flex pl-4 mb-2'>
					<div className='w-8 z-10 text-center pointer-events-none flex items-center justify-center'>
						<img src={iconEmail} alt='' />
					</div>
					<input
						type='email'
						className='w-full -ml-12 -mr-4 pl-14 pr-3 py-4 bg-tenary rounded-md border-2 border-transparent outline-none focus:border-primary-interact text-sm'
						placeholder={t("login.email", "Email address")}
						value={email}
						style={{ width: "calc(100% + 1rem)" }}
						onChange={handleEmailChange}
					/>
				</div>
				{ui.loginState > 200 && (
					<div className='text-primary text-sm mb-3 border-primary p-3 block border rounded-md my-4 '>
						{/* Validation error | User email doesn't exist | Wrong password */}
						{ui.loginState === 400 && (
							<p>{t("login.state.invalid_input", "Invalid input")}</p>
						)}
						{/* user not found | user not activated */}
						{ui.loginState === 401 && (
							<p>
								{t("login.state.unknown_user", "Unknown or unactivated user")}
							</p>
						)}
						{/* api key expired */}
						{ui.loginState === 419 && (
							<p>
								{t(
									"login.state.access_expired",
									"Previous session. Please log in again."
								)}
							</p>
						)}
						{/* Not in correct timeslot */}
						{ui.loginState === 403 && (
							<p>
								{t(
									"login.state.booked_mentioned_warning",
									"Please login at your booked date & time mentioned in your email."
								)}
								<br />
								{t("login.state.thank_you", "Thank you and see you soon.")}
							</p>
						)}
						{/* event or space not found */}
						{ui.loginState === 404 && (
							<p>{t("login.state.event_not_found", "Event not found")}</p>
						)}
						{/* no ticket found */}
						{ui.loginState === 405 && (
							<p>{t("login.state.no_ticket", "You're not invited")}</p>
						)}
						{ui.loginState === 500 && (
							<p>
								{t(
									"login.state.unknown_error",
									"Unknown error. Please try again later."
								)}
							</p>
						)}
					</div>
				)}
				<div className=''>
					{ui.loginState === 200 && (
						<div className='fixed w-screen h-screen top-0 left-0 bg-opacity-70 bg-white flex justify-center items-center'>
							<div className='bg-white px-12 py-6 font-semibold text-primary text-xl ring-opacity-40 ring-primary ring-2  shadow-lg rounded-md'>
								{t("login.state.connecting", "Connecting...")}
							</div>
						</div>
					)}

					{ui.loginState !== 1 ? (
						<button
							className='bg-primary text-white text-center
	hover:bg-primary-interact
 block shadow py-4 w-full rounded-md
 focus:ring-2 focus:ring-primary-interact uppercase'>
							{t("login.enter", "Enter")}
						</button>
					) : (
						<div
							className='bg-transparent text-primary text-center
	
 block py-4 w-full rounded-md
 ring-2 ring-primary-interact uppercase'>
							{t("login.state.logging_in", "Logging in")}
						</div>
					)}
				</div>

				{/* <div className='text-primary text-center pr-3 my-4'>
					<a
						href={assetHelper.absUrl("forgot-password")}
						target='_blank'
						className='hover:underline'
						rel='noreferrer'>
						{t("login.forgot_password", "Forgot password")}
					</a>
				</div> */}
				<div className='text-secondary text-xs text-center pr-3 pt-2 border-t border-gray-400'>
					<a href={assetHelper.absUrl("faq")} target='_blank' rel='noreferrer'>
						{t(
							"login.trouble_logging",
							"Trouble logging in or technical issues?"
						)}
					</a>
				</div>
			</div>
			{/* <DummyUsers numberOfDummies={6} withHost={true} set={0} /> */}
		</form>
	);
};

export default LoginForm;
