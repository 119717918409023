import ReactTooltip from "react-tooltip";
import { HostActionBar } from "../../components/ui/digital-showroom/host/HostActionBar";
import {
	ArrowLeft,
	ArrowRight,
	Asterisk,
	Call,
	CallEnd,
	Camera,
	CameraOff,
	Card,
	Chat,
	CheckSolid,
	Clock,
	Close,
	Couple,
	Download,
	Downloads,
	Exit,
	Gather,
	Goodiebag,
	Grid,
	Grid4,
	Group,
	Guide,
	Hamburger,
	Headset,
	Home,
	Info,
	Keyboard,
	Language,
	List,
	Loading,
	Location,
	Mouse,
	Microphone,
	MicrophoneOff,
	Navigation,
	Person,
	Pin,
	QA,
	RaiseHand,
	Save,
	Send,
	Settings,
	Sidebar,
	Speaker,
	SpeakerOff,
	Speech,
	Teleport,
	TeleportGroup,
	ThumbsDown,
	ThumbsUp,
	Trash,
	VCard,
} from "./index";

export function Gallery() {
	return (
		<>
			<div className='w-screen h-screen flex justify-center items-center'>
				<div className='p-4 rounded-xl shadow-lg bg-white max-w-7xl'>
					<div className=' grid grid-cols-12 gap-4'>
						<ArrowLeft
							data-tip='ArrowLeft'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<ArrowRight
							data-tip='ArrowRight'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Asterisk
							data-tip='Asterisk'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Call
							data-tip='Call'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<CallEnd
							data-tip='CallEnd'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Camera
							data-tip='Camera'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<CameraOff
							data-tip='CameraOff'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Card
							data-tip='Card'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Chat
							data-tip='Chat'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<CheckSolid
							data-tip='CheckSolid'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Clock
							data-tip='Clock'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Close
							data-tip='Close'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Couple
							data-tip='Couple'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Download
							data-tip='Download'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Downloads
							data-tip='Downloads'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Exit
							data-tip='Exit'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Gather
							data-tip='Gather'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Goodiebag
							data-tip='Goodiebag'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Grid
							data-tip='Grid'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Grid4
							data-tip='Grid4'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Group
							data-tip='Group'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Guide
							data-tip='Guide'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Hamburger
							data-tip='Hamburger'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Headset
							data-tip='Headset'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Home
							data-tip='Home'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Info
							data-tip='Info'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Keyboard
							data-tip='Keyboard'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Language
							data-tip='Language'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<List
							data-tip='List'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Loading
							data-tip='Loading'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Location
							data-tip='Location'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Mouse
							data-tip='Mouse'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Microphone
							data-tip='Microphone'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<MicrophoneOff
							data-tip='MicrophoneOff'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Navigation
							data-tip='Navigation'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Person
							data-tip='Person'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Pin
							data-tip='Pin'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<QA
							data-tip='QA'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<RaiseHand
							data-tip='RaiseHand'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Save
							data-tip='Save'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Send
							data-tip='Send'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Settings
							data-tip='Settings'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Sidebar
							data-tip='Sidebar'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Speaker
							data-tip='Speaker'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<SpeakerOff
							data-tip='SpeakerOff'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Speech
							data-tip='Speech'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Teleport
							data-tip='Teleport'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<TeleportGroup
							data-tip='TeleportGroup'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<ThumbsDown
							data-tip='ThumbsDown'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<ThumbsUp
							data-tip='ThumbsUp'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<Trash
							data-tip='Trash'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
						<VCard
							data-tip='VCard'
							data-for='gallery-tips'
							className='w-full h-full'
						/>
					</div>
				</div>
			</div>
			<ReactTooltip
				place='top'
				type='dark'
				className='text-center whitespace-nowrap'
				backgroundColor='#707070'
				effect='solid'
				id='gallery-tips'
				// getContent={(dataTip) => {
				// 	if (dataTip === null) return <></>;
				// 	const parts = dataTip.split("|");
				// 	return (
				// 		<div className='text-center'>
				// 			<b>{parts.shift()}</b>
				// 			{/* <br />
				// 			{parts.shift()} */}
				// 		</div>
				// 	);
				// }}
			/>
		</>
	);
}
