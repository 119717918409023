import constants from "../constants";

const defaultState = {
	queue: [],
	ignored: [],
};

/*
	id: uuid(),
	ts: new Date().getTime(),
	type: action.payload.message.type,
	message: action.payload.message.message,
	from: action.payload.message.publisher
*/

const modalReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.ADD_MODAL:
			return {
				...state,
				queue: state.queue.concat(action.payload),
			};
		case constants.CLOSE_MODAL:
			const newState = {
				queue: state.queue.filter((n) => n.id !== action.payload.id),
				ignored: state.queue.filter((n) => n.id !== action.payload.id),
			};
			// payload contains ignore
			// THEN PUT IT ON IGNORED STACK
			if (action.payload.ignored)
				newState.ignored = newState.ignored.concat([action.payload]);
			return newState;
		default:
			return state;
	}
};

export default modalReducer;
