import * as React from "react";

function Grid({ className = "", ...rest }) {
	return (
		<svg viewBox='0 0 30 30' className={`fill-current ${className}`} {...rest}>
			<rect x={6.11} y={9.18} width={5.28} height={5.28} rx={0.59} ry={0.59} />
			<rect x={12.36} y={9.18} width={5.28} height={5.28} rx={0.59} ry={0.59} />
			<rect x={18.61} y={9.18} width={5.28} height={5.28} rx={0.59} ry={0.59} />
			<rect x={6.11} y={15.54} width={5.28} height={5.28} rx={0.59} ry={0.59} />
			<rect
				x={12.36}
				y={15.54}
				width={5.28}
				height={5.28}
				rx={0.59}
				ry={0.59}
			/>
			<rect
				x={18.61}
				y={15.54}
				width={5.28}
				height={5.28}
				rx={0.59}
				ry={0.59}
			/>
		</svg>
	);
}

const MemoGrid = React.memo(Grid);
export default MemoGrid;
