import * as Yup from "yup";

// export const validateForm = (values) => {
//     const errors = {};
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
//     if (!values.first_name) {
//       errors.first_name = "Required";
//     }
//     if (!values.last_name) {
//       errors.last_name = "Last name is required!";
//     }
//     if (!values.email) {
//       errors.email = "Email is required!";
//     } else if (!regex.test(values.email)) {
//       errors.email = "This is not a valid email format!";
//     }
//     if (!values.password) {
//       errors.password = "Password is required";
//     } else if (values.password.length < 5) {
//       errors.password = "Minimum 5 characters required";
//     }
//     else if (values.password !== values.password_repeat) {
//       errors.password_repeat = "Password did not match";
//     }

//     else {
//       return ''
//     }

//     return errors;
//   };

export const Schema = Yup.object().shape({
	first_name: Yup.string()
		.required("First name is required")
		.min(3, "First name must be at least 3 characters"),
	last_name: Yup.string().required("Last name is required"),
	email: Yup.string().required("Email is required").email("Email is invalid"),
	password: Yup.string()
		.required("Password is required")
		.min(10, "Password must be at least 10 characters")
		.max(40, "Password must not exceed 40 characters")
		.matches(
			//eslint-disable-next-line
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*_\-+=`|\(){}\[\]:;"'<>,.?/]).{10,}$/,
			"Must have at least: 1 uppercase character, 1 digit and 1 non alphanumeric character"
		),
	password_confirmation: Yup.string()
		.required("Confirm Password is required")
		.oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
	company_name: Yup.string().required("Company name is required"),
	country: Yup.string().required("Country is required"),
	contact_person: Yup.string().required("Required"),
	prefix: Yup.string().required("Required"),
	native_language: Yup.string().required("This field is Required"),
	preferred_language: Yup.string().required("This field is Required"),
});
