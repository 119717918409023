import * as React from "react";

function Transparancy({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}>
			<path d="M21.9 8.1C21.9 5.8 20 4 17.7 4H8.1C5.8 4 4 5.8 4 8.1v9.6c0 2.3 1.8 4.1 4.1 4.2.1 2.3 2 4.1 4.2 4.1h9.6c2.3 0 4.1-1.8 4.1-4.1v-9.6c0-2.3-1.8-4.2-4.1-4.2zm-1.6 0h-8c-2.3 0-4.2 1.9-4.2 4.2v8c-1.4-.1-2.4-1.2-2.4-2.6V8.2c0-1.4 1.2-2.6 2.6-2.6h9.5c1.3 0 2.4 1.1 2.5 2.5zm-5.2 1.6l-5.4 5.4v-1.8l3.6-3.6h1.8zm4 0l-9.4 9.4v-1.8l7.7-7.7h1.7zm1.2 5.2v1.8l-3.6 3.6h-1.8l5.4-5.4zm-9.4 5.4l9.4-9.4v1.8l-7.7 7.7h-1.7zm13.5 1.6c0 1.4-1.1 2.5-2.5 2.5h-9.6c-1.4 0-2.5-1.1-2.6-2.4h8.1c2.3 0 4.1-1.8 4.1-4.1V9.7c1.4.1 2.4 1.2 2.4 2.6v9.6z" />
		</svg>
	);
}

const MemoTransparancy = React.memo(Transparancy);
export default MemoTransparancy;
