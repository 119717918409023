import { useState } from "react";
import ReactMarkdown from "react-markdown";
import SelectField from "../controls/SelectField";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui";
import constants from "../../store/constants";
import { Close } from "../../assets/icons";
// import { useTranslation } from "react-i18next";

export const SalesNotes = () => {
	// const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(0);
	const notes = useSelector((state) => state.sales.notes);
	const dispatch = useDispatch();
	const [content, setContent] = useState(notes[0].content);
	const notesOptions = notes.map((element) => {
		return { value: element.truck, label: element.truck };
	});
	const [selectedTruck, setSelectedTruck] = useState(notes[0]);

	const closePanel = () => {
		dispatch(uiActions.setLayer(constants.LAYER.SALES, false));
	};

	const handleTruckChange = (e) => {
		e.preventDefault();
		const notesByTruck = notes.filter((element) => {
			return element.truck === e.target.value;
		});

		setSelectedTruck(notesByTruck[0]);
		setContent(notesByTruck[0].content);
	};

	return (
		<div className="fixed right-0 top-0 w-full max-w-2xl h-screen layer--sales bg-white bg-opacity-90 p-8 shadow-xl">
			<div
				className="absolute right-6 top-6 cursor-pointer"
				onClick={(e) => {
					closePanel();
				}}>
				<Close className="w-8 h-8" />
			</div>
			<div className="w-full h-full flex flex-col">
				<SelectField
					value={selectedTruck.truck}
					onChange={handleTruckChange}
					placeholder="Select a truck"
					options={notesOptions}
					className="w-72 mb-8"
				/>
				<ul className="flex">
					<li
						onClick={() => setActiveTab(0)}
						className={`${
							activeTab === 0 ? "border-primary" : "border-gray-100"
						} uppercase cursor-pointer flex-grow text-center text-secondary pb-2 border-b-2 font-semibold text-sm`}>
						Customer
					</li>
					<li
						onClick={() => setActiveTab(1)}
						className={`${
							activeTab === 1 ? "border-primary" : "border-gray-100"
						} uppercase cursor-pointer flex-grow text-center text-secondary pb-2 border-b-2 font-semibold text-sm`}>
						Your contact
					</li>
					<li
						onClick={() => setActiveTab(2)}
						className={`${
							activeTab === 2 ? "border-primary" : "border-gray-100"
						} uppercase cursor-pointer flex-grow text-center text-secondary pb-2 border-b-2 font-semibold text-sm`}>
						Configuration
					</li>
					<li
						onClick={() => setActiveTab(3)}
						className={`${
							activeTab === 3 ? "border-primary" : "border-gray-100"
						} uppercase cursor-pointer flex-grow text-center text-secondary pb-2 border-b-2 font-semibold text-sm`}>
						Hotspots
					</li>
				</ul>
				<div className="flex-grow markdown">
					{activeTab === 0 && (
						<div className="py-8 flex flex-col space-y-4 text-lg">
							<h1 className="font-semibold text-3xl">Customer</h1>
							{content && content.customer && (
								<div className="flex flex-col space-y-2">
									<ReactMarkdown>{content.customer}</ReactMarkdown>
								</div>
							)}
						</div>
					)}
					{activeTab === 1 && (
						<div className="py-8 flex flex-col space-y-4 text-lg">
							<h1 className="font-semibold text-3xl">Your contact</h1>
							{content && content.yourContact && (
								<div>
									<ReactMarkdown>{content.yourContact}</ReactMarkdown>
								</div>
							)}
						</div>
					)}
					{activeTab === 2 && (
						<div className="py-8 flex flex-col space-y-4 text-lg">
							<h1 className="font-semibold text-3xl">Configuration</h1>
							{content && content.configuration && (
								<div>
									<ReactMarkdown>{content.configuration}</ReactMarkdown>
								</div>
							)}
						</div>
					)}
					{activeTab === 3 && (
						<div className="py-8 flex flex-col space-y-4 text-lg">
							<h1 className="font-semibold text-3xl">Hotspots</h1>
							{content && content.hotspots && (
								<div>
									<ReactMarkdown>{content.hotspots}</ReactMarkdown>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
