import React, { useState } from "react";
// import constants from "../store/constants";
import { useDispatch } from "react-redux";
// import { v4 as uuid } from "uuid";
import { overlayActions } from "../../store/overlay";
import constants from "../../store/constants";
import { uiActions } from "../../store/ui";
import { notificationActions } from "../../store/notification";
import { fireTruckInfoModal } from "../../store/modal/modalActions";

const DevActions = ({ t }) => {
	const dispatch = useDispatch();
	const [display] = useState(true);

	// const handleJoinDiscussionRoom = () => {
	// 	const [channelName, channelDisplayName, participants] = "BREAKOUT:ONE|TWO|4"
	// 		.split(":")[1]
	// 		.split("|");

	// 	dispatch({
	// 		type: constants.ADD_MODAL,
	// 		payload: {
	// 			type: constants.MESSAGE_TYPE.BREAKOUT,
	// 			id: uuid(),
	// 			ts: new Date().getTime(),
	// 			message: { channelName, channelDisplayName, participants },
	// 			from: "WEBSTREAM",
	// 		},
	// 	});
	// };

	// const joinExpert = (expertId) => {
	// 	// setDisplay(false);
	// 	dispatch({
	// 		type: constants.ADD_MODAL,
	// 		payload: {
	// 			type: constants.MESSAGE_TYPE.EXPERT,
	// 			id: uuid(),
	// 			ts: new Date().getTime(),
	// 			message: { expertId },
	// 			from: "WEBSTREAM",
	// 		},
	// 	});
	// };

	// const openHelp = () => {
	// 	dispatch(uiActions.openPanel(constants.PANEL.HELP));
	// };

	const openOverlay = (id) => {
		dispatch(
			overlayActions.openOverlay(
				id,
				constants.OVERLAY_TYPE.WALKTHROUGH,
				constants.FORMAT.ARRAY
			)
		);
	};

	const openOverlayQuestion = () => {
		if (sessionStorage.getItem("seen-truck-info") !== null) return;
		dispatch(fireTruckInfoModal("RRE160HE"));
	};

	return (
		display && (
			<>
				<div className="fixed layer-50 left-10 bottom-10 bg-white p-5 rounded-md w-48 z-40">
					{/* <button
				onClick={handleJoinDiscussionRoom}
				className='font-semibold max-w-xs uppercase rounded bg-black text-white hover:bg-gray-800 w-full py-3'>
				{t("base.join_discussion_room", "Join discussion room")}
			</button> */}
					{/* <button
						onClick={() => openHelp()}
						className='font-semibold max-w-xs uppercase rounded bg-black text-white hover:bg-gray-800 w-full py-3'>
						Open help panel
					</button>
					<button
						onClick={() => joinExpert(1)}
						className='font-semibold max-w-xs uppercase rounded bg-black text-white hover:bg-gray-800 w-full py-3'>
						Join expert 1
					</button>
					<button
						onClick={() => joinExpert(2)}
						className='font-semibold max-w-xs uppercase rounded bg-black text-white hover:bg-gray-800 w-full py-3'>
						Join expert 2
					</button> */}
					<button
						onClick={() => openOverlay("TOUCHSCREEN.page1")}
						className="font-semibold max-w-xs uppercase rounded bg-black text-white hover:bg-gray-800 w-full py-3 my-1">
						Open overlay 1
					</button>
					<button
						onClick={() => openOverlay("TOUCHSCREEN.page2")}
						className="font-semibold max-w-xs uppercase rounded bg-black text-white hover:bg-gray-800 w-full py-3 my-1">
						Open overlay 2
					</button>

					<button
						onClick={() => openOverlayQuestion()}
						className="font-semibold max-w-xs uppercase rounded bg-black text-white hover:bg-gray-800 w-full py-3 my-1">
						Open truck info
					</button>
				</div>
			</>
		)
	);
};

export default DevActions;
