import * as React from "react";

function Send({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M25.14 12.83L7 4a2.41 2.41 0 00-3.26 1.15 2.45 2.45 0 00-.07 1.95L6.83 15l-3.16 7.9A2.42 2.42 0 005 26a2.38 2.38 0 00.88.17A2.41 2.41 0 007 26l18.18-8.8a2.41 2.41 0 000-4.34zM5.16 6.5a.8.8 0 01.2-.88.77.77 0 01.9-.14l18 8.72h-16zm1.1 18a.8.8 0 01-1.07-.37.82.82 0 010-.65l3.08-7.7h16z" />
		</svg>
	);
}

const MemoSend = React.memo(Send);
export default MemoSend;
