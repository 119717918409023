import { createSelector } from "reselect";
import messagesSlice from "./messagesSlice";

import { groupSlice } from "../group";

export const getMessagesById = createSelector(
	[messagesSlice],
	(messages) => messages.byId
);

export const getMessagesByChannelName = (channelName) =>
	createSelector([getMessagesById], (messagesById) => {
		if (messagesById.hasOwnProperty(channelName))
			return messagesById[channelName];
		else return [];
	});

export const getMessagesForGroupChannel = createSelector(
	[getMessagesById, groupSlice],
	(messagesById, group) => {
		if (group === null || typeof group === "undefined") return [];
		else {
			if (messagesById.hasOwnProperty(group.messagingChannel))
				return messagesById[group.messagingChannel];
			else return [];
		}
	}
);
