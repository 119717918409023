/*eslint-disable */
import React, { RefObject, useEffect, useRef, useState } from "react";
import AgoraRTC, {
	BufferSourceAudioTrackInitConfig,
	CameraVideoTrackInitConfig,
	ClientConfig,
	CustomAudioTrackInitConfig,
	CustomVideoTrackInitConfig,
	IAgoraRTCClient,
	IBufferSourceAudioTrack,
	ICameraVideoTrack,
	ILocalAudioTrack,
	ILocalVideoTrack,
	IMicrophoneAudioTrack,
	IRemoteVideoTrack,
	MicrophoneAudioTrackInitConfig,
	ScreenVideoTrackInitConfig,
} from "agora-rtc-sdk-ng";

export default AgoraRTC;
export * from "agora-rtc-sdk-ng";

export const createClient = (config) => {
	let client;
	function createClosure() {
		if (!client) {
			client = AgoraRTC.createClient(config);
			//client.startProxyServer(3);
			AgoraRTC.setLogLevel(3);
		}
		return client;
	}
	return () => createClosure();
};

export function createMicrophoneAndCameraTracks(audioConfig, videoConfig) {
	let tracks = null;
	async function createClosure() {
		try {
			tracks = await AgoraRTC.createMicrophoneAndCameraTracks(
				audioConfig,
				videoConfig
			);
			return tracks;
		} catch (error) {
			console.log("Error", error);
			if (error.code === "NOT_READABLE") {
				window.alert(
					"It looks like your webcam is in use in another program. Please close and reload page"
				);
			}
			return false;
		}
	}
	return function useMicrophoneAndCameraTracks() {
		const [ready, setReady] = useState(false);
		const ref = useRef(tracks);

		useEffect(() => {
			if (ref.current === null) {
				createClosure().then((tracks) => {
					if (tracks !== false) {
						ref.current = tracks;
						setReady(true);
					}
				});
			} else {
				setReady(true);
			}
			return () => {
				tracks = null;
			};
		}, []);
		return { ready, tracks: ref.current };
	};
}

export function createBufferSourceAudioTrack(config) {
	let track = null;
	async function createClosure() {
		track = await AgoraRTC.createBufferSourceAudioTrack(config);
		return track;
	}
	return function useBufferSourceAudioTrack() {
		const [ready, setReady] = useState(false);
		const ref = useRef(track);

		useEffect(() => {
			if (ref.current === null) {
				createClosure().then((track) => {
					ref.current = track;
					setReady(true);
				});
			} else {
				setReady(true);
			}
			return () => {
				track = null;
			};
		}, []);
		return { ready, track: ref.current };
	};
}

export function createCameraVideoTrack(config) {
	let track = null;
	async function createClosure() {
		track = await AgoraRTC.createCameraVideoTrack(config);
		return track;
	}
	return function useCameraVideoTrack() {
		const [ready, setReady] = useState(false);
		const ref = useRef(track);

		useEffect(() => {
			if (ref.current === null) {
				createClosure().then((track) => {
					ref.current = track;
					setReady(true);
				});
			} else {
				setReady(true);
			}
			return () => {
				track = null;
			};
		}, []);
		return { ready, track: ref.current };
	};
}

export function createCustomAudioTrack(config) {
	let track = null;
	async function createClosure() {
		track = await AgoraRTC.createCustomAudioTrack(config);
		return track;
	}
	return function useCustomAudioTrack() {
		const [ready, setReady] = useState(false);
		const ref = useRef(track);

		useEffect(() => {
			if (ref.current === null) {
				createClosure().then((track) => {
					ref.current = track;
					setReady(true);
				});
			} else {
				setReady(true);
			}
			return () => {
				track = null;
			};
		}, []);
		return { ready, track: ref.current };
	};
}

export function createCustomVideoTrack(config) {
	let track = null;
	async function createClosure() {
		track = await AgoraRTC.createCustomVideoTrack(config);
		return track;
	}
	return function useCustomVideoTrack() {
		const [ready, setReady] = useState(false);
		const ref = useRef(track);

		useEffect(() => {
			if (ref.current === null) {
				createClosure().then((track) => {
					ref.current = track;
					setReady(true);
				});
			} else {
				setReady(true);
			}
			return () => {
				track = null;
			};
		}, []);
		return { ready, track: ref.current };
	};
}
export function getCameras() {
	let cameras = null;
	async function createClosure() {
		cameras = await AgoraRTC.getCameras();
		return cameras;
	}
	return function useCameras() {
		// const [ready, setReady] = useState(false);
		const ref = useRef(cameras);
		useEffect(() => {
			if (ref.current === null) {
				createClosure().then((camera) => {
					ref.current = cameras;
					//		setReady(true);
				});
			} else {
				//	setReady(true);
			}
			return () => {
				cameras = null;
			};
		});
		return ref.current;
	};
}
export function getMicrophones() {
	let mics = null;
	async function createClosure() {
		mics = await AgoraRTC.getMicrophones();
		return mics;
	}
	return function useMics() {
		const ref = useRef(mics);
		useEffect(() => {
			if (ref.current === null) {
				createClosure().then((mics) => {
					ref.current = mics;
				});
			}
			return () => {
				mics = null;
			};
		});
		return ref.current;
	};
}

export function createMicrophoneAudioTrack(config) {
	let track = null;
	async function createClosure() {
		track = await AgoraRTC.createMicrophoneAudioTrack(config);
		return track;
	}
	return function useMicrophoneAudioTrack() {
		const [ready, setReady] = useState(false);
		const ref = useRef(track);

		useEffect(() => {
			if (ref.current === null) {
				createClosure().then((track) => {
					ref.current = track;
					setReady(true);
				});
			} else {
				setReady(true);
			}
			return () => {
				track = null;
			};
		}, []);
		return { ready, track: ref.current };
	};
}

export function createScreenVideoTrack(
	config,
	withAudio // : 'enable' | 'disable' | 'auto'
) {
	let tracks;
	async function createClosure() {
		tracks = await AgoraRTC.createScreenVideoTrack(config, withAudio);
		return tracks;
	}
	return function useScreenVideoTrack() {
		const [ready, setReady] = useState(false);
		const ref = useRef(tracks);

		useEffect(() => {
			if (ref.current === undefined) {
				createClosure().then((tracks) => {
					ref.current = tracks;
					setReady(true);
				});
			} else {
				setReady(true);
			}
		}, []);
		return { ready, tracks: ref.current };
	};
}

export const AgoraVideoPlayer = (props) => {
	const vidDiv = useRef(null);
	const { videoTrack, ...other } = props;
	useEffect(() => {
		if (vidDiv.current !== null)
			videoTrack.play(vidDiv.current, { fit: "contain" });
		return () => {
			videoTrack.stop();
		};
	}, [videoTrack]);

	return <div {...other} ref={vidDiv} />;
};
/*eslint-enable */
