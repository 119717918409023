import axios from "axios";
import constants from "../constants";
import { authHeader } from "../../helpers";
import { uiActions } from "../ui";

import { loggingActions } from "../logging";
import { sendMessage } from "pubnub-redux";
import { callActions } from ".";
import { groupActions } from "../group";
import { chatActions } from "../chat";

export const userJoined = () => {};
export const setAppId = (appId) => {
	return {
		type: constants.SET_APP_ID,
		payload: appId,
	};
};
export const initCall = (
	channelName,
	is1to1,
	other,
	otheruuid,
	isSubscriber = false,
	isPresenter = false,
	hasChat = false,
	chatChannel = ""
) => {
	return {
		type: constants.INIT_CALL,
		payload: {
			channelName,
			is1to1,
			other,
			otheruuid,
			isSubscriber,
			isPresenter,
			hasChat,
			chatChannel,
		},
	};
};

export const initGroupCall = (participants) => {
	return function initGroupCallAsync(dispatch, getState) {
		// Start the group chat
		dispatch(uiActions.setLayer(constants.LAYER.CALL, true, false));
		dispatch(callActions.changeCallLayout(constants.CALL_LAYOUT.FULLSCREEN));
		// Also connect to command channel

		// TODO => CHECK IF UER IS ALREADY IN A GROUP
		const myId = getState().user.identity.uuid;
		const callId = "group-" + myId;

		dispatch(groupActions.setGroup("Group", callId, callId, myId));
		dispatch({ type: constants.SET_PARTICIPANTS, payload: participants });

		dispatch(chatActions.joinChannel(callId));

		for (let i = 0, l = participants.length; i < l; i++) {
			dispatch(
				sendMessage({
					channel: participants[i].uuid + "--private",
					message: {
						type: constants.MESSAGE_TYPE.HOSTEDCALL_INVITE,
						payload: { callID: callId },
					},
					sender: myId,
				})
			);
		}

		dispatch(initCall(callId, false));
	};
};
export const getListenToken = () => {
	// subscriber = 2
	return async function getCallTokenAsync(dispatch, getState, context) {
		const channelName = getState().call.channelName;
		//console.log("channelName", channelName);
		const mainToken = await axios
			.post(
				process.env.REACT_APP_API_ENDPOINT + "/services/call-token",
				{
					channel: channelName,
					role: 2,
				},
				{
					...authHeader(getState().user.key),
				}
			)
			.then((result) => {
				return result.data.token;
			})
			.catch((err) => {
				console.log(err);
			});
		const { screenToken, uid } = await axios
			.post(
				process.env.REACT_APP_API_ENDPOINT + "/services/call-token",
				{
					channel: channelName + "-screen",
					role: 2,
				},
				{
					...authHeader(getState().user.key),
				}
			)
			.then((result) => {
				return { screenToken: result.data.token, uid: result.data.id };
			})
			.catch((err) => {
				console.log(err);
			});

		dispatch({
			type: constants.JOIN_CALL,
			payload: {
				token: [mainToken, screenToken].join("||"),
				uid: uid,
			},
		});
	};
};

export const getCallToken = () => {
	return function getCallTokenAsync(dispatch, getState, context) {
		const channelName = getState().call.channelName;
		const isSubscriber = getState().call.isSubscriber;
		// Exit early
		if (isSubscriber) return dispatch(getListenToken());
		//console.log("channelName", channelName);
		axios
			.post(
				process.env.REACT_APP_API_ENDPOINT + "/services/call-token",
				{
					channel: channelName,
					role: 0,
				},
				{
					...authHeader(getState().user.key),
				}
			)
			.then((result) => {
				dispatch({
					type: constants.JOIN_CALL,
					payload: {
						token: result.data.token,
						uid: result.data.id,
					},
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

export const acceptCall = (other, channelName, avatar = "") => {
	return function openChatAsync(dispatch, getState, context) {
		// const me = getState().user.identity;

		if (!getState().event.identities.byId.hasOwnProperty(other)) return;

		// if (avatar === "") {
		// 	const identities = getState().event.identities.byId;
		// 	// Look up identity to generate avatar
		// 	avatar = identities.hasOwnProperty(other)
		// 		? identities[other].avatar
		// 		: "temp.png";
		// }
		dispatch(uiActions.setLayer(constants.LAYER.CALL, true, false));
		// console.log(getState().event.identities, other);
		// const channelName = `video-${[
		// 	me.videoUid,
		// 	getState().event.identities.byId[other].videoUid,
		// ]
		// 	.sort()
		// 	.join("-")}`;

		// Start call
		dispatch(
			initCall(channelName, true, getState().event.identities.byId[other].name)
		);

		dispatch(loggingActions.logActivity(constants.ACTIVITY.CALL_ACCEPT, other));
	};
};
export const leaveCall = () => {
	return function leaveCallAsync(dispatch, getState, context) {
		const other = getState().call.otheruuid;
		dispatch(loggingActions.logActivity(constants.ACTIVITY.CALL_ENDED, other));

		dispatch({ type: constants.LEAVE_CALL });
	};
};

export const changeCallLayout = (layout) => {
	return {
		type: constants.SET_CALL_LAYOUT,
		payload: layout,
	};
};
