import React, { useState, useCallback } from "react";

import {
	Microphone,
	MicrophoneOff,
	Camera,
	CameraOff,
	Speaker,
	SpeakerOff,
	Exit,
	ArrowLeft,
	CheckSolid,
} from "../../assets/icons";
import constants from "../../store/constants";

// import { useDispatch } from "react-redux";

const CallControls = ({
	tracks,
	allowLeave = true,
	leaveChannel,
	className,
	layout,
	setVolume,
	t,
}) => {
	const [trackState, setTrackState] = useState({
		video: true,
		audio: true,
		speaker: true,
	});

	// const dispatch = useDispatch();

	const [open, setOpen] = useState(true);
	const [micSelectorOpen, setMicSelectorOpen] = useState(false);
	const [camSelectorOpen, setCamSelectorOpen] = useState(false);

	const cameras = window.useCameras();
	const mics = window.useMics();
	// const client = window.useClient();
	const switchCamera = (deviceId) => {
		try {
			tracks[1].setDevice(deviceId);
		} catch (e) {
			console.log("Can't change camera input");
		}
		setCamSelectorOpen(false);
	};
	const switchMicrophone = (deviceId) => {
		setMicSelectorOpen(false);
		try {
			tracks[0].setDevice(deviceId);
		} catch (e) {
			console.log("Can't change microphone input");
		}
	};

	const mute = useCallback(
		async (type, override) => {
			if (type === "audio") {
				await tracks[0].setEnabled(override);
				setTrackState((ps) => {
					return { ...ps, audio: override };
					// return { ...ps, audio: !ps.audio };
				});
			} else if (type === "video") {
				await tracks[1].setEnabled(override);
				setTrackState((ps) => {
					return { ...ps, video: override };
					// return { ...ps, video: !ps.video };
				});
			} else if (type === "speaker") {
				if (override) setVolume(100);
				else setVolume(0);

				setTrackState((ps) => {
					return { ...ps, speaker: override };
					// return { ...ps, video: !ps.video };
				});
			}
		},
		[tracks, setVolume]
	);

	// useEffect(() => {
	// start is send from above, removed for eslint
	// 	if (start === false) return;
	// 	if (layout === constants.CALL_LAYOUT.FULLSCREEN) mute("video", true);
	// 	else mute("video", false);
	// }, [layout, mute, start]);

	return (
		<div className={` ${className} ${open ? "" : "closed"}`}>
			<div className='buttons flex space-x-2 md:space-x-4 xl:space-x-8  justify-center text-center text-xs mt-2'>
				{(layout === constants.CALL_LAYOUT.FULLSCREEN ||
					layout === constants.CALL_LAYOUT.SIDEBAR) && (
					<div>
						<button
							className={`rounded-full 
								${trackState.video ? "bg-black" : "bg-trueGray-300"}
								text-white w-14 h-14 flex justify-center items-center text-3xl mb-1 mx-auto`}
							onClick={() => {
								mute("video", !trackState.video);
							}}>
							{!trackState.video && <CameraOff />}
							{trackState.video && <Camera />}
						</button>
						<div
							className='flex space-x-2 relative items-center cursor-pointer'
							onClick={() => {
								if (cameras === null) return;
								setCamSelectorOpen(!camSelectorOpen);
							}}>
							<span>Camera</span>
							{cameras && (
								<ArrowLeft
									className={`${
										camSelectorOpen ? "rotate-90" : "-rotate-90"
									} cursor-pointer transition-transform duration-300 ease-in-out transform`}
								/>
							)}

							{/* Flyout */}
							{camSelectorOpen && (
								<ul className='overflow-hidden absolute top-0 left-1/2 transform -translate-y-full -translate-x-1/2 text-left bg-white rounded shadow-lg z-100 -mt-2'>
									{cameras.map((m, i) => (
										<li
											className='p-2 pl-7 border-b border-warmGray-200 whitespace-nowrap cursor-pointer hover:bg-trueGray-300 flex items-center'
											key={i}
											onClick={() => switchCamera(m.deviceId)}>
											{tracks[1]._deviceName === m.label && (
												<CheckSolid className='block text-secondary text-lg absolute left-2 top-1' />
											)}
											{m.label}
										</li>
									))}
								</ul>
							)}
						</div>
					</div>
				)}

				<div>
					<button
						className={`rounded-full 
								${trackState.audio ? "bg-black" : "bg-trueGray-300"}
								text-white w-14 h-14 flex justify-center items-center text-3xl mb-1 mx-auto`}
						onClick={() => {
							mute("audio", !trackState.audio);
						}}>
						{!trackState.audio && <MicrophoneOff />}
						{trackState.audio && <Microphone />}
					</button>

					<div
						className='flex space-x-2 relative items-center cursor-pointer'
						onClick={() => {
							if (mics === null) return;

							setMicSelectorOpen(!micSelectorOpen);
						}}>
						<span>Microphone</span>
						{mics && (
							<ArrowLeft
								className={`${
									micSelectorOpen ? "rotate-90" : "-rotate-90"
								} cursor-pointer transition-transform duration-300 ease-in-out transform`}
							/>
						)}

						{/* Flyout */}
						{micSelectorOpen && (
							<ul className='overflow-hidden absolute top-0 left-1/2 transform -translate-y-full -translate-x-1/2 bg-white rounded text-left shadow-lg z-100 -mt-2'>
								{mics.map((m, i) => (
									<li
										className='p-2 border-b border-warmGray-200 whitespace-nowrap cursor-pointer hover:bg-trueGray-300 pl-7 relative'
										key={i}
										onClick={() => switchMicrophone(m.deviceId)}>
										{tracks[0]._deviceName === m.label && (
											<CheckSolid className='block text-secondary text-lg absolute left-2 top-1' />
										)}
										{m.label}
									</li>
								))}
							</ul>
						)}
					</div>
				</div>

				<div>
					<button
						className={`rounded-full 
								${trackState.speaker ? "bg-black" : "bg-trueGray-300"}
								text-white w-14 h-14 flex justify-center items-center text-3xl mb-1 mx-auto`}
						onClick={() => {
							mute("speaker", !trackState.speaker);
						}}>
						{!trackState.speaker && <SpeakerOff />}
						{trackState.speaker && <Speaker />}
					</button>
					<span>{t("base.call_sound", "Sound")}</span>
				</div>

				{/* <div>
							<button
								className="rounded-full bg-black text-white w-14 h-14 flex justify-center items-center text-3xl mb-1"
								onClick={() => leaveCall()}
							>
								<Speaker />
							</button>
							<span>Sound</span>
						</div> */}
				{allowLeave && (
					<div>
						<button
							className='rounded-full bg-primary text-white w-14 h-14 flex justify-center items-center text-3xl mb-1'
							onClick={() => leaveChannel()}>
							<Exit className='text-3xl' />
						</button>
						<span>{t("global.exit", "Exit")}</span>
					</div>
				)}
			</div>

			{layout === constants.CALL_LAYOUT.SIDEBAR && (
				<>
					<div className='label absolute top-2 -translate-y-5 left-0 transform uppercase text-center w-full font-semibold  text-secondary text-xs tracking-wide'>
						{t("base.call_controls", "Controls")}
					</div>
					<div className='absolute top-0 right-0 mr-2 mt-2'>
						<ArrowLeft
							className={`${
								open ? "-rotate-90" : "rotate-90"
							} cursor-pointer transition-transform duration-300 ease-in-out transform`}
							onClick={() => {
								setOpen(!open);
							}}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default React.memo(CallControls);
