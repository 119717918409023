import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelectors } from "../../store/user";
import { Close } from "../../assets/icons";
import ServerImage from "../controls/ServerImage";
import { useMemo } from "react";
import defaultAvatar from "../../assets/img/default-avatar.png";
import { uiActions } from "../../store/ui";
import constants from "../../store/constants";
import { showDefaultProfilePicture } from "../../helpers/AssetHelper";

export function ProfilePanel() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const user = useSelector(userSelectors.getUserIdentity);

	const hasCompletedProfile = useMemo(() => {
		return !(
			user.name.indexOf("guest") > -1 &&
			user.role === "" &&
			user.company === ""
		);
	}, [user]);

	return (
		<div className='bg-white rounded-md shadow-lg absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-xs p-10 text-center'>
			<Close
				className='absolute right-6 top-6 cursor-pointer'
				onClick={() => dispatch(uiActions.closePanel())}
			/>
			<h1 className='font-semibold text-2xl mb-5'>
				{t("modal.profile.title", "Profile")}
			</h1>

			<div className='flex text-center flex-col space-y-4'>
				<figure className='text-center'>
					<div className='mx-auto rounded-full overflow-hidden w-24 h-24'>
						{showDefaultProfilePicture(user) ? (
							<img
								className='w-full h-full object-cover object-center bg-white'
								src={defaultAvatar}
								alt={user?.name}
							/>
						) : (
							<ServerImage
								className='w-full h-full object-cover object-center bg-white'
								src={user?.avatar}
								alt={user?.name}
							/>
						)}
					</div>
					<figcaption className='mt-3'>
						<span className='block font-bold'>{user.name}</span>
						<span className='block font-semibold'>{user.company}</span>
						<span className='block'>{user.role}</span>
						<button
							onClick={() => dispatch(userActions.logOut())}
							className='text-secondary text-sm mt-2'>
							logout
						</button>
					</figcaption>
				</figure>
				{!hasCompletedProfile && (
					<>
						{" "}
						<button
							className='bg-tenary rounded-md border-2 border-transparent outline-none border-primary hover:border-primary-interact font-semibold text-sm p-2 text-primary'
							onClick={() =>
								dispatch(uiActions.openPanel(constants.PANEL.REGISTRATION))
							}>
							Complete your profile
						</button>
						<p className='text-secondary text-sm'>
							To get access to additional features such as saving configurations
						</p>
					</>
				)}
			</div>
		</div>
	);
}
