import createAppStore from "./store";
import PubNub from "pubnub";
import { createBrowserHistory } from "history";

import {
	createClient,
	createMicrophoneAndCameraTracks,
	createMicrophoneAudioTrack,
	getCameras,
	getMicrophones,
} from "./libs/AgoraWrapper";

import i18n from "./i18n";

const isEmpty = (str) => !str?.length;

const AppSetup = ({
	pubnubPub = false,
	pubnubSub = false,
	callEnabled = true,
	baseUrl = "",
} = {}) => {
	const history = createBrowserHistory({
		basename: baseUrl,
	});

	let ready = false;

	let pubnub = null;

	if (!isEmpty(pubnubPub) && !isEmpty(pubnubSub)) {
		// INIT CHAT AND COMMAND COMMS
		const pubnubConfig = {
			publishKey: pubnubPub,
			subscribeKey: pubnubSub,

			suppressLeaveEvents: true, // Sometimes presence leave precedes join

			logVerbosity: false,
			restore: true,
			heartbeatInterval: 0,
		};

		pubnub = new PubNub(pubnubConfig);

		ready = true;
	}

	const store = createAppStore(
		{
			pubnub: {
				api: pubnub,
			},
			i18n,
		},
		history
	);

	if (callEnabled) {
		const config = {
			mode: "rtc",
			codec: "vp8",
		};

		// the create methods in the wrapper return a hook
		// the create method should be called outside the parent component
		// this hook can be used the get the client/stream in any component
		window.useClient = createClient(config);
		window.useLiveClient = createClient({
			mode: "live",
			codec: "vp8",
		});
		window.useLiveClient2 = createClient({
			mode: "live",
			codec: "vp8",
		});
		window.useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
		window.useMicrophoneTracks = createMicrophoneAudioTrack();
		window.useMics = getMicrophones();
		window.useCameras = getCameras();
	}
	return {
		pubnub,
		store,
		history,
		ready,
	};
};

export default AppSetup;
