import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Close } from "../../assets/icons";

import AttendeeListItem from "../itemrenderers/AttendeeListItem";
import ReactTooltip from "react-tooltip";
import { userSelectors } from "../../store/user";
import { presenceSelectors } from "../../store/presence";

import { useTimeout } from "react-use-timeout";
import { chatActions } from "../../store/chat";
import { eventActions } from "../../store/event";
import { uiActions } from "../../store/ui";
import { useTranslation } from "react-i18next";

// import dummy from "../../assets/img/dummy/participants.jpg";

/*
	[
		(state) => state.presence.byId,
		(state) => state.event.identities.byId,
		(state) => state.user?.identity?.uuid,
	],
	(presence, userData, me) => {
		if (!presence.hasOwnProperty("main")) return [];
		// console.log("userData", userData);
		return presence["main"].occupants
			.filter((u) => userData.hasOwnProperty(u.uuid) && u.uuid !== me)
			.map((u) => {
				return {
					...userData[u.uuid],
				};
			});
	}
);
*/

const AttendeesPanel = () => {
	const { t } = useTranslation();
	const attendees = useSelector(presenceSelectors.getSortedAttendees); // useSelector((state) => state.event.attendees);
	const me = useSelector(userSelectors.getUserIdentity);
	const dispatch = useDispatch();

	const [pendingChatInvites, setPendingChatInvites] = useState([]);
	const [pendingVcardInvites, setPendingVcardInvites] = useState([]);
	const [pendingCallInvites, setPendingCallInvites] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [tempSearchTerm, setTempSearchTerm] = useState("");

	const resetInvites = useCallback(() => {
		setPendingChatInvites([]);
		setPendingVcardInvites([]);
		setPendingCallInvites([]);
	}, []);

	const resetInvitesTimeout = useTimeout(resetInvites, 5000);

	const handleChatInvite = (attendee) => {
		dispatch(chatActions.sendChatInvite(attendee));
		setPendingChatInvites([...pendingCallInvites, attendee.uuid]);

		resetInvitesTimeout.start();
	};

	const handleVCardInvite = (attendee) => {
		dispatch(chatActions.sendVCardInvite(attendee));
		setPendingVcardInvites([...pendingVcardInvites, attendee.uuid]);

		resetInvitesTimeout.start();
	};

	const handleCallInvite = (attendee) => {
		dispatch(chatActions.sendCallInvite(attendee));
		setPendingCallInvites([...pendingCallInvites, attendee.uuid]);

		resetInvitesTimeout.start();
	};

	useEffect(() => {
		// Needs a rebinding for when list updates
		ReactTooltip.rebuild();
	});

	useEffect(() => {});

	// eslint-disable-next-line
	const refreshUserList = useCallback(() => {
		dispatch(eventActions.getPeopleInEvent());
	}, [dispatch]);
	// p-14
	return (
		<div
			className='bg-white rounded-md shadow-lg 
		p-10 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 
		w-full max-w-3xl scrollable-panel'>
			<Close
				className='absolute right-6 top-6 cursor-pointer'
				onClick={() => dispatch(uiActions.closePanel())}
			/>

			<h1 className='font-semibold text-2xl mb-3 text-center'>
				{t("base.attendees", "Attendees")}
			</h1>
			<p className='text-center'>
				{t(
					"base.part_info",
					"You’ll find an alphabetical list of active participants below.\nClick an icon to request a chat, videochat or vCard or locate someone."
				)}
			</p>
			{/* <button onClick={refreshUserList}>Refresh</button> */}
			<div className='my-3 flex border border-warmGray-300 text-sm mx-auto max-w-xs w-full rounded'>
				<input
					type='text'
					value={tempSearchTerm}
					onChange={(e) => {
						e.preventDefault();
						setTempSearchTerm(e.target.value);
					}}
					className='border-0 flex-grow px-2 bg-none focus:ring-0 focus:border-0'
				/>
				<button
					className='font-semibold border-white border-2 text-xs uppercase rounded bg-black text-white hover:bg-gray-800 py-2 px-4'
					onClick={(e) => {
						e.preventDefault();
						setSearchTerm(tempSearchTerm);
					}}>
					{t("global.search", "Search")}
				</button>
			</div>

			<div className='scrollable-panel__viewport'>
				<ul className='mt-2 striped-children border-t border-trueGray-300'>
					{attendees
						.filter((e) => {
							if (e.uuid === me.uuid) return false;
							if (searchTerm === "") return true;
							return e.name.toLowerCase().includes(searchTerm.toLowerCase());
						})
						.map(
							(element, idx) =>
								element.name && (
									<AttendeeListItem
										element={element}
										key={idx}
										handleVcardInvite={handleVCardInvite}
										handleInvite={handleChatInvite}
										handleCallInvite={handleCallInvite}
										showChatInvite={
											pendingChatInvites.indexOf(element.uuid) === -1
										}
										showVCardInvite={
											pendingVcardInvites.indexOf(element.uuid) === -1
										}
										showCallInvite={
											pendingCallInvites.indexOf(element.uuid) === -1
										}
										t={t}
										className=''
									/>
								)
						)}
				</ul>
			</div>
			{/* <img src={dummy} className="w-full" /> */}
			<ReactTooltip place='top' type='dark' effect='solid' />
		</div>
	);
};

export default AttendeesPanel;
