import * as React from "react";

function Headset({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M13.58 26.6a2.45 2.45 0 01-1.65-1.6 2.17 2.17 0 011.92-2.76 7.48 7.48 0 01.82 0c2 0 2.43.3 3.06 2.17h4.72a1.76 1.76 0 001.68-1.71c0-3 .15-6.1-.15-9.11a9.22 9.22 0 00-9.23-8 9.4 9.4 0 00-9.28 7.93c-.08.59 0 1.19-.15 1.78-.06.37-.2 1-.42 1a4.06 4.06 0 01-1.5-.1c-.09 0-.17-.13-.26-.2v-1.76c0-.19.1-.39.14-.59A11.79 11.79 0 0111.13 4a22.53 22.53 0 012.58-.62h2.06a2.07 2.07 0 00.33.1 11.69 11.69 0 019.88 9c.14.58.24 1.16.37 1.73v8.93a14.49 14.49 0 01-.73 1.6 4.44 4.44 0 01-2.71 1.83z" />
			<path d="M11.84 15.39v2.33a1.43 1.43 0 01-1.6 1.62c-2.33.05-3.46-1.06-3.46-3.39a18.83 18.83 0 010-2c.19-2 2.06-3 4.09-2.47a1.3 1.3 0 01.93 1.37c.05.85.04 1.7.04 2.54zM17.65 15.34v-2.47a1.44 1.44 0 011.51-1.5c2.34-.09 3.55 1.06 3.55 3.39a14.93 14.93 0 01-.06 2.12c-.32 2.13-2.33 2.83-4.12 2.33a1.25 1.25 0 01-.88-1.33c-.01-.88 0-1.69 0-2.54z" />
		</svg>
	);
}

const MemoHeadset = React.memo(Headset);
export default MemoHeadset;
