import * as React from "react";

function ArrowLeft({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M22.55 2.9a1.46 1.46 0 01.06 2.1L10.46 15l12.15 10a1.46 1.46 0 01-2.12 2L7.4 16a1.45 1.45 0 010-2L20.49 3a1.46 1.46 0 012.06-.05" />
		</svg>
	);
}

const MemoArrowLeft = React.memo(ArrowLeft);
export default MemoArrowLeft;
