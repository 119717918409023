import * as React from "react";

function ArrowRight({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M7.45 2.9A1.46 1.46 0 007.39 5l12.15 10L7.39 25a1.46 1.46 0 002.12 2L22.6 16a1.45 1.45 0 000-2L9.51 3a1.46 1.46 0 00-2.06-.1" />
		</svg>
	);
}

const MemoArrowRight = React.memo(ArrowRight);
export default MemoArrowRight;
