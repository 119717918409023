import constants from "../constants";
import axios from "axios";

import { authHeader } from "../../helpers";

// Fetch files that can be send to other users
// THESE ARE NOT MEANT TO BE DISPATCHED!
export const getEventFiles = async (key, dispatch) => {
	return axios
		.get(process.env.REACT_APP_API_ENDPOINT + "/files/get", {
			...authHeader(key),
		})
		.then((result) => {
			dispatch({ type: constants.SET_SALES_FILES, payload: result.data });
		});
};

export const getSalesNotes = async (key, dispatch) => {
	axios
		.get(process.env.REACT_APP_API_ENDPOINT + "/resource-set/sales_note/get", {
			...authHeader(key),
		})
		.then((result) => {
			dispatch({ type: constants.SET_SALES_NOTES, payload: result.data });
		});
};

export const sendFileToUsers = (fileIds, userIds) => {
	return function sendFileToUsersAsync(dispatch, getState) {
		axios
			.post(
				process.env.REACT_APP_API_ENDPOINT + "/goodiebag/send",
				{
					file_ids: fileIds,
					user_ids: userIds,
				},
				{
					...authHeader(getState().user.key),
				}
			)
			.then((result) => {
				dispatch({
					type: constants.SEND_FILES,
					payload: result.data,
				});

				console.log(result.data);
				/*dispatch({
					type: constants.ADD_NOTIFICATION,
					payload: result.data.message,
				});*/
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

/*
 $validator = Validator::make($request->all(), [
            'configuration' => 'required|string|max:255',
            'truck_name' => 'required|string|max:255',
            'truck_ref' => 'required|string|max:255',
            'comments' => 'nullable|string',
            'user_id' => 'nullable|integer', //other user id
            'ticket_id' => 'nullable|integer'
        ]);
*/
export const saveConfiguration = (
	configuration,
	truck_name,
	truck_ref,
	comments,
	user_id,
	ticket_id
) => {
	return function saveConfigurationAsync(dispatch, getState) {
		axios
			.post(
				process.env.REACT_APP_API_ENDPOINT + "/configurations/store",
				{
					configuration: configuration,
					truck_name: truck_name,
					truck_ref: truck_ref,
					comments: comments,
					user_id: user_id,
					ticket_id: ticket_id,
				},
				{
					...authHeader(getState().user.key),
				}
			)
			.then((result) => {
				console.log(result.data);
				/*dispatch({
					type: constants.ADD_NOTIFICATION,
					payload: result.data.message,
				});*/
			})
			.catch((err) => {
				console.log(err);
			});
	};
};
