import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import constants from "../store/constants";
import { eventSelectors } from "../store/event";
import { modalActions } from "../store/modal";
import CallDeclinedModel from "./modals/CallDeclinedModal";
import CallRequestModal from "./modals/CallRequestModal";
import ChatDeclinedModal from "./modals/ChatDeclinedModal";
import BreakoutRequestModal from "./modals/BreakoutRequestModal";
import ChatRequestModal from "./modals/ChatRequestModal";
import HostedCallRequestModal from "./modals/HostedCallRequestModal";
import VCardAcceptModal from "./modals/VCardAcceptModal";
import VCardDeclinedModal from "./modals/VCardDeclinedModal";
import VCardRequestModal from "./modals/VCardRequestModal";
import VideoRequestModal from "./modals/VideoRequestModal";
import { useTranslation } from "react-i18next";
import ExpertRequestModal from "./modals/ExpertRequestModal";
import TruckInfoModal from "./modals/TruckInfoModal";
// import ChatDeclinedModal from "./modals/ChatDeclinedModal";
// import VCardRequestModal from "./modals/VCardRequestModal";
// import VCardDeclinedModal from "./modals/VCardDeclinedModal";
// import VCardAcceptModal from "./modals/VCardAcceptModal";

// const getModalQueue = createSelector(
// 	(state)=>state.modal,
// 	(modal)=>modal.queue
// )

const ModalManager = () => {
	const { t } = useTranslation();
	const user = useSelector((state) => state.user);
	const identities = useSelector(eventSelectors.getEventIdentitiesById);

	const dispatch = useDispatch();

	const lastModal = useSelector((state) => {
		// console.log("Length of modals", state.modal.queue);
		if (state.modal.queue.length === 0) return false;

		const lastEntry = state.modal.queue[0];

		if (
			lastEntry.from === "EventManager" ||
			lastEntry.from === "WEBSTREAM" ||
			typeof lastEntry.from === "object"
		) {
			return lastEntry;
		}
		if (!identities.hasOwnProperty(lastEntry.from)) return false;

		const fullIdentity = identities[lastEntry.from];
		lastEntry.from = fullIdentity;

		return lastEntry;
	}, shallowEqual);

	const clearModal = (id, ignore) => {
		dispatch(modalActions.clearModal(id, ignore));
	};

	return (
		<>
			{lastModal?.type === constants.MESSAGE_TYPE.CHAT_INVITE && (
				<ChatRequestModal
					notification={lastModal}
					me={user.identity}
					callback={clearModal}
					t={t}
				/>
			)}

			{lastModal?.type === constants.MESSAGE_TYPE.CALL_INVITE && (
				<CallRequestModal
					notification={lastModal}
					me={user.identity}
					callback={clearModal}
					t={t}
				/>
			)}

			{lastModal?.type === constants.MESSAGE_TYPE.HOSTEDCALL_INVITE && (
				<HostedCallRequestModal
					notification={lastModal}
					me={user.identity}
					callback={clearModal}
					t={t}
				/>
			)}

			{lastModal?.type === constants.MESSAGE_TYPE.CHAT_DECLINE && (
				<ChatDeclinedModal
					notification={lastModal}
					me={user.identity}
					callback={clearModal}
					t={t}
				/>
			)}
			{lastModal?.type === constants.MESSAGE_TYPE.CALL_DECLINE && (
				<CallDeclinedModel
					notification={lastModal}
					me={user.identity}
					callback={clearModal}
					t={t}
				/>
			)}

			{lastModal?.type === constants.MESSAGE_TYPE.VCARD_REQUEST && (
				<VCardRequestModal
					notification={lastModal}
					me={user.identity}
					callback={clearModal}
					t={t}
				/>
			)}
			{lastModal?.type === constants.MESSAGE_TYPE.VCARD_DECLINE && (
				<VCardDeclinedModal
					notification={lastModal}
					callback={clearModal}
					t={t}
				/>
			)}
			{lastModal?.type === constants.MESSAGE_TYPE.VCARD_ACCEPT && (
				<VCardAcceptModal
					notification={lastModal}
					callback={clearModal}
					t={t}
				/>
			)}
			{lastModal?.type === constants.MESSAGE_TYPE.MEETING_START && (
				<VideoRequestModal
					notification={lastModal}
					callback={clearModal}
					t={t}
				/>
			)}

			{lastModal?.type === constants.MESSAGE_TYPE.BREAKOUT && (
				<BreakoutRequestModal
					notification={lastModal}
					callback={clearModal}
					t={t}
				/>
			)}

			{lastModal?.type === constants.MESSAGE_TYPE.EXPERT && (
				<ExpertRequestModal
					notification={lastModal}
					callback={clearModal}
					t={t}
				/>
			)}

			{lastModal?.type === constants.MESSAGE_TYPE.TRUCK_INFO && (
				<TruckInfoModal notification={lastModal} callback={clearModal} t={t} />
			)}
		</>
	);
};

export default React.memo(ModalManager);
