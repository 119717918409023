import React from "react";
import ServerImage from "../controls/ServerImage";
// import { Card, Chat, Camera } from "../../assets/icons";

const AttendeeGroupListItem = ({
	element,
	added,
	handleAdd,
	handleRemove,
	t,
}) => (
	<li className='flex py-3 border-b border-trueGray-300 space-x-4 items-center px-4'>
		<ServerImage
			className='rounded-full overflow-hidden w-12 h-12 flex-none'
			src={element?.avatar?.replace(".png", "-small.png")}
			alt={element?.name}
		/>
		<div className='flex flex-grow flex-nowrap space-x-4'>
			<div className='font-semibold w-full'>
				<span className='text-trueGray-400 text-xs block'>
					{t("base.participants.name", "Name")}
				</span>
				<span className='block'>{element?.name}</span>
			</div>

			<div className='attendees__list-item-details w-full'>
				<span className='text-trueGray-400 text-xs block'>
					{t("base.participants.company_name", "Company")}
				</span>
				<span className='block'>{element?.company}</span>
			</div>

			<div className='attendees__list-item-details w-full'>
				<span className='text-trueGray-400 text-xs block'>
					{t("base.participants.title", "Title")}
				</span>
				<span className='block'>{element?.role}</span>
			</div>
		</div>
		<div className='flex text-primary text-2xl space-x-4'>
			{added && (
				<button
					data-tip='Remove from call'
					onClick={(e) => {
						handleRemove(element.uuid);
					}}>
					-
				</button>
			)}
			{!added && (
				<button
					data-tip='Add to call'
					onClick={(e) => {
						handleAdd(element.uuid);
					}}>
					+
				</button>
			)}
		</div>
	</li>
);

export default AttendeeGroupListItem;
