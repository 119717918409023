import constants from "../constants";

const defaultState = {
	appId: "",
	isConnected: false,
	channelName: "",
	channelToken: "",
	hasChat: false,
	chatChannel: "",
	uid: 0,
	feeds: [],
	screenShareFeed: null,
	isHost: false,
	layout: constants.CALL_LAYOUT.OFF,
	is1to1: false,
	isPresenter: false,
	other: "",
	otheruuid: "",
	// If set to true, will close the current call
	remoteEnd: false,
	participantsInCall: [],
};

const callReducer = (state = defaultState, action) => {
	switch (action.type) {
		case "set_participants":
			return { ...state, participantsInCall: action.payload };
		case constants.SET_APP_ID:
			return {
				...state,
				appId: action.payload,
			};
		case constants.INIT_CALL:
			return {
				...state,
				channelName: action.payload.channelName,
				isActive: true,
				is1to1: action.payload.is1to1,
				other: action.payload.other,
				otheruuid: action.payload.otheruuid,
				isPresenter: action.payload.isPresenter,
				isSubscriber: action.payload.isSubscriber,
				hasChat: action.payload.hasChat,
				chatChannel: action.payload.chatChannel,
			};
		case constants.JOIN_CALL:
			return {
				...state,
				channelToken: action.payload.token,
				uid: action.payload.uid,
				isConnected: false,
			};
		case constants.LEAVE_CALL:
			return { ...defaultState, appId: state.appId };

		case constants.CALL_JOINED:
			return {
				...state,
				isConnected: true,
			};

		case constants.USER_JOINED_CALL:
			return { ...state };

		case constants.USER_LEFT_CALL:
			return { ...state };
		case constants.SET_CALL_LAYOUT:
			return {
				...state,
				layout: action.payload,
			};
		case constants.SET_REMOTE_END:
			return {
				...state,
				remoteEnd: action.payload,
			};
		default:
			return state;
	}
};

export default callReducer;
