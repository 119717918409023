import * as React from "react";

function Person({ className = "", ...rest }) {
	return (
		<svg
			viewBox="0 0 30 30"
			width="1em"
			height="1em"
			className={`fill-current ${className}`}
			{...rest}
		>
			<path d="M18.7 14.72a6 6 0 10-7.39 0A10 10 0 005 24v1a1 1 0 001 1h18a1 1 0 001-1v-1a10 10 0 00-6.3-9.28zM11 10a4 4 0 114 4 4 4 0 01-4-4zM7 24a8 8 0 0116 0zm0 0" />
		</svg>
	);
}

const MemoPerson = React.memo(Person);
export default MemoPerson;
