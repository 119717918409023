const constants = {
	SET_ACTIVE_CONVERSATION: "chat/set-active-conversation",
	JOIN_CONVERSATION: "chat/join-conversation",
	JOIN_CONVERSATIONS: "chat/join-conversations",
	LEAVE_CONVERSATION: "chat/leave-conversation",
	SET_TYPING_INDICATOR: "chat/set-typing-indicator",
	CLEAR_TYPING_INDICATOR: "chat/clear-typing-indicator",
	SET_LAST_MESSAGE_RECEIVED: "chat/set-last-message",

	SET_APPCONFIG: "app/set-config",
	SET_LANGUAGE: "app/set-language",
	APPEND_APPCONFIG: "app/append-config",
	APPEND_STREAMERCONFIG: "streamer/append-config",

	SET_EVENTCODE: "event/set-event-code",
	SET_EVENTTYPE: "event/set-event-type",
	SET_INVITECODE: "event/set-invite-code",
	SET_STARTIN: "event/set-event-start-in",
	SET_SHOW_VIDEO_ON_START: "event/set-show-video-on-start",
	SET_DETAILS: "event/set-details",
	SET_EVENTCONFIG: "event/set-config",
	SET_IDENTITY_ON_CHANNEL: "event/set-identity-on-channel",
	SET_IDENTITIES: "event/set-identities",
	ADD_IDENTITY: "event/add-identity",
	SET_EVENT_LOADSTATE: "event/set-load-state",

	SET_IS_HOSTED: "event/set-is-hosted",
	SET_CAN_HOST: "host/set-can-host",
	SET_INVITEES: "host/set-invitees",

	SET_GOODIEBAG_FETCHING: "goodiebag/set-goodiebag-fetching",
	SET_GOODIEBAG: "goodiebag/set",
	ADD_TO_GOODIEBAG: "goodiebag/add",
	REMOVE_FROM_GOODIEBAG: "goodiebag/remove",

	SET_SALES_NOTES: "sales/set-sales-notes",
	SET_SALES_FILES: "sales/set-sales-files",
	SEND_FILES: "sales/send-files",

	SET_APP_STATE: "ui/app-state",
	SET_UI_VISIBILITY: "ui/set-visibility",
	SET_PANEL: "ui/set-panel",
	SET_PANEL_WITH_DATA: "ui/set-panel-with-data",
	SET_PANEL_DATA: "ui/set-panel-data",
	ADD_MODAL: "ui/add-modal",
	CLOSE_MODAL: "ui/close-modal",
	CLOSE_NOTIFICATION: "ui/close-notification",
	ADD_NOTIFICATION: "ui/add-notification",
	ADD_PING: "ui/add-ping",
	CLEAR_PING: "ui/clear-ping",
	SET_ACTIONBAR_STATE: "ui/set-actionbar-state",
	SET_LOGIN_STATE: "ui/set-login-state",
	SET_ACTIVATION_STATE: "ui/set-activation-state",
	SET_REGISTRATION_STATE: "ui/set-registration-state",
	SET_ROUTE: "ui/set-route",
	SET_LAYERS: "ui/set-layers",
	SET_LAYER_DATA: "ui/set-layer-data",

	LOGIN: "user/login",
	LOGOUT: "user/logout",
	SET_USER_IDENTITY: "user/set-identity",
	SET_USER_ACCESS: "user/set-access",
	SET_USER_PREFERENCES: "user/set-preferences",
	SET_KEY: "user/set-key",
	SAVE_PROFILE_DATA: "user/save-profile-data",

	SET_GROUP: "group/set-group",
	SET_PARTICIPANTS: "group/set-participants",

	SET_APP_ID: "call/set-app-id",
	INIT_CALL: "call/init",
	CALL_JOINED: "call/joined",
	USER_JOINED_CALL: "call/user-joined",
	USER_LEFT_CALL: "call/user-left",
	JOIN_CALL: "call/set-channel",
	LEAVE_CALL: "call/leave-call",
	SET_CALL_LAYOUT: "call/set-layout",
	SET_REMOTE_END: "call/set-remote-end",

	SET_VIDEO: "video/set",
	CLEAR_VIDEO: "video/clear",
	SET_SUBTITLES: "video/subtitles",

	STREAMER_SET_ID: "streamer/set-id",
	STREAMER_SET_STATE: "streamer/set-state",
	STREAMER_ADD_OUTGOING_MESSAGE: "streamer/add-outgoing-message",
	STREAMER_ADD_INCOMING_MESSAGE: "streamer/add-incoming-message",
	STREAMER_CLEAR_OUTGOING_MESSAGES: "streamer/clear-outgoing-messages",
	STREAMER_CLEAR_INCOMING_MESSAGES: "streamer/add-incoming-messages",
	STREAMER_SET_MUTED: "streamer/set-muted",
	STREAMER_SET_NAVSTATE: "streamer/set-nav-state",

	CHANNEL_TYPE: {
		MAIN: "main",
		MAIN_INTERACTIVE: "main-interactive",
		CHAT: "chat",
		MINE: "mine",
		REALTIME: "realtime",
		PRIVATE: "private",
		GROUP: "group",
	},

	MESSAGE_TYPE: {
		// USE UNDERSCORE FOR UNITY INTEROPTABILITY
		NOTIFICATION: "NOTIFICATION",
		CHAT_INVITE: "CHAT_INVITE",
		CHAT_ACCEPT: "CHAT_ACCEPT",
		CHAT_DECLINE: "CHAT_DECLINE",
		CALL_INVITE: "CALL_INVITE",
		HOSTEDCALL_INVITE: "HOSTEDCALL_INVITE",
		HOSTEDCALL_ACCEPTED: "HOSTEDCALL_ACCEPTED",
		CALL_ACCEPT: "CALL_ACCEPT",
		CALL_DECLINE: "CALL_DECLINE",
		VCARD_REQUEST: "VCARD_REQUEST",
		VCARD_ACCEPT: "VCARD_ACCEPT",
		VCARD_DECLINE: "VCARD_DECLINE",
		ROOM_SWITCH: "ROOM_SWITCH",
		SESSION_START: "SESSION_START",
		SESSION_END: "SESSION_END",
		MEETING_START: "MEETING_START",
		MEETING_END: "MEETING_END",
		ROUTE_SWITCH: "ROUTE_SWITCH",
		GATHER_GROUP: "GATHER_GROUP",
		START_TOUR: "START_TOUR",
		RELEASE_GROUP: "RELEASE_GROUP",
		GROUP_TELEPORT: "GROUP_TELEPORT",
		SWITCH_VIDEO: "SWITCH_VIDEO",
		HOST_ARRIVED: "HOST_ARRIVED",
		RESTART_CALL: "RESTART_CALL",
		RESET: "RESET",
		USER_JOINED: "USER_JOINED",
		BREAKOUT: "BREAKOUT",
		EXPERT: "EXPERT",
		QUESTION: "QUESTION",
		SCREENSHARE_START: "START_SCREENSHARE",
		RECEIVE_FILE: "RECEIVE_FILE",
		TRUCK_INFO: "TRUCK_INFO",
	},

	NOTIFICATION: {
		CHAT: "CHAT",
		GOODIEBAG: "GOODIEBAG",
	},

	ASSET_TYPE: {
		VCARD: "2",
		FILE: "1",
		CONFIGURATION: "3",
	},

	PANEL: {
		NONE: "NONE",
		ATTENDEES: "ATTENDEES",
		GROUPCALL: "GROUPCALL",
		GOODIEBAG: "GOODIEBAG",
		FLOORPLAN: "FLOORPLAN",
		CHAT: "CHAT",
		HELP: "HELP",
		SETTINGS: "SETTINGS",
		PROFILE: "PROFILE",
		REGISTRATION: "REGISTRATION",
		SAVE_CONFIGURATION: "SAVE_CONFIGURATION",
	},

	OVERLAY_TYPE: {
		HOTSPOT: "hotspot",
		PAGE: "page",
		TUTORIAL: "tutorial",
		WALKTHROUGH: "walkthrough",
		SALES_NOTES: "sales_notes",
	},

	ACTIONBAR: {
		HIDDEN: "HIDDEN",
		MINI: "MINI",
		COLLAPSED: "COLLAPSED",
		EXPANDED: "EXPANDED",
	},

	CALL_LAYOUT: {
		FULLSCREEN: "FULLSCREEN",
		SIDEBAR: "SIDEBAR",
		PIP: "PIP",
		AUDIO_ONLY: "AUDIO_ONLY",
		OFF: "OFF",
	},

	LAYER: {
		STREAMER: "STREAMER",
		VIDEO: "VIDEO",
		CALL: "CALL",
		SALES: "SALES",
		FILES: "FILES",
		PARTICIPANTS: "PARTICIPANTS",
		IFRAME: "IFRAME",
		NONE: "NONE",
	},
	EVENT_LOAD_STATE: {
		UNLOADED: "UNLOADED",
		LOADING: "LOADING",
		LOADED: "LOADED",
	},
	STREAMER_STATE: {
		CONNECTING: "CONNECTING",
		UNAVAILABLE: "UNAVAILABLE",
		LOADING: "LOADING",
		IDLE: "IDLE",
		STOPPED: "STOPPED",
	},
	STREAMER_OUTGOING_ACTIONS: {
		WALKTHROUGH_RESET: "WALKTHROUGH:RESET",
		TELEPORT_GROUP: "TELEPORT:GROUP",
		SOUND_OFF: "SOUND:FALSE",
		SOUND_ON: "SOUND:TRUE",
		HOTSPOT_RESET: "HOTSPOT-RESET",
		WALKTHROUGH_STEP: "WALKTHROUGH:TOUCHSCREEN:$pageNumber",
		TELEPORT_ME: "TELEPORT:$reference",
		NAVMODE: "NAVMODE:$m",
		SAVE_CONFIGURATION: "SAVE_CONFIGURATION",
	},
	STREAMER_INCOMING_ACTIONS: {},
	USER_TYPE: {
		VISITOR: 1,
		EXPERT: 2,
		HOST: 3,
	},
	ACTIVITY_LOGGED: "user/activity-logged",
	ACTIVITY: {
		LOGIN: "LOGIN",
		LOGOUT: "LOGOUT", // $data = time spent (calculated)
		LOGIN_SUCCESS: "LOGIN_SUCCESS",
		LOGIN_FAILED: "LOGIN_FAILED", // $value = error code
		VIDEO_PLAYED: "VIDEO_PLAYED", // $value = video name
		CHAT_REQUEST: "CHAT_REQUEST", // $value = uuid
		CHAT_DECLINED: "CHAT_DECLINED", // $value = uuid
		CHAT_ACCEPT: "CHAT_ACCEPT", // $value = uuid
		VCARD_REQUEST: "VCARD_REQUEST", // $value = uuid
		VCARD_DECLINED: "VCARD_DECLINED", // $value = uuid
		VCARD_ACCEPT: "VCARD_ACCEPT", // $value = uuid
		CALL_REQUEST: "CALL_REQUEST", // $value = uuid
		CALL_DECLINED: "CALL_DECLINED", // $value = uuid
		CALL_ACCEPT: "CALL_ACCEPT", // $value = uuid
		CALL_ENDED: "CALL_ENDED", // $value = uuid
		FILE_DOWNLOAD: "FILE_DOWNLOAD", // $value = reference
		AREA_ENTER: "AREA_ENTER", // $value = name
		AREA_LEAVE: "AREA_LEAVE", //  $value = name, $data = time spent (calculated)
		ONBOARDING: "ONBOARDING",
		EXTERNAL_LINK: "EXTERNAL_LINK", // $value = uri
		FEEDBACK: "FEEDBACK", // $value = location/session, $data = feedback/question
		QUESTIONNAIRE: "QUESTIONNAIRE", // $value = reference, $data = answer(s) / json
		BUTTON_CLICK: "BUTTON_CLICK", // $value = reference
		UNIQUE_EVENT: "UNIQUE_EVENT", // $value = reference
		TIMED_EVENT_START: "TIMED_EVENT_START", // $value = reference
		TIMED_EVENT_END: "TIMED_EVENT_END", // $value = reference, $data = time spent (calculated)
		TELEPORT: "TELEPORT", // $value = reference
	},

	FORMAT: {
		ARRAY: "array",
		OBJECT: "object",
	},

	VISIBILITY: {
		SHOW: "SHOW",
		HIDE: "HIDE",
	},

	NOTIFICATION_TYPE: {
		INFO: "INFO",
		WARNING: "WARNING",
		CRITICAL: "CRITICAL",
		"EXPERT-INFO": "EXPERT-INFO",
	},
};

export default constants;
