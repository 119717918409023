import axios from "axios";
// import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useRef } from "react";

import { VideoPlayer } from "./classes/video-player";
import {
	registerGamepadEvents,
	registerKeyboardEvents,
	registerMouseEvents,
} from "./classes/register-events";
import { useMemo } from "react";
export const CONNECTION_STATES = {
	IDLE: "Idle",
	RESERVATION_READY: "Reservation_ready",
	CONNECTING: "Connecting",
	CONNECTED: "Connected",
	SERVICE_ERROR: "Service error",
	UNAVAILABLE: "Unavailable",
	DISCONNECTED: "Disconnected",
};
export const useStreamer = (config) => {
	const [streamerStatus, setStreamerStatus] = useState(CONNECTION_STATES.IDLE);
	const [uuid, setUuid] = useState(null);

	// Set defaults and override with config input
	const settings = useMemo(
		() => ({
			...{
				serverPath: "",
				uuid: null,
				https: true,
				iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }],
				autoReconnect: false, // implement
				autoStart: true, // implement
				messageCallback: (msg) => {},
				onReady: () => {},
				onSdkReady: () => {},
				onDisconnect: () => {},
			},
			...config,
		}),
		[config]
	);

	// Reference to video element
	const videoRef = useRef(null);
	// Reference to container holding video element
	const playerRef = useRef(null);
	// Reference to VideoPlayer lib component
	const streamerRef = useRef(null);

	// Hack for dev double mount
	const busy = useRef(false);

	const clearChildren = useCallback((element) => {
		while (element.firstChild) {
			element.removeChild(element.firstChild);
		}
	}, []);

	const onDisconnect = useCallback(() => {
		clearChildren(playerRef.current);
		streamerRef.current.stop();
		streamerRef.current = null;
		videoRef.current = null;
		if (settings.autoReconnect) playerRef.current = null;

		setStreamerStatus(CONNECTION_STATES.DISCONNECTED);
		settings.onDisconnect();
	}, [clearChildren, settings]);

	const setupVideoPlayer = useCallback(
		async (elements, connectionId, secure) => {
			const videoPlayer = new VideoPlayer(elements);

			await videoPlayer.setupConnection(
				secure,
				connectionId,
				settings.serverPath,
				settings.iceServers
			);

			videoPlayer.ondisconnect = onDisconnect;
			// Handle all messages internally until app tells us SDK is ready
			videoPlayer.receivedMessage = (msg) => {
				if (msg === "SDK_READY") {
					settings.onSdkReady();
					// Pass messages directly to client
					streamerRef.current.receivedMessage = settings.messageCallback;
				}
			};

			registerGamepadEvents(videoPlayer);
			registerKeyboardEvents(videoPlayer);
			registerMouseEvents(videoPlayer, elements[0]);

			return videoPlayer;
		},
		[onDisconnect, settings]
	);
	// Go out and fetch a UUID from the p2p server
	const getUuid = useCallback(async () => {
		return await new Promise((resolve) => {
			axios
				.get(
					`http${settings.https ? "s" : ""}://${settings.serverPath}${
						settings.https ? ":443" : ":80"
					}/reserve`
				)
				.then((res) => {
					resolve(res.data.uuid);
				})
				.catch((err) => {
					if (err.response === undefined)
						setStreamerStatus(CONNECTION_STATES.SERVICE_ERROR);
					else if (err.response.status === 404) {
						// alert("No stream available, try again later");
						setStreamerStatus(CONNECTION_STATES.UNAVAILABLE);
					}
					resolve(null);
				});
		});
	}, [settings]);

	const reserveVM = useCallback(async () => {
		busy.current = true;
		let uuid = settings.uuid;

		if (settings.uuid === null) {
			// Fetch a UUID to connect to from p2p server
			uuid = await getUuid();
			console.log("GOT A UUID ", uuid);
			busy.current = false;
		}
		if (uuid === null) {
			setStreamerStatus(CONNECTION_STATES.UNAVAILABLE);
		} else {
			setStreamerStatus(CONNECTION_STATES.RESERVATION_READY);
			setUuid(uuid);
		}
	}, [getUuid, settings.uuid]);

	const connect = useCallback(async () => {
		setStreamerStatus(CONNECTION_STATES.CONNECTING);

		const elementVideo = document.createElement("video");
		elementVideo.id = "Video";
		elementVideo.style.touchAction = "none";
		elementVideo.style.width = "100%";
		elementVideo.style.height = "100%";
		elementVideo.style.objectFit = "cover";
		playerRef.current.appendChild(elementVideo);

		setupVideoPlayer([elementVideo], uuid, settings.https).then((value) => {
			videoRef.current = elementVideo;
			streamerRef.current = value;
			setStreamerStatus(CONNECTION_STATES.CONNECTED);
			settings.onReady();

			busy.current = false;
		});
	}, [uuid, settings, setupVideoPlayer]);

	const videoStream = useCallback(
		(domElement) => {
			if (domElement && playerRef.current !== domElement) {
				playerRef.current = domElement;
				if (!busy.current) reserveVM();
			}
			// else if (playerRef.current!==null) {
			// 		// Cleanup component
			// 		//	=> Disconnect
			// 		//	=> Remove video element
			// 		onDisconnect();
			// 		//	=> Reset state
			// 		setStreamerStatus(CONNECTION_STATES.IDLE);
			// 		busy.current = false;
			// 	}
		},
		[reserveVM]
	);

	const sendMessage = useCallback(
		(msg) => {
			if (streamerRef.current === null)
				return console.warn("Streamer instance not set. Can't send message");
			streamerRef.current.sendCommand(msg);
		},
		[streamerRef]
	);

	const reconnect = useCallback(async () => {
		await reserveVM();
		connect();
	}, [reserveVM, connect]);

	const unmute = useCallback(() => {
		if (videoRef.current) videoRef.current.muted = false;
	}, []);

	return {
		streamerStatus,
		videoStream,
		sendMessage,
		reconnect,
		unmute,
		connect,
	};
};
